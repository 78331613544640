import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from '../../utils/css';
import { useIdWithFallback } from '../../utils/hooks';
import IconErrorRegular from '../Icon/lib/IconErrorRegular';


import styles from './InputError.module.scss';

function InputError({ id = null, className = null, children = null, ariaDescribedby = null, ...otherProps }) {
  const htmlId = useIdWithFallback('dsInputError', id);
  const allClasses = classNames([styles.inputerror, className || null]);

  return (
    <p className={allClasses} id={`${htmlId}Error`} aria-describedby={ariaDescribedby} aria-live="polite" {...otherProps}>
      {children.toString().replace(/ /g, "").length > 0 && (<>
        <IconErrorRegular size="s" />
        {children}
        </>)}
    </p>
  );
}

InputError.propTypes = {
  /** Element's id */
  id: PropTypes.string,
  /** Class names the user can give to the component. */
  className: PropTypes.string,
  /** Content of the error message. Automatically detected. */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Id of the input the error message refers to. */
  ariaDescribedby: PropTypes.string,
};

InputError.defaultProps = {
  id: null,
  className: null,
  children: null,
  ariaDescribedby: null,
};

export default InputError;
