// @flow

import * as R from 'ramda';
import React, { Component, type Element } from 'react';
import type { EntityMetadataT } from '../../../ducks/entities/entityTypes';
import DepartmentTreeCard from './DepartmentTreeCard';
import styles from './DepartmentTreeList.module.scss';

export type DepartmentStateEntityWithPreselectT = {
  selected?: boolean,
  +id: string,
  +alias: 'Department',
  +ownerDepartment?: string,
  +name: string,
  +path: string[],
  +enterpriseId?: string,
  +subDepartments: DepartmentStateEntityWithPreselectT[]
} & EntityMetadataT;

export type PropsT = {
  departments: DepartmentStateEntityWithPreselectT[],
  onRenderedTreeDepthIncreased: (string[]) => *,
  onRenderedTreeDepthDecreased: () => *,
  onPathChange: (string[], ?string) => *,
  onPeopleLinkClick: string => *,
  onEditLinkClick: string => *,
  renderedTreeDepth: number,
  selectedDepartmentId: ?string,
  onCreateDepartment: (*, ?string) => *
};

type StateT = {
  selectedDepartmentId: ?string,
  departments: DepartmentStateEntityWithPreselectT[]
};

export default class DepartmentTreeList extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.rootSelected = this.rootSelected.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.isInPath = this.isInPath.bind(this);
    this.isInSelectionPath = this.isInSelectionPath.bind(this);
    this.state = {
      selectedDepartmentId: props.selectedDepartmentId,
      departments: props.departments
    };
  }

  componentDidMount() {
    if (this.props.departments.length > 0) {
      const department = this.state.departments.find(d => d.id === this.state.selectedDepartmentId);
      this.props.onRenderedTreeDepthIncreased(department && department.path ? department.path : []);
    }
    this.setSelected();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: PropsT) {
    if (nextProps.departments.length > 0 && this.props.departments.length === 0) {
      const department = nextProps.departments.find(d => d.id === this.state.selectedDepartmentId);
      this.props.onRenderedTreeDepthIncreased(department && department.path ? department.path : []);
    }
    if (nextProps.departments !== this.props.departments) {
      this.setState({ departments: nextProps.departments }, this.setSelected);
    }
  }

  componentDidUpdate(prevProps: PropsT, prevState: StateT) {
    if (this.state.selectedDepartmentId !== prevState.selectedDepartmentId) {
      if (this.state.selectedDepartmentId !== null) {
        const department = this.state.departments.find(
          d => d.id === this.state.selectedDepartmentId
        );
        this.props.onPathChange(
          department && department.path ? department.path : [],
          prevState.selectedDepartmentId
        );
      } else {
        this.props.onPathChange(
          this.state.departments.length > 0 ? this.state.departments[0].path.slice(0, -1) : [],
          prevState.selectedDepartmentId
        );
      }
    }
  }

  componentWillUnmount() {
    if (this.props.departments.length > 0) {
      this.props.onRenderedTreeDepthDecreased();
    }
  }

  setSelected: *;

  setSelected() {
    this.setState({
      departments: this.state.departments.map((department, i, arr) => {
        if (department.selected) {
          this.setState({ selectedDepartmentId: department.id });
          delete arr[i].selected; // eslint-disable-line no-param-reassign
        }
        return department;
      })
    });
  }

  handleCardClick: *;

  handleCardClick(id: string) {
    this.setState(state => ({
      selectedDepartmentId: id === state.selectedDepartmentId ? null : id
    }));
  }

  rootSelected: *;

  rootSelected() {
    return this.state.selectedDepartmentId === null;
  }

  isSelected: *;

  isSelected(department: DepartmentStateEntityWithPreselectT) {
    if (!department) return false;
    if (this.state.selectedDepartmentId === department.id) return true;

    let isSiblingSelected = false;
    if (department.subDepartments !== undefined) {
      department.subDepartments.map(dep => {
        if (this.isSelected(dep)) isSiblingSelected = true;
        return true;
      });
    }

    return isSiblingSelected;
  }

  isInPath: *;

  isInPath(department: DepartmentStateEntityWithPreselectT) {
    return department.path && R.indexOf(this.state.selectedDepartmentId, department.path) !== -1;
  }

  isInSelectionPath: *;

  isInSelectionPath(department: DepartmentStateEntityWithPreselectT) {
    return this.isSelected(department) || this.rootSelected() || this.isInPath(department);
  }

  render(): Element<'ul'> {
    const { onPeopleLinkClick, onEditLinkClick, onCreateDepartment } = this.props;
    const { departments } = this.state;

    return (
      <ul className={styles.list}>
        {departments.filter(this.isInSelectionPath).map(department => (
          <li key={department.id}>
            <DepartmentTreeCard
              id={`department-card-${department.id}`}
              onClick={() => this.handleCardClick(department.id)}
              onClickPeopleLink={() => onPeopleLinkClick(department.id)}
              onClickEditLink={() => onEditLinkClick(department.id)}
              department={department}
              selected={this.isSelected(department)}
              onCreateDepartment={onCreateDepartment}
            />
            {this.isSelected(department) && (
              <DepartmentTreeList
                {...this.props}
                departments={[...(department.subDepartments || [])]}
              />
            )}
          </li>
        ))}
      </ul>
    );
  }
}
