/* eslint-disable max-classes-per-file */
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';

import { classNames } from '../../utils/css';
import { useIdWithFallback } from '../../utils/hooks';

import Popover from '../Popover';
import IconInformationRegular from '../Icon/lib/IconInformationRegular';
import IconCheckFilled from '../Icon/lib/IconCheckFilled';

import styles from './CheckboxV2.module.scss';

function CheckboxV2Internal(
  {
    id = null,
    className = null,
    children =  null,
    label = '',
    i18n_checkbox_ariaLabel = null,
    value = null,
    name = '',
    onChange = () => {},
    checked = false,
    disabled = false,
    style = null,
    // inputRef = null,
    ...otherProps
  }, ref
){
  const htmlId = useIdWithFallback('dsCheckboxV2', id);
  const inputRef = useRef(null);
  const [nowChecked, setNowChecked] = useState(checked);
  const [disabledState, setDisabledState] = useState(disabled);

  useImperativeHandle(ref, () => ({
    checked: (check) => {
      if(check === true || check === false){
        setNowChecked(check);
      }
      else {
        return nowChecked;
      }
    },

    disabled: (state) => {
      if(state === true || state === false){
        setDisabledState(state);
      }
      else {
        return disabledState;
      }
    },
  }));

  const onCheckboxChange = (e) => {
    if(e){
      setNowChecked(e.target.checked);
      onChange(e);
    }
    else{
      onChange();
    }
  };

  useEffect(() => {
    const oldValue = nowChecked;
    setNowChecked(checked);

    if(oldValue !== checked) {
      onChange();
    }
  }, [checked]);

  useEffect(() => {
    const oldValue = disabledState;
    setDisabledState(disabled);

    if(oldValue !== disabled) {
      onChange();
    }
  }, [disabled]);

  const allClasses = classNames([styles.checkboxV2, disabledState ? styles[`checkboxV2--disabled`] : null, className, style]);
  const content = label || children;

  return (
    <div id={`${htmlId}Contianer`} key={`${htmlId}Contianer`} className={allClasses} {...otherProps}>
        <input type="checkbox" ref={inputRef} id={htmlId} name={name || htmlId} value={value || ''} onChange={onCheckboxChange} checked={nowChecked} aria-label={label || children ? null : i18n_checkbox_ariaLabel} disabled={disabledState} />
        <label className={styles.checkboxV2__label} htmlFor={htmlId}>
          <IconCheckFilled className={styles.checkboxV2__icon} />
          {content}
        </label>
      </div>
  );
};

const CheckboxV2 = React.forwardRef(CheckboxV2Internal);
export default CheckboxV2;

CheckboxV2.propTypes = {
  /**
   * Element's id
   */
  id: PropTypes.string,
  /**
   * Any CSS classes for the component
   */
  className: PropTypes.string,
  /**
   * Component's content. Automatically detected.
   */
  children: PropTypes.node,
  /**
   * Checkbox's label text
   */
  label: PropTypes.string,
  /**
   * Checkbox's aria-label text. Use this when you don't want to use Checkbox with visible label.
   */
  i18n_checkbox_ariaLabel: PropTypes.string,
  /**
   * Checkbox's value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Checkbox's name
   */
  name: PropTypes.string,
  /**
   * The function run after the checkbox is checked or unchecked
   */
  onChange: PropTypes.func,
  /**
   * Whether the checkbox is checked
   */
  checked: PropTypes.bool,
  /**
   * Whether the checkbox is disabled
   */
  disabled: PropTypes.bool,
  /**
   * LEGACY (use `className` instead) - Any CSS classes for the component
   */
  style: PropTypes.oneOf([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * Ref for the actual input element, not the wrapper
   */
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.element })]),
};
