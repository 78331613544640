// @flow

import React, { type Element, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import queryString from 'querystring';
import axios, { CancelToken } from 'axios';
import HTTP from 'http-status-codes';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoginBase from './LoginBase';
import BackToLoginLink from '../../components/Button/BackToLoginLink';
import ActionButton from '../../components/Button/ActionButton';
import useFormAutoRegister from '../callFlows/callFlowGrid/details/useFormAutoRegister';
import InputField from '../callFlows/components/edit/children/InputField';
import {
  goTo,
  goToCallFlowServices,
  goToEnterpriseUsers,
  goToLandingPage,
  goToLogin
} from '../../navigationOperations';
import {
  operations as currentUserOps,
  selectors as currentUserSelectors
} from '../../ducks/currentUser';
import { getGotoDestination, getLoginConfigs, isUserLanguageValid } from './LoginUtil';
import type { UserConfigT } from '../../ducks/config';
import { getEnterprise } from '../../ducks/entities/enterprise/enterpriseOperations';
import { createSelectEnterpriseAction } from '../../ducks/currentUser/currentUserActions';
import { operations } from '../../ducks/config';
import {
  createOpenChangelogAction,
  createOpenOnboardingAction
} from '../../ducks/ui/header/headerUiActions';
import {
  createCsrfHeader,
  isAcdManager,
  isMultiEnterpriseAdmin,
  isSingleEnterpriseAdmin,
  isSwitchboardUser
} from '../../utils/accessRightUtils';
import { fetchCsrf } from '../../ducks/currentUser/currentUserOperations';
import styles from './LoginBase.module.scss';

type PropsT = {};

type FormT = {
  passphrase: string
};

// eslint-disable-next-line no-unused-vars
export const TwoStepAuthentication = (props: PropsT): Element<typeof LoginBase> => {
  const { t, i18n } = useTranslation();
  const hasSession = useSelector(state => currentUserSelectors.hasSession(state));
  const currentUser = useSelector(state => state.currentUser);
  const [sending, setSending] = useState(false);
  const [sendError, setSendError] = useState(false);
  const location = useLocation();
  const { redirect } = queryString.parse(location.search.slice(1));
  const dispatch = useDispatch();
  const token = location.state ? location.state.token : '';
  const platform = location.state ? location.state.platform : '';
  const username = location.state ? location.state.username : '';
  const password = location.state ? location.state.password : '';
  const isOtp = !!platform;
  const initialFormValues: FormT = {
    passphrase: ''
  };

  // form
  const methods = useFormAutoRegister(R.keys(initialFormValues), {
    defaultValues: initialFormValues
  });

  const forwardUserIfLoggedIn = () => {
    const getEnterpriseFromCurrentUser = () =>
      currentUser.enterprises && currentUser.enterprises[0] ? currentUser.enterprises[0] : null;

    if (hasSession) {
      if (redirect) {
        dispatch(goTo(redirect));
      } else if (isSingleEnterpriseAdmin(currentUser) || isSwitchboardUser(currentUser)) {
        const enterprise = getEnterpriseFromCurrentUser();
        if (enterprise) {
          dispatch(goToEnterpriseUsers(enterprise.id));
        }
      } else if (isMultiEnterpriseAdmin(currentUser)) {
        dispatch(goToLandingPage());
      } else if (isAcdManager(currentUser)) {
        const enterprise = getEnterpriseFromCurrentUser();
        if (enterprise) {
          dispatch(goToCallFlowServices(enterprise.id));
        }
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      forwardUserIfLoggedIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleSubmit = async formData => {
    setSending(true);
    try {
      setSendError(false);
      let response;
      if (isOtp) {
        response = await axios({
          method: 'POST',
          url: '/api/v1/otpauth/validate',
          data: {
            id: token,
            otpPassword: formData.passphrase
          },
          validateStatus: status => status === HTTP.OK
        });
      } else {
        response = await axios({
          method: 'POST',
          url: '/api/v1/2stepauth/validate',
          data: {
            username,
            password,
            token,
            twoStepAuthPassword: formData.passphrase
          },
          validateStatus: status => status === HTTP.OK
        });
      }

      if (response.data) {
        let returnedUser;
        if (isOtp) {
          returnedUser = await dispatch(
            currentUserOps.login(`${platform}${token}:${username}`, password)
          );
        } else {
          returnedUser = await dispatch(currentUserOps.login(username, password));
        }
        if (currentUser.status === 'generic_login_error') {
          return;
        }

        await dispatch(fetchCsrf());
        const destination = getGotoDestination(returnedUser, username, password);
        if (destination) {
          dispatch(goTo(destination));
          return;
        }

        const userConfig: UserConfigT = await dispatch(operations.getUserConfig());
        const bulletins = await dispatch(operations.getBulletins());
        const { modifiedUserConfig, shouldOpenOnboarding, shouldOpenChangelog } = getLoginConfigs(
          currentUser.environment,
          userConfig,
          bulletins
        );
        if (modifiedUserConfig) {
          await dispatch(
            operations.updateUserConfig(modifiedUserConfig, createCsrfHeader(currentUser))
          );
        }
        if (shouldOpenOnboarding) {
          dispatch(createOpenOnboardingAction());
        } else if (shouldOpenChangelog) {
          dispatch(createOpenChangelogAction());
        }

        if (!returnedUser.multiEnterpriseAdmin) {
          const enterprise =
            returnedUser.enterprises && returnedUser.enterprises[0]
              ? await dispatch(
                  getEnterprise(returnedUser.enterprises[0].id, CancelToken.source().token)
                )
              : null;
          if (enterprise) {
            dispatch(createSelectEnterpriseAction(enterprise.entID, enterprise.fullName));
          }
        }
        if (returnedUser.appLanguage && isUserLanguageValid(returnedUser.appLanguage)) {
          i18n.changeLanguage(returnedUser.appLanguage);
        }
      }
      setSending(false);
    } catch (error) {
      if (error.response && error.response.status === HTTP.NOT_FOUND) {
        dispatch(goToLogin());
      }
      if (error.response && error.response.status === HTTP.UNPROCESSABLE_ENTITY) {
        setSendError(true);
      }
      setSending(false);
    }
  };

  return (
    <LoginBase>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <div>
            <h3 className="ea-h3 ea-pad ea-pad--pad-bottom-1" id="login-page-title">
              {t('login.twoStepAuthentication.title')}
            </h3>
            <p>{t('login.twoStepAuthentication.description')}</p>
            <div className={`${styles.input} ea-input`}>
              <InputField
                field="passphrase"
                placeholder={t('login.twoStepAuthentication.inputPlaceholder')}
                maxLength={isOtp ? 8 : 5}
              />
            </div>
            <div className="ea-pad ea-pad--pad-top-1">
              <ActionButton
                id="confirm-2stepauth-button"
                label={t('login.twoStepAuthentication.confirmButton')}
                type="submit"
                loading={sending}
              />
            </div>
            {sendError && <p role="status">{t('login.twoStepAuthentication.error')}</p>}
            <div className={styles['login-link-container']}>
              <BackToLoginLink />
            </div>
          </div>
        </form>
      </FormProvider>
    </LoginBase>
  );
};

export default TwoStepAuthentication;
