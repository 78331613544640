// @flow strict-local

import * as R from 'ramda';
import React, { type Element, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { ReactComponent as CallbackIcon } from '../../../../../assets/callflow/details/callback-small.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import { update } from '../../../../../ducks/entities/acd/acdOperations';
import IntegerField from '../../../components/edit/children/IntegerField';
import { uploadAudios } from '../../../components/edit/children/audio/CallflowAudioUtils';
import { createIvrPatchPayload } from '../../../components/edit/CallflowPropertyUtils';
import type {
  AudioFieldT,
  PropertyAudioT
} from '../../../components/edit/children/audio/CallflowAudioUtils';
import RadioButton from '../../../components/edit/children/RadioButton';
import { getIntegerFieldLimits } from '../../../../../utils/validationUtils';
import AcdAudioField from '../../../components/edit/children/audio/AcdAudioField';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import {
  convertCallbackMethodToBooleanProperties,
  getInitialCallbackOption
} from './CallbackUtils';
import type { CallBackOptionsT } from './CallbackUtils';
import { convertSecondsToMinutes } from '../../../../../utils/timeutils';
import ToggleField from '../../../components/edit/children/ToggleField';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';
import styles from './EditCallbackDetails.module.scss';

type PropsT = {|
  callflowId: string
|};

type FormT = {
  callbackMethod: CallBackOptionsT,
  callbackWhenNoOperator: boolean,
  callbackWhenMaxTimeInQueue: boolean,
  callbackKeepTimeout: number,
  callbackRingingTimeout: number,
  callbackMaxAttempts: number,
  callbackFeedbackDuration: number,
  callbackReinjectionPauseDuration: number,
  callbackSendSmsOnRegister: boolean,
  callbackSendSmsOnSuccess: boolean,
  callbackSendSmsOnError: boolean,
  callbackAnnouncement: AudioFieldT<PropertyAudioT>,
  callbackAnnouncementBeforeExiting: AudioFieldT<PropertyAudioT>,
  callbackAlreadyInQueue: AudioFieldT<PropertyAudioT>,
  callbackRegistered: AudioFieldT<PropertyAudioT>,
  callbackWaitAnswer: AudioFieldT<PropertyAudioT>
};

let requestCancelTokenSource: CancelTokenSource;
let audioRequestCancelTokenSource: CancelTokenSource;

// $FlowFixMe: TODO: fix
export const acdCallbackSchema = yup.object().shape({
  callbackMethod: yup.string().required(),
  callbackWhenNoOperator: yup.boolean(),
  callbackWhenMaxTimeInQueue: yup.boolean(),
  callbackKeepTimeout: yup
    .number()
    .integer()
    .min(1)
    .max(200)
    .default(72)
    .required(),
  callbackRingingTimeout: yup
    .number()
    .integer()
    .min(10)
    .max(115)
    .default(30)
    .required(),
  callbackMaxAttempts: yup
    .number()
    .integer()
    .min(1)
    .max(10)
    .default(3)
    .required(),
  callbackFeedbackDuration: yup
    .number()
    .integer()
    .min(5)
    .max(30)
    .default(10)
    .required(),
  callbackReinjectionPauseDuration: yup
    .number()
    .integer()
    .min(60)
    .max(1200)
    .default(600)
    .required(),
  callbackSendSmsOnRegister: yup.boolean().required(),
  callbackSendSmsOnSuccess: yup.boolean().required(),
  callbackSendSmsOnError: yup.boolean().required()
});

export const EditCallbackDetails = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const ivrAcdCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};
  const acdAudioConverter = new AudioFieldConverter(acdData.enterpriseId, acdData.id, 'acds');
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const initialIvrProperties = {
    callbackKeepTimeout: acdCallCenter.callbackKeepTimeout,
    callbackWhenNoOperator: acdCallCenter.callbackWhenNoOperator,
    callbackWhenMaxTimeInQueue: acdCallCenter.callbackWhenMaxTimeInQueue,
    callbackRingingTimeout: acdCallCenter.callbackRingingTimeout,
    callbackMaxAttempts: acdCallCenter.callbackMaxAttempts,
    callbackFeedbackDuration: acdCallCenter.callbackFeedbackDuration,
    callbackReinjectionPauseDuration: acdCallCenter.callbackReinjectionPauseDuration,
    callbackSendSmsOnRegister: acdCallCenter.callbackSendSmsOnRegister,
    callbackSendSmsOnSuccess: acdCallCenter.callbackSendSmsOnSuccess,
    callbackSendSmsOnError: acdCallCenter.callbackSendSmsOnError,
    callbackAnnouncement: acdAudioConverter.convertToAudioPropertyField(
      'CallbackAnnouncement',
      R.path(['audios', 'callbackAnnouncement', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.callbackAnnouncement
    ),
    callbackAnnouncementBeforeExiting: acdAudioConverter.convertToAudioPropertyField(
      'CallbackAnnouncementBeforeExiting',
      R.path(
        ['audios', 'callbackAnnouncementBeforeExiting', 'extensionAudio', 'name'],
        acdCallCenter
      ),
      ivrAcdCallCenter.callbackAnnouncementBeforeExiting
    ),
    callbackAlreadyInQueue: acdAudioConverter.convertToAudioPropertyField(
      'CallbackAlreadyInQueue',
      R.path(['audios', 'callbackAlreadyInQueue', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.callbackAlreadyInQueue
    ),
    callbackRegistered: acdAudioConverter.convertToAudioPropertyField(
      'CallbackRegistered',
      R.path(['audios', 'callbackRegistered', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.callbackRegistered
    ),
    callbackWaitAnswer: acdAudioConverter.convertToAudioPropertyField(
      'CallbackWaitAnswer',
      R.path(['audios', 'callbackWaitAnswer', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.callbackWaitAnswer
    )
  };
  const initialFormValues: FormT = {
    callbackMethod: getInitialCallbackOption(acdData),
    ...initialIvrProperties
  };

  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    audioRequestCancelTokenSource = CancelToken.source();
    return () => {
      requestCancelTokenSource.cancel();
      audioRequestCancelTokenSource.cancel();
    };
  }, []);

  const callbackMethodOptions = [
    {
      label: t('callflows.editAcdCallbackDetails.callbackDisabled'),
      value: 'disabled'
    },
    {
      label: t('callflows.editAcdCallbackDetails.proposeCallbackRegularly'),
      value: 'proposeCallbackRegularly',
      children: (
        <div className={styles['inner-radio']}>
          <ToggleField
            field="callbackWhenNoOperator"
            elementId="callbackWhenNoOperator"
            label={t('callflows.editAcdCallbackDetails.callbackWhenNoOperator')}
            tooltip={t('callflows.editAcdCallbackDetails.callbackWhenNoOperatorTooltip')}
          />
          <ToggleField
            field="callbackWhenMaxTimeInQueue"
            elementId="callbackWhenMaxTimeInQueue"
            label={t('callflows.editAcdCallbackDetails.callbackWhenMaxTimeInQueue', {
              maxTime: convertSecondsToMinutes(R.path(['maxDurationInQueue'], acdCallCenter))
            })}
          />
        </div>
      )
    },
    {
      label: t('callflows.editAcdCallbackDetails.whenQueueFullOrTimeout'),
      value: 'whenQueueFullOrTimeout',
      children: (
        <div className={styles['inner-radio']}>
          <ToggleField
            field="callbackWhenNoOperator"
            label={t('callflows.editAcdCallbackDetails.callbackWhenNoOperator')}
          />
        </div>
      )
    },
    {
      label: t('callflows.editAcdCallbackDetails.callbackSystematic'),
      value: 'always'
    }
  ];

  // update
  const onSubmit = async (formData: FormT): Promise<AcdEntityT> => {
    const callbackMethodProperties = convertCallbackMethodToBooleanProperties(
      formData.callbackMethod,
      formData.callbackWhenNoOperator,
      formData.callbackWhenMaxTimeInQueue
    );

    const acdCallbackEnabled = callbackMethodProperties.acdGroupCallbackEnabled;
    const patchPayload = createIvrPatchPayload(
      {
        acdGroupCallbackEnabled: acdCallbackEnabled ? acdCallbackEnabled.value : false
      },
      formData,
      Object.keys(initialIvrProperties),
      callbackMethodProperties.properties
    );

    const failedAudioUploads = await uploadAudios(
      formData,
      audioRequestCancelTokenSource,
      createCsrfHeader(currentUser)
    );
    if (failedAudioUploads.length > 0) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        t('callflows.audioUploadFailed', { audioFileNames: failedAudioUploads.join(',') })
      );
    }

    return dispatch(
      update(
        acdData.enterpriseId,
        acdData.type,
        acdData.id,
        patchPayload,
        requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={acdData.id}
      icon={<CallbackIcon />}
      title={t('callflows.editAcdCallbackDetails.title')}
      description={parse(
        `${t('callflows.editAcdCallbackDetails.description1')}<br>${t(
          'callflows.editAcdCallbackDetails.description2'
        )}`
      )}
      defaultValues={initialFormValues}
      validationSchema={acdCallbackSchema}
      onSaveForm={onSubmit}
    >
      <RadioButton field="callbackMethod" options={callbackMethodOptions} />
      <IntegerField
        field="callbackKeepTimeout"
        title={t('callflows.editAcdCallbackDetails.callbackKeepTimeout')}
        inputDescription={t('callflows.editAcdCallbackDetails.callbackKeepTimeoutInputDescription')}
        postFixDescription={t('callflows.editAcdCallbackDetails.callbackKeepTimeoutPostFix')}
        tooltip={t(
          'callflows.viewAcdCallbackDetails.callbackKeepTimeoutTooltip',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdCallbackSchema.fields.callbackKeepTimeout)
        )}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdCallbackSchema.fields.callbackKeepTimeout)
        )}
      />

      <IntegerField
        field="callbackRingingTimeout"
        title={t('callflows.editAcdCallbackDetails.callbackRingingTimeout')}
        inputDescription={t(
          'callflows.editAcdCallbackDetails.callbackRingingTimeoutInputDescription'
        )}
        tooltip={t(
          'callflows.viewAcdCallbackDetails.callbackRingingTimeoutTooltip',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdCallbackSchema.fields.callbackRingingTimeout)
        )}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdCallbackSchema.fields.callbackRingingTimeout)
        )}
      />
      <IntegerField
        field="callbackMaxAttempts"
        maxLength={2}
        title={t('callflows.editAcdCallbackDetails.callbackMaxAttempts')}
        inputDescription={t('callflows.editAcdCallbackDetails.callbackMaxAttemptsInputDescription')}
        postFixDescription=""
        tooltip={t(
          'callflows.viewAcdCallbackDetails.callbackMaxAttemptsTooltip',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdCallbackSchema.fields.callbackMaxAttempts)
        )}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdCallbackSchema.fields.callbackMaxAttempts)
        )}
      />
      <IntegerField
        field="callbackFeedbackDuration"
        maxLength={2}
        title={t('callflows.editAcdCallbackDetails.callbackFeedbackDuration')}
        inputDescription={t(
          'callflows.editAcdCallbackDetails.callbackFeedbackDurationInputDescription'
        )}
        tooltip={t(
          'callflows.viewAcdCallbackDetails.callbackFeedbackDurationTooltip',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdCallbackSchema.fields.callbackFeedbackDuration)
        )}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdCallbackSchema.fields.callbackFeedbackDuration)
        )}
      />
      <IntegerField
        field="callbackReinjectionPauseDuration"
        maxLength={4}
        title={t('callflows.editAcdCallbackDetails.callbackReinjectionPauseDuration')}
        inputDescription={t(
          'callflows.editAcdCallbackDetails.callbackReinjectionPauseDurationInputDescription'
        )}
        tooltip={t(
          'callflows.viewAcdCallbackDetails.callbackReinjectionPauseDurationTooltip',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdCallbackSchema.fields.callbackReinjectionPauseDuration)
        )}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdCallbackSchema.fields.callbackReinjectionPauseDuration)
        )}
      />
      <AcdAudioField
        field="callbackAnnouncement"
        title={t('callflows.editAcdCallbackDetails.callbackAnnouncement')}
      />

      <AcdAudioField
        field="callbackAnnouncementBeforeExiting"
        title={t('callflows.editAcdCallbackDetails.callbackAnnouncementBeforeExiting')}
      />

      <AcdAudioField
        field="callbackAlreadyInQueue"
        title={t('callflows.editAcdCallbackDetails.callbackAlreadyInQueue')}
      />

      <AcdAudioField
        field="callbackRegistered"
        title={t('callflows.editAcdCallbackDetails.callbackRegistered')}
      />

      <AcdAudioField
        field="callbackWaitAnswer"
        title={t('callflows.editAcdCallbackDetails.callbackWaitAnswer')}
      />

      <ToggleField
        field="callbackSendSmsOnRegister"
        elementId="callbackSendSmsOnRegister"
        label={t('callflows.editAcdCallbackDetails.callbackSendSmsOnRegister')}
      />
      <ToggleField
        field="callbackSendSmsOnSuccess"
        elementId="callbackSendSmsOnSuccess"
        label={t('callflows.editAcdCallbackDetails.callbackSendSmsOnSuccess')}
      />
      <ToggleField
        field="callbackSendSmsOnError"
        elementId="callbackSendSmsOnError"
        label={t('callflows.editAcdCallbackDetails.callbackSendSmsOnError')}
      />
    </EditCallflowDetails>
  );
};

export default EditCallbackDetails;
