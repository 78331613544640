import React, { Component } from 'react';

import styles from '../Login.module.scss';

class ErrorMessage extends Component {
  constructor(props) {
    super(props);
  }

  errorLinkClick(e) {
    e.preventDefault();
    if (this.props.errorLink) {
      window.location.href = this.props.errorLink.url;
    }
  }

  render() {
    const { id, errorMessageText, errorLink } = this.props;

    return (
      <>
        {errorMessageText ? (
          <p id={id ? `${id}ErrorMessage` : null} className={styles.login__error} role="alert">
            {errorMessageText}
            {errorLink && (
              <>
                <br />
                <a href={errorLink.url} onClick={this.errorLinkClick.bind(this)}>
                  {errorLink.text}
                </a>
              </>
            )}
          </p>
        ) : (
          <p />
        )}
      </>
    );
  }
}

export default ErrorMessage;
