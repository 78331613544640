// @flow

import React, { useEffect, useRef } from 'react';
import Header from '@design-system/component-library/src/components/Header';
import HeaderNavigationSection from '@design-system/component-library/src/components/Header/subcomponents/HeaderNavigationSection';
import HeaderLogoAndService from '@design-system/component-library/src/components/Header/subcomponents/HeaderLogoAndService';
import HeaderNavigation from '@design-system/component-library/src/components/Header/subcomponents/HeaderNavigation';
import HeaderUserMenu from '@design-system/component-library/src/components/Header/subcomponents/HeaderUserMenu';
import HeaderHamburger from '@design-system/component-library/src/components/Header/subcomponents/HeaderHamburger';
import HeaderModels from '@design-system/component-library/src/components/Header/models';
import HeaderNavigationButton from '@design-system/component-library/src/components/Header/subcomponents/HeaderNavigationButton';
import IconMessageRegular from '@design-system/component-library/src/components/Icon/lib/IconMessageRegular';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import IconSearchRegular from '@design-system/component-library/src/components/Icon/lib/IconSearchRegular';
import IconStarRegular from '@design-system/component-library/src/components/Icon/lib/IconStarRegular';
import IconExternalLinkRegular from '@design-system/component-library/src/components/Icon/lib/IconExternalLinkRegular';
import {
  createCsrfHeader,
  isAcdManager,
  isAdmin,
  isSwitchboardUser
} from '../../utils/accessRightUtils';
import { isBrowserRunningE2eTests, isDev, isLocalhost, isProd, isTest } from '../../helpers';
import { ReactComponent as EnterpriseIconSmall } from '../../assets/enterprise-icon-small.svg';
import { updateConfig } from '../../ducks/currentUser/currentUserOperations';
import {
  createOpenChangelogAction,
  createOpenFeedbackAction,
  createOpenOnboardingAction
} from '../../ducks/ui/header/headerUiActions';
import { toTitleCase } from '../../utils/textUtils';
import { operations as currentUserOps } from '../../ducks/currentUser';
import { goToLogin } from '../../navigationOperations';
import { pushAnalyticsEvent } from '../../utils/analyticsHandler';
import { SHOW_PAGE_INTRODUCTION } from '../../matomo_constants';
import { operations } from '../../ducks/config';
import {
  deserializeIntroductionConfigValue,
  serializeIntroductionConfigValue
} from '../../userConfigHelpers';

import styles from './TopNavigation.module.scss';

export type PropsT = {|
  onClickTutorial?: ?() => *,
  viewName?: string,
  hideNavigation?: boolean
|};
export const TopNavigation = (props: PropsT) => {
  const { onClickTutorial, viewName, hideNavigation } = props;
  const { t, i18n } = useTranslation();
  const languages = Object.keys(i18n.services.resourceStore.data).filter(
    language => language !== 'aa'
  );
  const currentUser = useSelector(state => state.currentUser);
  const currentUserEnterpriseId = R.path(['currentEnterprise', 'id'], currentUser);
  const dispatch = useDispatch();
  const userPath = /users/;
  const forwardingsPath = /forwardings/;
  const callFlowPath = /callflows/;
  const historyPath = /historyactions/;
  const adminUiPath = /adminui/;
  const enterprisesPath = /enterprises\//;
  const hasAdminAccess = isAdmin(currentUser);
  const hasSwitchboardUserRights = isSwitchboardUser(currentUser);
  const isOlfAdmin = hasAdminAccess && !currentUser.hasWebAdminAccess;
  const getEnvironmentNumberWithSlash = () => {
    // Default environment would be mob3. However, the environment parameter should always be present
    const env = R.path(['environment'], currentUser) || 'mob3';

    return parseInt(env.slice(-1), 10) === 1 ? '/' : `${env.slice(-1)}/`;
  };
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const myReportsLink = `https://oma.elisa.fi/ring/${getEnvironmentNumberWithSlash()}raportointi/`;
  const omaElisaOEC = 'https://oma.elisa.fi/yrityksille/muutos-ja-poisto';
  const omaElisaOLF = 'https://yrityksille.elisa.fi/omaelisa/tuotteeni-ja-palveluni/';
  const omaElisaLink = currentUser.hasWebAdminAccess ? omaElisaOEC : omaElisaOLF;
  const webAdminLink =
    isProd() || isTest()
      ? `https://oma.elisa.fi/ring/${getEnvironmentNumberWithSlash()}webadmin/`
      : `https://pet3trunk.test.oma.elisa.fi/ring/${getEnvironmentNumberWithSlash()}webadmin/`;
  const enterpriseIsSelected =
    currentUserEnterpriseId !== undefined && currentUserEnterpriseId !== null;
  const onboardingOpen: boolean = useSelector(state => state.ui.header.onboardingOpen);
  const changelogOpen: boolean = useSelector(state => state.ui.header.changelogOpen);
  const enterpriseName = enterpriseIsSelected
    ? R.path(['currentEnterprise', 'fullName'], currentUser)
    : undefined;
  const MENU_ID = 'ds-mobile-menu';
  const REPORTS_URLS = {
    mob1: 'https://cprx9.mob1.elisa.fi/myreports/reports/#/reports/ACD',
    mob2: 'https://cprx9.mob2.elisa.fi/myreports/reports/#/reports/ACD',
    mob3: 'https://cprx9.mob3.elisa.fi/myreports/reports/#/reports/ACD'
  };
  const userConfig = useSelector(state => state.config.userConfig);
  const SHOW_PAGE_INTRODUCTION_CONFIG = 'show_page_introduction_v1';
  const WAIT_FOR_INTRODUCTION_MS = 1000;

  const updateIntroductionShownConfig = (userCfg, config) => {
    dispatch(
      operations.updateUserConfig(
        [
          ...R.reject(item => 'key' in item && item.key === SHOW_PAGE_INTRODUCTION_CONFIG, userCfg),
          {
            key: SHOW_PAGE_INTRODUCTION_CONFIG,
            // $FlowFixMe: no null check needed
            value: serializeIntroductionConfigValue([...config, viewName])
          }
        ],
        {
          'X-CSRF-TOKEN': currentUser.csrf
        }
      )
    );
  };

  const openIntroduction = async userCfg => {
    const foundIntroductionConfig = userCfg
      ? deserializeIntroductionConfigValue(
          userCfg.find(({ key }) => key === SHOW_PAGE_INTRODUCTION_CONFIG)
        )
      : null;
    if (!onboardingOpen && !changelogOpen) {
      await delay(WAIT_FOR_INTRODUCTION_MS);
      if (foundIntroductionConfig) {
        if (onClickTutorial && !foundIntroductionConfig.includes(viewName)) {
          updateIntroductionShownConfig(userCfg, foundIntroductionConfig);
          onClickTutorial();
        }
      } else if (userCfg && onClickTutorial) {
        updateIntroductionShownConfig(userCfg, []);
        onClickTutorial();
      }
    }
  };

  useEffect(() => {
    const fetchIntroductionConfig = async () => {
      let userCfg = userConfig;
      if (userCfg.length === 0) {
        userCfg = await dispatch(operations.getUserConfig());
      }
      openIntroduction(userCfg);
    };
    if (!isBrowserRunningE2eTests()) {
      fetchIntroductionConfig();
    }
    return () => {};
  }, [viewName, onboardingOpen, changelogOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectToMyReports = (platform: string) => {
    if (platform && platform in REPORTS_URLS) {
      return REPORTS_URLS[platform];
    }
    console.error(
      `Unknown platform key: ${platform}. Supported keys: ${Object.keys(REPORTS_URLS).join(',')}`
    );

    return '';
  };

  const getCurrentLanguageInContentHelpFormat = () => {
    const languageList = {
      sv: 'se',
      fi: 'fi',
      en: 'en'
    };
    return currentUser.appLanguage ? languageList[currentUser.appLanguage] || 'fi' : 'fi';
  };
  const createHelpUrl = ({ page, app, lang, platform }) =>
    `https://ringch.elisa.fi/api/v1/ringHelpRedirect/${page}?lang=${lang}&app=${app}&cluster=${platform}`;
  const ringUiInstructionsUrl = createHelpUrl({
    page: 'RingHelp_Home',
    app: 'Root',
    lang: getCurrentLanguageInContentHelpFormat(),
    platform: toTitleCase(currentUser.environment)
  });

  const omaRingInstructionsUrl = createHelpUrl({
    page: 'OmaRing_Home',
    app: 'OmaRing',
    lang: getCurrentLanguageInContentHelpFormat(),
    platform: toTitleCase(currentUser.environment)
  });

  const hamburgerRef = useRef();
  const loggedInItemList = [];
  const externalMenus = {
    sites: []
  };

  if (!hideNavigation) {
    if ((hasAdminAccess || hasSwitchboardUserRights) && currentUser.currentEnterprise) {
      loggedInItemList.push(
        HeaderModels.MenuItem({
          ...(userPath.test(window.location.pathname) && { current: 'true' }),
          tag: 'users',
          title: t('menu.peoplePage'),
          url: `/enterprises/${currentUserEnterpriseId || ''}/users`
        })
      );
    }

    if (
      (hasAdminAccess || hasSwitchboardUserRights) &&
      !(currentUser.featureFlags || []).includes('FEATURE-HIDE-SECTION-FORWARDINGS') &&
      currentUser.currentEnterprise
    ) {
      loggedInItemList.push(
        HeaderModels.MenuItem({
          ...(forwardingsPath.test(window.location.pathname) && { current: 'true' }),
          tag: 'forwardings',
          title: t('menu.forwardingsPage'),
          url: `/enterprises/${currentUserEnterpriseId || ''}/forwardings`
        })
      );
    }

    if (
      (hasAdminAccess || isAcdManager(currentUser)) &&
      !(currentUser.featureFlags || []).includes('FEATURE-HIDE-SECTION-CALLFLOWS') &&
      currentUser.currentEnterprise
    ) {
      loggedInItemList.push(
        HeaderModels.MenuItem({
          ...(callFlowPath.test(window.location.pathname) && { current: 'true' }),
          tag: 'callflows',
          title: t('menu.callFlowPage'),
          url: `/enterprises/${currentUserEnterpriseId || ''}/callflows`
        })
      );
    }

    if ((hasAdminAccess || isSwitchboardUser(currentUser)) && currentUser.currentEnterprise) {
      loggedInItemList.push(
        HeaderModels.MenuItem({
          ...(!userPath.test(window.location.pathname) &&
            !forwardingsPath.test(window.location.pathname) &&
            !callFlowPath.test(window.location.pathname) &&
            !historyPath.test(window.location.pathname) &&
            !adminUiPath.test(window.location.pathname) &&
            enterprisesPath.test(window.location.pathname) && { current: 'true' }),
          tag: 'enterpriseSettings',
          title: t('menu.enterpriseSettings'),
          url: `/enterprises/${currentUserEnterpriseId || ''}`
        })
      );
    }

    if (
      hasAdminAccess &&
      !(currentUser.featureFlags || []).includes('FEATURE-HIDE-SECTION-ACTIVITYLOG') &&
      currentUser.currentEnterprise
    ) {
      loggedInItemList.push(
        HeaderModels.MenuItem({
          ...(historyPath.test(window.location.pathname) && { current: 'true' }),
          tag: 'historyPage',
          title: t('menu.historyPage'),
          url: `/enterprises/${currentUserEnterpriseId || ''}/historyactions`
        })
      );
    }

    if (
      !currentUser.featureFlags?.includes('FEATURE-HIDE-SECTION-RAPI-ADMIN-UI') &&
      currentUser.multiEnterpriseAdmin &&
      (currentUser.loginType === 'BEHALF_OF_ELISA_AD' || isDev() || isLocalhost())
    ) {
      loggedInItemList.push(
        HeaderModels.MenuItem({
          tag: 'adminUI',
          ...(adminUiPath.test(window.location.pathname) && { current: 'true' }),
          title: t('menu.adminUI'),
          url: '/adminui'
        })
      );
    }

    if (
      isOlfAdmin &&
      !(currentUser.featureFlags || []).includes('FEATURE-HIDE-SECTION-MYREPORTS')
    ) {
      externalMenus.sites.push({
        title: t('menu.reportsPage'),
        url: redirectToMyReports(currentUser.environment)
      });
    }

    if (
      hasAdminAccess &&
      !(currentUser.featureFlags || []).includes('FEATURE-HIDE-SECTION-MYREPORTS-LINK')
    ) {
      externalMenus.sites.push({
        title: t('menu.reportsPage'),
        url: myReportsLink
      });
    }

    if (
      hasAdminAccess &&
      !(currentUser.featureFlags || []).includes('FEATURE-HIDE-SECTION-OMAELISA-LINK')
    ) {
      externalMenus.sites.push({
        title: t('menu.omaElisaPage'),
        url: omaElisaLink
      });
    }

    if (
      hasAdminAccess &&
      !isOlfAdmin &&
      !(currentUser.featureFlags || []).includes('FEATURE-HIDE-SECTION-WEBADMIN-LINK')
    ) {
      externalMenus.sites.push({
        title: t('menu.webadminPage'),
        url: webAdminLink
      });
    }
  }

  const userMenus = [
    {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(createOpenFeedbackAction());
      },
      icon: <IconMessageRegular />,
      tag: 'feedback',
      title: t('generic.feedbackButtonLabel'),
      url: ''
    },
    {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        pushAnalyticsEvent(SHOW_PAGE_INTRODUCTION);
        if (onClickTutorial) {
          onClickTutorial();
        }
      },
      icon: <IconSearchRegular />,
      tag: 'tutorial',
      title: t('helpDropdown.tutorial'),
      url: ''
    },
    {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(createOpenOnboardingAction());
      },
      icon: <IconStarRegular />,
      tag: 'onboarding',
      title: t('helpDropdown.onboarding'),
      url: ''
    },
    {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(createOpenChangelogAction());
      },
      icon: <IconStarRegular />,
      tag: 'changelog',
      title: t('helpDropdown.changelog'),
      url: ''
    },
    {
      icon: <IconExternalLinkRegular />,
      tag: 'instructions',
      title: t('helpDropdown.instructions'),
      url: 'https://yrityksille.elisa.fi/ohjeet/ring',
      target: '_blank'
    }
  ];

  if (!(currentUser.featureFlags || []).includes('FEATURE-HIDE-UI-INSTRUCTIONS-LINK')) {
    userMenus.push({
      icon: <IconExternalLinkRegular />,
      tag: 'ringUiInstructions',
      title: t('helpDropdown.ringUiInstructions'),
      url: ringUiInstructionsUrl,
      target: '_blank'
    });
  }

  if (!(currentUser.featureFlags || []).includes('FEATURE-HIDE-OMARING-INSTRUCTIONS-LINK')) {
    userMenus.push({
      icon: <IconExternalLinkRegular />,
      tag: 'omaRingInstructions',
      title: t('helpDropdown.omaRingInstructions'),
      url: omaRingInstructionsUrl,
      target: '_blank'
    });
  }

  const userMenuLinks = [
    HeaderModels.UserMenuLinkCategory({
      items: userMenus,
      onToggle: () => {},
      order: 1,
      tag: 'instructions',
      title: '',
      toggled: true
    })
  ];

  const availableLanguages = languages.map(language =>
    HeaderModels.Language({
      code: language,
      title: t(`languageSelector.languages.${language}`)
    })
  );

  const languageSelect = {
    languages: availableLanguages.map(lang => ({
      abbr: lang.code,
      name: lang.title
    })),
    onLanguageChange: language => {
      const languageCode = language.getAttribute('data-value');
      dispatch(updateConfig({ appLanguage: languageCode }, createCsrfHeader(currentUser)));
      i18n.changeLanguage(languageCode);
    },
    selectedLanguage: i18n.language ? i18n.language : 'fi'
  };

  const logo = {
    label: 'Elisa',
    onClick: () => {},
    url: '',
    target: '_self'
  };
  const service = {
    identifiers: ['OmaRing'],
    label: '',
    onClick: () => {},
    url: '',
    target: '_self'
  };
  const logout = HeaderModels.Logout({
    onClick: async () => {
      await dispatch(currentUserOps.logout(createCsrfHeader(currentUser)));
      dispatch(goToLogin());
    },
    title: t('generic.logoutButtonLabel')
  });

  return (
    <Header
      id="top-navigation"
      desktopBreakpointOffset={400}
      isLoggedIn
      siteSelect={externalMenus}
      {...(isAdmin(currentUser) ? { languageSelect } : null)}
    >
      <HeaderNavigationSection>
        <HeaderLogoAndService logo={logo} service={service} />
      </HeaderNavigationSection>
      <HeaderNavigationSection>
        <HeaderNavigation
          id={MENU_ID}
          returnFocusToRef={hamburgerRef}
          navigationItemList={loggedInItemList}
          siteSelect={externalMenus}
          {...(isAdmin(currentUser) ? { languageSelect } : null)}
        />
      </HeaderNavigationSection>
      <HeaderNavigationSection>
        {!hideNavigation && (
          <HeaderNavigationButton
            icon={<EnterpriseIconSmall />}
            className={styles['enterprise-name']}
            href={
              currentUser.multiEnterpriseAdmin
                ? '/enterprises'
                : `/enterprises/${currentUser.enterprises ? currentUser.enterprises[0].id : ''}`
            }
            i18nLabel={enterpriseIsSelected ? enterpriseName : t('menu.chooseEnterprise')}
          >
            <span id="enterpriseName">
              {enterpriseIsSelected ? enterpriseName : t('menu.chooseEnterprise')}
            </span>
            <span />
          </HeaderNavigationButton>
        )}
        <HeaderUserMenu
          user={{
            fullName: currentUser.fullName || '',
            name: currentUser.login || '',
            email: currentUser.email || currentUser.emails || ''
          }}
          userMenuLinkAccordions={userMenuLinks}
          logout={logout}
          i18nButtonLabel=""
          i18nLogin=""
          userPronounText=""
        />
        {!hideNavigation && (
          <HeaderHamburger
            i18nCloseAlt="close"
            i18nOpenAlt="menu"
            ref={hamburgerRef}
            toggledModalChild={MENU_ID}
          />
        )}
      </HeaderNavigationSection>
    </Header>
  );
};

export default TopNavigation;
