import React from 'react';
import PropTypes from 'prop-types';

import Logo from '../../../Logo';
import { classNames } from '../../../../utils/css';
import styles from './Logo.module.scss';

function FooterLogo({ linkHref, ariaLabelText, imageSrc, className }) {
  return <a className={classNames([styles.footer__logo, className])} href={linkHref}>
    {imageSrc ? <img className={styles.logo} alt={ariaLabelText} src={imageSrc} /> : <Logo color="primary" />}
  </a>
}

FooterLogo.defaultProps = {
  className: undefined,
  linkHref: 'https://elisa.fi',
  ariaLabelText: 'Elisa',
  imageSrc: '',
};

FooterLogo.propTypes = {
  className: PropTypes.string,
  linkHref: PropTypes.string,
  ariaLabelText: PropTypes.string,
  imageSrc: PropTypes.string,
};

export default FooterLogo;
