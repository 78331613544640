import React from 'react';
import PropTypes from 'prop-types';

import styles from './SocialIcons.module.scss';

export function LinkWrapper({ href, aria, Icon, handleClick }) {
  if (!href || !aria || !Icon) return null;
  return (
    <li>
      <a
        className={styles['footer__socialicon-link']}
        href={href}
        aria-label={aria}
        onClick={handleClick}
      >
        <Icon className={styles.footer__socialicon} />
      </a>
    </li>
  );
}

LinkWrapper.propTypes = {
  href: PropTypes.string.isRequired,
  aria: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
  handleClick: PropTypes.func,
};
