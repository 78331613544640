import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import Input from '../../Input';

import globalStyles from '../../../globals/scss/_typography.scss';
import styles from '../Login.module.scss';

import { classNames } from '../../../utils/css';

class UsernamePassword extends Component {
  static propTypes = {
    /** Login component's id  */
    id: PropTypes.string,
    /** Container classname */
    className: PropTypes.string,
    /** Values for the inputs */
    values: PropTypes.object,
    /** Whether the first input field is automatically focused or not */
    autofocus: PropTypes.bool,
    /** Validation errors for the inputs */
    errors: PropTypes.object,
    /** Inputs which have been touched */
    touched: PropTypes.object,
    /** Flag for being submitted */
    isSubmitting: PropTypes.bool,
    /** Interface showing error messsage */
    errorMessage: PropTypes.element,
    /** Initial value for username field */
    usernameValue: PropTypes.string,
    /** Initial value for password field */
    passwordValue: PropTypes.string,
    /** Callback for changes on inputs */
    handleChange: PropTypes.func,
    /** Callback for inputs loossing focus */
    handleBlur: PropTypes.func,
    /** Callbak for submitting form */
    handleSubmit: PropTypes.func,
    /** Interface for switching login type */
    loginTypesSwitcher: PropTypes.element,
    /** Link to help with the login */
    loginHelp: PropTypes.element,
    /** Text for creating a new login */
    loginHelpText: PropTypes.string,
    /** Link to creating a new login */
    loginCreate: PropTypes.element,
    /** URL for login help */
    loginHelpUrl: PropTypes.string,
    /** URL to creating a new login */
    createLoginUrl: PropTypes.string,
    /** Object with i18n strings */
    translations: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      usernameActive: false,
      passwordActive: false,
      inputType: 'password',
    };
  }

  handleBlur = (e, name) => {
    switch (e.target.name) {
      case 'username':
        this.setState({ usernameActive: false });
        break;
      case 'password':
        this.setState({ passwordActive: false });
        break;
      default:
        break;
    }
  };

  handleFocus = (e, name) => {
    switch (e.target.name) {
      case 'username':
        this.setState({ usernameActive: true });
        break;
      case 'password':
        this.setState({ passwordActive: true });
        break;
      default:
        break;
    }
  };

  render() {
    const { autofocus, className, createLoginUrl, errors, errorMessage, handleChange, handleBlur, handleSubmit, id, isSubmitting, loginTypesSwitcher, loginHelp, loginCreate, loginHelpUrl, touched, values, wide } = this.props;

    const {
      i18n_login_header,
      i18n_login_header_usernamePassword,
      i18n_login_usernameLabel,
      i18n_login_usernamePlaceholder,
      i18n_login_loginButtonLabel,
      i18n_login_passwordPlaceholder,
      i18n_login_passwordLabel,
      i18n_login_loginHelpText,
      i18n_login_createLoginText,
    } = this.props.translations;

    return (
      <form onSubmit={handleSubmit} className={className}>
        <div id={id} ref={this.myRef}>
          <h3 className={classNames([globalStyles['h3--book'], styles['login__view--title']])}>{i18n_login_header_usernamePassword || i18n_login_header}</h3>

          {errorMessage}

          <Input
            className={styles.login__input}
            name="username"
            onValueChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
              this.handleBlur(e);
            }}
            onFocus={(e) => {
              this.handleFocus(e);
            }}
            value={values.username}
            active={this.state.usernameActive.toString()}
            placeholder={i18n_login_usernamePlaceholder}
            label={i18n_login_usernameLabel || i18n_login_usernamePlaceholder}
            touched={touched.username}
            error={errors.username}
            wide={wide}
            autoFocus={autofocus}
            optionalText=""
          />
          <Input
            className={styles.login__input}
            name="password"
            type={this.state.inputType}
            onValueChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
              this.handleBlur(e);
            }}
            onFocus={(e) => {
              this.handleFocus(e);
            }}
            value={values.password}
            active={this.state.passwordActive.toString()}
            placeholder={i18n_login_passwordPlaceholder}
            label={i18n_login_passwordLabel || i18n_login_passwordPlaceholder}
            error={errors.password}
            touched={touched.password}
            autocomplete="new-password"
            wide={wide}
            optionalText=""
          />
          <Button className={styles[`login__submit-button`]} id="button-login-with-username-password" size="l" block type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
            {i18n_login_loginButtonLabel}
          </Button>

          {loginTypesSwitcher}

          {loginHelp}

          {loginCreate}
        </div>
      </form>
    );
  }
}

export const validate = (values, errors, translations) => {
  if (!values.username) {
    errors.username = translations.i18n_login_errorUsernameRequired;
  }
  if (!values.password) {
    errors.password = translations.i18n_login_errorPasswordRequired;
  }
};

export default UsernamePassword;
