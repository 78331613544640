// @flow strict-local
import React, { type Element, useEffect } from 'react';
import { CancelToken, CancelTokenSource } from 'axios';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import AccordionItem from '@design-system/component-library/src/components/Accordion/AccordionItem';
import Accordion from '@design-system/component-library/src/components/Accordion';
import { useTranslation } from 'react-i18next';
import EditCallflowDetails from '../components/edit/EditCallflowDetails';
import InputField from '../components/edit/children/InputField';
import { updateWelcomeAttendant } from '../../../ducks/entities/welcomeAttendant/welcomeAttendantOperations';
import { updatePlayMusic } from '../../../ducks/entities/playMusic/playMusicOperations';
import { updateExtensionGroup } from '../../../ducks/entities/extensionGroup/extensionGroupOperations';
import { updateSpeedDial } from '../../../ducks/entities/speedDial/speedDialOperations';
import { update as updateAcd } from '../../../ducks/entities/acd/acdOperations';
import type { CallFlowEntityT } from '../../../ducks/entities/callFlow';
import RadioButton from '../components/edit/children/RadioButton';
import GroupPriority from '../components/GroupPriority/GroupPriority';
import GroupDisplayedNumber from '../components/GroupDisplayedNumber/GroupDisplayedNumber';
import GroupDisplayedLabels from '../components/GroupDisplayedLabels/GroupDisplayedLabels';
import TooltipExtension from '../../../components/TooltipExtension';
import UnattachedStepsField from '../components/edit/children/welcomeAttendantUnattachedSteps/UnattachedStepsField';
import NumberRangeSelector from '../components/edit/children/NumberRangeSelector/NumberRangeSelector';
import type { NumberRangeFieldT } from '../components/edit/children/NumberRangeSelector/NumberRangeSelector';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';
import ToggleField from '../components/edit/children/ToggleField';
import useEnterprise from '../../useEnterprise';
import { getGroupDisplayPolicies } from '../components/edit/CallflowPropertyUtils';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import AcdExtensionAudioField from '../components/edit/children/audio/AcdExtensionAudioField';
import AudioFieldConverter from '../components/edit/children/audio/AudioFieldConverter';
import { uploadAudios } from '../components/edit/children/audio/CallflowAudioUtils';
import { getIntegerFieldLimits } from '../../../utils/validationUtils';
import styles from './EditCallflowContent.module.scss';

export type PropsT = {|
  callflowId: string,
  onClick: () => void,
  hideBillingSectionSubTopic?: boolean
|};

type FormT = {
  label: string,
  hiddenDirectory: boolean,
  // Only ACD & Extgroup have this field
  overrideEnterpriseGroupsDisplayPolicies: ?boolean,
  active?: boolean,
  priority?: ?number,
  groupDisplayedNumberPolicy?: ?{ value: string, label: string },
  groupDisplayedLabelsTerminalsPolicy?: ?(string[]),
  pstnNumber: ?NumberRangeFieldT,
  extensionNumber: ?NumberRangeFieldT
};

let patchCancelTokenSource: CancelTokenSource;

export const EditCallflowContent = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId, onClick, hideBillingSectionSubTopic } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const callflow: CallFlowEntityT =
    useSelector(state => state.entities.callFlow.byId[callflowId]) || {};
  const labelValue = (callflow.label || '').replace(/^kutsu:/, '');
  const showActiveSelector: boolean = callflow && R.path(['active'], callflow) !== undefined;

  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const priority = callflow && R.path(['priority'], callflow);
  const showNumberDisplayPolicies =
    (callflow.type === 'EXTENSION_GROUP' ||
      callflow.type === 'ACD_CUSTOMER_SERVICE' ||
      callflow.type === 'ACD_SWITCHBOARD') &&
    !(currentUser.featureFlags || []).includes('FEATURE-HIDE-CALLFLOW-GROUP-DISPLAY-POLICIES');
  const { enterprise } = useEnterprise(callflow.enterpriseId);

  const {
    groupDisplayedNumberPolicy,
    groupDisplayedLabelsTerminalsPolicy
  } = getGroupDisplayPolicies(enterprise, callflow);
  const acdAudioConverter = new AudioFieldConverter(
    callflow.enterpriseId,
    callflow.id,
    'acds',
    'AnnoucementService'
  );
  const initialFormValues: FormT = {
    label: labelValue,
    // $FlowFixMe PlayMusic and Speeddial doesn't have this field
    groupFreeText: callflow.groupFreeText,
    hiddenDirectory: !!callflow.hiddenDirectory,
    // $FlowFixMe PlayMusic and Speeddial doesn't have active field
    ...(showActiveSelector && { active: Boolean(callflow.active) }),
    priority,
    // $FlowFixMe: Only ACD & Extgroup have this field
    overrideEnterpriseGroupsDisplayPolicies: true,
    groupDisplayedNumberPolicy: groupDisplayedNumberPolicy
      ? {
          label: t(`callflows.numberToDisplay.${groupDisplayedNumberPolicy}`),
          value: groupDisplayedNumberPolicy
        }
      : null,
    groupDisplayedLabelsTerminalsPolicy,
    pstnNumber: null,
    extensionNumber: null,
    inactiveGroupMessage: acdAudioConverter.convertToAudioPropertyField(
      'InactiveGroupMessage',
      'AnnoucementService_InactiveGroupMessage.mp3'
    )
  };

  useEffect(() => {
    patchCancelTokenSource = CancelToken.source();
    return () => {
      patchCancelTokenSource.cancel();
    };
  }, []);

  const acdUpdateData = (formData, label) => {
    const updateData = {
      label: !label ? formData.label : label,
      groupFreeText: formData.groupFreeText,
      hiddenDirectory: formData.hiddenDirectory,
      active: formData.active,
      priority: formData.priority,
      groupDisplayedNumberPolicy: formData.groupDisplayedNumberPolicy.value,
      groupDisplayedLabelsTerminalsPolicy: formData.groupDisplayedLabelsTerminalsPolicy,
      ...(showNumberDisplayPolicies
        ? {
            overrideEnterpriseGroupsDisplayPolicies:
              formData.overrideEnterpriseGroupsDisplayPolicies
          }
        : {})
    };
    return updateData;
  };

  const updateCallFlow = async formData => {
    if (callflow) {
      if (callflow.type === 'ACD_CUSTOMER_SERVICE' || callflow.type === 'ACD_SWITCHBOARD') {
        // $FlowFixMe Improve formData typing
        const failedAudioUploads = await uploadAudios(
          formData,
          CancelToken.source(),
          createCsrfHeader(currentUser)
        );
        if (failedAudioUploads.length > 0) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(
            t('callflows.audioUploadFailed', { audioFileNames: failedAudioUploads.join(',') })
          );
        }
      }
      const { enterpriseId } = callflow;
      if (callflow.type === 'WELCOME_ATTENDANT') {
        const newData = {
          ivr: {
            label: formData.label,
            hiddenDirectory: formData.hiddenDirectory
          }
        };
        return dispatch(
          updateWelcomeAttendant(
            enterpriseId,
            callflow.id,
            newData,
            patchCancelTokenSource.token,
            createCsrfHeader(currentUser)
          )
        );
      }
      if (callflow.type === 'ACD_CUSTOMER_SERVICE') {
        return dispatch(
          updateAcd(
            enterpriseId,
            callflow.type,
            callflow.id,
            acdUpdateData(formData),
            patchCancelTokenSource.token,
            createCsrfHeader(currentUser)
          )
        );
      }
      if (callflow.type === 'ACD_SWITCHBOARD') {
        return dispatch(
          updateAcd(
            enterpriseId,
            callflow.type,
            callflow.id,
            acdUpdateData(formData, `kutsu:${formData.label}`),
            patchCancelTokenSource.token,
            createCsrfHeader(currentUser)
          )
        );
      }
      if (callflow.type === 'PLAY_MUSIC') {
        const newData = {
          label: formData.label,
          hiddenDirectory: formData.hiddenDirectory,
          addressNumber: callflow.addressNumber
        };
        return dispatch(
          updatePlayMusic(
            enterpriseId,
            callflow.id,
            newData,
            patchCancelTokenSource.token,
            createCsrfHeader(currentUser)
          )
        );
      }
      if (callflow.type === 'EXTENSION_GROUP') {
        const newData = {
          label: formData.label,
          hiddenDirectory: formData.hiddenDirectory,
          active: formData.active,
          groupDisplayedNumberPolicy: formData.groupDisplayedNumberPolicy.value,
          groupDisplayedLabelsTerminalsPolicy: formData.groupDisplayedLabelsTerminalsPolicy,
          ...(showNumberDisplayPolicies
            ? {
                overrideEnterpriseGroupsDisplayPolicies:
                  formData.overrideEnterpriseGroupsDisplayPolicies
              }
            : {})
        };
        return dispatch(
          updateExtensionGroup(
            enterpriseId,
            callflow.id,
            newData,
            patchCancelTokenSource.token,
            createCsrfHeader(currentUser)
          )
        );
      }
      if (callflow.type === 'SPEED_DIAL' || callflow.type === 'OC') {
        const newData = { label: formData.label, hiddenDirectory: formData.hiddenDirectory };
        return dispatch(
          updateSpeedDial(
            enterpriseId,
            callflow.id,
            newData,
            patchCancelTokenSource.token,
            createCsrfHeader(currentUser)
          )
        );
      }
    }
    return null;
  };

  const onSubmit = async formData => updateCallFlow(formData);

  const directoryVisibilityOptions = [
    {
      label: t('callflows.editCallflowContent.hiddenDirectoryTrue'),
      value: true
    },
    {
      label: t('callflows.editCallflowContent.hiddenDirectoryFalse'),
      value: false
    }
  ];

  const validationSchema = yup.object().shape({
    priority: yup
      .number()
      .integer()
      .min(0)
      .max(99)
      .default(0)
      .required(),
    label: yup
      .string()
      .min(1, '')
      .required('')
  });

  const pstnNumberOptions = [
    {
      value: '0102912000 - 0102912999',
      label: '0102912000 - 0102912999'
    }
  ];

  const billingRelatedChanges = (
    <AccordionItem key="billingArea">
      <NumberRangeSelector
        field="pstnNumber"
        title={t('callflows.editCallflowContent.pstnFieldTitle')}
        rangeLabel={t('callflows.editCallflowContent.pstnFieldRange')}
        numberLabel={t('callflows.editCallflowContent.pstnFieldNumber')}
        numberOptions={pstnNumberOptions}
        numberRangeOptions={[]}
      />
      <NumberRangeSelector
        field="extensionNumber"
        title={t('callflows.editCallflowContent.extensionFieldTitle')}
        rangeLabel={t('callflows.editCallflowContent.extensionFieldRange')}
        numberLabel={t('callflows.editCallflowContent.extensionFieldNumber')}
        numberOptions={pstnNumberOptions}
        numberRangeOptions={[]}
      />
    </AccordionItem>
  );

  const billingSection = hideBillingSectionSubTopic ? (
    billingRelatedChanges
  ) : (
    <Accordion heading={t('callflows.editCallflowContent.billingSectiontTitle')}>
      {billingRelatedChanges}
    </Accordion>
  );

  return (
    <EditCallflowDetails
      nodeId="callflow-page"
      icon={null}
      title=""
      description={t('callflows.editCallflowContent.description')}
      defaultValues={initialFormValues}
      onSaveForm={onSubmit}
      onCancel={onClick}
      containerStyle={styles.container}
      validationSchema={validationSchema}
    >
      {showActiveSelector ? (
        <ToggleField
          field="active"
          elementId="active"
          label={t('callflows.editCallflowContent.active')}
          tooltip={t('callflows.editCallflowContent.activeDescription')}
        />
      ) : null}
      {(callflow.type === 'ACD_CUSTOMER_SERVICE' || callflow.type === 'ACD_SWITCHBOARD') &&
      !(currentUser.featureFlags || []).includes('FEATURE-HIDE-CALLFLOW-INACTIVEGROUPMESSAGE') ? (
        <AcdExtensionAudioField
          field="inactiveGroupMessage"
          title={t('callflows.viewCallflowContent.inactiveGroupMessage')}
          activeField="active"
        />
      ) : null}
      <InputField
        field="label"
        title={t('callflows.editCallflowContent.name')}
        description={t('callflows.editCallflowContent.nameDescription')}
        maxLength={100}
      />
      {callflow.type === 'ACD_CUSTOMER_SERVICE' || callflow.type === 'ACD_SWITCHBOARD' ? (
        <InputField
          field="groupFreeText"
          title={t('callflows.viewCallflowContent.groupFreeTextTitle')}
          maxLength={200}
          type="textarea"
          showLength
        />
      ) : null}
      {priority !== undefined ? (
        <GroupPriority
          field="priority"
          errorMessage={t(
            'integerField.error',
            // $FlowFixMe: TODO: fix
            getIntegerFieldLimits(validationSchema.fields.priority)
          )}
        />
      ) : null}
      {groupDisplayedNumberPolicy ? (
        <GroupDisplayedNumber field="groupDisplayedNumberPolicy" />
      ) : null}
      {groupDisplayedNumberPolicy ? (
        <GroupDisplayedLabels field="groupDisplayedLabelsTerminalsPolicy" />
      ) : null}
      <RadioButton
        field="hiddenDirectory"
        title={t('callflows.editCallflowContent.hiddenDirectory')}
        options={directoryVisibilityOptions}
        tooltip={
          <TooltipExtension
            title={t('callflows.viewCallflowContent.phonebookVisibility')}
            text={t('callflows.viewCallflowContent.phonebookVisibilityTooltip')}
          />
        }
      />
      {callflow && callflow.type === 'WELCOME_ATTENDANT' ? (
        <UnattachedStepsField welcomeAttendant={callflow} />
      ) : null}
      {!(currentUser.featureFlags || []).includes('FEATURE-HIDE-CALLFLOW-BILLING-EDIT')
        ? billingSection
        : null}
    </EditCallflowDetails>
  );
};

export default EditCallflowContent;
