import React from 'react';
import PropTypes from 'prop-types';

import allIcons from './lib';
import ALL_COLORS from '../../utils/colors';

export default class Icon extends React.Component {
  static propTypes = {
    /**
     *  Id of icon to request
     */
    icon: PropTypes.string,
    /**
     * Type of icon outline
     */
    type: PropTypes.oneOf(['light', 'regular', 'filled']),
    /**
     * Size of icon
     */
    size: PropTypes.oneOf(['s', 'm', 'l', 'xl', 'fluid']),
    /**
     * Color of icon. One of Elisa design system's colors. Available colors: https://designsystem-storybook-pub.csf.elisa.fi/?path=/story/colors--page
     */
    color: PropTypes.oneOf(ALL_COLORS),
  }

  static defaultProps = {
    type: 'regular',
    size: 'm',
  }

  constructor(props) {
    const {
      icon,
      type,
    } = props;

    super();

    this.iconComponent = allIcons[`${icon}--${type}`];
  }

  render() {
    const IconComponent = this.iconComponent;

    return (
      IconComponent ?
      <IconComponent
        {...this.props}
      /> :
      null
    )
  }
}
