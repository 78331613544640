// @flow

import React, { type Element, useEffect, useState } from 'react';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import type Moment from 'moment';
import moment from 'moment';
import Radio from '@design-system/component-library/src/components/Radio';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TimeRangePicker from '../DateTimePicker/TimeRangePicker';

import styles from './CallForwardingScheduler.module.scss';

export type PropsT = {|
  field: string,
  disabled: boolean
|};

export const CallForwardingScheduler = (props: PropsT): Element<'div'> => {
  const { disabled, field } = props;
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const values = watch();
  const value: string = values[field];
  const [scheduled, setScheduled] = useState(value ? 'true' : 'false');
  const ALL_DAYS = 'All';

  const weekdayTranslations = [
    { label: t('calendars.weekdays.monday'), value: 'Mon' },
    { label: t('calendars.weekdays.tuesday'), value: 'Tue' },
    { label: t('calendars.weekdays.wednesday'), value: 'Wed' },
    { label: t('calendars.weekdays.thursday'), value: 'Thu' },
    { label: t('calendars.weekdays.friday'), value: 'Fri' },
    { label: t('calendars.weekdays.saturday'), value: 'Sat' },
    { label: t('calendars.weekdays.sunday'), value: 'Sun' }
  ];

  const parseSelectedDaysFromTimeFilterValue = () => {
    if (value) {
      const daysPart = value.split('/')[0];
      if (daysPart === ALL_DAYS) {
        return weekdayTranslations.map(day => day.value);
      }
      return daysPart.split('+');
    }
    return [];
  };

  const parseTimeRangeFromTimeFilterValue = () => {
    if (value) {
      const timesPart = value.split('/')[1];
      const times = timesPart.split('-');
      return { start: moment(times[0], 'HH:mm'), end: moment(times[1], 'HH:mm') };
    }
    return null;
  };

  const [timeRange, setTimeRange] = useState<{ start: Moment, end: Moment } | null>(
    parseTimeRangeFromTimeFilterValue()
  );
  const [selectedDays, setSelectedDays] = useState(parseSelectedDaysFromTimeFilterValue());

  const generateTimeFilter = () => {
    if (timeRange) {
      const daySchedule = selectedDays.join('+');
      const timeSchedule = `${timeRange.start.format('HH:mm')}-${timeRange.end.format('HH:mm')}`;
      const dayTimeSchedule = `${daySchedule}/${timeSchedule}`;
      setValue(field, dayTimeSchedule, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  };

  useEffect(() => {
    if (scheduled === 'true') {
      generateTimeFilter();
    } else {
      setValue(field, '', {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [timeRange, selectedDays, scheduled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!values[field]) {
      setScheduled('false');
      setTimeRange(null);
      setSelectedDays([]);
    }
  }, [values[field]]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnTimeRangeChange = range => {
    setTimeRange(range);
  };

  const generateWeekDays = () => {
    return (
      <div className={styles['day-list']}>
        {weekdayTranslations.map(day => (
          <Checkbox
            id={`weekday_${day.value}_check`}
            key={`weekday_${day.value}_check`}
            name="weekdaysCheck"
            label={day.label}
            checked={selectedDays.includes(day.value)}
            onChange={() => {
              if (selectedDays.includes(day.value)) {
                setSelectedDays(selectedDays.filter(item => item !== day.value) ?? []);
              } else {
                setSelectedDays(() => [...selectedDays, day.value]);
              }
            }}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={styles.content}>
      {disabled && (
        <div>
          <div className={styles.title}>{t('forwardingDetails.scheduledTitle')}</div>
          <div>{t('forwardingDetails.scheduledInfo')}</div>
        </div>
      )}
      {!disabled && (
        <div>
          <div className={styles['title-area']}>
            <div className={styles.title}>{t('forwardingDetails.scheduledTitle')}</div>
          </div>
          <Radio.Group value={scheduled} key={scheduled}>
            <Radio
              id="scheduledDisabled"
              key="scheduledDisabled"
              name="scheduled-option"
              onChange={() => setScheduled('false')}
              className={styles.radio}
              label={t('forwardingDetails.scheduledDisabled')}
              value="false"
            />
            <Radio
              id="scheduledEnabled"
              key="scheduledEnabled"
              name="scheduled-option"
              onChange={() => setScheduled('true')}
              className={styles.radio}
              label={t('forwardingDetails.scheduledEnabled')}
              value="true"
            />
          </Radio.Group>
        </div>
      )}
      {scheduled === 'true' && !disabled && (
        <div className="customModal">
          {generateWeekDays()}
          <TimeRangePicker
            translateMock={t}
            validationMessage={t('calendars.rangeValidationError')}
            onChange={handleOnTimeRangeChange}
            initialStartTime={timeRange ? timeRange.start : null}
            initialEndTime={timeRange ? timeRange.end : null}
          />
        </div>
      )}
    </div>
  );
};

export default CallForwardingScheduler;
