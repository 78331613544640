"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoppingCartPaymentOption = void 0;
function ShoppingCartPaymentOption(_a) {
    var id = _a.id, label = _a.label, _b = _a.selected, selected = _b === void 0 ? false : _b;
    return {
        id: id,
        label: label,
        selected: selected,
    };
}
exports.ShoppingCartPaymentOption = ShoppingCartPaymentOption;
exports.default = {};
