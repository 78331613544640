// @flow

import React, { type Element } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router-for-react18';
import store, { history } from './store';
import OpenRoutes from './routes/OpenRoutes';

const App = (): Element<*> => (
  <Provider store={store}>
    <div>
      <ConnectedRouter history={history}>
        <OpenRoutes />
      </ConnectedRouter>
    </div>
  </Provider>
);

export default App;
