// @flow strict-local

import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken } from 'axios';
import * as R from 'ramda';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UnhandledIcon } from '../../../../../assets/callflow/details/speeddialling-small.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import { update } from '../../../../../ducks/entities/acd/acdOperations';
import type {
  AudioFieldT,
  PropertyAudioT
} from '../../../components/edit/children/audio/CallflowAudioUtils';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import { createIvrPatchPayload } from '../../../components/edit/CallflowPropertyUtils';
import { createFwdProperty } from '../../../components/edit/children/forwardingField/ForwardingUtils';
import type { ForwardingPropertyT } from '../../../components/edit/children/forwardingField/ForwardingUtils';
import AcdAudioField from '../../../components/edit/children/audio/AcdAudioField';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import { uploadAudios } from '../../../components/edit/children/audio/CallflowAudioUtils';
import { validateForwardingField } from '../../../../../utils/validationUtils';
import ACDForwardingField from '../../../components/edit/children/forwardingField/ACDForwardingField';
import ToggleField from '../../../components/edit/children/ToggleField';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';

export type PropsT = {|
  callflowId: string
|};

type FormT = {
  closingFwd: ForwardingPropertyT,
  closingMsg: AudioFieldT<PropertyAudioT>,
  exceptionalClosing: boolean,
  exceptionalClosingMsg: AudioFieldT<PropertyAudioT>
};

export const EditCalendarClosed = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const ivrAcdCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};
  const acdAudioFieldConverter = new AudioFieldConverter(acdData.enterpriseId, acdData.id, 'acds');
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const initialProperties = {
    closingFwd: createFwdProperty(ivrAcdCallCenter.closingFwd),
    closingMsg: acdAudioFieldConverter.convertToAudioPropertyField(
      'ClosingMsg',
      R.path(['audios', 'closingMsg', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.closingMsg
    ),
    exceptionalClosing: acdCallCenter.exceptionalClosing,
    exceptionalClosingMsg: acdAudioFieldConverter.convertToAudioPropertyField(
      'ExceptionalClosingMsg',
      R.path(['audios', 'exceptionalClosingMsg', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.exceptionalClosingMsg
    )
  };
  const initialFormValues: FormT = {
    ...initialProperties
  };

  const validationSchema = yup.object().shape({
    closingFwd: validateForwardingField(t('callflows.validation.forwardingError'))
  });

  // update
  const onSubmit = async (formData: FormT): Promise<AcdEntityT> => {
    const patchPayload = createIvrPatchPayload({}, formData, Object.keys(initialProperties));

    // $FlowFixMe Improve formData typing
    const failedAudioUploads = await uploadAudios(
      formData,
      CancelToken.source(),
      createCsrfHeader(currentUser)
    );
    if (failedAudioUploads.length > 0) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        t('callflows.audioUploadFailed', { audioFileNames: failedAudioUploads.join(',') })
      );
    }
    return dispatch(
      update(
        acdData.enterpriseId,
        acdData.type,
        acdData.id,
        patchPayload,
        CancelToken.source().token,
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={acdData.id}
      icon={<UnhandledIcon />}
      title={t('callflows.editCalendarClosed.title')}
      description={t('callflows.editCalendarClosed.description')}
      defaultValues={initialFormValues}
      onSaveForm={onSubmit}
      validationSchema={validationSchema}
    >
      <ACDForwardingField
        field="closingFwd"
        title={t('callflows.editCalendarClosed.closingFwd')}
        filterSearchResults={entry => (entry ? entry.value !== acdData.addressNumber : false)}
      />
      <AcdAudioField field="closingMsg" title={t('callflows.editCalendarClosed.closingMsg')} />
      <ToggleField
        field="exceptionalClosing"
        elementId="exceptionalClosing"
        label={t('callflows.editCalendarClosed.exceptionalClosing')}
      />
      <AcdAudioField
        field="exceptionalClosingMsg"
        title={t('callflows.editCalendarClosed.exceptionalClosingMsg')}
        disabledFn={(formData: FormT) => !formData.exceptionalClosing}
      />
    </EditCallflowDetails>
  );
};

export default EditCalendarClosed;
