import React from 'react';
import PropTypes from 'prop-types';
import { useIdWithFallback } from '../../utils/hooks';
import { classNames } from '../../utils/css';

import styles from './SkeletonLoader.module.scss';

function SkeletonLoader(
  {
    id = null,
    className = null,
    type = 'text',
    count = 1,
    ...otherProps
  }
){
  const htmlId = useIdWithFallback('dsSkeletonLoader', id);
  const allClasses = classNames([
    styles.skeletonloader,
    className || null]
  );

  const createContent = (type, count) => {
    let html = null;

    switch(type) {
      case 'text':
        html = <div className={styles[`skeletonloader--text`]} />;
        break;
      case 'title':
        html = <div className={styles[`skeletonloader--title`]} />;
        break;
      case 'image':
        html = <div className={styles[`skeletonloader--image`]} />;
        break;
      case 'button':
        html = <div className={styles[`skeletonloader--button`]} />;
        break;
      case 'table':
        html = <div className={styles[`skeletonloader--table`]} />;
        break;
      default:
        html = <div className={styles[`skeletonloader--text`]} />;
        break;
    }

    const componentHTML = [];
    for (let index = 0; index < count; index++) {
      componentHTML.push(html);
    }

    return componentHTML;
  }

  return (
    <div id={htmlId} className={allClasses} {...otherProps}>
      {createContent(type, count)}
    </div>
  );
}

export default SkeletonLoader;

SkeletonLoader.propTypes = {
  /** Element's id */
  id: PropTypes.string,
  /** Class names the user can give to the component */
  className: PropTypes.string,
  /** Skeleton loader's type */
  type: PropTypes.oneOf(['text', 'title', 'image', 'button', 'table']),
  /** Number of type to render */
  count: PropTypes.number,
};

SkeletonLoader.defaultProps = {
  id: null,
  className: null,
  type: 'text',
  count: 1,
};
