// @flow

import React, { type Element } from 'react';
import * as R from 'ramda';
import Moment from 'moment';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { AbsoluteTimeSlotEntityT } from '../../../ducks/entities/calendar/calendarTypes';
import type { DateTimeIntervalEntryT, IntervalEntryT } from '../callFlowGrid/details/CalendarUtils';
import { compareDateAscbyStart } from '../callFlowGrid/details/CalendarUtils';
import AbsoluteTimeSlot from './AbsoluteTimeSlot';
import styles from './AbsoluteTimeSlots.module.scss';

export type PropsT = {|
  absoluteTimeSlots: AbsoluteTimeSlotEntityT[],
  editable: boolean,
  handleDelete: (slot: IntervalEntryT) => void,
  handleUpdate: (slot: IntervalEntryT) => void
|};

const AbsoluteTimeSlots = (props: PropsT): Element<'div'> | null => {
  const { absoluteTimeSlots, editable, handleDelete, handleUpdate } = props;
  const { t } = useTranslation();

  const renderTimeSlotString = slot => (
    <AbsoluteTimeSlot
      key={`${slot.id}-absolute-slot`}
      slot={slot}
      handleDelete={handleDelete}
      handleUpdate={handleUpdate}
      editable={editable}
    />
  );
  const formatDateInterval = (interval: DateTimeIntervalEntryT): IntervalEntryT => {
    const { start, end, id } = interval;
    const str = {
      id,
      active: interval.active,
      interval: `${start.format('DD.MM.Y HH:mm')} - ${end.format('kk:mm')}`
    };
    return str;
  };

  const parseAbsoluteTimeSlotTimes = (
    absoluteTimeSlot: AbsoluteTimeSlotEntityT
  ): DateTimeIntervalEntryT => {
    const { startTime, endTime } = absoluteTimeSlot;
    return {
      start: Moment(startTime),
      end: Moment(endTime),
      id: absoluteTimeSlot.id,
      active: absoluteTimeSlot.active
    };
  };

  const sortAndRenderAbsoluteTimeSlots = (): Element<'div'>[] =>
    // $FlowFixMe compose missing in types
    R.compose(
      R.map(renderTimeSlotString),
      R.map(formatDateInterval),
      R.sort(compareDateAscbyStart),
      R.map(parseAbsoluteTimeSlotTimes)
    )(absoluteTimeSlots);
  const isNotEmpty = arr => Array.isArray(arr) && arr.length > 0;
  return isNotEmpty(absoluteTimeSlots) ? (
    <div>
      <div className={classnames({ [styles['editable-title']]: editable })}>
        {t('callflows.details.absoluteTimeSlotsTitle')}
      </div>
      <div>{sortAndRenderAbsoluteTimeSlots()}</div>
    </div>
  ) : null;
};

export default AbsoluteTimeSlots;
