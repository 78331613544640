import React from 'react';
import PropTypes from 'prop-types';
import Megastack from './MegaStack';
import styles from '../../Footer.module.scss';
import linkStyles from './Links.module.scss';

function Links({ links, handleClick }) {
  return (
    <div className={styles.footer__content}>
      {links.map((subcategory, index) => {
        if (!subcategory.megastack) return null;
        return (
          <div className={linkStyles.footer__column} key={index}>
            <Megastack item={subcategory.megastack} parentMenu="mega" handleClick={handleClick} />
          </div>
        );
      })}
    </div>
  );
}

Links.propTypes = {
  handleClick: PropTypes.func,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      megastack: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string,
          pages: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              url: PropTypes.string,
              target: PropTypes.string,
            })
          ),
        })
      ),
    })
  ).isRequired,
};

export default Links;
