import React from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';

import { classNames } from '../../utils/css';

import IconArrowLeftFilled from '../Icon/lib/IconArrowLeftFilled';
import IconArrowRightFilled from '../Icon/lib/IconArrowRightFilled';
import IconCalendarRegular from '../Icon/lib/IconCalendarRegular';

import Input from '../Input';
import Button from '../Button';

import styles from './DatePicker.module.scss';

import { KEY_RETURN } from '../../utils/keycodes';

/**
 * Returns a shallow copy of the object with omitted keys omitted.
 * @param {Object} obj
 * @param {string[]} omittedKeys
 * @returns {Object}
 */
function omit(obj, ...omittedKeys) {
  return Object.keys(obj).reduce((newObj, key) => {
    if (!omittedKeys.includes(key)) {
      // eslint-disable-next-line no-param-reassign
      newObj[key] = obj[key];
    }
    return newObj;
  }, {});
}
class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.htmlId = props.id || nextId(`ds${this.constructor.name}`);

    if (this.props.input_start_date_name !== 'datestart') {
      console.warn("DatePicker component's `input_start_date_name` property is deprecated. Use `inputStartDateName` property instead. `input_start_date_name` property will be removed in a future version (during summer 2024).");
    }

    if (this.props.input_end_date_name !== 'dateend') {
      console.warn("DatePicker component's `input_end_date_name` property is deprecated. Use `inputEndDateName` property instead. `input_end_date_name` property will be removed in a future version (during summer 2024).");
    }

    if (this.props.input_start_date_class) {
      console.warn("DatePicker component's `input_start_date_class` property is deprecated. Use `inputStartDateClass` property instead. `input_start_date_class` property will be removed in a future version (during summer 2024).");
    }

    if (this.props.input_start_date_id) {
      console.warn("DatePicker component's `input_start_date_id` property is deprecated. Use `inputStartDateId` property instead. `input_start_date_id` property will be removed in a future version (during summer 2024).");
    }

    if (this.props.input_end_date_class) {
      console.warn("DatePicker component's `input_end_date_class` property is deprecated. Use `inputEndDateClass` property instead. `input_end_date_class` property will be removed in a future version (during summer 2024).");
    }

    if (this.props.inputEndDdateClass) {
      console.warn("DatePicker component's `inputEndDdateClass` property is deprecated. Use `inputEndDateClass` property instead. `inputEndDdateClass` property will be removed in a future version (during summer 2024).");
    }

    if (this.props.input_end_date_id) {
      console.warn("DatePicker component's `input_end_date_id` property is deprecated. Use `inputEndDateId` property instead. `input_end_date_id` property will be removed in a future version (during summer 2024).");
    }

    /**
     * HANDLE ZERO AND ONE BASED DATES HERE -->
     */

    //* TODAY *//
    let today;
    // If today is passed as an date object - use it as it is. ZERO BASED!
    if(typeof props.today.getMonth === 'function') {
      today = props.today;
    }
    // If it's an array, convert it to date object. ONE BASED!
    else {
      today = new Date(props.today[0], props.today[1] - 1, props.today[2]);
    }

    let daySelected = [null, null, null];

    //* SELECTED DAY *//
    // If selected day is passed as an date object - use it as it is. ZERO BASED!
    if(typeof props.selectedDay.getMonth === 'function') {
      daySelected = [props.selectedDay.getFullYear(), props.selectedDay.getMonth(), props.selectedDay.getDate()];
    }
    // If it's an array, convert it to date object. ONE BASED!
    else {
      daySelected = [props.selectedDay[0], props.selectedDay[1] - 1, props.selectedDay[2]];
    }

    //* VISIBLE RANGE *//
    let visibleRange = [
      [2000, 1, 1],
      [2100, 1, 1],
    ];
    // If selected day is passed as an date object - use it as it is. ZERO BASED!
    if(typeof props.visibleRange[0].getMonth === 'function') {
      visibleRange[0] = [props.visibleRange[0].getFullYear(), props.visibleRange[0].getMonth(), props.visibleRange[0].getDate()];
      visibleRange[1] = [props.visibleRange[1].getFullYear(), props.visibleRange[1].getMonth(), props.visibleRange[1].getDate()];
    }
    // If it's an array, convert it to date object. ONE BASED!
    else {
      visibleRange = props.visibleRange;
      visibleRange[0][1] -= 1;
      visibleRange[1][1] -= 1;
    }

    /**
     * <-- HANDLE ZERO AND ONE BASED DATES HERE
     */

    // const day = props.selectedDay[0] || typeof props.selectedDay.getMonth === 'function' ? daySelected[2] : today.getDate();
    const month = props.selectedDay[0] || typeof props.selectedDay.getMonth === 'function' ? daySelected[1] : today.getMonth();
    const year = props.selectedDay[0] || typeof props.selectedDay.getMonth === 'function' ? daySelected[0] : today.getFullYear();

    this.state = {
      // day,
      month,
      year,
      realToday: new Date(),
      today,
      daySelected,
      selectedRange: [
        [null, null, null],
        [null, null, null],
      ],
      isHidden: !props.defaultOpen,
      visibleRange,
    };

    this.changeMonth = this.changeMonth.bind(this);
    this.setRange = this.setRange.bind(this);
    this.showCalendar = this.showCalendar.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.setDateFromInput = this.setDateFromInput.bind(this);
    this.handleOnValueChange = this.handleOnValueChange.bind(this);
    this.checkValidity = this.checkValidity.bind(this);

    /* popover */
    this.triggerRef = React.createRef();
    this.inputStartRef = React.createRef();
    this.inputEndRef = React.createRef();
    this.popupRef = React.createRef();
  }

  /* Handle dates */
  // Change this to Moment.js maybe?
  /* eslint-disable class-methods-use-this */
  handleDate = (params) => {
    let { dateFormat = 'yyyy-mm-dd' } = params;
    const { dateString, pad = true } = params;

    const date = new Date(dateString);

    const json = {
      yyyy: date.getFullYear(),
      mm: pad
        ? Number(date.getMonth() + 1)
            .toString()
            .padStart(2, 0)
        : Number(date.getMonth() + 1),
      dd: pad ? Number(date.getDate()).toString().padStart(2, 0) : Number(date.getDate()),
    };

    Object.entries(json).forEach(([key, value]) => {
      const patt = new RegExp(key, 'gm');
      dateFormat = dateFormat.replace(patt, value);
    });

    // This is pretty stupid...
    if (dateFormat.toLowerCase().indexOf('nan') === -1) {
      return dateFormat;
    }
    return '';
  };
  /* eslint-enable class-methods-use-this */

  setDateFromInput = (params) => {
    const { dateFormat, dateInputPattern } = this.props;
    const { isRange = false, day } = params;

    const dateArray = day.split(/[-/.]/);

    if (dateInputPattern.test(day)) {
      const df = dateFormat.split(/[-/.]/);

      const year = df.indexOf('yyyy');
      const month = df.indexOf('mm');
      const date = df.indexOf('dd');

      const { disableAllPastDays,
        disabledDays,
        disableWeekends,
        disablePreviousMonths,
        visibleRange,
        rangeMaxLength,
        i18n_datepicker_errorMessage_disabled,
        i18n_datepicker_errorMessage_default,
        i18n_datepicker_errorMessage_weekend,
        i18n_datepicker_errorMessage_endBeforeStart,
        i18n_datepicker_errorMessage_rangeLength
        } = this.props;

      const { today, selectedRange } = this.state;

/**
 * check disabled days / -->
 */
      let inputError = false;
      let inputErrorMessage;
      let rangeError = false;
      let rangeErrorMessage;

      // Past days are disabled
      if( disableAllPastDays ) {
        if( new Date(dateArray[year], dateArray[month]-1, dateArray[date]).getTime() < today.getTime()) {
          inputError = true;
          inputErrorMessage = i18n_datepicker_errorMessage_disabled || i18n_datepicker_errorMessage_default;
        }
      }

      // Past month is disabled
      if( disablePreviousMonths ) {
        const testDate = new Date(dateArray[year], dateArray[month]-1, 1);
        const compareDate = new Date(today.getFullYear(), today.getMonth(), 1);
        if( testDate.getTime() < compareDate.getTime() ) {
          inputError = true;
          inputErrorMessage = i18n_datepicker_errorMessage_disabled || i18n_datepicker_errorMessage_default;
        }
      }

      // Disabled days are defined
      if( disabledDays ) {
        disabledDays.forEach((d) => {
          if (new Date(d[0], d[1] - 1, d[2]).getTime() === new Date(dateArray[year], dateArray[month]-1, dateArray[date]).getTime()) {
            inputError = true;
            inputErrorMessage = i18n_datepicker_errorMessage_disabled || i18n_datepicker_errorMessage_default
          }
        });
      }

      // weekends are disabled
      if( disableWeekends ) {
        const testDate = new Date(dateArray[year], dateArray[month]-1, dateArray[date]).getDay();
        if( testDate === 6 || testDate === 0) {
          inputError = true;
          inputErrorMessage = i18n_datepicker_errorMessage_weekend || i18n_datepicker_errorMessage_default
        }
      }

      // Outside visible range
      if( visibleRange ) {
        if(
          new Date(dateArray[year], dateArray[month]-1, dateArray[date]).getTime() < new Date(visibleRange[0][0], visibleRange[0][1], visibleRange[0][2]).getTime() ||
          new Date(dateArray[year], dateArray[month]-1, dateArray[date]).getTime() > new Date(visibleRange[1][0], visibleRange[1][1], visibleRange[1][2]).getTime()
          ) {
          inputError = true;
          inputErrorMessage = i18n_datepicker_errorMessage_disabled || i18n_datepicker_errorMessage_default
        }
      }
      if(!params.el) {
        this.inputStartRef.current.errorMessage(inputError ? inputErrorMessage : '');
      }

      else {
        params.el.current.errorMessage(inputError ? inputErrorMessage : '');
      }

/**
 * <-- / check disabled days
 */

      if (!isRange) {
        this.setState({
          month: Number(dateArray[month] - 1),
          year: Number(dateArray[year]),
          daySelected: [Number(dateArray[year]), Number(dateArray[month] - 1), Number(dateArray[date])]
        });
      }

      if (isRange && !inputError) {
        this.setState({
          month: Number(dateArray[month] - 1),
          year: Number(dateArray[year]),
        });

       const tempRangeStart = params.rangeStart ? [dateArray[year], dateArray[month], dateArray[date]]: [selectedRange[0][0], selectedRange[0][1]+1, selectedRange[0][2]];
       const tempRangeEnd = params.rangeEnd ?  [dateArray[year], dateArray[month], dateArray[date]] : [selectedRange[1][0], selectedRange[1][1]+1, selectedRange[1][2]];

        const compareStart = new Date(`${Number(tempRangeStart[0])}, ${Number(tempRangeStart[1])}, ${Number(tempRangeStart[2])}`);
        const compareEnd = new Date(`${Number(tempRangeEnd[0])}, ${Number(tempRangeEnd[1])}, ${Number(tempRangeEnd[2])}`);

        // Range end day is before start date
        if (compareStart > compareEnd) {
          rangeError = true;
          rangeErrorMessage = i18n_datepicker_errorMessage_endBeforeStart;
        }
        // Range is too long
        const msInDay = 24 * 60 * 60 * 1000; // milliseconds in a day
        const rangeLength = Math.round(Math.abs((compareStart - compareEnd) / msInDay));
        if (rangeMaxLength && rangeLength + 1 > rangeMaxLength) {
          rangeError = true;
          rangeErrorMessage = i18n_datepicker_errorMessage_rangeLength;
        }

        params.el.current.errorMessage(rangeError ? rangeErrorMessage : '');
        if(!rangeError) {
          this.setRange( [dateArray[year], dateArray[month], dateArray[date]], params.rangeStart, params.rangeEnd );
        }
      }
    }

    this.handleOnValueChange();
  };

  checkValidity = (trgt) => {
    const { dateInputPattern, i18n_datepicker_errorMessage_formatting} = this.props;

    if(trgt.current.getValue() !== '' && !dateInputPattern.test(trgt.current.getValue())) {
      trgt.current.errorMessage(i18n_datepicker_errorMessage_formatting);
    }
  }

  // Toggle calendar visibility
  showCalendar = ( show ) => {
      this.setState({ isHidden: !show });
  };

  // Change month
  changeMonth = (add) => {
    let { year } = this.state;
    const { month } = this.state;

    const newMonth = month + add;

    // change year when changing from december to january
    if (newMonth > 11) {
      this.setState({ month: 0, year: (year += 1) });
    }

    // change year when changing from january to december
    else if (newMonth < 0) {
      this.setState({ month: 11, year: (year -= 1) });
    } else {
      this.setState({ month: newMonth });
    }
  };

  // Set range
  setRange = (dayData, rangeStart, rangeEnd) => {
    const { selectedRange } = this.state;
    const { rangeMaxLength, dateFormat } = this.props;

    // from keyboard input
    if (rangeStart || rangeEnd ) {
      const dateArray = [dayData[0], dayData[1] - 1, dayData[2]];
      const tempRangeStart = selectedRange[0][0] ? selectedRange[0] : dateArray;
      const tempRangeEnd = selectedRange[1] && selectedRange[1][0] ? selectedRange[1] : dateArray;
      const range = [ rangeStart ? dateArray : tempRangeStart, rangeEnd ? dateArray : tempRangeEnd];

     this.setState({ selectedRange: range }, () => {
        this.handleOnValueChange();
      });
    return;
    }

    let tempHolder = [dayData, ...selectedRange];
    // Clear range
    if (tempHolder.length > 2) {
      tempHolder = [dayData];
    }
    if (tempHolder.length === 2) {
      const compareStart = new Date(tempHolder[0][0], tempHolder[0][1], tempHolder[0][2]);
      const compareEnd = new Date(tempHolder[1][0], tempHolder[1][1], tempHolder[1][2]);

      // Switch dates if start date is greater than end date
      if (compareStart > compareEnd) {
        const moveToEnd = tempHolder.shift(); // Take first date
        tempHolder.push(moveToEnd); // and move it to end
      }
      // Range max length
      const msInDay = 24 * 60 * 60 * 1000; // milliseconds in a day
      const rangeLength = Math.round(Math.abs((compareStart - compareEnd) / msInDay));
      if (rangeMaxLength && rangeLength + 1 > rangeMaxLength) {
        const rangeMaxEnd = new Date(tempHolder[0][0], tempHolder[0][1], tempHolder[0][2]);
        rangeMaxEnd.setDate(rangeMaxEnd.getDate() + (rangeMaxLength - 1));
        tempHolder[1] = [rangeMaxEnd.getFullYear(), rangeMaxEnd.getMonth(), rangeMaxEnd.getDate()];
      }
    }
    this.setState({ selectedRange: tempHolder }, () => {
      this.inputStartRef.current.value( this.handleDate({ dateString: `${tempHolder[0][0]}, ${tempHolder[0][1] + 1}, ${tempHolder[0][2]}`, dateFormat }));

      this.inputEndRef.current.errorMessage('');
      this.inputStartRef.current.errorMessage('');

      if(tempHolder[1]) {
        this.inputEndRef.current.value( this.handleDate({ dateString: `${tempHolder[1][0]}, ${tempHolder[1][1] + 1}, ${tempHolder[1][2]}`, dateFormat }));
      }
      this.handleOnValueChange();
    });
  };

  handleOnValueChange = () => {
    const { selectRange, onValueChange } = this.props;
    if (!selectRange) {
      // Need timeout here - otherwise it fires too fast and gets previous value
      setTimeout(() => {
        onValueChange(this.inputStartRef.current.getValue());
      }, 100);
    } else {
      // Need timeout here - otherwise it fires too fast and gets previous value
      setTimeout(() => {
        onValueChange({
          rangeStart: this.inputStartRef.current.getValue(),
          rangeEnd: this.inputEndRef.current.getValue(),
        });
      }, 100);
    }
  };

  render() {
    const {
      className,
      dateFormat,
      dateFormatLocale,
      localeOutputFormat,
      dateInputPattern,
      disabledDays,
      disablePreviousMonths,
      disableAllPastDays,
      disableWeekends,
      hideAfterSelect,
      i18n_datepicker_date_format,
      i18n_datepicker_end_label,
      i18n_datepicker_start_label,
      i18n_input_optionalText,
      i18n_datepicker_errorMessage,
      i18n_datepicker_errorMessage_default,
      i18n_datepicker_errorMessage_disabled,
      i18n_datepicker_errorMessage_weekend,
      i18n_datepicker_errorMessage_formatting,
      i18n_datepicker_errorMessage_endBeforeStart,
      i18n_datepicker_errorMessage_rangeLength,
      id,
      inputStartDateName,
      inputEndDateName,
      inputStartDateClass,
      inputStartDateId,
      inputEndDateClass,
      inputEndDdateClass,
      inputEndDateId,
      input_start_date_name, // eslint-disable-line camelcase
      input_end_date_name, // eslint-disable-line camelcase
      input_start_date_class, // eslint-disable-line camelcase
      input_start_date_id, // eslint-disable-line camelcase
      input_end_date_class, // eslint-disable-line camelcase
      input_end_date_id, // eslint-disable-line camelcase
      locale,
      padEmptyDays,
      optional,
      selectRange,
      defaultOpen,
      onValueChange,
      rangeMaxLength,
      selectedDay,
      dateMaxLength,
      debug,
      wrapRow,
      ...otherProps
    } = this.props;

    // Get day names
    function getWeekDays() {
      const baseDate = new Date();
      baseDate.setDate(baseDate.getDate() + ((1 + 7 - baseDate.getDay()) % 7 || 7)); // Set to Monday
      const weekDays = [];
      for (let i = 0; i < 7; i += 1) {
        weekDays.push(baseDate.toLocaleDateString(locale, { weekday: 'short' }));
        baseDate.setDate(baseDate.getDate() + 1);
      }
      return weekDays;
    }

    // Render day names
    const renderWeekDays = () => {
      const items = [];
      const weekDays = getWeekDays(locale);

      weekDays.forEach((weekday) => {
        items.push(<div key={`${this.htmlId}-weekday-${weekday}`}>{weekday}</div>);
      });
      return items;
    };

    const getFirstDayOfMonth = () => {
      const { year, month } = this.state;
      const day = new Date(year, month, 1).getDay();
      return day;
    };

    const getLastDayOfMonth = () => {
      const { year, month } = this.state;
      const day = new Date(year, month + 1, 0).getDay();
      return day;
    };

    // First day of the month is
    let monthStartPad = getFirstDayOfMonth();
    if (monthStartPad > 0) {
      monthStartPad -= 1;
    } // compensate the week starting day (which according to javascript is sunday)
    else {
      monthStartPad = 6;
    } // sunday is last day of the week

    // Last day of the month is
    let monthEndPad = getLastDayOfMonth(); // why +1 though?!
    if (monthEndPad > 0) {
      monthEndPad = 7 - monthEndPad;
    } // if last day is sunday, no days needs to be added

    /**
     *
     *  Render days starts here
     */
    const renderDays = () => {
      const { today, year, month, realToday, daySelected, selectedRange, visibleRange } = this.state;

      // Pad beginning of calendar if needed
      const days = []; // array to hold days in selected month
      const lastMonthLastDay = new Date(year, month - 1, 0).getDate(); // Add last days from last month in the beginning of calendar
      for (let i = monthStartPad; i > 0; i -= 1) {
        days.push(
          <div key={`${this.htmlId}-padStart-${i}`} className={classNames([styles.day, styles['not-this-month']])}>
            {padEmptyDays ? lastMonthLastDay - i + 1 : '\u00A0'}
          </div>
        );
      }

      // Current (selected) month days
      for (let i = 0, j = new Date(year, month + 1, 0).getDate(); i < j; i += 1) {
        const dayData = [year, month, i + 1];

        /* Check if day is disabled */
        let dayIsDisabled =
          // Disable past day is set or day is outside allowed range
          !!(
            (disableAllPastDays && ((i + 1 < today.getDate() && month <= today.getMonth() && year <= today.getFullYear()) || (month < today.getMonth() && year <= today.getFullYear()) || year < today.getFullYear())) ||
            // Outside range
            new Date(dayData.toString()).getTime() < new Date(visibleRange[0]) ||
            new Date(dayData.toString()).getTime() > new Date(visibleRange[1])
          );
        // No need to check disabled days array if day is already disabled above
        if (!dayIsDisabled) {
          disabledDays.forEach((day) => {
            // convert date to milliseconds since the ECMAScript epoch
            if (new Date(day[0], day[1] - 1, day[2]).getTime() === new Date(year, month, i + 1).getTime()) {
              dayIsDisabled = true;
            }
          });
        }
        // disable weekend days
        if (!dayIsDisabled && disableWeekends) {
          dayIsDisabled = !!((monthStartPad + i + 1) % 7 === 0 || (monthStartPad + i + 2) % 7 === 0);
        }
        // Add all relevant classes for the day
        /*
          Today (is date real today?)
          Date is selected
          Disabled (is listed in disabled days array or disable all past day is true and applicable )
          Weekend (saturdays and sundays)
          Hidden (when day is outside the visible range (if given))
        */

        const dayClasses = classNames([
          styles.day,
          styles['day-default'],

          // Today. Hide if any day is selected to avoid confusion
          month === realToday.getMonth() && year === realToday.getFullYear() && i === realToday.getDate() - 1 && daySelected[0] === null  && selectedRange.toString().replaceAll(',','') === '' ? styles.today : null,

          // Select day
          daySelected.toString() === dayData.toString() ? styles['day-selected'] : null,

          // Disabled day
          dayIsDisabled ? styles['day-disabled'] : null,

          // Weekend
          (monthStartPad + i + 1) % 7 === 0 || (monthStartPad + i + 2) % 7 === 0 ? styles['day-weekend'] : null,

          // Is outside visible range?
          visibleRange &&
          (new Date(Number(dayData[0]), Number(dayData[1]), Number(dayData[2])).getTime() < new Date(visibleRange[0][0], visibleRange[0][1], visibleRange[0][2]).getTime() ||
            new Date(Number(dayData[0]), Number(dayData[1]), Number(dayData[2])).getTime() > new Date(visibleRange[1][0], visibleRange[1][1], visibleRange[1][2]).getTime())
            ? styles['day-disabled']
            : null,

          // Is inside selected range
          selectedRange.length === 2 &&
          new Date(Number(dayData[0]), Number(dayData[1]), Number(dayData[2])) >= new Date(Number(selectedRange[0][0]), Number(selectedRange[0][1]), Number(selectedRange[0][2])) &&
          new Date(Number(dayData[0]), Number(dayData[1]), Number(dayData[2])) <= new Date(Number(selectedRange[1][0]), Number(selectedRange[1][1]), Number(selectedRange[1][2]))
            ? styles['day-selected-range']
            : null,

          // Range start
          // Here toString() comparison is ok to use
          selectedRange.length === 2 && dayData.toString() === selectedRange[0].toString() ? styles['day-selected-range-start'] : null,
          selectedRange.length === 2 && selectedRange[1].toString() !== selectedRange[0].toString() && dayData.toString() === selectedRange[1].toString() ? styles['day-selected-range-end'] : null,
          selectedRange.length === 2 && selectedRange[0].toString() === selectedRange[1].toString() && dayData.toString() === selectedRange[1].toString() ? styles['day-selected'] : null,

          selectedRange.length === 1 && dayData.toString() === selectedRange[0].toString() ? styles['day-selected'] : null,

        ]);

        // Apply styles
        days.push(
          <Button
            role="presentation"
            key={`${this.htmlId}-day-${(dayData[0], dayData[1], dayData[2])}`}
            tabIndex={!dayIsDisabled ? 0 : -1}
            onClick={() => {
              if (dayIsDisabled) {
                return;
              }
              if (selectRange && !dayIsDisabled) {
                this.setRange(dayData);
              } else {
                this.inputStartRef.current.value( this.handleDate({ dateString: `${dayData[0]}, ${dayData[1] + 1}, ${dayData[2]}`, dateFormat }));
                this.setState({ daySelected: dayData }, () => {
                  this.inputStartRef.current.errorMessage(''); // clear input error message
                  this.handleOnValueChange();
                });
              }
              if (hideAfterSelect) {
                this.showCalendar(false);
              }
              else if(!selectRange || selectedRange.length <= 1) {
                  this.inputStartRef.current.focus(true);
                }
                else {
                  this.inputEndRef.current.focus(true);
                }
            }}
            className={dayClasses}
          >
            <span>{i + 1}</span>
          </Button>
        );
      }

      // Pad end of calendar if needed
      for (let i = 0; i < monthEndPad; i += 1) {
        days.push(
          <div key={`${this.htmlId}-padEnd-${i}`} className={classNames([styles.day, styles['not-this-month']])}>
            {padEmptyDays ? i + 1 : '\u00A0'}
          </div>
        );
      }
      return days;
    };

    const { today, month, year, daySelected, isHidden, visibleRange } = this.state;
    const allClasses = classNames([styles.calendar, className]);

    // Hide or show next and previous month buttons
    const disablePrevMonth = !!(
      (disablePreviousMonths && month <= today.getMonth() && year <= today.getFullYear()) ||
      (month - 1 <= new Date(visibleRange[0]).getMonth() && year <= new Date(visibleRange[0]).getFullYear())
    );

    const disableNextMonth = !!(month > new Date(visibleRange[1]).getMonth() && year >= new Date(visibleRange[1]).getFullYear());

    const showErrorMessage = () => {
      if (i18n_datepicker_errorMessage) {
        return { i18n_input_errorMessage: i18n_datepicker_errorMessage };
      }

      return false;
    };

    return (
      <>
        {/* Normal date select */}

        <span ref={this.triggerRef} id={this.htmlId} className={classNames([styles['datepicker-wrapper'],  wrapRow ? styles['datepicker-wrapper__row'] : null ])}>
        {!selectRange && (
            <Input
              autocomplete="off"
              className={inputStartDateClass || input_start_date_class} // eslint-disable-line camelcase
              icon={<IconCalendarRegular alt="" aria-hidden="true" />}
              id={inputStartDateId || input_start_date_id || `${this.htmlId}Input`} // eslint-disable-line camelcase
              i18n_input_optionalText={i18n_input_optionalText}
              {...showErrorMessage()}
              label={i18n_datepicker_start_label} // eslint-disable-line camelcase
              name={inputStartDateName || input_start_date_name} // eslint-disable-line camelcase
              onFocus={ () => { this.showCalendar(true); }}
              onKeyDown={(e)=>{
                if(e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                  this.showCalendar(true);
                }
                if(e.key === 'Escape') {
                  this.showCalendar(false);
                }
              }}
              onKeyUp={(event) => {
                this.setDateFromInput({ isRange: false, day: event.currentTarget.value, el: this.inputStartRef });
              }}

              onClick={ () => {
                if( isHidden ) {
                  this.setState({ isHidden: false });
                }
              }}

              onBlur={(e) => {
                if(!document.getElementById(this.htmlId).contains(e.nativeEvent.relatedTarget)) {
                  this.showCalendar(false);
                }
                this.checkValidity(this.inputStartRef)
              }}
              optional={optional || null}
              placeholder={dateFormatLocale || dateFormat}
              ref={this.inputStartRef}
              aria-label={`${i18n_datepicker_date_format} ${dateFormatLocale}`}
              type="text"
              defaultValue={this.handleDate({ dateString: `${daySelected[0]}, ${daySelected[1] + 1}, ${daySelected[2]}`, dateFormat })}
              maxlength={dateMaxLength}
            />
        )}

        {/* Range select */}
        {selectRange && (
          <>
            <Input
              autocomplete="off"
              className={inputStartDateClass || input_start_date_class} // eslint-disable-line camelcase
              icon={<IconCalendarRegular alt="" aria-hidden="true" />}
              id={inputStartDateId || input_start_date_id || `${this.htmlId}Input`} // eslint-disable-line camelcase
              i18n_input_optionalText={i18n_input_optionalText}
              label={i18n_datepicker_start_label} // eslint-disable-line camelcase
              name={inputStartDateName || input_start_date_name} // eslint-disable-line camelcase

              optional={optional || null}
              placeholder={dateFormat}
              ref={this.inputStartRef}
              type="text"

              onKeyDown={(e)=>{
                if(e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                  this.showCalendar(true);
                }
                if(e.key === 'Escape') {
                  this.showCalendar(false);
                }
              }}
              onKeyUp={(event) => {
                this.setDateFromInput({ isRange: true, day: event.currentTarget.value, el: this.inputStartRef, rangeStart: true, rangeEnd: false });
              }}

              onClick={ () => {
                if( isHidden ) {
                  this.setState({ isHidden: false });
                }
              }}

              onBlur={(e) => {
                if(!document.getElementById(this.htmlId).contains(e.nativeEvent.relatedTarget)) {
                  this.showCalendar(false);
                }
                this.checkValidity(this.inputStartRef);
              }}
              maxlength={dateMaxLength}
            />

            <Input
              autocomplete="off"
              className={inputEndDateClass || inputEndDdateClass || input_end_date_class} // eslint-disable-line camelcase
              icon={<IconCalendarRegular alt="" aria-hidden="true" />}
              id={inputEndDateId || input_end_date_id} // eslint-disable-line camelcase
              i18n_input_optionalText={i18n_input_optionalText}
              label={i18n_datepicker_end_label} // eslint-disable-line camelcase
              name={inputEndDateName || input_end_date_name} // eslint-disable-line camelcase
              optional={optional || null}
              placeholder={dateFormat}
              ref={this.inputEndRef}
              type="text"

              onKeyDown={(e)=>{
                if(e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                  this.showCalendar(true);
                }
                if(e.key === 'Escape') {
                  this.showCalendar(false);
                }
              }}
              onKeyUp={(event) => {
                this.setDateFromInput({ isRange: true, day: event.currentTarget.value, el: this.inputEndRef,  rangeStart: false, rangeEnd: true });
              }}

              onClick={ () => {
                if( isHidden ) {
                  this.setState({ isHidden: false });
                }
              }}

              onBlur={(e) => {
                if(!document.getElementById(this.htmlId).contains(e.nativeEvent.relatedTarget)) {
                  this.showCalendar(false);
                }

                this.checkValidity(this.inputEndRef);
              }}
              maxlength={dateMaxLength}
            />
          </>
        )}

        <div className={allClasses} data-show={defaultOpen ? 'true' : null} hidden={isHidden} id={`${this.htmlId}__calendar`} ref={this.popupRef} {...omit(otherProps, 'visibleRange')}>
          <div className={styles['month-container']}>
            <Button
              color='white'
              id={`${this.htmlId}PrevMonthBtn`}
              role="button"
              tabIndex="0"
              className={classNames([disablePrevMonth ? styles['button-disabled'] : null, styles['prev-month']])}
              onClick={() => {
                if (disablePrevMonth) {
                  return;
                }
                this.changeMonth(-1);
              }}
              onKeyUp={(e) => {
                if (e.key === KEY_RETURN) {
                  if (disablePrevMonth) {
                    return false;
                  }
                }
                return '';
              }}
            >
              <IconArrowLeftFilled color={disablePrevMonth ? 'white' : 'primary'} />
            </Button>
            <h4>
              {new Date(year, month, 1).toLocaleString(locale, { month: 'long' })} {year}
            </h4>
            <Button
              color='white'
              id={`${this.htmlId}NextMonthBtn`}
              role="button"
              tabIndex="0"
              className={classNames([disableNextMonth ? styles['button-disabled'] : null, styles['next-month']])}
              onClick={() => {
                if (disableNextMonth) {
                  return false
                }
                this.changeMonth(1);
                return '';
              }}
              onKeyUp={(e) => {
                if (e.key === KEY_RETURN) {
                  if (disableNextMonth) {
                    return false;
                  }
                }
                return '';
              }}
            >
              <IconArrowRightFilled color={disableNextMonth ? 'white' : 'primary'} />
            </Button>
          </div>
          <div className={styles['daynames-container']}>{renderWeekDays()}</div>
          <div className={styles['days-container']}>{renderDays()}</div>
        </div>

      </span>
      </>
    );
  }
}

DatePicker.propTypes = {
  /** Element's id */
  id: PropTypes.string,
  /** Class names you want to give to the component */
  className: PropTypes.string,
  /** Date format */
  dateFormat: PropTypes.string,
  /** Date format shown in input placeholder */
  dateFormatLocale: PropTypes.string,
  /** this is not working ? */
  dateInputPattern: PropTypes.instanceOf(RegExp),
  /** Date max lenght */
  dateMaxLength: PropTypes.number,
  /** LEGACY - not used anymore */
  debug: PropTypes.bool,
  /** Disaled days. format [[ yyyy,mm,dd ]] */
  disabledDays: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  /** Allow or disallow user to navigate to past */
  disablePreviousMonths: PropTypes.bool,
  /** Disable all past days */
  disableAllPastDays: PropTypes.bool,
  /** Disable weekends */
  disableWeekends: PropTypes.bool,
  /** Hide modal automatically after date is picked */
  hideAfterSelect: PropTypes.bool,
  /** Label for start date */
  i18n_datepicker_start_label: PropTypes.string,
  /** Label for end date */
  i18n_datepicker_end_label: PropTypes.string,
  /** Optional field indicator text */
  i18n_input_optionalText: PropTypes.string,
  /** Label for date validation error */
  i18n_datepicker_errorMessage: PropTypes.string,
  /** Label for disabled day error */
  i18n_datepicker_errorMessage_default: PropTypes.string,
  /** Label for default error on selected day */
  i18n_datepicker_errorMessage_disabled: PropTypes.string,
  /** Label for weekend day error */
  i18n_datepicker_errorMessage_weekend: PropTypes.string,
  /** Label for date format error */
  i18n_datepicker_errorMessage_formatting: PropTypes.string,
  /* info text for date format */
  i18n_datepicker_date_format: PropTypes.string,
  /* Info text for end day being before start date */
  i18n_datepicker_errorMessage_endBeforeStart: PropTypes.string,
  /* Info text for too long selected range */
  i18n_datepicker_errorMessage_rangeLength: PropTypes.string,
  /** input name for starting date */
  inputStartDateName: PropTypes.string,
  /** input name for ending date */
  inputEndDateName: PropTypes.string,
  /** start date input className */
  inputStartDateClass: PropTypes.string,
  /** start date input id */
  inputStartDateId: PropTypes.string,
  /** end date input className */
  inputEndDateClass: PropTypes.string,
  /** end date input id */
  inputEndDateId: PropTypes.string,
  /** Class names you want to give to the component wrapper */
  wrapRow: PropTypes.bool,
  /**
   * @deprecated Use `inputStartDateName` property instead. `input_start_date_name` property will be removed in a future version (during summer 2024).
   */
  input_start_date_name: PropTypes.string, // eslint-disable-line camelcase
  /**
   * @deprecated Use `inputEndDateName` property instead. `input_end_date_name` property will be removed in a future version (during summer 2024).
   */
  input_end_date_name: PropTypes.string, // eslint-disable-line camelcase
  /**
   * @deprecated Use `inputStartDateClass` property instead. `input_start_date_class` property will be removed in a future version (during summer 2024).
   */
  input_start_date_class: PropTypes.string, // eslint-disable-line camelcase
  /**
   * @deprecated Use `inputStartDateId` property instead. `input_start_date_id` property will be removed in a future version (during summer 2024).
   */
  input_start_date_id: PropTypes.string, // eslint-disable-line camelcase
  /**
   * @deprecated Use `inputEndDateClass` property instead. `input_end_date_class` property will be removed in a future version (during summer 2024).
   */
  input_end_date_class: PropTypes.string, // eslint-disable-line camelcase
  /** 
   * @deprecated Use `inputEndDateClass` property instead. `inputEndDdateClass` property will be removed in a future version (during summer 2024).
   */
  inputEndDdateClass: PropTypes.string,
  /**
   * @deprecated Use `inputEndDateId` property instead. `input_end_date_id` property will be removed in a future version (during summer 2024).
   */
  input_end_date_id: PropTypes.string, // eslint-disable-line camelcase
  /** Locale. Default fi-FI */
  locale: PropTypes.string,
  /** Locale options */
  localeOutputFormat: PropTypes.shape({
    weekday: PropTypes.string,
    year: PropTypes.string,
    month: PropTypes.string,
    day: PropTypes.string,
  }),
  /** Add previous and last month days to beginning and end of calendar when week does not start on monday and/or end on sunday */
  padEmptyDays: PropTypes.bool,
  /** range max length */
  rangeMaxLength: PropTypes.number,
  /** Whether field is optional or not. By default all fields are mandatory. */
  optional: PropTypes.bool,
  /** Predefined selected day, format [ yyyy, mm, dd ] */
  selectedDay: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.instanceOf(Date)]),
  /** enable range select */
  selectRange: PropTypes.bool,
  /** What month is shown when the modal opens. Does not affect the _actual_ today which we take from javascript date object instead. Format [[ yyyy, mm, dd ]], where day and month are 1-based (i.e. first day of january is 1.1.). Year is also normal, 2023 is 2023. */
  today:  PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.instanceOf(Date)]),
  /** Visible range (that user can pick the date from). Format [ yyyy, mm, dd ], where day and month are 1-based (i.e. first day of january is 1.1.). Year is also normal, 2023 is 2023. */
  visibleRange: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  ]),
  /** Returns input value as a string for single day picker and object for range selector { rangeStart: "x.x.xxxx", rangeEnd: "y.y.yyyy" } */
  onValueChange: PropTypes.func,
  /** Whether the datepicker's calendar is open by default */
  defaultOpen: PropTypes.bool,
};

DatePicker.defaultProps = {
  id: null,
  className: null,
  dateFormat: 'dd.mm.yyyy',
  dateFormatLocale: 'pp.kk.vvvv',
  dateInputPattern: /\d{1,2}[-/.]\d{1,2}[-/.]\d{4}/,
  dateMaxLength: 10,
  debug: false,
  disabledDays: [],
  disableAllPastDays: false,
  disablePreviousMonths: false,
  disableWeekends: false,
  hideAfterSelect: true,
  i18n_datepicker_date_format: 'Anna päivämäärä muodossa',
  i18n_datepicker_start_label: 'Valitse päivä (pp.kk.vvvv)',
  i18n_datepicker_end_label: 'Valitse päivä',
  i18n_input_optionalText: '(ei pakollinen)',
  i18n_datepicker_errorMessage: null,
  i18n_datepicker_errorMessage_default: 'Valittu päivä ei käy',
  i18n_datepicker_errorMessage_disabled: 'Valittu päivä ei ole valittavissa',
  i18n_datepicker_errorMessage_weekend: 'Viikonlopun päivät eivät ole valittavissa',
  i18n_datepicker_errorMessage_formatting: 'Tarkista päivämäärän muoto',
  i18n_datepicker_errorMessage_endBeforeStart: 'Lopetuspäivä ei voi olla ennen aloituspäivää',
  i18n_datepicker_errorMessage_rangeLength: 'Valittu jakso on liian pitkä',
  inputStartDateName: 'datestart',
  inputEndDateName: 'dateend',
  inputStartDateClass: null,
  inputStartDateId: null,
  inputEndDateClass: null,
  inputEndDdateClass: null,
  inputEndDateId: null,
  input_start_date_name: 'datestart', // eslint-disable-line camelcase
  input_end_date_name: 'dateend', // eslint-disable-line camelcase
  input_start_date_class: null, // eslint-disable-line camelcase
  input_start_date_id: null, // eslint-disable-line camelcase
  input_end_date_class: null, // eslint-disable-line camelcase
  input_end_date_id: null, // eslint-disable-line camelcase
  locale: 'fi-FI',
  localeOutputFormat: { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' },
  padEmptyDays: false,
  rangeMaxLength: 0,
  optional: false,
  selectedDay: [null, null, null],
  selectRange: false,
  today: [new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()],
  visibleRange: [
    [2000, 1, 1],
    [2100, 1, 1],
  ],
  onValueChange: () => {
    /* NOOP */
  },
  /** Modal */
  defaultOpen: false,
  wrapRow: false
};

export default DatePicker;
