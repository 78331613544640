// @flow

/* eslint-disable jsx-a11y/label-has-for */

import * as R from 'ramda';
import React, { Component } from 'react';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import classNames from 'classnames';
import type { IdTreeT } from '../../ducks/entities/department/departmentTypes';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import styles from './IdTreeCombobox.module.scss';

export type PropsT = {|
  disabled: boolean,
  label: string,
  hideKey?: ?string,
  hideKeyAndChildren: ?string,
  options: IdTreeT,
  onChange: (*) => *,
  value: { label: string, value: string },
  renderItem: *,
  hasEmptyOption: boolean,
  hasRootOption: boolean,
  rootOptionKey: ?string,
  className?: string
|};

type StateT = {
  opened: boolean
};

export class IdTreeCombobox extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.createItems = this.createItems.bind(this);
    this.traverse = this.traverse.bind(this);
    this.createListItem = this.createListItem.bind(this);
    this.createRootItem = this.createRootItem.bind(this);
  }

  onOptionChange: *;

  onOptionChange(option: string) {
    this.props.onChange(option);
  }

  createItems: () => Array<*>;

  createItems(): Array<*> {
    let items = this.props.hasEmptyOption ? [this.createListItem('empty')] : [];
    items = this.props.hasRootOption ? R.prepend(this.createRootItem(), items) : items;
    if (this.props.options) {
      this.traverse(this.props.options, items);
    }
    return items;
  }

  createListItem: (string | null) => *;

  createListItem(id: string | null) {
    return {
      label: this.props.renderItem({ _value: id }),
      value: id,
      html: this.props.renderItem({
        _value: id
      })
    };
  }

  createRootItem: (*) => *;

  createRootItem() {
    const item = {
      value: this.props.rootOptionKey,
      label: '>'
    };

    return {
      ...item,
      html: (
        <div>
          <HomeIcon /> &gt;
        </div>
      )
    };
  }

  traverse: *;

  traverse(options: IdTreeT, items: *) {
    options.forEach(({ id, children }) => {
      if (this.props.hideKeyAndChildren !== id) {
        if (this.props.hideKey !== id) {
          items.push(this.createListItem(id));
        }
        if (children != null) {
          this.traverse(children, items);
        }
      }
    });
  }

  render() {
    const { disabled, value, label } = this.props;

    const selectedValue = value.value;
    return (
      <Dropdown
        items={this.createItems()}
        label={label}
        disabled={disabled}
        selectedValue={selectedValue}
        className={classNames(styles['target-options'], this.props.className)}
        onValueChange={option => {
          this.onOptionChange({ label: option.dataset.label, value: option.dataset.value });
        }}
      />
    );
  }
}

export default IdTreeCombobox;
