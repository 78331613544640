/* eslint-disable max-classes-per-file */
import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { classNames } from '../../utils/css';
import { useIdWithFallback } from '../../utils/hooks';

import Popover from '../Popover';
import IconInformationRegular from '../Icon/lib/IconInformationRegular';

import styles from './CheckboxGroup.module.scss';

function CheckboxGroupInternal(
  {
    id = null,
    className = null,
    label = null,
    i18n_checkboxgroup_infoText = null,
    i18n_checkboxgroup_optionalText = '(ei pakollinen)',
    optional = false,
    children = null,
    disabled = null,
    toggleController = null,
    value = [],
    ...otherProps
  },
  ref
) {
  const htmlId = useIdWithFallback('dsCheckboxGroup', id);
  const groupRef = useRef(null);
  const [groupValue, setGroupValue] = useState(value);
  const [isDisabled, setIsDisabled] = useState(disabled);

  useImperativeHandle(ref, () => ({
    value: (newValue) => {
      const ids = [];
      children.forEach((element) => {
        ids.push(element.props.id);
      });

      if (newValue) {
        setGroupValue(newValue);

        ids.forEach((oneid) => {
          const el = document.querySelector(`#${oneid}`);

          if (newValue?.includes(el.value)) {
            el.checked = true;
          } else {
            el.checked = false;
          }
        });
      } else {
        setGroupValue(newValue);

        ids.forEach((oneid) => {
          const el = document.querySelector(`#${oneid}`);
          el.checked = false;
        });
      }
    },

    getValue: () => groupValue,

    disabled: (disable) => {
      if (disable === true || disable === false) {
        setIsDisabled(disable);
        return undefined;
      }
      return isDisabled;
    },
  }));

  const toggleAll = () => {
    const toggleControllerElement = document.querySelector(`#${toggleController}`);
    const ids = [];
    children.forEach((element) => {
      ids.push(element.props.id);
    });

    const values = [];
    ids.forEach((oneid) => {
      if (oneid !== toggleController) {
        const el = document.querySelector(`#${oneid}`);
        el.checked = toggleControllerElement.checked;

        if (toggleControllerElement.checked === true) {
          values.push(el.value);
        }
      }
    });

    setGroupValue(values);
  };

  const handleChildClick = (e) => {
    const toggleControllerElement = document.querySelector(`#${toggleController}`);
    if (e.target !== toggleControllerElement) {
      const index = groupValue.indexOf(e.target.value);

      if (index > -1 && e.target.checked === false) {
        groupValue.splice(index, 1);
        toggleControllerElement.checked = false;
      } else if (index === -1 && e.target.checked === true) {
        groupValue.push(e.target.value);
      }
    }
  };

  useEffect(() => {
    const ids = [];
    children.forEach((element) => {
      ids.push(element.props.id);
    });

    ids.forEach((oneid) => {
      const el = document.querySelector(`#${oneid}`);

      if (value?.includes(el.value)) {
        el.checked = true;
      }
    });
  }, [value]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    const toggleControllerElement = document?.querySelector(`#${toggleController}`);
    if (toggleControllerElement) {
      toggleControllerElement.addEventListener('click', toggleAll);
    }

    return () => {
      if (toggleControllerElement) {
        toggleControllerElement.removeEventListener('click', toggleAll);
      }
    };
  });

  useEffect(() => {
    const ids = [];
    children.forEach((element) => {
      ids.push(element.props.id);
    });

    ids.forEach((oneid) => {
      const el = document.querySelector(`#${oneid}`);
      el?.addEventListener('click', handleChildClick);
    });

    return () => {
      ids.forEach((oneid) => {
        const el = document.querySelector(`#${oneid}`);
        el?.removeEventListener('click', handleChildClick);
      });
    };
  });

  let optionalIndicator = null;
  if (optional === true) {
    optionalIndicator = i18n_checkboxgroup_optionalText;
  }

  const allClasses = classNames([styles[`checkbox-group`], isDisabled ? styles[`checkbox-group-disabled`] : null, className]);

  return (
    <fieldset id={htmlId} key={htmlId} role="group" ref={groupRef} className={allClasses} {...otherProps} disabled={isDisabled}>
      {label || optionalIndicator ? (
        <legend className={styles[`checkbox-group--labelarea-label`]}>
          <span className={styles[`checkbox-group--labelarea-label-content`]}>
            {label}
            {optionalIndicator ? ` ${optionalIndicator}` : null}
          </span>
          {i18n_checkboxgroup_infoText ? <Popover triggerElement={<IconInformationRegular />} placement="top" i18n_popover_contentText={i18n_checkboxgroup_infoText} /> : null}
        </legend>
      ) : null}
      {children}
    </fieldset>
  );
}

const CheckboxGroup = React.forwardRef(CheckboxGroupInternal);
export default CheckboxGroup;

CheckboxGroup.propTypes = {
  /**
   * Group's id
   */
  id: PropTypes.string,
  /**
   * Any CSS classes for the component
   */
  className: PropTypes.string,
  /**
   * Label text for the checkbox group
   */
  label: PropTypes.string,
  /**
   * Whether field is optional or not. By default all fields are mandatory.
   */
  optional: PropTypes.bool,
  /**
   * Optional field indicator text
   */
  i18n_checkboxgroup_optionalText: PropTypes.string,
  /**
   * Additional information and instructions for the checkbox group
   */
  i18n_checkboxgroup_infoText: PropTypes.string,
  /**
   * Component's content. Automatically detected.
   */
  children: PropTypes.node,
  /**
   * Whether all the Checkboxes in the group are disabled.
   * NOTE: This is done via CSS. We recommend disabling each Checkbox separately.
   */
  disabled: PropTypes.bool,
  /**
   *
   */
  toggleController: PropTypes.string,
  /**
   *
   */
  value: PropTypes.arrayOf(PropTypes.string),
};
