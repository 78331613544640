import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from '../Login.module.scss';

class LoginCreate extends Component {
  static propTypes = {
    /** Object with i18n strings */
    translations: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { createLoginUrl, createLoginClick } = this.props;

    const { i18n_login_createLoginText } = this.props.translations;

    return (
      <>
        {i18n_login_createLoginText && createLoginUrl ? (
          <div className={styles.login__create}>
            <a href={createLoginUrl} onClick={createLoginClick}>
              {i18n_login_createLoginText}
            </a>
          </div>
        ) : null}
      </>
    );
  }
}

export default LoginCreate;
