// @flow strict-local

import React, { type Element, useEffect, useState } from 'react';
import * as R from 'ramda';
import Input from '@design-system/component-library/src/components/Input';
import Radio from '@design-system/component-library/src/components/Radio';
import { useDispatch } from 'react-redux';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { CancelToken } from 'axios';
import { useTranslation } from 'react-i18next';
import Search from '@design-system/component-library/src/components/Search';
import IconSearchRegular from '@design-system/component-library/src/components/Icon/lib/IconSearchRegular';
import { useFormContext } from 'react-hook-form';
import type { UserListElementT } from '../../../../ducks/entities/user/userTypes';
import { SubstituteSelection } from './SubstituteSelection';
import CenterHorizontally from '../../../../components/CenterHorizontally/CenterHorizontally';
import { getAllForwardingTargets } from '../../../../ducks/entities/directory/directoryOperations';
import type { DirectoryFwdT } from '../../../../ducks/entities/directory/directoryOperations';
import Tooltip from '../../../../components/Tooltip';
import { PHONE_NUMBER_MAX_LENGTH } from '../../../../fieldValidators';

import styles from './ForwardingTarget.module.scss';

type OwnPropsT = {|
  field: string,
  enterpriseId: string,
  substitutes: UserListElementT[],
  enterpriseRule: boolean,
  errorMessage: string,
  userIsLoading?: boolean,
  filterSearchResults?: DirectoryFwdT => boolean
|};

export type PropsT = OwnPropsT;
let requestDestinationsCancelTokenSource;

export const ForwardingTarget = ({
  field,
  substitutes,
  enterpriseId,
  enterpriseRule,
  errorMessage,
  userIsLoading
}: PropsT): Element<'div' | typeof CenterHorizontally> => {
  const { t } = useTranslation();
  const [searchResults, setSearchResults] = useState<DirectoryFwdT[]>([]);
  const [searchKey, setSearchKey] = useState(0);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const dispatch = useDispatch();
  // form
  const { setValue, watch } = useFormContext();
  const values = watch();

  const fetchDirectories = async searchText => {
    if (requestDestinationsCancelTokenSource) {
      requestDestinationsCancelTokenSource.cancel();
    }
    requestDestinationsCancelTokenSource = CancelToken.source();
    const results = await dispatch(
      getAllForwardingTargets(enterpriseId, requestDestinationsCancelTokenSource.token, {
        search: searchText
      })
    );

    if (results) {
      setSearchResults(results);
    }
  };

  useEffect(() => {
    requestDestinationsCancelTokenSource = CancelToken.source();
    const fetchTargets = async () => {
      setShowLoadingSpinner(true);
      await fetchDirectories('');
      setShowLoadingSpinner(false);
      if (values[field].target.value !== '') {
        setSearchKey(new Date().getTime());
      }
    };
    fetchTargets();
    return () => {
      requestDestinationsCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const subtituteSelection = <SubstituteSelection field={field} substitutes={substitutes} />;
  const substituteSelected =
    values[field] &&
    values[field].target.type === 'INTERNAL' &&
    !!substitutes.find(sub => sub.addressNumber === values[field].target.value);

  const parseFieldValue = () => {
    if (values[field] && !values[field].target.type) {
      return 'ENT_VM';
    }
    if (substituteSelected) {
      return 'SUBSTITUTE';
    }
    if (
      values[field] &&
      values[field].target.type === 'INTERNAL' &&
      !substitutes.find(sub => sub.addressNumber === values[field].target.value)
    ) {
      return 'INTERNAL';
    }

    return values[field] ? values[field].target.type : '';
  };

  const radioOnChange = (type: string, value: string = '') => {
    setSearchResults([]);
    setValue(
      field,
      {
        ...values[field],
        target: {
          type,
          value,
          text: ''
        }
      },
      {
        shouldValidate: true,
        shouldDirty: true
      }
    );
  };

  const fieldValue = parseFieldValue();

  return showLoadingSpinner ? (
    <CenterHorizontally>
      <LoadingSpinner />
    </CenterHorizontally>
  ) : (
    <div className={styles['forwarding-target-area']}>
      {errorMessage && (
        <div data-cy="forwarding-target-error" className={styles['specific-validation-error']}>
          {errorMessage}
        </div>
      )}
      <Radio.Group value={fieldValue}>
        <Radio
          id="forwarding-details-target-option-1"
          name="target-option"
          onChange={() => radioOnChange('ENT_VM')}
          className={styles.radio}
          label={t('forwardingTarget.targetToVoicemail')}
          value="ENT_VM"
        />
        <Radio
          id="forwarding-details-target-option-2"
          name="target-option"
          onChange={() => radioOnChange('ENT_RCPT')}
          className={styles.radio}
          label={t('forwardingTarget.targetToRcpt')}
          value="ENT_RCPT"
        />
        {!enterpriseRule && (
          <div className={styles['option-area']}>
            <div>
              <Radio
                id="forwarding-details-target-option-sub"
                name="target-option"
                onChange={() => radioOnChange('INTERNAL', substitutes[0].addressNumber)}
                className={styles.radio2}
                label={`${t('forwardingTarget.targetToSubstitute')}${
                  substitutes && substitutes.length === 1 && substitutes[0].userName
                    ? `: ${substitutes[0].userName}`
                    : ''
                }`}
                value="SUBSTITUTE"
                disabled={!substitutes || R.isEmpty(substitutes)}
              />
              {substituteSelected && substitutes && substitutes.length > 1 && subtituteSelection}
            </div>
            {userIsLoading && (
              <CenterHorizontally>
                <LoadingSpinner />
              </CenterHorizontally>
            )}
          </div>
        )}
        <Radio
          id="enterprise-forwarding-details-target-option-3"
          name="target-option"
          onChange={() => {
            setSearchResults([]);
            radioOnChange('INTERNAL', '');
          }}
          className={styles.radio3}
          label={
            <div className={styles['label-container']}>
              {t('forwardingTarget.targetToInternal')}
              <div className={styles.tooltip}>
                <Tooltip>{t('forwardingTarget.transferInternalTooltip')}</Tooltip>
              </div>
            </div>
          }
          value="INTERNAL"
        />
        {values[field].target.type === 'INTERNAL' &&
          !substitutes.find(sub => sub.addressNumber === values[field].target.value) && (
            <Search
              key={searchKey}
              id="forwarding_search_internal"
              className={styles['search-area']}
              items={searchResults}
              selectedValue={values[field].target.value}
              i18n_search_helpText={t('callflows.acdForwardingField.transferInternalInfo')}
              i18n_search_errorMessage={t('callflows.acdForwardingField.transferInternalError')}
              onSearch={value => {
                setValue(
                  field,
                  {
                    ...values[field],
                    target: {
                      type: 'INTERNAL',
                      value: ''
                    }
                  },
                  {
                    shouldValidate: true,
                    shouldDirty: true
                  }
                );
                fetchDirectories(value);
              }}
              customSearchIcon={<IconSearchRegular />}
              onValueChange={element => {
                setValue(
                  field,
                  {
                    ...values[field],
                    target: {
                      type: 'INTERNAL',
                      value: element.dataset.value,
                      text: element.innerText
                    }
                  },
                  {
                    shouldValidate: true,
                    shouldDirty: true
                  }
                );
              }}
            />
          )}
        <Radio
          id="enterprise-forwarding-details-target-option-4"
          name="target-option"
          onChange={() =>
            radioOnChange('EXTERNAL', values[field].type === 'EXTERNAL' ? values[field].value : '')
          }
          className={styles.radio}
          label={t('forwardingTarget.targetToExternal')}
          value="EXTERNAL"
        />
        {values[field].target.type === 'EXTERNAL' && (
          <Input
            id="external-target-input"
            onValueChange={e =>
              setValue(
                field,
                {
                  ...values[field],
                  target: {
                    type: 'EXTERNAL',
                    value: e.currentTarget.value
                  }
                },
                {
                  shouldValidate: true,
                  shouldDirty: true
                }
              )
            }
            defaultValue={values[field].target.value || ''}
            maxlength={PHONE_NUMBER_MAX_LENGTH}
            className={styles['external-target']}
          />
        )}
        <Radio
          id="enterprise-forwarding-details-target-option-5"
          name="target-option"
          onChange={() =>
            setValue(
              field,
              {
                ...values[field],
                target: {
                  type: 'REJECTION',
                  value: values[field].target.type === 'REJECTION' ? values[field].target.value : ''
                }
              },
              {
                shouldValidate: true,
                shouldDirty: true
              }
            )
          }
          className={styles.radio}
          label={t('forwardingTarget.targetToRejection')}
          value="REJECTION"
        />
      </Radio.Group>
    </div>
  );
};

export default ForwardingTarget;
