import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from '../Login.module.scss';

class LoginHelp extends Component {
  static propTypes = {
    /** Object with i18n strings */
    translations: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { loginHelpUrl, loginHelpClick } = this.props;

    const { i18n_login_loginHelpText } = this.props.translations;

    return (
      <>
        {i18n_login_loginHelpText && loginHelpUrl ? (
          <div className={styles.login__help}>
            <a href={loginHelpUrl} onClick={loginHelpClick}>
              {i18n_login_loginHelpText}
            </a>
          </div>
        ) : null}
      </>
    );
  }
}

export default LoginHelp;
