// @flow
import React, { type Element } from 'react';
import Radio from '@design-system/component-library/src/components/Radio';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import AudioFileSelection from './AudioFileSelection';
import type {
  VoiceMailAudioTypesT,
  VoiceMailEntityT
} from '../../../../../../ducks/entities/voicemailTypes';
import Tooltip from '../../../../../../components/Tooltip';

import styles from './VoiceMailAudioField.module.scss';

export type PropsT = {|
  field: string,
  title?: string,
  description?: string,
  voicemailSettings: VoiceMailEntityT,
  enterpriseId: string,
  entityId: string,
  serviceType: VoiceMailAudioTypesT
|};

export type VoiceMailAudioFieldT = {
  level: 'PhoneNumber' | 'AudioName' | 'Greeting',
  audioNameFileToImport: *,
  greetingAudioToImport: *
};

export const VoiceMailAudioField = (props: PropsT): Element<'div'> => {
  const {
    field,
    title,
    description,
    enterpriseId,
    entityId,
    voicemailSettings,
    serviceType
  } = props;

  // form
  const { setValue, watch } = useFormContext();
  const values = watch();
  const audioField: VoiceMailAudioFieldT = values[field];

  const { t } = useTranslation();

  const hasAudioNameFile = !!R.path(
    ['audios', 'audioName', 'files', 'durationInSeconds'],
    voicemailSettings
  );
  const hasGreetingFile = !!R.path(
    ['audios', 'greeting', 'files', 'durationInSeconds'],
    voicemailSettings
  );

  const audioNameAudio = {
    enterpriseId,
    callflowType: 'acds',
    callflowId: entityId,
    audioType: 'voicemail',
    propertyName: 'AudioName',
    serviceType
  };

  const greetingAudio = {
    enterpriseId,
    callflowType: 'acds',
    callflowId: entityId,
    audioType: 'voicemail',
    propertyName: 'Greeting',
    serviceType
  };

  return (
    <div className={title && styles['title-padding']}>
      {title && <div className={classnames(styles['small-title'])}>{title}</div>}
      <div className={styles.description}>
        {description === undefined ? t('callflows.audioField.description') : description}
      </div>
      <div
        className={styles.container}
        role="group"
        aria-label="Message type"
        id="voicemail-type-selection"
      >
        <Radio.Group value={audioField.level}>
          <div className={styles['radio-container']}>
            <Radio
              name="voicemailMessage"
              label={t('voicemailSettings.audioDefault')}
              value="PhoneNumber"
              className={styles.radio}
              onChange={() =>
                setValue(
                  field,
                  {
                    ...values[field],
                    level: 'PhoneNumber'
                  },
                  {
                    shouldValidate: false,
                    shouldDirty: true
                  }
                )
              }
            />
            <Tooltip>
              {parse(
                `${t('voicemailSettings.audioDefaultTooltip1')}<br/><br/>${t(
                  'voicemailSettings.audioDefaultTooltip2'
                )}<br/><br/><li>${t('voicemailSettings.audioDefaultTooltip3')}</li><li>${t(
                  'voicemailSettings.audioDefaultTooltip4'
                )}</li>`
              )}
            </Tooltip>
          </div>
          <div className={styles['radio-container']}>
            <Radio
              name="voicemailMessage"
              value="AudioName"
              className={styles.radio}
              onChange={() =>
                setValue(
                  field,
                  {
                    ...values[field],
                    level: 'AudioName'
                  },
                  {
                    shouldValidate: false,
                    shouldDirty: true
                  }
                )
              }
              label={t('voicemailSettings.audioAudioName')}
            />
            <Tooltip>{t('voicemailSettings.audioAudioTooltip')}</Tooltip>
          </div>

          <div className={styles.audio} id="audio-name-audio-file-selection">
            <AudioFileSelection
              audio={audioNameAudio}
              onChange={file =>
                setValue(
                  field,
                  {
                    ...values[field],
                    audioNameFileToImport: file
                  },
                  {
                    shouldValidate: false,
                    shouldDirty: true
                  }
                )
              }
              showInitialView={hasAudioNameFile}
              disabled={false}
            />
          </div>
          <div className={styles['radio-container']}>
            <Radio
              name="voicemailMessage"
              value="Greeting"
              className={styles.radio}
              onChange={() =>
                setValue(
                  field,
                  {
                    ...values[field],
                    level: 'Greeting'
                  },
                  {
                    shouldValidate: false,
                    shouldDirty: true
                  }
                )
              }
              label={t('voicemailSettings.audioGreeting')}
            />
            <Tooltip>{t('voicemailSettings.audioGreetingTooltip')}</Tooltip>
          </div>
          <div className={styles.audio} id="greeting-audio-file-selection">
            <AudioFileSelection
              className={styles.audio}
              audio={greetingAudio}
              onChange={file =>
                setValue(
                  field,
                  {
                    ...values[field],
                    greetingAudioToImport: file
                  },
                  {
                    shouldValidate: false,
                    shouldDirty: true
                  }
                )
              }
              showInitialView={hasGreetingFile}
              disabled={false}
            />
          </div>
        </Radio.Group>
      </div>
    </div>
  );
};

export default VoiceMailAudioField;
