// @flow

/* eslint-disable no-undef */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';
import App from './App';
import interceptXhr from './interceptors';
import './i18n';

interceptXhr();

Modal.setAppElement('#root');

console.info('version', process.env.REACT_APP_VERSION); // eslint-disable-line no-console

if (process.env.REACT_APP_VERSION !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENV
  });
  Sentry.configureScope(scope => {
    scope.setTag('hostname', window.location.hostname);
  });
}

// $FlowFixMe
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <DndProvider backend={HTML5Backend} context={window}>
    <App />
  </DndProvider>
);
