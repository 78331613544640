"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoppingCartItem = exports.ShoppingCartItemAddon = exports.ShoppingCartItemPrice = exports.ShoppingCartPrice = void 0;
function ShoppingCartPrice(_a) {
    var name = _a.name, amount = _a.amount, unit = _a.unit;
    return {
        name: name,
        amount: amount,
        unit: unit,
    };
}
exports.ShoppingCartPrice = ShoppingCartPrice;
function ShoppingCartItemPrice(_a) {
    var amount = _a.amount, unit = _a.unit;
    return {
        amount: amount,
        unit: unit,
    };
}
exports.ShoppingCartItemPrice = ShoppingCartItemPrice;
function ShoppingCartItemAddon(_a) {
    var _b = _a.disclaimer, disclaimer = _b === void 0 ? [] : _b, id = _a.id, name = _a.name, price = _a.price;
    return {
        disclaimer: disclaimer,
        id: id,
        name: name,
        price: ShoppingCartItemPrice({ amount: price.amount, unit: price.unit }),
    };
}
exports.ShoppingCartItemAddon = ShoppingCartItemAddon;
function ShoppingCartItem(_a) {
    var _b = _a.addons, addons = _b === void 0 ? [] : _b, _c = _a.disclaimer, disclaimer = _c === void 0 ? [] : _c, id = _a.id, _d = _a.image, image = _d === void 0 ? null : _d, name = _a.name, price = _a.price, quantity = _a.quantity, url = _a.url;
    return {
        addons: addons.map(function (addon) {
            return ShoppingCartItemAddon({
                disclaimer: addon.disclaimer,
                id: addon.id,
                name: addon.name,
                price: addon.price,
            });
        }),
        disclaimer: disclaimer,
        id: id,
        image: image,
        name: name,
        price: ShoppingCartItemPrice(price),
        quantity: quantity,
        url: url,
    };
}
exports.ShoppingCartItem = ShoppingCartItem;
