/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../utils/css';
import Radio from './Radio';
import styles from './RadioGroup.module.scss';
import { useIdWithFallback } from '../../utils/hooks';
import RadioGroupContext from './RadioGroupContext';

function RadioGroupInternal(
  {
    id = null,
    className = null,
    label = null,
    i18n_radiogroup_infoText = null,
    i18n_radiogroup_errorMessage = null,
    i18n_radiogroup_helpText = null,
    children = null,
    value = null,
    name = '',
    onChange = () => {},
    disabled = false,
    required = null,
    optional = false,
    ...otherProps
  },
  ref,
){
  let arrayChildren = children;
  if(children === null) {
    arrayChildren = [];
  }
  else if(!Array.isArray(children)) {
    arrayChildren = [children];
  }

  let valueFromChildren = value;
  if(arrayChildren.length > 0 && !value) {
    arrayChildren.map((child) => {
      if(child && child.props && child.props.checked === true) {
        valueFromChildren = child.props.value;
      }
    });
  }
  else if(!value) {
    if(arrayChildren.props?.checked === true) {
      valueFromChildren = arrayChildren.props.value;
    }
  }

  const htmlId = useIdWithFallback('dsRadioGroup', id);
  const compName = name || `${htmlId}-radio`;
  const [errorMessage, setErrorMessage] = useState(i18n_radiogroup_errorMessage);
  const [selectedValue, setSelectedValue] = useState(value || valueFromChildren);
  const [disabledValue, setDisabledValue] = useState(disabled);
  const componentValue = useMemo(() => ({disabledValue, setDisabledValue, errorMessage, setErrorMessage, selectedValue, setSelectedValue}), [disabledValue, setDisabledValue, errorMessage, setErrorMessage, selectedValue, setSelectedValue]);

  useImperativeHandle(ref, () => ({
    value: (itemValue) => {
      setSelectedValue(itemValue);
      return itemValue;
    },

    getValue: () => selectedValue,

    // eslint-disable-next-line consistent-return
    errorMessage: (message) => {
      if(typeof(message) === 'string') {
        setErrorMessage(message);
      }
      else {
        return errorMessage;
      }
    },

    disabled: (newDisabledValue) => {
      if(newDisabledValue === true || newDisabledValue === false){        
        setDisabledValue(newDisabledValue);
      }
      else {
        return disabledValue;
      }
    }
  }));

  let inputLabel = null;
  if (label) {
    inputLabel = <span className={styles[`radio-group--labelarea-label-content`]}>{label}</span>;
  }

  let inputInfo = null;
  if (i18n_radiogroup_infoText) {
    const PopoverComponent = require('../Popover').default; // eslint-disable-line global-require
    const IconInformationRegularComponent = require('../Icon/lib/IconInformationRegular').default; // eslint-disable-line global-require
    inputInfo = <PopoverComponent triggerElement={<IconInformationRegularComponent />} placement="top">{i18n_radiogroup_infoText}</PopoverComponent>
  }

  let inputError = null;
  if (errorMessage) {
    const ErrorComponent = require('../InputError').default; // eslint-disable-line global-require
    inputError = <ErrorComponent id={`${htmlId}Error`}>{errorMessage}</ErrorComponent>;
  }

  let inputHelp = null;
  if (i18n_radiogroup_helpText) {
    const HelpComponent = require('../InputHelp').default; // eslint-disable-line global-require
    inputHelp = <HelpComponent id={`${htmlId}Help`}>{i18n_radiogroup_helpText}</HelpComponent>;
  }

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    if(onChange) onChange(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    setDisabledValue(disabled);
  }, [disabled]);

  useEffect(() => {
    setErrorMessage(i18n_radiogroup_errorMessage);
  }, [i18n_radiogroup_errorMessage]);

  const classes = classNames([
    styles[`radio-group`],    
    disabledValue ? styles[`radio-group-disabled`] : null,
    errorMessage ? styles[`radio-group--error`] : null,
    className,
  ]);

  return (
    <RadioGroupContext.Provider value={componentValue}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <fieldset id={htmlId} name={`${compName}-group`} className={classes} {...otherProps}>
        {label ? (
          <div className="ds-radio-group--labelarea">
            <legend className={styles[`radio-group--labelarea-label`]}>
              {inputLabel} {inputInfo}
            </legend>
          </div>)
        : null}
        {arrayChildren.map((radio, j) => (
          <Radio
            {...radio.props}
            key={`${htmlId}Radio${j}`}
            id={radio.props.id || `${htmlId}Radio${j}`}
            className={radio.props.className}
            label={radio.props.label}
            value={radio.props.value}
            checked={!value ? radio.props.checked : radio.props.value === selectedValue}
            name={radio.props.name || compName}
            onChange={radio.props.onChange}
            disabled={disabledValue === true ? true : radio.props.disabled}
            labelClassName={radio.props.labelClassName}
            required={radio.props.required}
            inputRef={radio.props.inputRef}
            inputProps={radio.inputProps}
          >
            {radio ? radio.props.children : null}
          </Radio>
        ))}
        {inputError}
        {inputHelp}
      </fieldset>
    </RadioGroupContext.Provider>
  );
}

const RadioGroup = React.forwardRef(RadioGroupInternal);
export default RadioGroup;

RadioGroup.propTypes = {
  /** Group's id */
  id: PropTypes.string,
  /** Any CSS classes for the component */
  className: PropTypes.string,
  /** Label text for the radio group */
  label: PropTypes.string,
  /** Additional information and instructions for the radio group */
  i18n_radiogroup_infoText: PropTypes.string,
  /** Instructions for the radio group */
  i18n_radiogroup_helpText: PropTypes.string,
  /** Error message for the radio group */
  i18n_radiogroup_errorMessage: PropTypes.string,
  /** Component's content. Automatically detected. */
  children: PropTypes.node,
  /** Radio's default value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Radio's name for all in the group */
  name: PropTypes.string,
  /** The function run after any Radio in the group is checked or unchecked */
  onChange: PropTypes.func,
  /** Whether the all the Radios in the group are disabled */
  disabled: PropTypes.bool,
};

RadioGroup.defaultProps = {
  id: null,
  className: null,
  label: null,
  i18n_radiogroup_infoText: null,
  i18n_radiogroup_errorMessage: null,
  i18n_radiogroup_helpText: null,
  children: null,
  value: null,
  name: '',
  onChange: () => {},
  disabled: null,
  optional: false,
};