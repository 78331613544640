// @flow

export type AnalyticsDataLayerEventT = {
  event: 'trackEvent', // always "trackEvent"
  eventCategory: 'redux', // always "redux",
  eventAction: string, // e.g. 'ADMING_LOGIN_SUCCESSFUL_THROUGH_ELISA_SSO',
  eventValue?: number, // if event has some numerical data related. The numerical data is counted as sum on GA
  eventLabel?: string //  whitelisted payload, preferably single string, if many then preferably CSV
};

const pushToDataLayer = (obj: AnalyticsDataLayerEventT) => {
  const _paq = (window._paq = window._paq || []); // eslint-disable-line
  _paq.push([obj.event, obj.eventCategory, obj.eventAction, obj.eventLabel, obj.eventValue]); // eslint-disable-line
};

const getAnalyticsDataLayerDefaultEvent = (eventAction: string): AnalyticsDataLayerEventT => {
  return {
    event: 'trackEvent',
    eventCategory: 'redux',
    eventAction
  };
};

const phoneNumberRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
const looksLikePhoneNumber = value => {
  return value && phoneNumberRegex.test(value);
};

export const sanitizeQuery = (query: ?string) => {
  if (!query) {
    return null;
  }
  if (query.length > 50) {
    // Hide novels, but still send something so we can measure the users' propensity for rambling
    return '[ANON TOO LONG]';
  }
  const queryWithoutSpaces = query.replace(/\s+/g, '');
  if (looksLikePhoneNumber(queryWithoutSpaces)) {
    return '[ANON PHONENUMBER]';
  }
  // Hide possible emails and phone numbers
  return query.replace(/\b[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+\b/g, '[ANON EMAIL]');
};

export const pushAnalyticsEvent = (
  eventAction: string,
  eventLabel: ?string,
  eventValue: ?number | ?string
) => {
  const sanitizedEventLabel = sanitizeQuery(eventLabel);

  // $FlowFixMe Complex nested type for flow
  const data: AnalyticsDataLayerEventT = {
    ...getAnalyticsDataLayerDefaultEvent(eventAction),
    ...(eventValue != null ? { eventValue: parseInt(eventValue, 10) } : {}),
    ...(sanitizedEventLabel ? { eventLabel: sanitizedEventLabel } : {})
  };
  pushToDataLayer(data);
};
