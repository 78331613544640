// @flow

import React, { type Element } from 'react';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import AccordionItem from '@design-system/component-library/src/components/Accordion/AccordionItem';
import Accordion from '@design-system/component-library/src/components/Accordion';
import { useTranslation } from 'react-i18next';
import EditCalendarForm from './EditCalendarForm';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import type { CallFlowCalendarEntityT } from '../../../ducks/entities/calendar/calendarTypes';
import CalendarSection from '../components/view/children/calendar/CalendarSection/CalendarSection';
import ConfirmButton from '../../../components/Button/ConfirmButton';
import { enterpriseCalendarRegExp } from '../../../helpers';

import styles from './CalendarContent.module.scss';

export type PropsT = {|
  isLoadingCalendar: boolean,
  calendars: CallFlowCalendarEntityT[],
  searchText: string,
  editModes: {},
  enterpriseId: string,
  updateCalendar: (*, *) => Promise<void>,
  toggleEditMode: string => void
|};

const CalendarContent = (props: PropsT): Element<typeof CenterHorizontally | typeof Accordion> => {
  const {
    isLoadingCalendar,
    calendars,
    searchText,
    editModes,
    enterpriseId,
    updateCalendar,
    toggleEditMode
  } = props;
  const isDefaultCalendar = ownerAdmtiveDomainId => ownerAdmtiveDomainId === '0.';

  const { t } = useTranslation();

  const calendarDetailsView = (cal, removeEditButton) => (
    <>
      <CalendarSection calendar={cal} />
      {!removeEditButton && (
        <ConfirmButton
          id="modify-calendar-button"
          className={styles['modify-button']}
          label={t('calendars.modifyCalendarButton')}
          hideWhenViewing
          onClickAction={() => toggleEditMode(cal.id)}
        />
      )}
    </>
  );

  return isLoadingCalendar ? (
    <CenterHorizontally>
      <LoadingSpinner />
    </CenterHorizontally>
  ) : (
    <Accordion className={styles.header}>
      {calendars &&
        calendars
          .sort((a, b) =>
            a.name
              .replace(enterpriseCalendarRegExp, '')
              .localeCompare(b.name.replace(enterpriseCalendarRegExp, ''))
          )
          .filter(
            cal =>
              !searchText ||
              cal.name
                .replace(enterpriseCalendarRegExp, '')
                .toUpperCase()
                .includes(searchText.toUpperCase())
          )
          .map(
            cal =>
              cal && (
                <AccordionItem
                  id={`calendar-${cal.id}`}
                  key={`calendar-${cal.id}`}
                  heading={`${cal.name.replace(enterpriseCalendarRegExp, '')} 
                                ${
                                  isDefaultCalendar(cal.ownerAdmtiveDomainId)
                                    ? t('calendars.cannotEditTitle')
                                    : ''
                                }`}
                >
                  <div className={styles['calendar-row']}>
                    {isDefaultCalendar(cal.ownerAdmtiveDomainId) && (
                      <div className={styles['info-row']}>{t('calendars.cannotEditInfo')}</div>
                    )}
                    {editModes[cal.id] ? (
                      <EditCalendarForm
                        enterpriseId={enterpriseId}
                        calendar={cal}
                        onSaveForm={formData => updateCalendar(cal, formData)}
                        onCancel={event => {
                          if (event) {
                            event.preventDefault();
                          }
                          toggleEditMode(cal.id);
                        }}
                      />
                    ) : (
                      calendarDetailsView(cal, isDefaultCalendar(cal.ownerAdmtiveDomainId))
                    )}
                  </div>
                </AccordionItem>
              )
          )}
    </Accordion>
  );
};

export default CalendarContent;
