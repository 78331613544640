// TODO: Use this example instead https://www.w3.org/TR/wai-aria-practices/examples/dropdown/dropdown-select-only.html

import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useIdWithFallback } from '../../utils/hooks';
import IconArrowDownRegular from '../Icon/lib/IconArrowDownRegular';
import { classNames } from '../../utils/css';
import styles from './Dropdown.module.scss';
import { KEY_DOWN, KEY_END, KEY_ESC, KEY_HOME, KEY_RETURN, KEY_UP } from '../../utils/keycodes';
import { inSearchOrder } from '../../utils/search';

function DropdownInternal(
  {
    id = null,
    className = null,
    label = null,
    name = null,
    disabled = null,
    readonly = null,
    items = null,
    selectedValue = null,
    i18n_dropdown_ariaLabel = null,
    i18n_dropdown_placeholderText = null,
    i18n_dropdown_errorMessage = '',
    i18n_dropdown_infoText = null,
    i18n_dropdown_helpText = null,
    i18n_dropdown_optionalText = '(ei pakollinen)',
    optional = false,
    onValueChange = () => {},
    onValueSelect = () => {},
    onOptionsHide = () => {},
    onOptionsShow = () => {},
    integrated = false,
    options = null,
    onChange = null,
    value = null,
    light = false,
    selectRef = null,
    ...otherProps
  },
  ref
){
  if(options) {
    console.warn("Dropdown component's `options` property is deprecated. Use `items` instead. `options` property will be removed in a future version (during summer 2024).");
  }

  if(onChange) {
    console.warn("Dropdown component's `onChange` property is deprecated. Use `onValueChange` instead. `onChange` property will be removed in a future version (during summer 2024).");
  }

  if(value) {
    console.warn("Dropdown component's `value` property is deprecated. Use `selectedValue` instead. `value` property will be removed in a future version (during summer 2024).");
  }

  if(light) {
    console.warn("Dropdown component's `light` property is deprecated. Use `integrated` instead. `light` property will be removed in a future version (during summer 2024).");
  }

  const getFirstValue = () => {
    if(items && items.length > 0){
      let fv = null;
      for (let i = 0; i < items.length; i++) {
        if(items[i].value) {
          fv = items[i].value;
          break;
        }
      }
      return fv;
    }
    else {
      return '';
    }
  };

  let firstValue = getFirstValue() || '';
  const dropdownRef = useRef(null);
  const valueRef = useRef(null);
  const listboxRef = useRef(null);
  const htmlId = useIdWithFallback('dsDropdown', id);
  const [shown, setShown] = useState(false);
  const [disabledState, setDisabledState] = useState(disabled);
  const [readonlyState, setReadonlyState] = useState(readonly);
  const [selectedItem, setSelectedItem] = useState(selectedValue || (i18n_dropdown_placeholderText ? selectedValue : firstValue));
  const [currentItems, setCurrentItems] = useState(items || []);
  const [errorMessage, setErrorMessage] = useState(i18n_dropdown_errorMessage);
  const [activeDescendantId, setActiveDescendantId] = useState(selectedValue ? `${htmlId}Option${selectedValue.replace(/ /g,'')}` : (i18n_dropdown_placeholderText ? null : `${htmlId}Option${firstValue.replace(/ /g,'')}`));
  const [keysSoFar, setKeysSoFar] = useState('');

  const showListbox = () => {
    if(!disabledState && !readonlyState) {
      listboxRef.current.setAttribute('data-show', 'true');
      setShown(true);
      onOptionsShow();
    }
  };

  const hideListbox = () => {
    if (shown) {
      setShown(false);
      onOptionsHide();
    }

    listboxRef.current.setAttribute('data-show', 'false');
    const ddButton = document?.getElementById(`${htmlId}Button`);
    ddButton?.focus();
    onOptionsHide(dropdownRef);
  };

  const getItemByValue = (value) => {
    const val = listboxRef.current.querySelector(`[data-value="${value}"]`);
    return val;
  }

  // Functions visible to outside.
  useImperativeHandle(ref, () => ({
    value: (itemValue) => {
      // Select item only if it is in currentSelection
      const newSelectedItem = getItemByValue(itemValue);
      if (newSelectedItem) {
        // const newSelectedItem = getItemByValue(itemValue);
        valueRef.current.value = itemValue;
        setSelectedItem(itemValue);
        focusItem(newSelectedItem);
        onValueSelect(itemValue);
      }
      else {
        setSelectedItem(null);
        valueRef.current.value = null;
      }
    },

    getValue: () => selectedItem,

    itemList: (items) => {
      if(items){
        const newItems = items || [];
        let found = false;
        for(let i = 0; i < newItems.length; i++) {
          if (newItems[i].value == selectedItem) {
            found = true;
            break;
          }
        }

        if(!found){
          setSelectedItem(null);
          valueRef.current.value = null;
        }
        setCurrentItems(newItems);
      }
      else {
        return currentItems;
      }
    },

    disabled: (state) => {
      if(state === true || state === false){
        setDisabledState(state);
        // valueRef.current.disabled = state;
      }
      else {
        return disabledState;
      }
    },

    readonly: (state) => {
      if(state === true || state === false) {
        const buttonElement = document.getElementById(`${htmlId}Button`);
        setReadonlyState(state);
        valueRef.current.readOnly = state;
        buttonElement.setAttribute('readonly', state);
      }
      else {
        return readonlyState;
      }
    },

    errorMessage: (message) => {
      if(typeof(message) === 'string') {
        setErrorMessage(message);
      }
      else {
        return errorMessage;
      }
    },
  }));

  const createOptions = (items) => {
    if(items && items.length > 0) {
      const ddOptions = [];
      const newOptions = items || options;

      if(i18n_dropdown_placeholderText){
        ddOptions.push(<li className={styles.dropdown__option} role='title' id={`${htmlId}OptionPlaceholder`} key={`${htmlId}OptionPlaceholder`} data-value={null} onClick={selectOption} onKeyDown={selectOption}>
          {i18n_dropdown_placeholderText}
          </li>);
      }

      for (let i = 0; i < newOptions.length; i += 1) {
        const option = newOptions[i];
        ddOptions.push(createOption(option, i));
      }

      return ddOptions;
    }
    else {
      return null;
    }
  }

  const createOption = (item, index) => {
    let optionClasses;
    let dataValue;
    let ariaSelected;
    let content;
    let listItem;
    let role;
    const isDisabled = item.disabled;
    let iId = null;

    if(item.id) {
      iId = item.id;
    }
    else if(item.value) {
        iId = `${htmlId}Option${item.value || index}`;
      }
      else {
        iId = `${htmlId}OptionTitle${item.value || index}`;
      }

    // New Dropdowns are supposed to use objects as items.
    if (typeof item === 'object') {
      optionClasses = classNames([
        styles.dropdown__option,
        isDisabled ? styles['dropdown__option--disabled'] : null,
        !item.value ? styles.dropdown__option__title : null,
        !isDisabled && item.value && selectedItem === item.value ? styles['dropdown__option--selected'] : null,
      ]);

      role = item.value ? 'option' : 'title';
      dataValue = item.value;
      ariaSelected = selectedItem === item.value ? 'true' : 'false';
      content = item.html || item.label;

      listItem = (
        <li className={optionClasses} role={role} id={iId} key={iId} data-value={dataValue} aria-label={item.label || null} aria-selected={ariaSelected} onClick={!isDisabled ? selectOption : undefined} onKeyDown={!isDisabled ? selectOption : undefined} disabled={isDisabled}>
          {content}
        </li>
      );
    }
    // For migration purposes we support the old kind of dropdowns from React Patterns library
    else {
      optionClasses = classNames([styles.dropdown__option, selectedItem === item ? styles['dropdown__option--selected'] : null]);

      role = 'option';
      dataValue = item;
      ariaSelected = selectedItem === item ? 'true' : 'false';
      content = item;

      listItem = (
        <li className={optionClasses} role={role} id={iId} key={iId} data-value={dataValue} aria-selected={ariaSelected} onClick={!isDisabled ? selectOption : undefined} onKeyDown={!isDisabled ? selectOption : undefined}>
          {content}
        </li>
      );
    }

    return listItem;
  }

  const focusFirstItem = () => {
    const disabledStyle = styles['dropdown__option--disabled'];
    const firstItem = listboxRef.current.querySelector(`[role="option"]:not(.${disabledStyle})`);

    if (firstItem) {
      setActiveDescendantId(firstItem.id);
      focusItem(firstItem);
    }
  }

  const setupFocus = () => {
    if (activeDescendantId) {
      return;
    }
    focusFirstItem();
  }

  const focusItem = (itemToFocus) => {
    const newValue = itemToFocus.dataset.value;
    const newId = itemToFocus.id;
    /*
      Updating state re-renders options, thus losing the data-expanded & data-show attributes.
      This effectively closes the dropdown, so we might as well force visible to false here.
    */
    setActiveDescendantId(newId);
    setSelectedItem(newValue);

    itemToFocus.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });

    if (onValueChange) {
      onValueChange(itemToFocus);
    } else if (onChange) {
      onChange(itemToFocus);
    }
    // As this closes the dropdown (which IMHO is a bug), we'll move the focus back to the dropdown button
    const buttonElement = document?.getElementById(`${htmlId}Button`);
    buttonElement?.current?.focus();
  }

  const getActivedescendantElement = () => {
    const items = [].slice.call(listboxRef.current.children);
    return items.find((item) => item.id === activeDescendantId) || items[0];
  }

  const getFirstLabel = () => {
    let result = null;
    if (items) {
      for (let i = 0; i < items.length; i += 1) {
        if (items[i].value) {
          result = items[i].html || items[i].label;
          break;
        }
      }
      return result;
    }
    if (options) {
      const items = options;
      for (let i = 0; i < items.length; i += 1) {
        if (items[i]) {
          result = items[i];
          break;
        }
      }
      return result;
    }
    return false;
  }

  const getSelectedLabel = () => {
    let result = null;
    if (items) {
      for (let i = 0; i < items.length; i += 1) {
        if (items[i].value === selectedItem) {
          result = items[i].html || items[i].label;
        }
      }
      return result;
    }
    if (options) {
      const items = options;
      for (let i = 0; i < items.length; i += 1) {
        if (items[i] === selectedItem) {
          result = items[i];
        }
      }
      return result;
    }
    return null;
  }

  const selectOption = (e) => {
    focusItem(e.currentTarget);

    if(onValueSelect) {
      onValueSelect(e.currentTarget);
    }

    hideListbox();
  }

  const toggleOptions = (e) => {
    e.stopPropagation();
    if (shown) {
      hideListbox();
    } else {
      showListbox();
    }
  }

  const checkHide = (e) => {
    if ( dropdownRef.current && (e.target === dropdownRef.current || dropdownRef.current.contains(e.target))) {
      e.preventDefault();
      return;
    }

    if(shown){
      hideListbox();
    }
  }

  const clearKeysSoFarAfterDelay = () => {
    if (keyClear) {
      clearTimeout(keyClear);
      keyClear = null;
    }
    let keyClear = setTimeout(() => {
      setKeysSoFar('');
      keyClear = null;
    }, 500);
  }

  const moveToFirstItem = () => {
    focusFirstItem();
  }

  const moveToLastItem = () => {
    const disabledStyle = styles['dropdown__option--disabled'];
    const lastChild = listboxRef.current.querySelector(`[role="option"]:not(.${disabledStyle}):last-of-type`);

    focusItem(lastChild);
  }

  const findItemToFocus = (key) => {
    // We get all keys here, for example Tab would have a key = 'Tab'. So we have to filter the values a bit.
    const character = key && key.length === 1 ? key : '';
    if (!key || key.length !== 1) {
      return null;
    }
    const titleClass = styles.dropdown__option__title;
    const disabledStyle = styles['dropdown__option--disabled'];
    const itemList = [].slice.call(listboxRef.current.querySelectorAll(`[role="option"]:not(.${titleClass}):not(.${disabledStyle})`));
    const currentIndex = itemList.findIndex((item) => item.id === activeDescendantId);
    const currentItem = itemList[currentIndex];
    if (!character) {
      return currentItem;
    }
    const search = keysSoFar + character;

    setKeysSoFar(search);
    clearKeysSoFarAfterDelay();
    return inSearchOrder(currentIndex, itemList).find((el) => el.getAttribute('aria-label').toLowerCase().startsWith(search)) || null;
  }

  const checkKeyPress = (e) => {
    if(!disabledState && !readonlyState) {
      const itemToFocus = findItemToFocus(e.key);
      const buttonElement = document.getElementById(`${htmlId}Button`);
      
      switch (e.key) {
        case KEY_ESC:
        case KEY_RETURN:
          e.preventDefault();

          if(onValueSelect) {
            const currentItem = getActivedescendantElement();
            onValueSelect(currentItem);
          }

          hideListbox();
          buttonElement.focus();
          break;
        case KEY_UP:
        case KEY_DOWN:
          e.preventDefault();
          showListbox();
          if (!activeDescendantId) {
            setupFocus();
          } else {
            let nextItem = null;
            const currentItem = getActivedescendantElement();
            if (!currentItem) {
              return;
            }

            const titleClass = styles.dropdown__option__title;
            const disabledStyle = styles['dropdown__option--disabled'];
            if (e.key === KEY_UP) {
              nextItem = currentItem.previousElementSibling;

              if (nextItem && (nextItem.classList.contains(titleClass) || nextItem.classList.contains(disabledStyle))) {
                nextItem = nextItem.previousElementSibling;
              }
            } else {
              nextItem = currentItem.nextElementSibling;

              if (nextItem && (nextItem.classList.contains(titleClass) || nextItem.classList.contains(disabledStyle))) {
                nextItem = nextItem.nextElementSibling;
              }
            }

            if (nextItem) {
              focusItem(nextItem);
            }
          }
          break;
        case KEY_HOME:
          e.preventDefault();
          moveToFirstItem();
          break;
        case KEY_END:
          e.preventDefault();
          moveToLastItem();
          break;
        default:
          if (itemToFocus) {
            focusItem(itemToFocus);
          }
          break;
      }
    }
  }

  useEffect(() => {
    valueRef.current.value = selectedValue;
    setSelectedItem(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    setCurrentItems(items);
  }, [items]);

  useEffect(() => {
    setErrorMessage(i18n_dropdown_errorMessage);
  }, [i18n_dropdown_errorMessage]);

  useEffect(() => {
    setDisabledState(disabled);
  }, [disabled]);

  useEffect(() => {
    setReadonlyState(readonly);
  }, [readonly]);

  useEffect(() => {
    document?.addEventListener('click', checkHide);
    
    return () => {
      document?.removeEventListener('click', checkHide);
    };
  });

  let optionalIndicator = null;
  if (optional === true) {
    optionalIndicator = i18n_dropdown_optionalText;
  }

  let ddLabel = null;
  if (label) {
    const LabelComponent = require('../Label').default;
    ddLabel = <LabelComponent id={`${htmlId}Label`} labelFor={`${htmlId}DropdownArea`}>{label}</LabelComponent>;
  }

  let ddInfo = null;
  if (i18n_dropdown_infoText) {
    const PopoverComponent = require('../Popover').default;
    const IconInformationRegularComponent = require('../Icon/lib/IconInformationRegular').default;
    ddInfo = <PopoverComponent triggerElement={<IconInformationRegularComponent />} placement="top">{i18n_dropdown_infoText}</PopoverComponent>
  }

  let ddError = null;
  if (errorMessage) {
    const ErrorComponent = require('../InputError').default;
    ddError = <ErrorComponent className={styles.dropdown__errormessage} id={`${htmlId}Error`}>{errorMessage}</ErrorComponent>;
  }

  let ddHelp = null;
  if (i18n_dropdown_helpText) {
    const HelpComponent = require('../InputHelp').default;
    ddHelp = <HelpComponent className={styles.dropdown__helptext} id={`${htmlId}Help`}>{i18n_dropdown_helpText}</HelpComponent>;
  }

  if(!selectedItem && !i18n_dropdown_placeholderText) {
    firstValue = currentItems && currentItems[0] && currentItems[0].value && typeof(currentItems[0].value) === 'string' ? currentItems[0].value : null;
  }

  const classes = classNames([
    styles.dropdown,
    className || null,
    errorMessage || i18n_dropdown_errorMessage !== '' ? styles[`dropdown-error`] : null,
    disabledState ? styles[`dropdown-disabled`] : null,
    readonlyState ? styles[`dropdown-readonly`] : null,
    integrated ? styles[`dropdown-integrated`] : null
  ]);

  return (
    <div id={htmlId} className={classes} role="group" {...otherProps}>
      { ddLabel ?
        <div className={styles.dropdown__labelarea}>
          {ddLabel} {optionalIndicator} {ddInfo}
        </div>
      : null }

      <div id={`${htmlId}DropdownArea`} className={styles[`dropdown--inputarea`]} ref={dropdownRef}>
        <input type="text" id={`${htmlId}Input`} className={styles[`dropdown--valueinput`]} ref={valueRef} name={name} value={selectedItem || firstValue || ''} aria-errormessage={`${htmlId}Error`} aria-activedescendant={activeDescendantId} required={!optional} readOnly />

        <button
          ref={selectRef}
          id={`${htmlId}Button`}
          type="button"
          className={styles.dropdown__button}
          role="combobox"
          aria-label={i18n_dropdown_ariaLabel}
          aria-labelledby={`${htmlId}Label ${htmlId}Button`}
          aria-expanded="false"
          aria-controls={`${htmlId}Listbox`}
          aria-readonly="true"
          aria-disabled={disabled}
          onClick={toggleOptions}
          onKeyUp={checkKeyPress}
        >
          {selectedItem ? getSelectedLabel() : i18n_dropdown_placeholderText || getFirstLabel()}
          <IconArrowDownRegular />
        </button>
        <ul
          id={`${htmlId}Listbox`}
          className={styles.dropdown__options}
          ref={listboxRef}
          role="listbox"
          aria-expanded={shown}
          aria-labelledby={`${htmlId}Label`}
          aria-multiselectable="false"
        >
          {createOptions(currentItems)}
        </ul>
        {ddError}
        {ddHelp}
      </div>
    </div>
  );
};

const Dropdown = React.forwardRef(DropdownInternal);
export default Dropdown;

Dropdown.propTypes = {
  /**
   * ID of the component
   */
  id: PropTypes.string,
  /**
   * Class names you want to give to the component
   */
  className: PropTypes.string,
  /**
   * Label text for the component
   */
  label: PropTypes.string,
  /**
   * Name of the form control. Submitted with the form as part of a name/value pair.
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefname)
   */
  name: PropTypes.string,
  /**
   * Whether the dropdown is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * List of dropdown's options
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      html: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
      disabled: PropTypes.bool,
    })
  ),
  /**
   * Placeholder text for the component
   */
  i18n_dropdown_placeholderText: PropTypes.string,
  /**
   * Selected option's value
   */
  selectedValue: PropTypes.string,
  /**
   * Error message for the field
   */
  i18n_dropdown_errorMessage: PropTypes.string,
  /**
   * Additional information and instructions for the field
   */
  i18n_dropdown_infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Always visible help text for the field
   */
  i18n_dropdown_helpText: PropTypes.string,
  /**
   * Optional field indicator text
   */
  i18n_dropdown_optionalText: PropTypes.string,
  /**
   * Aria-label for the dropdown button.
   * This should only be used when this component is used as part of another component.
   */
  i18n_dropdown_ariaLabel: PropTypes.string,
  /**
   * Whether field is optional or not. By default, all fields are mandatory.
   */
  optional: PropTypes.bool,
  /**
   * Function that runs when value is changed
   */
  onValueChange: PropTypes.func,
  /**
   * Whether to use dropdown as integrated part of another component/pattern
   */
  integrated: PropTypes.bool,
  /**
   * Ref for the dropdown's opening button
   */
  selectRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.element })]),
  /**
   * @deprecated Use `items` instead. `options` property will be removed in a future version (during summer 2024).
   */
  options: PropTypes.arrayOf(PropTypes.string),
  /**
   * @deprecated Use `onValueChange` instead. `onChange` property will be removed in a future version (during summer 2024).
   */
  onChange: PropTypes.func,
  /**
   * @deprecated Use `selectedValue` instead. `value` property will be removed in a future version (during summer 2024).
   */
  value: PropTypes.string,
  /**
   * @deprecated Use `integrated` instead. `light` property will be removed in a future version (during summer 2024).
   */
  light: PropTypes.bool,
};
