import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useId } from 'react-id-generator';
import { createPopper } from '@popperjs/core';
import IconCloseFilled from '../Icon/lib/IconCloseFilled';

import { classNames } from '../../utils/css';

import styles from './Popover.module.scss';

import { KEY_ESC } from '../../utils/keycodes';
import { INTERACTIVE_ELEMENTS } from '../../utils/elements';

function PopoverInternal(
  {
    id = null,
    className = null,
    triggerElement = null,
    i18n_popover_triggerAriaLabel = 'Lue lisää',
    placement = 'top',
    children = null,
    i18n_popover_contentText = null,
    i18n_popover_closeButtonTitle = 'Sulje',
    onClose = null,
    onOpen = null,
    defaultOpen = false,
    autoFocus = true,
    i18n_tooltip_closeButtonText = null,
    tooltipContent = null,
    onClosed = null,
    onOpened = null,
    isInitiallyOpen = false,
    ...otherProps
  },
  ref,
) {
  if(autoFocus === false) {
    console.warn("Popover component's `autoFocus` property is deprecated. Remove `autoFocus` property instead. For accessibility reasons close button should automatically be focused. `autoFocus` property will be removed in a future version (during summer 2024).");
  }

  if(tooltipContent) {
    console.warn("Popover component's `tooltipContent` property is deprecated. Use `i18n_popover_contentText` property instead. `tooltipContent` property will be removed in a future version (during summer 2024).");
  }

  if(onOpened) {
    console.warn("Popover component's `onOpened` property is deprecated. Use `onOpen` property instead. `onOpened` property will be removed in a future version (during summer 2024).");
  }

  if(onClosed) {
    console.warn("Popover component's `onClosed` property is deprecated. Use `onClose` property instead. `onClosed` property will be removed in a future version (during summer 2024).");
  }
  
  if(i18n_tooltip_closeButtonText) {
    console.warn("Popover component's `i18n_tooltip_closeButtonText` property is deprecated. Use `i18n_popover_closeButtonTitle` property instead. `i18n_tooltip_closeButtonText` property will be removed in a future version (during summer 2024).");
  }

  if(isInitiallyOpen) {
    console.warn("Popover component's `isInitiallyOpen` property is deprecated. Use `defaultOpen` property instead. `isInitiallyOpen` property will be removed in a future version (during summer 2024).");
  }

  const popoverRef = useRef(null);
  const triggerRef = useRef(null);
  const closeRef = useRef(null);
  const popupRef = useRef(null);
  const arrowRef = useRef(null);
  const fallbackId = useId(1, 'dsPopover')[0];
  const htmlId = id || fallbackId;
  const [open, setOpen] = useState(defaultOpen || isInitiallyOpen || false);
  const [previousElement, setPreviousElement] = useState(document.body || null);

  let popperInstance = null;

  const hidePopover = () => {
    const pop = popupRef.current;

    if (previousElement) {
      previousElement.focus();
    }

    popupRef.current.removeAttribute('data-show');
    setOpen(false);

    document?.removeEventListener('keydown', keyActions);

    // Disable the event listeners
    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [...options.modifiers, { name: 'eventListeners', enabled: false }],
    }));

    if (onClose) {
      onClose(pop);
    }
    if (onClosed) {
      onClosed(pop);
    }
    return false;
  };

  const showPopover = () => {
    setPreviousElement(document.activeElement || document.body || null);
    popupRef.current.setAttribute('data-show', '');
    setOpen(true);

    document?.addEventListener('keydown', keyActions);

    // Enable the event listeners
    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [...options.modifiers, { name: 'eventListeners', enabled: true }],
    }));

    // Update popover's position
    popperInstance.update();

    if (onOpen) {
      onOpen(popupRef);
    }
    if (onOpened) {
      onOpened(popupRef);
    }
    return false;
  };

  const toggleOpen = () => {
    if (open) {
      hidePopover();
    } else {
      showPopover();
    }
  };

  const keyActions = (e) => {
    if (e.key === KEY_ESC) {
      if (popupRef.current.contains(document?.activeElement))
      {
        hidePopover();
      }
    }

    return false;
  };

  // Functions visible to outside.
  useImperativeHandle(ref, () => ({
    togglePopover: () => {
      toggleOpen();
    },

    openPopover: () => {
      showPopover();
    },

    closePopover: (e) => {
      hidePopover(e);
    },

    open: (value) => {
      if (value === true) {
        showPopover();
      } else if (value === false) {
        hidePopover();
      } else {
        return open;
      }

      return false;
    },
  }));

  let trigger = null;
  if (triggerElement) {
    // If trigger is interactive HTML element
    if (INTERACTIVE_ELEMENTS.includes(triggerElement.type)) {
      trigger = (
        <button
          type="button"
          ref={triggerRef}
          onClick={() => toggleOpen()}
          id={`${htmlId}Trigger`}
          className={styles[`popover-trigger`]}
          aria-label={i18n_popover_triggerAriaLabel}
          aria-haspopup="true"
          aria-expanded={open}
          aria-describedby={`${htmlId}Popover`}
          {...triggerElement.props} // eslint-disable-line react/jsx-props-no-spreading
        >
          {triggerElement.props.children}
        </button>
      );
    } else {
      trigger = (
        <button
          type="button"
          ref={triggerRef}
          onClick={() => toggleOpen()}
          id={`${htmlId}Trigger`}
          className={styles[`popover-trigger`]}
          aria-label={i18n_popover_triggerAriaLabel}
          aria-haspopup="true"
          aria-expanded={open}
          aria-describedby={`${htmlId}Popover`}
        >
          {triggerElement}
        </button>
      );
    }
  }

  useEffect(() => {
    popperInstance = createPopper(triggerRef.current, popupRef.current, {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 18],
          },
        },
        {
          name: 'arrow',
          options: {
            padding: 2,
          },
        },
      ],
    });

    const closeButton = document.querySelector(`#${`${htmlId}CloseButton`}`) || null;
    if (autoFocus && closeButton) {
      closeButton.focus();
    }
  });

  const popoverClasses = classNames([styles.popover, className || null]);

  return (
    <span id={htmlId} className={styles[`popover-holder`]} ref={popoverRef}>
      {trigger}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <span id={`${htmlId}Popover`} className={popoverClasses} role="tooltip" ref={popupRef || null} data-show={defaultOpen || isInitiallyOpen ? 'true' : null} {...otherProps}>
        <button
          id={`${htmlId}CloseButton`}
          className={styles[`popover-close`]}
          type="button"
          aria-label={i18n_popover_closeButtonTitle || i18n_tooltip_closeButtonText}
          title={i18n_popover_closeButtonTitle || i18n_tooltip_closeButtonText}
          ref={closeRef}
          onClick={() => hidePopover()}
        >
          <IconCloseFilled aria-hidden="true" size="s" />
        </button>
        <span id={`${htmlId}Content`} className={styles[`popover-content`]}>
          {children || i18n_popover_contentText || tooltipContent}
        </span>
        <span id={`${htmlId}Arrow`} className={styles[`popover-arrow`]} ref={arrowRef} data-popper-arrow />
      </span>
    </span>
  );
}

const Popover = React.forwardRef(PopoverInternal);
export default Popover;

Popover.propTypes = {
  /**
   * Id of the component
   */
  id: PropTypes.string,
  /**
   * Class names you want to give to the component
   */
  className: PropTypes.string,
  /**
   * Element that triggers the popover
   */
  triggerElement: PropTypes.node,
  /**
   * Aria label for the trigger element
   */
  i18n_popover_triggerAriaLabel: PropTypes.string,
  /**
   * Default direction the popover appears relation to the trigger element
   */
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  /**
   * Popover's content text
   */
  i18n_popover_contentText: PropTypes.string,
  /**
   * Component's content. Automatically detected. If this and content are both given, this overrides content.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  /**
   * Close button's title text. Important for accessibility.
   */
  i18n_popover_closeButtonTitle: PropTypes.string,
  /**
   * Function that runs when popover opens
   */
  onOpen: PropTypes.func,
  /**
   * Function that runs when popover closes
   */
  onClose: PropTypes.func,
  /**
   * Whether the popover is open by default
   */
  defaultOpen: PropTypes.bool,
  /**
   * @deprecated Remove `autoFocus` property instead. For accessibility reasons close button should automatically be focused. `autoFocus` property will be removed in a future version (during summer 2024).
   */
  autoFocus: PropTypes.bool,
  /**
   * @deprecated Use `i18n_popover_contentText` property instead. `tooltipContent` property will be removed in a future version (during summer 2024).
   */
  tooltipContent: PropTypes.string,
  /**
   * @deprecated Use `onOpen` property instead. `onOpened` property will be removed in a future version (during summer 2024).
   */
  onOpened: PropTypes.func,
  /**
   * @deprecated Use `onClose` property instead. `onClosed` property will be removed in a future version (during summer 2024).
   */
  onClosed: PropTypes.func,
  /**
   * @deprecated Use `i18n_popover_closeButtonTitle` property instead. `i18n_tooltip_closeButtonText` property will be removed in a future version (during summer 2024).
   */
  i18n_tooltip_closeButtonText: PropTypes.string,
  /**
   * @deprecated Use `defaultOpen` property instead. `isInitiallyOpen` property will be removed in a future version (during summer 2024).
   */
  isInitiallyOpen: PropTypes.bool,
};
