// @flow

/* eslint-disable import/prefer-default-export, no-use-before-define */

import * as R from 'ramda';
import React, { type Element, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { DepartmentStateEntityT } from '../../../ducks/entities/department';
import DepartmentTreeBreadcrumb from './DepartmentTreeBreadcrumb';
import DepartmentTreeList, { type DepartmentStateEntityWithPreselectT } from './DepartmentTreeList';
import NotFoundCard from '../../../components/NotFoundCard/NotFoundCard';
import { goToDepartment, goToEnterprise } from '../../../navigationOperations';
import styles from './DepartmentTreeBrowser.module.scss';

type PropsT = {
  departments: DepartmentStateEntityT[],
  activeDepartment: ?$PropertyType<DepartmentStateEntityT, 'id'>, // eslint-disable-line react/no-unused-prop-types
  enterpriseId: string,
  onCreateDepartment: (*) => void,
  onOpenDetailsModify: (*) => void,
  onOpenDetailsPeople: (*) => void
};

/* TODO: fix to work with React 18
  const calculateX = (depth, hasSubDepartments) => {
  let columns = parseInt(styles.columns, 10);
  if (
    depth * parseInt(styles.columnWidth, 10) >
      parseInt(styles.browserWidth, 10) - parseInt(styles.columnWidth, 10) &&
    !hasSubDepartments
  ) {
    columns += 1;
  }
  const columnsVisible = Math.max(0, depth - columns);
  return columnsVisible * -parseInt(styles.columnWidth, 10);
}; */

export const DepartmentTreeBrowser = (props: PropsT): Element<'div'> => {
  const {
    enterpriseId,
    onCreateDepartment,
    onOpenDetailsModify,
    onOpenDetailsPeople,
    departments
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const treeDepth = useSelector(state =>
    R.path(['location', 'state', 'renderedTreeDepth'], state.router)
  );

  const [renderedTreeDepth, setRenderedTreeDepth] = useState(
    treeDepth || { current: 0, direction: 'FORWARD' }
  );

  const activeDepartment = useSelector(state =>
    R.pathOr([props.activeDepartment], ['location', 'state', 'path'], state.router)
  );

  const selectedDepartmentId = activeDepartment !== null ? R.last(activeDepartment) : null;

  /* TODO: fix to work with React 18
  const hasSubDepartments = useSelector(state =>
    departmentSelectors.departmentHasSubDepartments(
      state,
      state.currentUser.currentEnterprise ? state.currentUser.currentEnterprise.id : '',
      selectedDepartmentId
    )
  );
*/
  const handleOnRenderedTreeDepthIncreased = (path: string[]) => {
    setRenderedTreeDepth({
      ...renderedTreeDepth,
      current:
        path && renderedTreeDepth.current <= path.length
          ? renderedTreeDepth.current + 1
          : renderedTreeDepth.current
    });
  };

  const handleOnRenderedTreeDepthDecreased = () => {
    setRenderedTreeDepth({
      ...renderedTreeDepth,
      current: renderedTreeDepth.current - 1
    });
  };

  const handleOnPathChange = (path: string[], prevSelectedDepartmentId: ?string) => {
    const prevDepartmentIdx = prevSelectedDepartmentId
      ? path.findIndex(e => e === prevSelectedDepartmentId)
      : 0;
    if (path.length > 0) {
      dispatch(
        goToDepartment(enterpriseId, path[path.length - 1], {
          detailsMode: 'hidden',
          path,
          renderedTreeDepth: {
            current: path.length - 1,
            direction: prevDepartmentIdx < 0 ? 'BACKWARD' : 'FORWARD'
          }
        })
      );
    } else {
      dispatch(
        goToEnterprise(enterpriseId, {
          detailsMode: 'hidden',
          path: undefined,
          selectionCount: 0,
          renderedTreeDepth: {
            current: 0,
            direction: 'BACKWARD'
          }
        })
      );
    }
  };

  const selectPath = (path: string[]) => {
    if (path.length > 0) {
      const departmentsWithSelections: DepartmentStateEntityWithPreselectT[] = [
        ...(props.departments: any) // eslint-disable-line flowtype/no-weak-types
      ];
      let current: DepartmentStateEntityWithPreselectT[] = departmentsWithSelections;
      for (let i = 0; i < path.length; i++) {
        for (let j = 0; j < current.length; j++) {
          if (path[i] === current[j].id) {
            current[j].selected = true;
            if (current[j].subDepartments) {
              current = current[j].subDepartments;
            }
            break;
          }
        }
      }
      dispatch(
        goToDepartment(enterpriseId, path[path.length - 1], {
          detailsMode: 'hidden',
          path,
          renderedTreeDepth: {
            current: path.length - 1,
            direction: 'BACKWARD'
          }
        })
      );
    } else {
      dispatch(
        goToEnterprise(enterpriseId, {
          detailsMode: 'hidden',
          path: undefined,
          renderedTreeDepth: {
            current: 0,
            direction: 'BACKWARD'
          }
        })
      );
    }
  };

  const departmentTree = (
    <div className={styles.browser}>
      {departments.length !== 0 && (
        <div>
          {
            // $FlowFixMe
            <DepartmentTreeList
              onRenderedTreeDepthIncreased={handleOnRenderedTreeDepthIncreased}
              onRenderedTreeDepthDecreased={handleOnRenderedTreeDepthDecreased}
              onPathChange={handleOnPathChange}
              onPeopleLinkClick={onOpenDetailsPeople}
              onEditLinkClick={onOpenDetailsModify}
              departments={departments}
              selectedDepartmentId={selectedDepartmentId}
              renderedTreeDepth={renderedTreeDepth.current}
              onCreateDepartment={onCreateDepartment}
            />
          }
        </div>
      )}
    </div>
  );

  const emptyDepartmentTree = (
    <NotFoundCard
      id="departments-no-results"
      label={t('enterprise.noDepartmentsFoundMsg')}
      linkLabel={t('enterprise.addFirstDepartmentLink')}
      onClickAction={onCreateDepartment}
      type="organization"
    />
  );

  const departmentTreeBreadcrumb = (
    <div className={styles['breadcrumb-container']}>
      <DepartmentTreeBreadcrumb path={activeDepartment || []} onPathSelect={selectPath} />
    </div>
  );
  return (
    <div>
      {departments.length !== 0 && departmentTreeBreadcrumb}
      {departments.length === 0 ? emptyDepartmentTree : departmentTree}
    </div>
  );
};

export default DepartmentTreeBrowser;
