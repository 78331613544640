import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from '../../LoadingSpinner';

import globalStyles from '../../../globals/scss/_typography.scss';
import styles from '../Login.module.scss';

import { classNames } from '../../../utils/css';

class MobilePhonePolling extends Component {
  static propTypes = {
    /** Login component's id  */
    id: PropTypes.string,
    /** Container classname */
    className: PropTypes.string,
    mobileAuthEventId: PropTypes.string,
    cancelMobileAuthPolling: PropTypes.func.isRequired,
  };

  static defaultProps = {
    mobileAuthEventId: '',
  };

  handlePollingCancel(e) {
    e.preventDefault();
    this.props.cancelMobileAuthPolling();
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { id, className, mobileAuthEventId } = this.props;

    const { i18n_login_mobilePollingText, i18n_login_mobilePollingCancelLogin } = this.props.translations;

    return (
      <div id={id} ref={this.myRef} className={className}>
        <div className={styles[`login__mobile-polling`]}>
          <LoadingSpinner />
          <p>{i18n_login_mobilePollingText}</p>
          <h3 className={classNames([globalStyles['h3--book'], styles['login__view--title']])}>{mobileAuthEventId}</h3>
        </div>
        <div className={styles[`login__mobile-polling-cancel`]}>
          <a href="#" onClick={this.handlePollingCancel.bind(this)}>
            {i18n_login_mobilePollingCancelLogin}
          </a>
        </div>
      </div>
    );
  }
}

export default MobilePhonePolling;
