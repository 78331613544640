// @flow strict-local
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import axios, { CancelToken } from 'axios';
import type { Canceler } from 'axios';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import Card from '@design-system/component-library/src/components/Card';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../BaseContainer/BaseContainer';
import { goToEditUser, goToEnterpriseForwardings } from '../../../navigationOperations';
import useUsers from './useUsers';
import useForwarding from './useForwarding';
import { InputField } from '../../callFlows/components/edit/children/InputField';
import ForwardingForm from './form/ForwardingForm';
import useForwardingDetailsTour from './useForwardingDetailsTour';
import PresenceStateSelector from './form/components/PresenceStateSelector';
import ForwardingTargetSelector from './form/components/ForwardingTargetSelector';
import TargetNumberFiltersSelector from './form/components/TargetNumberFiltersSelector';
import SpecificCallerFilterSelector from './form/components/SpecificCallerFilterSelector';
import { ForwardingEnterpriseInfoBox } from './form/title/ForwardingEnterpriseInfoBox';
import ForwardingUsername from './form/title/ForwardingUsername';
import EditGroupsForwardingInfoBox from './form/title/EditGroupsForwardingInfoBox';
import useForwardingActions from './form/useForwardingActions';
import { getEnterprise } from '../../../ducks/entities/enterprise/enterpriseOperations';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import { DirectorySelector } from '../../../components/DirectorySelector/DirectorySelector';
import {
  convertForwardingFormToForwardingData,
  forwardingFormSchema,
  initializeForwardingForm
} from './form/forwardingFormUtils';
import type { ForwardingFormT } from './form/forwardingFormUtils';
import Notifications from '../../../components/Notifications/Notifications';
import RadioButton from '../../callFlows/components/edit/children/RadioButton';
import useEnterprise from '../../useEnterprise';
import type { ForwardingActionT } from '../../../ducks/entities/callForwarding/callForwardingTypes';
import { pushAnalyticsEvent } from '../../../utils/analyticsHandler';
import type { InternalUserEntityT } from '../../../ducks/entities/user/userTypes';
import ForwardingTypeSelector from './form/components/ForwardingTypeSelector';
import CallForwardingScheduler from '../../../components/CallForwardingScheduler/CallForwardingScheduler';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import GoBackLink from '../../../components/Button/GoBackLink';
import { FORWARDING_LABEL_MAX_LENGTH } from '../../../fieldValidators';
import {
  CREATE_CALL_FORWARDING,
  CREATE_ENTERPRISE_CALL_FORWARDING,
  CREATE_TEAM_CALL_FORWARDING,
  DELETE_CALL_FORWARDING,
  DELETE_TEAM_CALL_FORWARDING,
  UPDATE_CALL_FORWARDING,
  UPDATE_ENTERPRISE_CALL_FORWARDING,
  UPDATE_TEAM_CALL_FORWARDING
} from '../../../matomo_constants';
import TopNavigation from '../../navigation/TopNavigation';
import styles from './ForwardingDetails.module.scss';

export type PropsT = {||};
const PROCESS_CHECK_DELAY = 5000;

// eslint-disable-next-line no-unused-vars
const ForwardingDetails = (props: PropsT) => {
  const location = useLocation();
  const { id: enterpriseId, forwardingId } = useParams();

  const addressNumber: string = useSelector(state =>
    R.path(['router', 'location', 'state', 'addressNumber'], state)
  );

  const forwardingToCopy = R.path(['state', 'callForwarding'], location);
  const singleUser: InternalUserEntityT = useSelector(state =>
    R.path(['router', 'location', 'state', 'singleUser'], state)
  );

  const { t } = useTranslation();

  const action: ForwardingActionT =
    location.state && location.state.action ? location.state.action : 'create';

  const {
    forwarding,
    visualForwardingType,
    isForwardingLoading,
    destinationName,
    UUID,
    retrievedContacts
  } = useForwarding(enterpriseId || '', forwardingId || '', action);
  const dispatch = useDispatch();

  const [user, userIsLoading] = useUsers(enterpriseId || '', addressNumber);
  const userId = user ? user.id : '';
  const isEnterpriseForwarding = location.state && location.state.isEnterpriseForwarding;
  const [startTour] = useForwardingDetailsTour({});
  const [contacts, setContacts] = useState();
  const [searched, setSearched] = useState();
  const [presenceStates, setPresenceStates] = useState([]);
  const currentUser = useSelector(state => state.currentUser);
  const [toBeSearched, setToBeSearched] = useState();
  const cancelFetch = React.useRef<Canceler>();
  const cancelRequest = React.useRef<Canceler>();

  const initialFormValues: ForwardingFormT = initializeForwardingForm(
    forwardingToCopy || forwarding,
    visualForwardingType,
    !!isEnterpriseForwarding,
    user,
    destinationName,
    contacts
  );

  const [forwardingType, setForwardingType] = useState(visualForwardingType);

  const stopForwardingSearch = () => {
    axios({
      method: 'POST',
      // $FlowFixMe: enterpriseId already null checked
      url: `/api/v1/enterprises/${enterpriseId}/forwarding/done?uuid=${UUID}`,
      cancelToken: new CancelToken(canceler => {
        cancelRequest.current = canceler;
      }),
      headers: createCsrfHeader(currentUser)
    });
  };

  // if deep-linked then load new enterprise and select it
  useEnterprise(enterpriseId);

  useEffect(() => {
    setForwardingType(visualForwardingType);
    return () => {};
  }, [visualForwardingType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let id;
    if (retrievedContacts) {
      setContacts(retrievedContacts);
    } else if (UUID && UUID !== '') {
      id = setInterval(async () => {
        try {
          const response = await axios({
            method: 'GET',
            // $FlowFixMe: enterpriseId already null checked
            url: `/api/v1/enterprises/${enterpriseId}/forwarding/process?uuid=${UUID}`,
            cancelToken: new CancelToken(canceler => {
              cancelRequest.current = canceler;
            })
          });
          setSearched(response.data.searched);
          setToBeSearched(response.data.toBeSearched);
          if (
            response &&
            response.data.toBeSearched > 0 &&
            response.data.searched === response.data.toBeSearched
          ) {
            setContacts(response.data.contacts);
            clearInterval(id);
            stopForwardingSearch();
          }
        } catch (e) {
          console.log(e);
        }
      }, PROCESS_CHECK_DELAY);
    }
    return () => {
      if (id) {
        stopForwardingSearch();
        clearInterval(id);
      }
    };
  }, [UUID, retrievedContacts]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchExtensionIdsByAddressNumbers = addressNumbers => {
    return axios({
      method: 'POST',
      // $FlowFixMe: enterpriseId already null checked
      url: `/api/v1/enterprises/${enterpriseId}/userIds/byAddressNumbers`,
      data: addressNumbers,
      cancelToken: new CancelToken(canceler => {
        cancelRequest.current = canceler;
      }),
      headers: createCsrfHeader(currentUser)
    });
  };

  const getExtensionIdsForUsers = async members => {
    if (enterpriseId && members.length > 0) {
      const addressNumbers = members
        .filter(member => member.publicInfo && member.publicInfo.addressNumber)
        .map(member => member.publicInfo.addressNumber);
      const extensionIds = await fetchExtensionIdsByAddressNumbers(addressNumbers);
      // $FlowFixMe
      return members.map(member => {
        const extensionFound = extensionIds.data.find(
          ext => member.publicInfo.addressNumber === ext.addressNumber
        );
        if (extensionFound) {
          const { extensionId } = extensionFound;
          return {
            id: member.internalAddressId,
            userId: extensionId,
            username: `${member.publicInfo.firstName ? member.publicInfo.firstName : ''} ${
              member.publicInfo.lastName ? member.publicInfo.lastName : ''
            }`,
            forwardingId: member.forwardingId
          };
        }
        return [];
      });
    }
    return [];
  };

  const {
    updateCallForwarding,
    updateCallForwardings,
    createEnterpriseForwarding,
    createUserForwarding,
    deleteCallForwardings
  } = useForwardingActions({ enterpriseId, action });

  const addNewEnterpriseForwarding = async newForwarding => {
    if (isEnterpriseForwarding && action === 'create' && enterpriseId) {
      // $FlowFixMe: varying read-only fields on newForwarding
      await createEnterpriseForwarding(newForwarding); // eslint-disable-line no-param-reassign
      pushAnalyticsEvent(CREATE_ENTERPRISE_CALL_FORWARDING);

      await dispatch(
        getEnterprise(
          enterpriseId,
          new CancelToken(canceler => {
            cancelFetch.current = canceler;
          })
        )
      );
    }
  };

  const modifyEnterpriseForwarding = async newForwarding => {
    if (isEnterpriseForwarding && action !== 'create' && enterpriseId) {
      // $FlowFixMe varying read-only fields on newForwarding
      await updateCallForwarding({ id: forwardingId, ...newForwarding });
      pushAnalyticsEvent(UPDATE_ENTERPRISE_CALL_FORWARDING);

      await dispatch(
        getEnterprise(
          enterpriseId,
          new CancelToken(canceler => {
            cancelFetch.current = canceler;
          })
        )
      );
    }
  };

  const addNewUserForwarding = async (
    newForwarding,
    usersForwardingsToAdd,
    overwriteOverlapping
  ) => {
    if (
      !isEnterpriseForwarding &&
      (action === 'create' || forwardingToCopy) &&
      usersForwardingsToAdd.length > 0
    ) {
      await createUserForwarding(
        // $FlowFixMe varying read-only fields on newForwarding
        newForwarding,
        usersForwardingsToAdd,
        overwriteOverlapping
      );
      if (action === 'group') {
        pushAnalyticsEvent(CREATE_TEAM_CALL_FORWARDING);
      } else {
        pushAnalyticsEvent(CREATE_CALL_FORWARDING);
      }
    }
  };

  const modifyUserForwarding = async (newForwarding, usersForwardingsToModify) => {
    if (!isEnterpriseForwarding && action === 'modify' && usersForwardingsToModify.length === 1) {
      // $FlowFixMe varying read-only fields on newForwarding
      await updateCallForwarding({ id: forwardingId, ...newForwarding });
      pushAnalyticsEvent(UPDATE_CALL_FORWARDING);
    } else if (
      !isEnterpriseForwarding &&
      action === 'group' &&
      usersForwardingsToModify.length > 0
    ) {
      await updateCallForwardings(usersForwardingsToModify, newForwarding);
      pushAnalyticsEvent(UPDATE_TEAM_CALL_FORWARDING);
    }
  };

  const deleteUserForwarding = async usersForwardingsToRemove => {
    if (!isEnterpriseForwarding && usersForwardingsToRemove.length > 0) {
      await deleteCallForwardings(usersForwardingsToRemove);
      if (action === 'group') {
        pushAnalyticsEvent(DELETE_TEAM_CALL_FORWARDING);
      } else {
        pushAnalyticsEvent(DELETE_CALL_FORWARDING);
      }
    }
  };

  const fixForwardingIds = membersToModify => {
    return membersToModify.map(member => {
      if (!member.forwardingId) {
        const foundInitialValue = initialFormValues.members.find(
          im => member.id && im.internalAddressId === member.id
        );
        if (foundInitialValue) {
          // eslint-disable-next-line no-param-reassign
          member.forwardingId = foundInitialValue.forwardingId;
        }
      }
      return member;
    });
  };

  const onSaveForm = async (
    form: ForwardingFormT,
    membersToRemove,
    membersToAdd,
    membersToModify
  ) => {
    const usersForwardingsToRemove = await getExtensionIdsForUsers(membersToRemove);
    const usersForwardingsToAdd = user
      ? [{ userId, username: '' }]
      : await getExtensionIdsForUsers(membersToAdd);
    const usersForwardingsToModify = user
      ? [{ userId, username: '', forwardingId: '' }]
      : await getExtensionIdsForUsers(membersToModify);

    const fixedUsersForwardingsToModify = fixForwardingIds(usersForwardingsToModify);
    const newForwarding = convertForwardingFormToForwardingData(form, presenceStates);

    await addNewEnterpriseForwarding(newForwarding);

    await modifyEnterpriseForwarding(newForwarding);

    await addNewUserForwarding(newForwarding, usersForwardingsToAdd, form.overwriteOverlapping);
    await modifyUserForwarding(newForwarding, fixedUsersForwardingsToModify);

    await deleteUserForwarding(usersForwardingsToRemove);

    if (enterpriseId) {
      if (singleUser) {
        dispatch(goToEditUser(enterpriseId || '', singleUser.id, 'internalUser', true));
      } else {
        dispatch(goToEnterpriseForwardings(enterpriseId));
      }
    }
  };

  if (!enterpriseId || !forwarding || isForwardingLoading) {
    // Prevent form initialization from invalid values
    return <div />;
  }
  const fullName = user ? [user.firstName, user.lastName].join(' ').trim() : '';
  const overwriteOverlappingOptions = [
    {
      label: t('forwardingDetails.overwriteOverlapping.disableOld'),
      value: 'disableOld'
    },
    {
      label: t('forwardingDetails.overwriteOverlapping.doNotCreate'),
      value: 'doNotCreate'
    }
  ];

  const isLoadingUser = addressNumber && !userId;
  const showMemberSection = !userId && !isEnterpriseForwarding;
  // Initialize form after the user has been loaded
  const forwardingForm = () => (
    <ForwardingForm
      defaultValues={initialFormValues}
      validationSchema={forwardingFormSchema(t, location, forwardingType)}
      presenceStates={presenceStates}
      onSubmit={async (form, membersToRemove, membersToAdd, membersToModify) => {
        await onSaveForm(form, membersToRemove, membersToAdd, membersToModify);
      }}
      onCancel={e => {
        if (e) {
          e.preventDefault();
        }
        if (singleUser) {
          dispatch(goToEditUser(enterpriseId || '', singleUser.id, 'internalUser', true));
        } else {
          dispatch(goToEnterpriseForwardings(enterpriseId));
        }
      }}
      action={action}
      formButtonsStyle={styles['buttons-area']}
    >
      <div className={styles['form-area']}>
        <div className={styles['bottom-area']}>
          <div className={styles['left-area']}>
            <InputField
              field="label"
              title={t('forwardingDetails.cfName')}
              shouldValidate
              disabled={!!forwardingToCopy}
              inputStyle={styles.label}
              maxLength={FORWARDING_LABEL_MAX_LENGTH}
            />
            {showMemberSection && (
              <div>
                <RadioButton
                  title={t('forwardingDetails.overwriteOverlapping.header')}
                  field="overwriteOverlapping"
                  options={overwriteOverlappingOptions}
                />
                <DirectorySelector
                  field="members"
                  enterpriseId={enterpriseId}
                  title={t('forwardingDetails.createRuleForMany')}
                  shouldValidate
                  forceSmall
                />
              </div>
            )}
            <ForwardingTypeSelector
              field="forwardingType"
              createForEnterprise={isEnterpriseForwarding}
              onChange={type => {
                setForwardingType(type);
                setPresenceStates([]);
              }}
            />

            {forwardingType === 'PRESENCE' && (
              <PresenceStateSelector field="presenceStates" setFieldValue={setPresenceStates} />
            )}

            <ForwardingTargetSelector
              field="forwardingTarget"
              enterpriseId={enterpriseId}
              isEnterpriseForwarding={!!isEnterpriseForwarding}
              userId={userId}
              userIsLoading={userIsLoading}
              shouldValidate
            />
          </div>
          <div id="additional-settings-box" className={styles['right-area']}>
            <Card
              heading={<b>{t('forwardingDetails.additionalSettings')}</b>}
              content={
                <div>
                  <SpecificCallerFilterSelector field="specificCallerFilter" shouldValidate />
                  <TargetNumberFiltersSelector field="filtersOnTargetNumber" shouldValidate />
                  <CallForwardingScheduler
                    field="timeFilter"
                    disabled={forwardingType === 'PRESENCE'}
                  />
                </div>
              }
              type="outlined"
            />
          </div>
        </div>
      </div>
    </ForwardingForm>
  );
  return (
    <BaseContainer
      header={
        <TopNavigation
          onClickTutorial={() =>
            startTour(
              forwardingType,
              action === 'create',
              (action === 'create' || !!forwardingToCopy) && !userId,
              forwardingType === 'PRESENCE',
              forwardingType === 'NO_ANSWER'
            )
          }
          viewName="forwardingdetails"
        />
      }
    >
      <Notifications tags={['callforwarding-fail-single-user']} />
      <div className={styles['forwarding-details-page']}>
        <GoBackLink
          className={styles.link}
          id="header-link"
          text={t('forwardingDetails.link')}
          onClick={() =>
            singleUser
              ? dispatch(goToEditUser(enterpriseId || '', singleUser.id, 'internalUser', true))
              : dispatch(goToEnterpriseForwardings(enterpriseId || ''))
          }
        />
        <div className={styles['info-box-container']}>
          {isEnterpriseForwarding ? (
            <ForwardingEnterpriseInfoBox />
          ) : (
            userId && <ForwardingUsername fullName={fullName} editing={action !== 'create'} />
          )}
          {forwardingToCopy && <EditGroupsForwardingInfoBox forwarding={forwardingToCopy} />}
        </div>
        {isLoadingUser || (forwardingToCopy && !initialFormValues.members) ? (
          <CenterHorizontally>
            {searched && (
              <div>{t('forwardingDetails.searchForwarding', { searched, toBeSearched })}</div>
            )}
            <LoadingSpinner />
          </CenterHorizontally>
        ) : (
          forwardingForm()
        )}
      </div>
    </BaseContainer>
  );
};

export default ForwardingDetails;
