// @flow

import React, { type Element } from 'react';
import classNames from 'classnames';
import IconAddRegular from '@design-system/component-library/src/components/Icon/lib/IconAddRegular';
import styles from './CreateButton.module.scss';
import SecondaryButton from './SecondaryButton';

type PropsT = {
  id: string,
  text: string,
  onClickAction: Event => void,
  showTextAlways?: boolean
};

const CreateButton = (props: PropsT): Element<typeof SecondaryButton> => {
  const { id, text, onClickAction, showTextAlways } = props;
  return (
    <SecondaryButton
      id={id}
      className={classNames(styles['create-link-button'])}
      trailing={<IconAddRegular size="m" color="secondary-blue" />}
      label={text}
      onClickAction={onClickAction}
      alwaysShowText={showTextAlways}
    />
  );
};

export default CreateButton;
