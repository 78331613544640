// @flow

import React, { type Element } from 'react';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import classnames from 'classnames';
import ResultItem from '../../../../../../components/Search/ResultItem';
import type { ResultItemT } from '../../../../../../components/Search/ResultItem';
import type { DirectoryEntityT } from '../../../../../../ducks/entities/directory';
import styles from './ResultItems.module.scss';

const MAX_SEARCH_RESULTS = 20;

export type SearchResultT = {
  ...$Shape<DirectoryEntityT>,
  index?: number,
  ref?: *
};

export type PropsT = {|
  searchResults: SearchResultT[],
  isLoading?: boolean,
  maxSearchResults?: number,
  firstResultElement?: Element<'li'>,
  searchResultsRef: *,
  onSelectItem: ResultItemT => void,
  selectedResult: number
|};

export const ResultItems = (props: PropsT): Element<'ul'> | null | typeof LoadingSpinner => {
  const {
    searchResults,
    isLoading,
    maxSearchResults,
    firstResultElement,
    searchResultsRef,
    onSelectItem,
    selectedResult
  } = props;

  const renderResultItem = (index: number, result: SearchResultT, selected: boolean) => {
    return (
      <li
        key={result.id}
        ref={(el: ?HTMLElement) => {
          // eslint-disable-next-line no-param-reassign
          result.ref = el;
        }}
      >
        <ResultItem
          result={{
            index,
            addressNumber: result.publicInfo.addressNumber,
            label: result.displayName
          }}
          onSelect={onSelectItem}
          selected={selected}
        />
      </li>
    );
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (searchResults && searchResults.length > 0) {
    return (
      <ul className={classnames(styles['list-results'])} ref={searchResultsRef}>
        {firstResultElement}
        {searchResults
          .filter((_, index) => index < (maxSearchResults || MAX_SEARCH_RESULTS))
          .map((result, index) => renderResultItem(index, result, index === selectedResult))}
      </ul>
    );
  }
  return null;
};

export default ResultItems;
