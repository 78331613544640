import React from 'react';
import PropTypes from 'prop-types';
import { useId } from 'react-id-generator';

import Popover from '../Popover';
import IconInformationRegular from '../Icon/lib/IconInformationRegular';
import IconArrowDownRegular from '../Icon/lib/IconArrowDownRegular';

import { classNames } from '../../utils/css';

import styles from './Search.module.scss';

import { KEY_RETURN } from '../../utils/keycodes';

const keyCode = {
  BACKSPACE: 8,
  TAB: 9,
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  DELETE: 46,
};

const getMatchingItemLabel = (selectedValue, items) => {
  if (items) {
    const selected = selectedValue;
    for (let i = 0; i < items.length; i += 1) {
      if (typeof items[i] === 'object' && items[i].value === selected) {
        return items[i].label;
      }
      if (typeof items[i] === 'string' && items[i] === selected) {
        return items[i];
      }
    }
  }
  return null;
};

const getItemByValue = (items, selectedValue) => {
  if (items) {
    for (let i = 0; i < items.length; i += 1) {
      if (items[i].dataset.value === selectedValue) {
        return items[i];
      }
    }
  }
  return null;
};

const isPrintableCharacter = (str) => str.length === 1 && str.match(/\S/);

// eslint-disable-next-line react/function-component-definition
const Option = ({ item, activeItemId, onClick }) => {
  const fallbackId = useId()[0];
  const optionId = item.id || fallbackId;
  let optionClasses;
  let role;
  let dataValue;
  let ariaSelected;
  let content;
  let ariaLabel;

  if (typeof item === 'object') {
    optionClasses = classNames([
      styles.search__option,
      item.disabled ? styles['search__option--disabled'] : null,
      !item.value ? styles.search__option__title : null,
      !item.disabled && activeItemId === optionId ? styles['search__option--selected'] : null,
    ]);
    role = item.value ? 'option' : 'title';
    dataValue = item.value;
    ariaSelected = activeItemId === optionId;
    content = item.html || item.label;
    ariaLabel = item.ariaLabel;
  } else if (typeof item === 'string') {
    optionClasses = classNames([styles.search__option, activeItemId === optionId ? styles['search__option--selected'] : null]);
    role = 'option';
    dataValue = item;
    ariaSelected = activeItemId === optionId;
    content = item;
  }

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <li
      className={optionClasses}
      role={role}
      id={optionId}
      key={optionId}
      data-value={dataValue}
      aria-selected={ariaSelected}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === KEY_RETURN) {
          onClick();
        }
      }}
      aria-label={ariaLabel}
    >
      {content}
    </li>
  );
  /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
};

Option.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ disabled: PropTypes.bool, value: PropTypes.string, html: PropTypes.node, label: PropTypes.string, ariaLabel: PropTypes.string })]),
  activeItemId: PropTypes.string,
  onClick: PropTypes.func,
};

Option.defaultProps = {
  item: null,
  activeItemId: null,
  onClick: () => {},
};

class Search extends React.Component {
  static elementOrAncestorHasClass = (element, searchRef) => {
    if (!element || element.length === 0 || !searchRef || searchRef === null) {
      return false;
    }
    const parent = element;
    const { id } = searchRef;
    do {
      if (parent === document) {
        break;
      }
      if (parent.id.indexOf(id) >= 0) {
        return true;
      }
    } while (parent === parent.parentNode);
    return false;
  };

  static findMatchInRange = (list, startIndex, endIndex, content) => {
    for (let n = startIndex; n < endIndex; n += 1) {
      const label = list[n].textContent;
      if (label && label.toLowerCase().indexOf(content.toLowerCase()) === 0) {
        return list[n];
      }
    }
    return null;
  };

  constructor(props) {
    super(props);

    this.searchRef = React.createRef();
    this.listRef = React.createRef();

    this.state = {
      visible: false,
      ariaExpanded: false,
      inputContent: '',
      selectedValue: undefined,
      error: undefined,
    };

    this.toggleOptions = this.toggleOptions.bind(this);
    this.showOptions = this.showOptions.bind(this);
    this.hideOptions = this.hideOptions.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.focusItem = this.focusItem.bind(this);
    this.setupFocus = this.setupFocus.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.getActivedescendantElement = this.getActivedescendantElement.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.findItemToFocus = this.findItemToFocus.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.showErrorIfNoItemSelected = this.showErrorIfNoItemSelected.bind(this);
  }

  componentDidMount() {
    const { selectedValue } = this.props;
    this.setSelectedItem(selectedValue);
    document?.addEventListener('click', this.onDocumentClick);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedValue } = this.props;
    if (nextProps.selectedValue && selectedValue !== nextProps.selectedValue) {
      this.setSelectedItem(nextProps.selectedValue);
    }
  }

  componentWillUnmount() {
    document?.removeEventListener('click', this.onDocumentClick);
  }

  handleBlur() {
    const { i18n_search_errorMessage, onBlur } = this.props;
    const showError = this.showErrorIfNoItemSelected();
    if (onBlur) {
      onBlur(showError && i18n_search_errorMessage);
    }
  }

  handleKeyDown(event) {
    const { error, activeDescendant } = this.state;
    const key = event.which || event.keyCode;
    switch (key) {
      case keyCode.ESC:
        event.preventDefault();
        this.showErrorIfNoItemSelected();
        this.hideOptions();
        break;
      case keyCode.UP:
      case keyCode.DOWN:
        event.preventDefault();
        this.showOptions();
        if (!activeDescendant) {
          this.setupFocus();
        } else {
          let nextItem = this.getActivedescendantElement();
          if (!nextItem) {
            return;
          }
          const titleClass = styles.search__option__title;
          const disabledStyle = styles['search__option--disabled'];
          if (key === keyCode.UP) {
            nextItem = nextItem.previousElementSibling;
            if (nextItem && (nextItem.classList.contains(titleClass) || nextItem.classList.contains(disabledStyle))) {
              nextItem = nextItem.previousElementSibling;
            }
          } else {
            nextItem = nextItem.nextElementSibling;
            if (nextItem && (nextItem.classList.contains(titleClass) || nextItem.classList.contains(disabledStyle))) {
              nextItem = nextItem.nextElementSibling;
            }
          }
          this.focusItem(nextItem);
        }
        break;
      case keyCode.HOME:
        event.preventDefault();
        this.focusFirstItem();
        break;
      case keyCode.END:
        event.preventDefault();
        this.focusLastItem();
        break;
      case keyCode.RETURN:
        this.onSubmit();
        this.toggleOptions(event);
        break;
      case keyCode.TAB:
        if (!error) {
          this.onSubmit();
        }
        this.hideOptions();
        break;
      default:
        break;
    }
  }

  handleKeyUp(event) {
    const { visible, activeDescendant } = this.state;
    const key = event.which || event.keyCode;
    if (key === keyCode.ESC) {
      return;
    }
    if (isPrintableCharacter(event.key) || key === keyCode.SPACE) {
      if (!visible) {
        this.showOptions();
        if (!activeDescendant) {
          this.setupFocus();
        }
      }
    }
  }

  onDocumentClick(e) {
    const { visible } = this.state;
    if (visible === true) {
      const { target } = e;
      const search = this.searchRef.current || null;
      if (search && !Search.elementOrAncestorHasClass(target, search)) {
        this.hideOptions();
      }
    }
  }

  onSubmit(selectedItem) {
    const { items, onValueChange } = this.props;
    const item = selectedItem || this.getActivedescendantElement();
    if (item) {
      this.setState((prev) => ({
        ...prev,
        inputContent: getMatchingItemLabel(item.dataset.value, items),
        activeDescendant: item.id,
        selectedValue: item.dataset.value,
        error: undefined,
      }));
      if (onValueChange) {
        onValueChange(item);
      }
    }
  }

  setSelectedItem(selectedValue) {
    const { i18n_search_placeholderText, items } = this.props;
    const matchedItem = selectedValue ? getItemByValue(this.listRef.current.children, selectedValue) : this.getFirstItem();

    const initialInputContent = getMatchingItemLabel(matchedItem?.dataset.value, items) || i18n_search_placeholderText;
    this.setState((prev) => ({
      ...prev,
      inputContent: initialInputContent,
      activeDescendant: matchedItem?.id,
      selectedValue,
    }));
  }

  getActivedescendantElement() {
    const { activeDescendant } = this.state;
    const selected = activeDescendant;
    const items = this.listRef.current.children;
    let result = items[0];

    if (selected) {
      for (let i = 0; i < items.length; i += 1) {
        if (items[i].id === selected) {
          result = items[i];
        }
      }
    }
    return result;
  }

  getFirstItem() {
    const disabledStyle = styles['search__option--disabled'];
    return this.listRef.current.querySelector(`[role="option"]:not(.${disabledStyle})`);
  }

  getLastItem() {
    const disabledStyle = styles['search__option--disabled'];
    return this.listRef.current.querySelector(`[role="option"]:not(.${disabledStyle}):last-of-type`);
  }

  setupFocus() {
    const { activeDescendant } = this.state;
    if (activeDescendant) {
      return;
    }
    this.focusFirstItem();
  }

  findItemToFocus(content, domRef, activeItem) {
    const titleClass = styles.search__option__title;
    const disabledStyle = styles['search__option--disabled'];
    const itemList = domRef.querySelectorAll(`[role="option"]:not(.${titleClass}):not(.${disabledStyle})`);
    let searchIndex = null;
    for (let i = 0; i < itemList.length; i += 1) {
      if (itemList[i].id === activeItem) {
        searchIndex = i;
      }
    }
    let nextMatch = Search.findMatchInRange(itemList, searchIndex + 1, itemList.length, content);
    if (!nextMatch) {
      nextMatch = Search.findMatchInRange(itemList, 0, searchIndex, content);
    }
    this.focusItem(nextMatch);
  }

  focusItem(itemToFocus) {
    if (itemToFocus) {
      this.setState((prev) => ({ ...prev, activeDescendant: itemToFocus.id }));
      this.listRef.current.scrollTo('smooth', itemToFocus.offsetTop);
    }
  }

  focusFirstItem() {
    this.focusItem(this.getFirstItem());
  }

  focusLastItem() {
    this.focusItem(this.getLastItem());
  }

  selectOption(e) {
    this.onSubmit(e.currentTarget);
    this.hideOptions();
  }

  showOptions() {
    this.setState((prev) => ({ ...prev, visible: true, ariaExpanded: true }));
  }

  hideOptions() {
    this.setState((prev) => ({ ...prev, visible: false, ariaExpanded: false }));
  }

  toggleOptions(e) {
    e.stopPropagation();
    const { visible } = this.state;
    if (!visible) {
      this.showOptions();
    } else {
      this.hideOptions();
    }
  }

  showErrorIfNoItemSelected() {
    const { i18n_search_errorMessage } = this.props;
    const { selectedValue } = this.state;
    if (!selectedValue) {
      this.setState((prev) => ({
        ...prev,
        error: i18n_search_errorMessage,
      }));
      return true;
    }
    return false;
  }

  render() {
    const {
      id,
      className,
      disabled,
      label,
      name,
      items,
      i18n_search_errorMessage,
      i18n_search_infoText,
      i18n_search_helpText,
      i18n_search_placeholderText,
      i18n_search_optionalText,
      optional,
      selectedValue,
      onValueChange,
      inlineBlock,
      onBlur,
      onFocus,
      onSearch,
      ariaLabel,
      isSearchIconVisible,
      customSearchIcon,
      inputProps,
      ...otherProps
    } = this.props;

    const idPrefix = id ?? 'dssearch';

    let optionalIndicator = null;
    if (optional === true) {
      optionalIndicator = i18n_search_optionalText;
    }

    const { ariaExpanded, error, activeDescendant, inputContent, visible } = this.state;
    const classes = classNames([styles.search, className || null, disabled ? styles['search-disabled'] : null, inlineBlock ? styles['search-inlineBlock'] : null]);

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div id={`${idPrefix}Search`} ref={this.searchRef} className={classes} {...otherProps}>
        <div className={styles.search__labelarea}>
          {label || optionalIndicator ? (
            <label id={`${idPrefix}Label`} className={styles['search__labelarea-label']} htmlFor={`${idPrefix}input`}>
              {label}
              {optionalIndicator ? ` ${optionalIndicator}` : null}
            </label>
          ) : null}
          {i18n_search_infoText ? <Popover triggerElement={<IconInformationRegular />} placement="top" i18n_popover_contentText={i18n_search_infoText} /> : null}
        </div>
        {isSearchIconVisible && (
          <div className={styles.search__icon}>
            <div className={styles.search__icon__container}>
              {customSearchIcon || (
                <IconArrowDownRegular
                  className={ariaExpanded && styles['search__icon__arrow--reverse']}
                  onClick={this.toggleOptions} // FIXME: Click does not work, because 1st it calls toggleOption and set ariaExpanded = true, then it calls onDocumentClick to make ariaExpanded = false
                  aria-labelledby={label ? `${idPrefix}Label` : undefined}
                  aria-label={ariaLabel}
                />
              )}
            </div>
          </div>
        )}
        <input
          id={`${idPrefix}input`}
          className={classNames([styles.search__input, disabled ? styles['search__input--disabled'] : null, error && styles['search__input-error']])}
          disabled={disabled}
          type="text"
          role="combobox"
          onClick={this.toggleOptions}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          aria-expanded={ariaExpanded}
          aria-autocomplete="none"
          aria-haspopup
          aria-labelledby={label ? `${idPrefix}Label` : undefined}
          aria-label={ariaLabel}
          aria-owns={`${idPrefix}searchCombox`}
          aria-activedescendant={activeDescendant}
          aria-controls={`${idPrefix}searchCombox`}
          onFocus={onFocus}
          onBlur={this.handleBlur}
          name={name}
          value={inputContent}
          onChange={(e) => {
            const content = e.target.value;
            if (onSearch) {
              onSearch(content);
              this.setState((prev) => ({ ...prev, inputContent: content, activeDescendant: undefined, selectedValue: undefined }));
            } else {
              this.findItemToFocus(content, this.listRef.current, activeDescendant);
              this.setState((prev) => ({ ...prev, inputContent: content, selectedValue: undefined }));
            }
          }}
          {...inputProps}
        />
        <ul id={`${idPrefix}searchCombox`} className={styles.search__options} ref={this.listRef} tabIndex="-1" role="listbox" aria-labelledby={label ? `${idPrefix}Label` : undefined} aria-label={ariaLabel} data-show={visible}>
          {items.map((item, index) => (
            <Option item={item} activeItemId={activeDescendant} onClick={!item.disabled ? this.selectOption : null} key={item.label || item || index} />
          ))}
        </ul>
        {error && (
          <p className={styles.search__errormessage} id={`${idPrefix}Error`}>
            {error}
          </p>
        )}
        {i18n_search_helpText && (
          <p className={styles.search__helptext} id={`${idPrefix}Help`}>
            {i18n_search_helpText}
          </p>
        )}
      </div>
    );
  }
}

Search.propTypes = {
  /**
   * Id of the component
   */
  id: PropTypes.string,
  /**
   * Class names you want to give to the component
   */
  className: PropTypes.string,
  /**
   * Label text for the component
   */
  label: PropTypes.string,
  /**
   * Name of the form control. Submitted with the form as part of a name/value pair.
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefname)
   */
  name: PropTypes.string,
  /**
   * List of search's result options
   */
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string.isRequired,
        ariaLabel: PropTypes.string,
        value: PropTypes.string,
        html: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
        disabled: PropTypes.bool,
      }),
      PropTypes.string.isRequired,
    ])
  ),
  /**
   * Placeholder text for the component
   */
  i18n_search_placeholderText: PropTypes.string,
  /**
   * Error message to display when no value is selected
   */
  i18n_search_errorMessage: PropTypes.string,
  /**
   * Additional information and instructions for the field
   */
  i18n_search_infoText: PropTypes.string,
  /**
   * Always visible help text for the field
   */
  i18n_search_helpText: PropTypes.string,
  /**
   * Optional field indicator text
   */
  i18n_search_optionalText: PropTypes.string,
  /**
   * Selected option's value
   */
  selectedValue: PropTypes.string,
  /**
   * Whether field is optional or not. By default all fields are mandatory.
   */
  optional: PropTypes.bool,
  /**
   * Whether field is disabled or not. By default set to false.
   */
  disabled: PropTypes.bool,
  /**
   * Function that runs when value is changed
   */
  onValueChange: PropTypes.func,
  /**
   * Function that runs when search text changes
   */
  onSearch: PropTypes.func,
  /**
   * Function that runs when search input focus is moved out
   */
  onBlur: PropTypes.func,
  /**
   * Function that runs when search input is focussed
   */
  onFocus: PropTypes.func,
  /**
   * Show search input in inline block view
   */
  inlineBlock: PropTypes.bool,
  /**
   * aria label of the component
   */
  ariaLabel: PropTypes.string,
  /**
   * Manage visibility of search box icon
   */
  isSearchIconVisible: PropTypes.bool,
  /**
   * Custom search box icon
   */
  customSearchIcon: PropTypes.node,
  /**
   * Custom props for the input element
   */
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
};

Search.defaultProps = {
  id: null,
  className: null,
  disabled: false,
  label: null,
  name: null,
  items: null,
  i18n_search_errorMessage: 'Please select a value',
  i18n_search_infoText: null,
  i18n_search_helpText: null,
  i18n_search_placeholderText: null,
  i18n_search_optionalText: '(ei pakollinen)',
  optional: false,
  selectedValue: null,
  onValueChange: () => {},
  onSearch: null,
  onBlur: () => {},
  onFocus: () => {},
  inlineBlock: false,
  ariaLabel: null,
  isSearchIconVisible: true,
  customSearchIcon: null,
  inputProps: {}
};

export default Search;
