// @flow
import React, { type Element } from 'react';
import { type FieldProps } from 'redux-form';
import Input from '@design-system/component-library/src/components/Input';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import IdTreeComboboxField from './IdTreeCombobox';

export type PropsT = FieldProps & {
  disabled?: boolean,
  inputFieldRef?: *,
  showLength?: boolean,
  maxlength?: number,
  removeCharactersFromMaxLength?: string,
  ...
};

// $FlowFixMe
const InputField = ({ inputFieldRef, input, disabled, ...rest }: PropsT): Element<typeof Input> => {
  let foundCharactersRemoved = 0;
  if (rest.removeCharactersFromMaxLength) {
    const foundCharacters = input.value.match(rest.removeCharactersFromMaxLength);
    foundCharactersRemoved = foundCharacters ? foundCharacters.length : 0;
  }
  const { value, ...inputWithoutValue } = input;
  return (
    <Input
      {...rest}
      // $FlowFixMe: redux-form's FieldProps are not exact
      {...inputWithoutValue}
      defaultValue={input.value}
      maxlength={rest.maxlength + foundCharactersRemoved}
      onValueChange={input.onChange}
      i18n_input_errorMessage={rest.meta.error}
      touched={rest.meta.touched}
      ref={inputFieldRef}
      i18n_input_helpText={
        rest.showLength && input.value.replace
          ? `${input.value.replace(rest.removeCharactersFromMaxLength || '', '').length} / ${
              rest.maxlength
            }`
          : ''
      }
    />
  );
};

const CheckboxField = ({ input, ...rest }: PropsT): Element<typeof Checkbox> => (
  // $FlowFixMe: redux-form's FieldProps are not exact
  <Checkbox {...rest} {...input} />
);

const IdTreeCombobox = ({
  input,
  disabled,
  ...rest
}: PropsT): Element<typeof IdTreeComboboxField> => (
  // $FlowFixMe
  <IdTreeComboboxField disabled={disabled} {...input} {...rest} />
);

export { InputField, CheckboxField, IdTreeCombobox };
