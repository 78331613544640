import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from '../../../../utils/css';
import styles from './Divider.module.scss';

function Divider({ className }) {
  return <hr className={classNames([styles['footer-divider'], className])} />
}

Divider.propTypes = {
  className: PropTypes.string,
};

Divider.defaultProps = {
  className: undefined,
};

export default Divider;
