// @flow strict-local

import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken } from 'axios';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WelcomeAnnouncementIcon } from '../../../../../assets/callflow/details/welcome-announcement-small.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import { update } from '../../../../../ducks/entities/acd/acdOperations';
import type {
  AudioFieldT,
  PropertyAudioT
} from '../../../components/edit/children/audio/CallflowAudioUtils';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import { uploadAudios } from '../../../components/edit/children/audio/CallflowAudioUtils';
import { createIvrPatchPayload } from '../../../components/edit/CallflowPropertyUtils';
import AcdAudioField from '../../../components/edit/children/audio/AcdAudioField';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import ToggleField from '../../../components/edit/children/ToggleField';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';

export type PropsT = {|
  callflowId: string
|};

type FormT = {
  useWelcomeMsg: boolean,
  bypassWelcome: boolean,
  welcomeMsg: AudioFieldT<PropertyAudioT>,
  initialRingbackTone: boolean,
  keepWaitingMusicWhenBypassWelcome: boolean,
  waitingAnnounceMsg: AudioFieldT<PropertyAudioT>,
  ringMsg: AudioFieldT<PropertyAudioT>
};

export const EditNotificationDetails = (
  props: PropsT
): Element<typeof EditCallflowDetails> | null => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);

  if (!acdData) {
    return null;
  }

  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const acdIvrCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};
  const callflowAudioFieldConverter = new AudioFieldConverter(
    acdData.enterpriseId,
    acdData.id,
    'acds'
  );

  const initialFormValues: FormT = {
    useWelcomeMsg: !acdCallCenter.skipWelcomeMsg,
    bypassWelcome: acdCallCenter.bypassWelcome,
    welcomeMsg: callflowAudioFieldConverter.convertToAudioPropertyField(
      'WelcomeMsg',
      R.path(['audios', 'welcomeMsg', 'extensionAudio', 'name'], acdCallCenter),
      acdIvrCallCenter.welcomeMsg
    ),
    initialRingbackTone: acdCallCenter.initialRingbackTone,
    keepWaitingMusicWhenBypassWelcome: acdCallCenter.keepWaitingMusicWhenBypassWelcome,
    waitingAnnounceMsg: callflowAudioFieldConverter.convertToAudioPropertyField(
      'WaitingAnnounceMsg',
      R.path(['audios', 'waitingAnnounceMsg', 'extensionAudio', 'name'], acdCallCenter),
      acdIvrCallCenter.waitingAnnounceMsg
    ),
    ringMsg: callflowAudioFieldConverter.convertToAudioPropertyField(
      'RingMsg',
      R.path(['audios', 'ringMsg', 'extensionAudio', 'name'], acdCallCenter),
      acdIvrCallCenter.ringMsg
    )
  };

  // update
  const onSubmit = async (formData: FormT): Promise<AcdEntityT> => {
    let propertyNames = ['bypassWelcome', 'welcomeMsg'];
    if (((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API')) {
      propertyNames = [
        ...propertyNames,
        'initialRingbackTone',
        'keepWaitingMusicWhenBypassWelcome',
        'waitingAnnounceMsg',
        'ringMsg'
      ];
    }

    const patchPayload = createIvrPatchPayload({}, formData, propertyNames, [
      {
        skipWelcomeMsg: {
          level: 'Extension',
          value: !formData.useWelcomeMsg
        }
      }
    ]);
    // $FlowFixMe Improve formData typing
    const failedAudioUploads = await uploadAudios(
      formData,
      CancelToken.source(),
      createCsrfHeader(currentUser)
    );
    if (failedAudioUploads.length > 0) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        t('callflows.audioUploadFailed', { audioFileNames: failedAudioUploads.join(',') })
      );
    }
    return dispatch(
      update(
        acdData.enterpriseId,
        acdData.type,
        acdData.id,
        patchPayload,
        CancelToken.source().token,
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={acdData.id}
      icon={<WelcomeAnnouncementIcon />}
      title={t('callflows.editNotificationDetails.title')}
      description={t('callflows.editNotificationDetails.description')}
      defaultValues={initialFormValues}
      onSaveForm={onSubmit}
    >
      <ToggleField
        field="useWelcomeMsg"
        elementId="useWelcomeMsg"
        label={t('callflows.editNotificationDetails.useWelcomeMsg')}
      />

      <ToggleField
        field="bypassWelcome"
        elementId="bypassWelcome"
        label={t('callflows.editNotificationDetails.bypassWelcome')}
        disabledFn={(formValues: FormT) => !formValues.useWelcomeMsg}
      />

      <AcdAudioField
        field="welcomeMsg"
        title={t('callflows.editNotificationDetails.welcomeMsg')}
        disabledFn={(formValues: FormT) => !formValues.useWelcomeMsg}
      />

      {((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') ? (
        <div>
          <AcdAudioField field="ringMsg" title={t('callflows.editNotificationDetails.ringMsg')} />
          <ToggleField
            field="initialRingbackTone"
            elementId="initialRingbackTone"
            label={t('callflows.editNotificationDetails.initialRingbackTone')}
            disabledFn={(formValues: FormT) => !formValues.useWelcomeMsg}
          />
          <ToggleField
            field="keepWaitingMusicWhenBypassWelcome"
            elementId="keepWaitingMusicWhenBypassWelcome"
            label={t('callflows.editNotificationDetails.keepWaitingMusicWhenBypassWelcome')}
            disabledFn={(formValues: FormT) => !formValues.useWelcomeMsg}
          />
          <AcdAudioField
            field="waitingAnnounceMsg"
            title={t('callflows.editNotificationDetails.waitingAnnounceMsg')}
          />
        </div>
      ) : null}
    </EditCallflowDetails>
  );
};

export default EditNotificationDetails;
