// @flow
/* eslint-disable import/prefer-default-export */

export const IMPORT_CONTACTS = 'IMPORT_CONTACTS_';
export const INTERCEPTOR_RECEIVED_UNAUTHORIZED = 'INTERCEPTOR_RECEIVED_UNAUTHORIZED';
export const INTERCEPTOR_RECEIVED_FORBIDDEN = 'INTERCEPTOR_RECEIVED_FORBIDDEN';
export const ADMIN_OPEN_LOCKED_USER_REQUEST = 'ADMIN_OPEN_LOCKED_USER_REQUEST';
export const ADMIN_OPEN_LOCKED_USER_SUCCESS = 'ADMIN_OPEN_LOCKED_USER_SUCCESS';
export const ADMIN_OPEN_LOCKED_USER_FAILURE = 'ADMIN_OPEN_LOCKED_USER_FAILURE';
export const USER_LOGOUT_SUCCESSFUL = 'USER_LOGOUT_SUCCESSFUL';
export const REMOVE_FAVOURITE_USER = 'REMOVE_FAVOURITE_USER';
export const ADD_FAVOURITE_USER = 'ADD_FAVOURITE_USER';
export const OPEN_FAVOURITE_USER_FROM_CAROUSEL = 'OPEN_FAVOURITE_USER_FROM_CAROUSEL';
export const OPEN_RECENT_USER_FROM_CAROUSEL = 'OPEN_RECENT_USER_FROM_CAROUSEL';
export const LOGIN_SUCCESS_MULTI_ENTERPRISE_ADMIN = 'LOGIN_SUCCESS_MULTI_ENTERPRISE_ADMIN';
export const LOGIN_SUCCESS_SINGLE_ENTERPRISE_ADMIN = 'LOGIN_SUCCESS_SINGLE_ENTERPRISE_ADMIN';
export const LOGIN_SUCCESS_SWITCHBOARD_ACD_MANGER_USER =
  'LOGIN_SUCCESS_SWITCHBOARD_ACD_MANGER_USER';
export const LOGIN_SUCCESS_SWITCHBOARD_USER = 'LOGIN_SUCCESS_SWITCHBOARD_USER';
export const LOGIN_SUCCESS_ACD_MANGER_USER = 'LOGIN_SUCCESS_ACD_MANGER_USER';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const ELISA_SSO_LOGIN_FAILURE_INVALID_ACCESS_RIGHTS =
  'ELISA_SSO_LOGIN_FAILURE_INVALID_ACCESS_RIGHTS';
export const RING_USER_LOGGED_WITHOUT_ADDITIONAL_ROLES =
  'RING_USER_LOGGED_WITHOUT_ADDITIONAL_ROLES';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const CREATE_ENTERPRISE_CALL_FORWARDING = 'CREATE_ENTERPRISE_CALL_FORWARDING';
export const UPDATE_ENTERPRISE_CALL_FORWARDING = 'UPDATE_ENTERPRISE_CALL_FORWARDING';
export const CREATE_TEAM_CALL_FORWARDING = 'CREATE_TEAM_CALL_FORWARDING';
export const CREATE_CALL_FORWARDING = 'CREATE_CALL_FORWARDING';
export const UPDATE_CALL_FORWARDING = 'UPDATE_CALL_FORWARDING';
export const UPDATE_TEAM_CALL_FORWARDING = 'UPDATE_TEAM_CALL_FORWARDING';
export const DELETE_TEAM_CALL_FORWARDING = 'DELETE_TEAM_CALL_FORWARDING';
export const DELETE_CALL_FORWARDING = 'DELETE_CALL_FORWARDING';
export const USER_CLICKED_SSO_PASSWORD_RESET_LINK = 'USER_CLICKED_SSO_PASSWORD_RESET_LINK';
export const EXPORT_CONTACTS = 'EXPORT_CONTACTS_';
export const SHOW_INTERNAL_USER = 'SHOW_INTERNAL_USER';
export const ADMIN_CLICKED_RESET_USER_PASSWORD = 'ADMIN_CLICKED_RESET_USER_PASSWORD';
export const ADMIN_INITIALIZED_RESET_USER_PASSWORD_SUCCESS =
  'ADMIN_INITIALIZED_RESET_USER_PASSWORD_SUCCESS';
export const ADMIN_INITIALIZED_RESET_USER_PASSWORD_FAILURE =
  'ADMIN_INITIALIZED_RESET_USER_PASSWORD_FAILURE';
export const SHOW_USER_PRESENCE_DETAILS = 'SHOW_USER_PRESENCE_DETAILS';
export const SHOW_PAGE_INTRODUCTION = 'SHOW_PAGE_INTRODUCTION';
