// @flow strict-local

import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { compose } from 'redux';
import type { ExactPropsT } from '../../../../commonTypes';
import type { PlayMusicGridElementT } from '../../../../ducks/entities/callFlow/callFlowTypes';
import type { PlayMusicEntityT } from '../../../../ducks/entities/playMusic/playMusicTypes';
import GridElementContainer from '../gridElement/GridElementContainer';
import PlayMusicDetails from '../details/playMusic/PlayMusicDetails';
import EditPlayMusic from '../details/playMusic/EditPlayMusic';

type OwnPropsT = {|
  data: PlayMusicEntityT,
  enterpriseId: string,
  hideDescription: boolean,
  single?: boolean
|};

type StatePropsT = {||};

type StateT = {};
export type PropsT = ExactPropsT<OwnPropsT, StatePropsT, WithTranslationProps>;

export class PlayMusicElement extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.state = {};
  }

  render(): Element<typeof GridElementContainer> | null {
    const { t, data, enterpriseId, hideDescription, single } = this.props;
    const gridElement: PlayMusicGridElementT = {
      id: data.id,
      type: 'PLAY_MUSIC',
      name: data.ivrName || t('callflows.type.playMusic.defaultName'),
      number: data.addressNumber,
      description: t('callflows.type.playMusic.description'),
      enterpriseId,
      rootNode: {
        id: 'PlayMusic',
        type: 'PLAY_MUSIC',
        description: 'Tiedote'
      }
    };
    if (gridElement.type === 'PLAY_MUSIC') {
      const callflowId = `${enterpriseId}-PLAY_MUSIC-${data.id}`;
      return (
        <GridElementContainer
          key={gridElement.id}
          gridElement={gridElement}
          hideDescription={hideDescription}
          single={single}
          viewContentSupplier={() => <PlayMusicDetails callflowId={callflowId} />}
          editContentSupplier={() => <EditPlayMusic callflowId={callflowId} />}
        />
      );
    }
    return null;
  }
}

const mapStateToProps = () => ({});

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)
)(PlayMusicElement);
