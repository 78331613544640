
import React from 'react';

import { ReactComponent as IconSource } from './iconFiles/webcam--filled.svg';
import IconContainer from '../IconContainer';

export default function IconWebcamFilled(props) {
  return (
    <IconContainer
      {...props}
      source={IconSource}
    />
  );
}
  