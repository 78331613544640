// @flow

import React, { type ChildrenArray, type Element, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import { FormProvider, useForm } from 'react-hook-form';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import ForwardingConfirmDialog from './ForwardingConfirmDialog';
import type {
  ForwardingActionT,
  PresenceStateTypeT
} from '../../../../ducks/entities/callForwarding/callForwardingTypes';
import CancelButton from '../../../../components/Button/CancelButton';
import ActionButton from '../../../../components/Button/ActionButton';

import styles from './ForwardingForm.module.scss';

type PropsT = {
  children: ChildrenArray<void | null | boolean | string | number | Element<*>>,
  defaultValues: {
    [string]: *
  },
  validationSchema?: ?{
    [string]: *
  },
  onSubmit: (*, *, *, *) => Promise<*>,
  onCancel: (*) => void,
  onDelete?: (*) => void,
  containerStyle?: string,
  disableCallflowNotifications?: boolean,
  action: ForwardingActionT,
  formButtonsStyle?: string,
  presenceStates: PresenceStateTypeT[]
};

export const ForwardingForm = (props: PropsT): Element<'div'> => {
  const {
    children,
    defaultValues,
    onSubmit,
    validationSchema,
    onCancel,
    containerStyle,
    action,
    presenceStates
  } = props;

  const { t } = useTranslation();
  // state
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  const fieldNames = R.keys(defaultValues);

  const methods = useForm({
    defaultValues,
    resolver: validationSchema ? yupResolver(validationSchema) : null
  });
  fieldNames.forEach(name =>
    methods.register(name, {
      shouldUnregister: true
    })
  );

  const mounted = useRef(false);
  const selectedUsers = methods.getValues().members;
  const selectedIds = selectedUsers ? selectedUsers.map(user => user.id) : [];
  const defaultIds = defaultValues.members ? defaultValues.members.map(member => member.id) : [];
  const checkIfValuesAreModified = () => {
    const currentValues = methods.getValues();
    return (
      currentValues.filtersOnTargetNumber !== defaultValues.filtersOnTargetNumber ||
      currentValues.forwardingTarget !== defaultValues.forwardingTarget ||
      currentValues.specificCallerFilter !== defaultValues.specificCallerFilter ||
      currentValues.timeFilter !== defaultValues.timeFilter
    );
  };

  const areValuesModified = checkIfValuesAreModified();
  const membersToRemove = defaultValues.members
    ? defaultValues.members.filter(member => !selectedIds.includes(member.id))
    : [];
  const membersToAdd = selectedUsers
    ? selectedUsers.filter(user => action === 'create' || !defaultIds.includes(user.id))
    : [];
  const membersToModify =
    selectedUsers && areValuesModified
      ? selectedUsers.filter(user => action !== 'create' && defaultIds.includes(user.id))
      : [];

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const onSubmitForm = () => {
    setConfirmDialogOpen(true);
  };

  const formButtons = (
    <div className={classnames(styles['button-area'], containerStyle)}>
      <CancelButton
        id="forwarding-cancel-button"
        data-cy="forwarding-cancel-button"
        className={styles['cancel-button']}
        label={t('callflows.editCallflowDetails.onCancelLabel')}
        onClickAction={onCancel}
        hideWhenViewing
      />
      <ActionButton
        id="forwarding-save-button"
        label={t('callflows.editCallflowDetails.onSaveLabel')}
        onClickAction={() => {}}
        disabled={!methods.formState.isDirty || !methods.formState.isValid}
        loading={isProcessing}
        type="submit"
      />
    </div>
  );

  return (
    <div className={classnames(styles.container, containerStyle)}>
      {confirmDialogOpen && (
        <ForwardingConfirmDialog
          membersToRemove={membersToRemove}
          membersToAdd={membersToAdd}
          membersToModify={membersToModify}
          presenceStates={presenceStates}
          isLoading={isProcessing}
          forwardingForm={methods.getValues()}
          onClose={() => {
            if (mounted.current) {
              setConfirmDialogOpen(false);
            }
          }}
          onConfirm={async () => {
            setIsProcessing(true);
            await onSubmit(methods.getValues(), membersToRemove, membersToAdd, membersToModify);
            setConfirmDialogOpen(false);
            setIsProcessing(false);
          }}
        />
      )}
      <FormProvider {...methods}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form
          onKeyPress={e => {
            // eslint-disable-next-line no-unused-expressions
            e.key === 'Enter' && e.preventDefault();
          }}
          onSubmit={methods.handleSubmit(onSubmitForm)}
        >
          <div className={styles.content}>{children}</div>
          {formButtons}
        </form>
      </FormProvider>
    </div>
  );
};

export default ForwardingForm;
