import React from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import { classNames } from '../../utils/css';

import styles from './Loader.module.scss';
import typographyStyles from '../../globals/scss/_typography.scss';

class Loader extends React.Component {
  constructor(props) {
    super(props);

    this.htmlId = props.id || nextId(`ds${this.constructor.name}`);

    if(this.props.header) {
      console.warn("Loader component's `header` property is deprecated. Use `i18n_loader_heading` property instead. `header` property will be removed in a future version (during summer 2024).");
    }

    if(this.props.description) {
      console.warn("Loader component's `description` property is deprecated. Use `i18n_loader_description` property instead. `description` property will be removed in a future version (during summer 2024).");
    }

    if(this.props.ariaText) {
      console.warn("Loader component's `ariaText` property is deprecated. Use `i18n_loader_ariaText` property instead. `ariaText` property will be removed in a future version (during summer 2024).");
    }
  }

  render() {
    const { id, className, i18n_loader_heading, i18n_loader_description, i18n_loader_ariaText, header, description, ariaText, ...otherProps } = this.props;

    const classes = classNames([styles.loader, className || null]);

    const headingClasses = classNames([styles[`loader-heading`]]);

    const descriptionClasses = classNames([styles[`loader-description`], typographyStyles[`text--m`], `ds-color--neutral-600`, `ds-margin-vertical--1`]);

    return (
      <div id={this.htmlId} className={classes} {...otherProps} aria-live="polite">
        <LoadingSpinner size="xl" i18n_loadingspinner_ariaText={i18n_loader_ariaText || ariaText} />
        {i18n_loader_heading || header ? <p className={headingClasses}>{i18n_loader_heading}</p> : null}
        {i18n_loader_description || description ? <p className={descriptionClasses}>{i18n_loader_description}</p> : null}
      </div>
    );
  }
}

Loader.propTypes = {
  /**
   * Id of the component
   */
  id: PropTypes.string,
  /**
   * Class names you want to give to the component
   */
  className: PropTypes.string,
  /**
   * Heading text for the component
   */
  i18n_loader_heading: PropTypes.string,
  /**
   * Description/content text for the component
   */
  i18n_loader_description: PropTypes.string,
  /**
   * Screen reader accessible description/content text for the component
   */
  i18n_loader_ariaText: PropTypes.string,
  /**
   * @deprecated Use `i18n_loader_heading` property instead. `header` property will be removed in a future version (during summer 2024).
   */
  header: PropTypes.string,
  /**
   * @deprecated Use `i18n_loader_description` property instead. `description` property will be removed in a future version (during summer 2024).
   */
  description: PropTypes.string,
  /**
   * @deprecated Use `i18n_loader_ariaText` property instead. `ariaText` property will be removed in a future version (during summer 2024).
   */
  ariaText: PropTypes.string,
};

Loader.defaultProps = {
  id: null,
  className: null,
  i18n_loader_heading: null,
  i18n_loader_description: null,
  i18n_loader_ariaText: 'Ladataan',
  header: null,
  description: null,
  ariaText: null,
};

export default Loader;
