// @flow

import * as R from 'ramda';
import Input from '@design-system/component-library/src/components/Input';
import React, { type Element, useRef, useState } from 'react';
import classnames from 'classnames';
import { CancelToken } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ResultItems from './ResultItems';
import directoryOperations from '../../../../../../ducks/entities/directory/directoryOperations';
import { DEFAULT_DEBOUNCE_DURATION } from '../../../../../../utils/timeutils';
import type { ResultItemT } from '../../../../../../components/Search/ResultItem';
import type { SearchResultT } from './ResultItems';
import { isValidAddressNumber } from '../../../../../../utils/validationUtils';

import styles from './SearchInternalDestination.module.scss';

const MAX_SEARCH_RESULTS = 20;

export type PropsT = {|
  handleOnTargetSelected: (string, string) => void,
  initialValue?: string,
  placeholder: string,
  isLoading?: boolean,
  maxSearchResults?: number,
  errors?: *
|};

let requestDestinationsCancelTokenSource;
export const SearchInternalDestination = (props: PropsT): Element<'div'> => {
  const {
    isLoading,
    maxSearchResults,
    initialValue,
    handleOnTargetSelected,
    placeholder,
    errors
  } = props;

  const [searchText, setSearchText] = useState(initialValue || '');
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [showResults, setShowResults] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResultT[]>([]);
  const searchResultsRef = useRef(null);

  const currentUser = useSelector(state => state.currentUser);
  const enterpriseId = R.path(['currentEnterprise', 'id'], currentUser) || '';
  const dispatch = useDispatch();

  const selectNextResult = (): boolean => {
    if (
      selectedIndex < searchResults.length - 1 &&
      (!maxSearchResults || selectedIndex < maxSearchResults - 1)
    ) {
      setSelectedIndex(selectedIndex + 1);
      return !!(
        searchResultsRef &&
        searchResultsRef.current &&
        searchResults &&
        searchResults[selectedIndex].ref
      );
    }
    return false;
  };

  const selectPreviousResult = (): boolean => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);

      return !!(
        searchResultsRef &&
        searchResultsRef.current &&
        searchResults &&
        searchResults[selectedIndex - 1] &&
        searchResults[selectedIndex].ref
      );
    }
    return false;
  };

  const fetchDirectories = async (searchTerm: string) => {
    if (enterpriseId) {
      if (requestDestinationsCancelTokenSource) {
        requestDestinationsCancelTokenSource.cancel();
      }
      requestDestinationsCancelTokenSource = CancelToken.source();
      const results = await dispatch(
        directoryOperations.searchDirectory(
          currentUser.currentEnterprise.id,
          requestDestinationsCancelTokenSource.token,
          {
            size: MAX_SEARCH_RESULTS,
            search: searchTerm || '',
            type: 'all'
          }
        )
      );
      if (results && results.results) {
        setSearchResults([...results.results.filter(r => r.publicInfo && r.displayName)]);
      }
    }
  };

  // Wrap debounce inside callback-function, this prevents redeclaring the debounce function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchDirectories = React.useCallback(
    AwesomeDebouncePromise(fetchDirectories, DEFAULT_DEBOUNCE_DURATION),
    []
  );

  const onSelectItem = (result: ResultItemT) => {
    if (result && result.index !== undefined && searchResults[result.index]) {
      const addr = searchResults[result.index].publicInfo.addressNumber || '';
      const text = searchResults[result.index].displayName.concat(
        isValidAddressNumber(addr) ? ` (${addr})` : ''
      );
      setSearchText(text);
      handleOnTargetSelected(addr, text);
      setSelectedIndex(0);
      setShowResults(false);
    }
  };

  const handleKeyDown = (event: SyntheticKeyboardEvent<>) => {
    setShowResults(true);
    if (event.key && ['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
      event.preventDefault();
      if (event.key === 'ArrowDown') {
        const hasNext = selectNextResult();
        if (hasNext) {
          // $FlowFixMe selectNextResult has the checks
          searchResultsRef.current.scroll(0, searchResults[selectedIndex].ref.offsetTop);
        }
      } else if (event.key === 'ArrowUp') {
        const hasPrevious = selectPreviousResult();
        if (hasPrevious) {
          // $FlowFixMe selectNextResult has the checks
          searchResultsRef.current.scroll(0, searchResults[selectedIndex].ref.offsetTop - 50);
        }
      } else if (event.key === 'Enter' && searchResults[selectedIndex]) {
        onSelectItem({
          index: selectedIndex,
          label: searchResults[selectedIndex].displayName,
          addressNumber: searchResults[selectedIndex].publicInfo.addressNumber
        });
      }
    }
  };

  const handleOnFocus = () => {
    setShowResults(true);
  };

  const handleOnChange = event => {
    setSearchText(event.target.value);
    debounceFetchDirectories(event.target.value);
  };

  return (
    <div
      id="search-content"
      tabIndex={0}
      role="button"
      className={classnames(styles.search, styles['input-wrapper'])}
      onKeyDown={event => {
        handleKeyDown(event);
      }}
    >
      <div data-cy="search-input">
        <Input
          id="search-by-internal-target"
          data-cy="search-input"
          className={classnames({ [styles['hide-error']]: showResults })}
          onValueChange={handleOnChange}
          value={searchText || ''}
          maxlength={50}
          touched
          onFocus={handleOnFocus}
          placeholder={placeholder}
          i18n_input_errorMessage={errors && (errors.message || 'error')}
        />
        {showResults && (
          <ResultItems
            searchResults={searchResults}
            maxSearchResults={MAX_SEARCH_RESULTS}
            searchResultsRef={searchResultsRef}
            onSelectItem={onSelectItem}
            isLoading={isLoading}
            selectedResult={selectedIndex}
          />
        )}
      </div>
    </div>
  );
};

export default SearchInternalDestination;
