// @flow strict

import * as R from 'ramda';

const NO_ANSWER_FORWARDING_DELAY_OPTIONS: number[] = [15000, 30000, 45000, 60000, 90000, 115000];

export const getNoAnswerForwardingDelayOptionsAsStrings = (): string[] =>
  R.map(
    option => ({ label: (option / 1000).toString(), value: (option / 1000).toString() }),
    NO_ANSWER_FORWARDING_DELAY_OPTIONS
  );

export const getNoAnswerForwardingDelayNumeric = (value: string): number =>
  Number.parseInt(value, 10) * 1000;

export const getNoAnswerForwardingDelayString = (value: number): string =>
  (value / 1000).toString();
