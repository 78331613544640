import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import {INPUT_TYPES} from '../../utils/elements';

const ARIA_AUTOCOMPLETE_VALUES = ['inline', 'list', 'both', 'none'];

function InputV2Internal(
  props,
  ref
){
  const {
    /* eslint-disable react/prop-types */
    ariaActivedescendant = null,
    ariaAutocomplete = null,
    ariaExpanded = null,
    ariaHaspopup = null,
    ariaInvalid = 'false',
    ariaLabel = null,
    ariaOwns = null,
    autocomplete = null,
    autofocus = false,
    children = null,
    className = null,
    clear = false,
    controlled = null,
    defaultValue = null,
    dirName = null,
    disabled = false,
    error = '',
    form = null,
    helpText = null,
    i18n_input_clearFieldTitle = 'Tyhjennä kenttä',
    i18n_input_errorMessage = '',
    i18n_input_helpText = null,
    i18n_input_hidePasswordTitle = 'Piilota salasana',
    i18n_input_infoText = null,
    i18n_input_optionalText = '(ei pakollinen)',
    i18n_input_showPasswordTitle = 'Näytä salasana',
    icon = null,
    id = null,
    inputmode = null,
    label = null,
    max = null,
    maxlength = null,
    maxrows = 10,
    min = null,
    minlength = null,
    minrows = 1,
    multiple = null,
    name = null,
    onBlur = () => {},
    onChange = null,
    onClearInput = () => {},
    onClick = () => {},
    onFocus = () => {},
    onKeyDown = () => {},
    onKeyUp = () => {},
    onPasswordToggle = () => {},
    onValueChange = () => {},
    optional = false,
    optionalText = null,
    pattern = null,
    placeholder = null,
    readonly = false,
    role = null,
    rows = null,
    size = null,
    step = null,
    style = null,
    textareaRows = null,
    touched = false,
    type = 'text',
    value = '',
    // inputRef = null,
    ...otherProps
    /* eslint-enable react/prop-types */
  } = props;

  return <Input {...props} {...otherProps} ref={ref} />;
};

/**
 * @deprecated InputV2 component is deprecated. Use Input instead. InputV2 will be removed in a future version (during summer 2024).
 */
const InputV2 = React.forwardRef(InputV2Internal);
export default InputV2;

InputV2.propTypes = {
  /**
   * aria-activedescendant attribute of the input box
   */
  ariaActivedescendant: PropTypes.string,
  /**
   * aria-autocomplete attribute of the input box
   */
  ariaAutocomplete: PropTypes.oneOf(ARIA_AUTOCOMPLETE_VALUES),
  /**
   * aria-expanded attribute of the input box
   */
  ariaExpanded: PropTypes.bool,
  /**
   * aria-haspopup attribute of the input box
   */
  ariaHaspopup: PropTypes.bool,
  /**
   * aria-invalid attribute of the input box
   */
  ariaInvalid: PropTypes.oneOf(['true', 'false', 'grammar', 'spelling']),
  /**
   * Input's aria-label
   */
  ariaLabel: PropTypes.string,
  /**
   * aria-owns attribute of the input box
   */
  ariaOwns: PropTypes.string,
  /**
   * Hint for form autofill feature
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefautocomplete)
   */
  autocomplete: PropTypes.string,
  /**
   * Automatically focus the form control when the page is loaded
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefautofocus)
   */
  autofocus: PropTypes.bool,
  /**
   * Component's options. Automatically detected.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  /**
   * Class names you want to give to the component
   */
  className: PropTypes.string,
  /**
   * Flag for showing a clear control
   */
  clear: PropTypes.bool,
  /**
   * Forces the component to be controlled of uncontrolled.
   * If not specified, the component tries to deduct this from given props (controlled component has value prop while uncontrolled doesn't).
   */
  controlled: PropTypes.bool,
  /**
   * Default value of the input. Use this to store the original value if needed.
   */
  defaultValue: PropTypes.string,
  /**
   * Name of form field to use for sending the element's directionality in form submission
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefdirname)
   */
  dirName: PropTypes.string,
  /**
   * Whether the form control is disabled
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefdisabled)
   */
  disabled: PropTypes.bool,
  /**
   * Associates the control with a form element
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefform)
   */
  form: PropTypes.string,
  /**
   * Title of clear button in field
   */
  i18n_input_clearFieldTitle: PropTypes.string,
  /**
   * Error message for the field
   */
  i18n_input_errorMessage: PropTypes.string,
  /**
   * Always visible help text for the field
   */
  i18n_input_helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Title of hide password button in field
   */
  i18n_input_hidePasswordTitle: PropTypes.string,
  /**
   * Additional information and instructions for the field
   */
  i18n_input_infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Optional field indicator text
   */
  i18n_input_optionalText: PropTypes.string,
  /**
   * Title of show password button in field
   */
  i18n_input_showPasswordTitle: PropTypes.string,
  /**
   * Icon for the input field as an Icon component
   */
  icon: PropTypes.node,
  /**
   * Element's id. Input element will get the raw version of this property.
   */
  id: PropTypes.string,
  /**
   * Input's inputmode
   */
  inputmode: PropTypes.oneOf(['none', 'text', 'decimal', 'numeric', 'tel', 'search', 'email', 'url']),
  /**
   * Label text for the input
   */
  label: PropTypes.string,
  /**
   * Maximum value
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefmax)
   */
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Maximum length (number of characters) of `value`
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefmaxlength)
   */
  maxlength: PropTypes.number,
  /**
   * Maximum number of rows in textarea
   */
  maxrows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Minimum value
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefmin)
   */
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Minimum length (number of characters) of `value`
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefminlength)
   */
  minlength: PropTypes.number,
  /**
   * Minimum number of rows in textarea
   */
  minrows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Whether to allow multiple values
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefmultiple)
   */
  multiple: PropTypes.bool,
  /**
   * Name of the form control. Submitted with the form as part of a name/value pair.
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefname)
   */
  name: PropTypes.string,
  /**
   * Function that runs when user moves focus out of field
   */
  onBlur: PropTypes.func,
  /**
   * LEGACY (use `onValueChange` instead) - Function that runs when user moves focus out of field
   */
  onChange: PropTypes.func,
  /**
   * Callback for clear control
   */
  onClearInput: PropTypes.func,
  /**
   * Function that runs when user clicks on field
   */
  onClick: PropTypes.func,
  /**
   * Function that runs when user moves focus into field
   */
  onFocus: PropTypes.func,
  /**
   * Function that runs when user presses down key
   */
  onKeyDown: PropTypes.func,
  /**
   * Function that runs when user presses up key
   */
  onKeyUp: PropTypes.func,
  /**
   * Function that runs when user toggles password's visibility
   */
  onPasswordToggle: PropTypes.func,
  /**
   * Function that runs when user changes field's value
   */
  onValueChange: PropTypes.func,
  /**
   * Whether field is optional or not. By default all fields are mandatory.
   */
  optional: PropTypes.bool,
  /**
   * Regex pattern the `value` must match to be valid
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefpattern)
   */
  pattern: PropTypes.string,
  /**
   * Text that appears in the form control when it has no value set
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefplaceholder)
   */
  placeholder: PropTypes.string,
  /**
   * The value is not editable
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefreadonly)
   */
  readonly: PropTypes.bool,
  /**
   * role of the input box
   */
  role: PropTypes.string,
  /**
   * Initial number of rows in textarea.
   * If you want text area to be always the same size, set minrows and maxrows to same value with each others
   */
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * If the control is presented as a scrolling list box (e.g. when multiple is specified), this attribute represents the number of rows in the list that should be visible at one time.
   */
  size: PropTypes.number,
  /**
   * Incremental values that are valid.
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefstep)
   */
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Whether a user has visited the input field or not
   */
  touched: PropTypes.bool,
  /**
   * Type of the input
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdeftype)
   */
  type: PropTypes.oneOf(INPUT_TYPES),
  /**
   * Current value of the form control. Submitted with the form as part of a name/value pair.
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefvalue)
   */
  value: PropTypes.string,
  /**
   * Ref for the actual input element, not the wrapper
   */
  // inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.element })]),
  /**
   * LEGACY (use `className` or React's native `styles` parameter instead) - Styles you want to give to the component
   */
  style: PropTypes.oneOf([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * LEGACY (use `i18n_input_errorMessage` instead) - Error message for the field
   */
  error: PropTypes.node,
  /**
   * LEGACY (use `i18n_input_helpText` instead) - Always visible help text for the field
   */
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * LEGACY (use `i18n_input_optionalText` instead) - Optional field indicator text
   */
  optionalText: PropTypes.string,
  /**
   * LEGACY (use `rows` instead) - Initial number of rows in textarea.
   */
  textareaRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}