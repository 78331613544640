import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from '../../utils/css';
import ALL_COLORS from '../../utils/colors';

import styles from './Icon.module.scss';

function IconContainer(props) {
  const {
    className,
    size,
    color,
    id,
    source,
    i18n_icon_ariaLabel,
    ...otherProps
  } = props;

  const allClasses = classNames([
      styles.icon,
      size ? styles[`icon--size-${size}`] : styles[`icon--size-m`],
      color && styles[`icon--color-${color}`],
      className,
    ]);

  const Source = source;

  return (
    <span
      className={allClasses}
      id={id}
      role="img"
      size={size}
      aria-hidden={!i18n_icon_ariaLabel}
      aria-label={i18n_icon_ariaLabel || null}
      {...otherProps}
      >
        <Source />
      </span>
  );
}

IconContainer.propTypes = {
  /**
   * SVG source of icon
   */
  source: PropTypes.func,
  /**
   * Size of icon
   */
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', 'fluid']),
  /**
   * Color of icon. One of Elisa design system's colors. Available colors: https://storybook.elisa.fi/?path=/story/colors--page
   */
  color: PropTypes.oneOf(ALL_COLORS),
  /**
   * Aria-label for the icon
   */
  i18n_icon_ariaLabel: PropTypes.string
}

export default IconContainer;
