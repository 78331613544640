import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';

/* eslint-disable react/require-default-props, no-unused-vars */
function ModalV2Internal(props, ref) {
  const {
    id = null,
    className = null,
    autoOpen = false,
    size = 'm',
    heading = null,
    content = null,
    buttons = null,
    openerElement = null,
    children = null,
    i18n_modal_ariaLabel = null,
    i18n_modal_closeButtonTitle = 'Sulje',
    onModalOpen = () => {},
    onModalClose = () => {},
    useBackdrop = true,
    closeWithBackdropClick = true,
    autoFocus = true,
    ...otherProps
  } = props;

  console.warn("ModalV2 component is deprecated. Use Modal instead. ModalV2 will be removed in a future version (during summer 2024).");

  return <Modal {...props} {...otherProps} ref={ref} />;
}

/**
 * @deprecated ModalV2 component is deprecated. Use Modal instead. ModalV2 will be removed in a future version (during summer 2024).
 */
const ModalV2 = React.forwardRef(ModalV2Internal);
export default ModalV2;

ModalV2.propTypes = {
  /**
   * Id of the modal
   */
  id: PropTypes.string,
  /**
   * Class names you want to give to the component
   */
  className: PropTypes.string,
  /**
   * Size of the modal
   */
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl']),
  /**
   * Heading text for the modal. Optional.
   */
  heading: PropTypes.string,
  /**
   * Content of the modal
   */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Array of Button components
   */
  buttons: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])),
  /**
   * Component's content. Automatically detected. If this and content are both given, this overrides content.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  /**
   * Aria label for the modal. Use this if you are not using `heading` or if you use `children`.
   */
  i18n_modal_ariaLabel: PropTypes.string,
  /**
   * Element that opens the modal. You can also use other ways to open it.
   */
  openerElement: PropTypes.node,
  /**
   * Close button's title text. Important for accessibility.
   */
  i18n_modal_closeButtonTitle: PropTypes.string,
  /**
   * Function that is launched when the modal is opened. Returns the modal's id.
   */
  onModalOpen: PropTypes.func,
  /**
   * Function that launches when the close button (on modal's upper right corner) is clicked. Returns the event
   */
  onModalClose: PropTypes.func,
  /**
   * Whether the modal is visible by default
   */
  autoOpen: PropTypes.bool,
  /**
   * Whether the modal uses backdrop or not
   */
  useBackdrop: PropTypes.bool,
  /**
   * Whether a click on backdrop closes the modal or not
   */
  closeWithBackdropClick: PropTypes.bool,
  /**
   * Whether the first focusable element is automatically focused
   */
  autoFocus: PropTypes.bool,
};
