import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import IconCloseRegular from '../Icon/lib/IconCloseRegular';

import { classNames } from '../../utils/css';
import { useIdWithFallback } from '../../utils/hooks';
import { KEY_ESC, KEY_TAB } from '../../utils/keycodes';

import styles from './Modal.module.scss';
import backdropStyles from '../Backdrop/Backdrop.module.scss';

// FIXME use FOCUSABLE_SELECTOR from css.js ?
const FOCUSABLE_ELEMENTS = `a, button, input, select, textarea, svg, area, details, summary, iframe, object, embed, [tabindex], [contenteditable]`;

/* eslint-disable react/require-default-props */
function ModalInternal(
  {
    id = null,
    className = null,
    autoOpen = false,
    size = 'm',
    heading = null,
    content = null,
    buttons = null,
    openerElement = null,
    children = null,
    i18n_modal_ariaLabel = null,
    i18n_modal_closeButtonTitle = 'Sulje',
    onModalOpen = () => {},
    onModalClose = () => {},
    useBackdrop = true,
    closeWithBackdropClick = true,
    autoFocus = true,
    ...otherProps
  },
  ref
) {
  const modalRef = useRef(null);
  const htmlId = useIdWithFallback('dsModal', id);
  const backdropId = `${htmlId}Backdrop`;
  const [lastFocusedElement, setLastFocusedElement] = useState(null);
  const [previousElement, setPreviousElement] = useState(document.body || null);
  const [visible, setVisible] = useState(autoOpen || false);

  const hideModal = () => {
    if (previousElement) {
      previousElement.focus();
    }

    setVisible(false);
    onModalClose(modalRef.current);
    return false;
  };

  const showModal = () => {
    setPreviousElement(document.activeElement || document.body || null);
    setVisible(true);
    onModalOpen(modalRef);
    return false;
  };

  // Functions visible to outside.
  useImperativeHandle(ref, () => ({
    toggleModal: () => {
      if (visible) {
        hideModal();
      } else {
        showModal();
      }
    },

    openModal: () => {
      showModal();
    },

    closeModal: (e) => {
      hideModal(e);
    },

    visible: (value) => {
      if (value === true) {
        showModal();
      } else if (value === false) {
        hideModal();
      } else {
        return visible;
      }

      return false;
    },
  }));

  const keyActions = (e) => {
    if (e.key === KEY_TAB) {
      const modal = document.querySelector(`#${htmlId}`);
      if (modal) {
        const nodes = [modal.querySelectorAll(FOCUSABLE_ELEMENTS)];
        const focusables = nodes[0];
        const firstElement = focusables[0];
        const lastElement = focusables[focusables.length - 1];

        if (!e.shiftKey && document.activeElement === lastElement) {
          firstElement.focus();
          return e.preventDefault();
        }

        if (e.shiftKey && document.activeElement === firstElement) {
          lastElement.focus();
          e.preventDefault();
        }
      }
    } else if (e.key === KEY_ESC) {
      hideModal();
    }

    return false;
  };

  const updateLastFocus = () => {
    const el = document?.activeElement;
    if (modalRef.current && modalRef.current.contains(el) && FOCUSABLE_ELEMENTS.includes(el.nodeName.toLowerCase())) {
      setLastFocusedElement(el);
    }
  };

  useEffect(() => {
    const closeButton = document?.querySelector(`#${`${htmlId}CloseButton`}`);
    if (closeButton && autoFocus && visible) {
      if (lastFocusedElement && lastFocusedElement !== closeButton && lastFocusedElement.id) {
        const elementToFocus = document?.querySelector(lastFocusedElement.id);
        if (elementToFocus) {
          elementToFocus.focus();
        }
      }
      else if (lastFocusedElement && lastFocusedElement !== closeButton && !lastFocusedElement.id) {
        setLastFocusedElement(lastFocusedElement);
        lastFocusedElement.focus();
      }
      else {
        setLastFocusedElement(closeButton);
        closeButton.focus();
      }
    }
    else if (lastFocusedElement && lastFocusedElement.id) {
      const elementToFocus = document?.querySelector(lastFocusedElement.id);
      if (elementToFocus) {
        elementToFocus.focus();
      }
    }

    document?.addEventListener('keydown', keyActions);
    document?.addEventListener('focusin', updateLastFocus);

    return () => {
      document?.removeEventListener('keydown', keyActions);
      document?.removeEventListener('focusin', updateLastFocus);

      if (!visible) {
        setLastFocusedElement(null);
      }
    };
  });

  // This function checks that the user clicked the actual backdrop itself
  // and not any of its children (i.e. the modal).
  const backdropClick = (e) => {
    let backdrop = null;
    if (document) {
      backdrop = document.getElementById(backdropId);
      if (e.target === backdrop && e.currentTarget === backdrop) {
        if (useBackdrop && closeWithBackdropClick) {
          hideModal();
        }
      }
    } else if (e.target === e.currentTarget) {
      if (useBackdrop && closeWithBackdropClick) {
        hideModal();
      }
    }
  };

  let opener = null;
  if (openerElement) {
    opener = React.cloneElement(openerElement, {
      onClick: () => {
        setVisible(true);

        if (onModalOpen) {
          onModalOpen(modalRef);
        }
      },
    });
  }

  let modalHeading = null;
  if (heading) {
    modalHeading = (
      <h3 id={`${htmlId}Heading`} className={styles[`modal-heading`]}>
        {heading}
      </h3>
    );
  }

  let modalContent = null;
  if (content) {
    if (typeof content === 'string') {
      modalContent = <p className={styles[`modal-content`]}>{content}</p>;
    } else {
      modalContent = <section className={styles[`modal-content`]}>{content}</section>;
    }
  }

  if (children) {
    modalContent = children;
  }

  const allClasses = classNames([styles.modal, styles[`modal--size-${size}`], visible ? styles[`modal--visible`] : null, className]);
  const backdropClasses = classNames([backdropStyles.backdrop, useBackdrop === false ? backdropStyles[`backdrop--transparent`] : null, visible ? backdropStyles[`backdrop--visible`] : null]);

  return (
    <>
      {opener}
      {visible ? (
        <div id={backdropId} className={backdropClasses} role="presentation" onClick={!closeWithBackdropClick || (closeWithBackdropClick && closeWithBackdropClick === true) ? backdropClick : null}>
          <div ref={modalRef} id={htmlId} className={allClasses} role="dialog" aria-modal="true" aria-label={i18n_modal_ariaLabel} aria-labelledby={heading ? `${htmlId}Heading` : null} {...otherProps}>
            <button id={`${htmlId}CloseButton`} type="button" className={styles[`modal-close`]} aria-label={i18n_modal_closeButtonTitle} title={i18n_modal_closeButtonTitle} onClick={hideModal}>
              <IconCloseRegular aria-hidden="true" />
            </button>
            <div id={`${htmlId}Contentarea`} className={styles[`modal-contentarea`]}>
              {modalHeading}

              {modalContent}

              {buttons && buttons.length > 0 ? <div className={styles[`modal-buttons`]}>{buttons.map((button) => button)}</div> : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

const Modal = React.forwardRef(ModalInternal);
export default Modal;

Modal.propTypes = {
  /**
   * Id of the modal
   */
  id: PropTypes.string,
  /**
   * Class names you want to give to the component
   */
  className: PropTypes.string,
  /**
   * Size of the modal
   */
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl']),
  /**
   * Heading text for the modal. Optional.
   */
  heading: PropTypes.string,
  /**
   * Content of the modal
   */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Array of Button components
   */
  buttons: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])),
  /**
   * Component's content. Automatically detected. If this and content are both given, this overrides content.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  /**
   * Aria label for the modal. Use this if you are not using `heading` or if you use `children`.
   */
  i18n_modal_ariaLabel: PropTypes.string,
  /**
   * Element that opens the modal. You can also use other ways to open it.
   */
  openerElement: PropTypes.node,
  /**
   * Close button's title text. Important for accessibility.
   */
  i18n_modal_closeButtonTitle: PropTypes.string,
  /**
   * Function that is launched when the modal is opened. Returns the modal's id.
   */
  onModalOpen: PropTypes.func,
  /**
   * Function that launches when the close button (on modal's upper right corner) is clicked. Returns the event
   */
  onModalClose: PropTypes.func,
  /**
   * Whether the modal is visible by default
   */
  autoOpen: PropTypes.bool,
  /**
   * Whether the modal uses backdrop or not
   */
  useBackdrop: PropTypes.bool,
  /**
   * Whether a click on backdrop closes the modal or not
   */
  closeWithBackdropClick: PropTypes.bool,
  /**
   * Whether the first focusable element is automatically focused
   */
  autoFocus: PropTypes.bool,
};
