import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useIdWithFallback } from '../../utils/hooks';
import DummyContext from './DummyContext';

function Dummy2({
  id = null,
  label = null,
  value = null,
}) {
  const htmlId = useIdWithFallback('dsDummyTwo', id);
  const {selectedValue, setSelectedValue} = useContext(DummyContext);

  const onInputChange = (e) => {
    setSelectedValue(e.target.value);
  }

  return (
    <label
      htmlFor={htmlId}
    >
      <input
        type="radio"
        id={htmlId}
        value={value}
        onChange={onInputChange}
        checked={selectedValue === value}
      />
      {label}
    </label>
  );
}


Dummy2.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  value:  PropTypes.string,
  selected: PropTypes.bool
};

Dummy2.defaultProps = {
  id: null,
  label: null,
  value: null,
  selected: false,
};

export default Dummy2;
