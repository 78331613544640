// @flow strict-local

import React, { type Element, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import * as R from 'ramda';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UnhandledIcon } from '../../../../../assets/callflow/details/speeddialling-small.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import { update } from '../../../../../ducks/entities/acd/acdOperations';
import type {
  AudioFieldT,
  PropertyAudioT
} from '../../../components/edit/children/audio/CallflowAudioUtils';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import { uploadAudios } from '../../../components/edit/children/audio/CallflowAudioUtils';
import { createIvrPatchPayload } from '../../../components/edit/CallflowPropertyUtils';
import { createFwdProperty } from '../../../components/edit/children/forwardingField/ForwardingUtils';
import type { ForwardingPropertyT } from '../../../components/edit/children/forwardingField/ForwardingUtils';
import AcdAudioField from '../../../components/edit/children/audio/AcdAudioField';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import { validateForwardingField } from '../../../../../utils/validationUtils';
import ACDForwardingField from '../../../components/edit/children/forwardingField/ACDForwardingField';
import ToggleField from '../../../components/edit/children/ToggleField';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';

export type PropsT = {|
  callflowId: string
|};

export type VoiceMailTargetT = 'LOGGED_AGENTS' | 'ALL_AGENTS' | 'NO_AGENTS';

type FormT = {
  busyFwd: ForwardingPropertyT,
  queueFullMsg: AudioFieldT<PropertyAudioT>,
  goodByeFwd: ForwardingPropertyT,
  goodByeMsg: AudioFieldT<PropertyAudioT>,
  noOperator: boolean,
  noOperatorFwd: ForwardingPropertyT,
  noOperatorMsg: AudioFieldT<PropertyAudioT>
};

let requestCancelTokenSource: CancelTokenSource;
let audioRequestCancelTokenSource: CancelTokenSource;

export const getSendVoiceMailToAgentsValue = (
  blockVoiceMailToAgents: boolean,
  blockVoiceMailToUnloggedAgents: boolean
): VoiceMailTargetT => {
  if (!blockVoiceMailToAgents) {
    if (blockVoiceMailToUnloggedAgents) {
      return 'LOGGED_AGENTS';
    }
    return 'ALL_AGENTS';
  }
  return 'NO_AGENTS';
};

export const EditUnhandled = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const ivrAcdCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const acdAudioConverter = new AudioFieldConverter(acdData.enterpriseId, acdData.id, 'acds');

  const initialProperties = {
    busyFwd: createFwdProperty(ivrAcdCallCenter.busyFwd),
    queueFullMsg: acdAudioConverter.convertToAudioPropertyField(
      'QueueFullMsg',
      R.path(['audios', 'queueFullMsg', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.queueFullMsg
    ),
    goodByeFwd: createFwdProperty(ivrAcdCallCenter.goodByeFwd),
    goodByeMsg: acdAudioConverter.convertToAudioPropertyField(
      'GoodByeMsg',
      R.path(['audios', 'goodByeMsg', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.goodByeMsg
    ),
    noOperator: acdCallCenter.noOperator,
    noOperatorFwd: createFwdProperty(ivrAcdCallCenter.noOperatorFwd),
    noOperatorMsg: acdAudioConverter.convertToAudioPropertyField(
      'NoOperatorMsg',
      R.path(['audios', 'noOperatorMsg', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.noOperatorMsg
    )
  };

  const acdUnhandledSchema = yup.object().shape({
    busyFwd: validateForwardingField(t('callflows.validation.forwardingError')),
    goodByeFwd: validateForwardingField(t('callflows.validation.forwardingError')),
    noOperatorFwd: validateForwardingField(t('callflows.validation.forwardingError'))
  });

  const initialFormValues: FormT = {
    ...initialProperties
  };

  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    audioRequestCancelTokenSource = CancelToken.source();
    return () => {
      requestCancelTokenSource.cancel();
      audioRequestCancelTokenSource.cancel();
    };
  }, []);

  // update
  const onSubmit = async (formData: FormT): Promise<AcdEntityT> => {
    const patchPayload = createIvrPatchPayload({}, formData, Object.keys(initialProperties));

    // $FlowFixMe Improve formData typing
    const failedAudioUploads = await uploadAudios(
      formData,
      audioRequestCancelTokenSource,
      createCsrfHeader(currentUser)
    );
    if (failedAudioUploads.length > 0) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        t('callflows.audioUploadFailed', { audioFileNames: failedAudioUploads.join(',') })
      );
    }
    return dispatch(
      update(
        acdData.enterpriseId,
        acdData.type,
        acdData.id,
        patchPayload,
        requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={acdData.id}
      icon={<UnhandledIcon />}
      title={t('callflows.editUnhandled.title')}
      description={t('callflows.editUnhandled.description')}
      defaultValues={initialFormValues}
      validationSchema={acdUnhandledSchema}
      onSaveForm={onSubmit}
    >
      <AcdAudioField field="queueFullMsg" title={t('callflows.editUnhandled.queueFullMsg')} />
      <ACDForwardingField
        field="busyFwd"
        title={t('callflows.editUnhandled.busyFwd')}
        filterSearchResults={entry => (entry ? entry.value !== acdData.addressNumber : false)}
      />
      <AcdAudioField field="goodByeMsg" title={t('callflows.editUnhandled.goodByeMsg')} />
      <ACDForwardingField
        field="goodByeFwd"
        title={t('callflows.editUnhandled.goodByeFwd')}
        filterSearchResults={entry => (entry ? entry.value !== acdData.addressNumber : false)}
      />

      <ToggleField
        field="noOperator"
        elementId="noOperator"
        label={t('callflows.editUnhandled.noOperator')}
      />
      <AcdAudioField
        field="noOperatorMsg"
        title={t('callflows.editUnhandled.noOperatorMsg')}
        disabledFn={(formData: FormT) => !formData.noOperator}
      />
      <ACDForwardingField
        field="noOperatorFwd"
        title={t('callflows.editUnhandled.noOperatorFwd')}
        filterSearchResults={entry => (entry ? entry.value !== acdData.addressNumber : false)}
        disabledFn={(formData: FormT) => !formData.noOperator}
      />
    </EditCallflowDetails>
  );
};

export default EditUnhandled;
