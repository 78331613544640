/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../utils/css';
import Product from './SelectGroup__Product';
import styles from "./SelectGroup.module.scss";
import { useIdWithFallback } from '../../utils/hooks';
import SelectGroupContext from './SelectGroupContext';

function SelectGroupInternal(
  {
    id = null,
    className = null,
    products = null,
    name = null,
    multiselect = false,
    forceselection = false,
    label = null,
    autoFocus = false,
    i18n_SelectGroup_infoText = null,
    i18n_SelectGroup_errorMessage = null,
    i18n_SelectGroup_helpText = null,
    i18n_SelectGroup_optionalText = null,
    optional = false,
    type = 'normal',
    value = null,
    onChange = () => {},
    ...otherProps
  },
  ref,
){
  let valueFromChildren = multiselect ? [] : null;
  let valueFromProductList = false;

  if(!value) {
    products.forEach(product => {
      if(Array.isArray(product)) {
        product.forEach(child => {
          if(child.selected === true) {
            valueFromProductList = true;
            multiselect ? valueFromChildren.push(child.id) : valueFromChildren = child.id;
          }
        });
      }
      else {
        if(product.selected === true) {
          valueFromProductList = true;
          multiselect ? valueFromChildren.push(product.id) : valueFromChildren = product.id;
        }
      }
    });
  }

  const mounted = useRef(false);
  const htmlId = useIdWithFallback('dsSelectGroup', id);
  const compName = name || `${htmlId}-choice`;
  const productsLists = Array.isArray(products[0]) ? products : [products];
  const [errorMessage, setErrorMessage] = useState(i18n_SelectGroup_errorMessage);
  const [selectedValues, setSelectedValues] = useState(value || valueFromChildren);
  const componentValue = useMemo(() => ({selectedValues, setSelectedValues}), [selectedValues, setSelectedValues]);

  useImperativeHandle(ref, () => ({
    value: (itemValue) => {
      setSelectedValues(itemValue);
      onChangeFun(itemValue);
      return itemValue;
    },

    getValue: () => selectedValues,

    // eslint-disable-next-line consistent-return
    errorMessage: (message) => {
      if(typeof(message) === 'string') {
        setErrorMessage(message);
      }
      else {
        return errorMessage;
      }
    },
  }));

  const onChangeFun = (values) => {
    if(mounted.current === true) {
      if(multiselect && typeof values === "string") {
        let arrayContent = values.substring(2, values.length - 2);
        arrayContent = arrayContent.split('","');
        const selection = arrayContent; //JSON.parse(values);
        onChange(selection);
      }
      else {
        onChange(values);
      }
    }
  }

  let inputLabel = null;
  if (label) {
    inputLabel = <span className="ds-selectgroup--labelarea-label-content">{label}</span>;
  }

  let optionalIndicator = null;
  if (optional === true) {
    optionalIndicator = i18n_SelectGroup_optionalText;
  }

  let inputInfo = null;
  if (i18n_SelectGroup_infoText) {
    const PopoverComponent = require('../Popover').default; // eslint-disable-line global-require
    const IconInformationRegularComponent = require('../Icon/lib/IconInformationRegular').default; // eslint-disable-line global-require
    inputInfo = <PopoverComponent triggerElement={<IconInformationRegularComponent />} placement="top">{i18n_SelectGroup_infoText}</PopoverComponent>
  }

  let inputError = null;
  if (errorMessage) {
    const ErrorComponent = require('../InputError').default; // eslint-disable-line global-require
    inputError = <ErrorComponent id={`${htmlId}Error`}>{errorMessage}</ErrorComponent>;
  }

  let inputHelp = null;
  if (i18n_SelectGroup_helpText) {
    const HelpComponent = require('../InputHelp').default; // eslint-disable-line global-require
    inputHelp = <HelpComponent id={`${htmlId}Help`}>{i18n_SelectGroup_helpText}</HelpComponent>;
  }

  const classes = classNames([
    styles.selectgroup,
    type === 'normal' ? styles[`selectgroup--type-normal`] : null,
    type === 'column' ? styles[`selectgroup--type-column`] : null,
    inputError ? styles[`selectgroup--error`] : null,
    label ? null : styles[`selectgroup--nomargin`],
    className,
  ]);

  useEffect(() => {
    if(mounted.current === true) {
    if(!valueFromProductList) {
      setSelectedValues(value);
      onChangeFun(value);
    }
  }
  }, [value]);

  useEffect(() => {
    if(mounted.current === true) {
    if(multiselect && typeof selectedValues === "string") {
      let arrayContent = selectedValues.substring(2, selectedValues.length - 2);
      arrayContent = arrayContent.split('","');
      const selection = arrayContent; //JSON.parse(selectedValues);
      setSelectedValues(selection);
      onChange(selection);
    }
    else {
      onChange(selectedValues);
    }
  }
  }, [selectedValues]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <SelectGroupContext.Provider value={componentValue}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <fieldset id={htmlId} name={multiselect ? htmlId : name} className={classes} {...otherProps}>
        {label ? (
          <div className="ds-selectgroup--labelarea">
            <legend className={styles[`selectgroup--labelarea-label`]}>
              {inputLabel} {optionalIndicator} {inputInfo}
            </legend>
          </div>)
        : null}

        {productsLists.map((productitems, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${htmlId}productitem${i}`} className={styles.selectgroup__list} role={!multiselect ? 'radiogroup' : null}>
            {productitems.map((product, j) => (
              <Product
                id={`${htmlId}Product${product.id}`}
                autoFocus={autoFocus && i === 0 && j === 0}
                key={`${htmlId}Product${product.id}`}
                product={product}
                multiselect={multiselect}
                type={!multiselect ? 'radio' : 'checkbox'}
                name={compName}
                forceselection={multiselect ? forceselection : null}
              />
            ))}
          </div>
        ))}

        {inputError}
        {inputHelp}
      </fieldset>
    </SelectGroupContext.Provider>
  );
}

const SelectGroup = React.forwardRef(SelectGroupInternal);
export default SelectGroup;

SelectGroup.defaultProps = {
  id: null,
  products: null,
  className: null,
  name: null,
  label: null,
  i18n_SelectGroup_infoText: null,
  multiselect: false,
  forceselection: false,
  i18n_SelectGroup_errorMessage: null,
  i18n_SelectGroup_helpText: null,
  i18n_SelectGroup_optionalText: null,
  optional: false,
  autoFocus: false,
  type: 'normal',
  value: null,
  onChange: () => {},
};

SelectGroup.propTypes = {
  /**
   * Element's id
   */
  id: PropTypes.string,
  /**
   * Additional CSS classes for the component
   */
  className: PropTypes.string,
  /**
   * Name of the component. This is for form purposes.
   */
  name: PropTypes.string,
  /**
   * Label text for the toggle
   */
  label: PropTypes.string,
  /**
   * Additional information and instructions for the SelectGroup
   */
  i18n_SelectGroup_infoText: PropTypes.string,
  /**
   * Whether the user can select multiple options ot only one
   */
  multiselect: PropTypes.bool,
  /**
   * Whether the user must select at least one
   */  
  forceselection: PropTypes.bool,
  /**
   * Error message for the SelectGroup
   */
  i18n_SelectGroup_errorMessage: PropTypes.string,
  /**
   * Help text for the SelectGroup
   */
  i18n_SelectGroup_helpText: PropTypes.string,
  /**
   * Optional text for the SelectGroup
   */
  i18n_SelectGroup_optionalText: PropTypes.string,
  /**
   * Whether the input is optional to fill
   */
  optional: PropTypes.bool,
  /**
   * Automatically set focus to first element. Optional.
   */
  autoFocus: PropTypes.bool,
  /**
   * Array of Products OR Array of Arrays of Products
   */
  products: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        selected: PropTypes.bool,
        disabled: PropTypes.bool,
        icon: PropTypes.node,
        image: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
        }),
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          value: PropTypes.string,
          classNames: PropTypes.string,
          name: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          selected: PropTypes.bool,
          disabled: PropTypes.bool,
          icon: PropTypes.node,
          image: PropTypes.shape({
            url: PropTypes.string,
            alt: PropTypes.string,
          }),
        })
      ),
    ])
  ),
  /**
   * Determins the visual style of the component
   */
  type: PropTypes.oneOf(['normal', 'column']),
  /**
   * Value of the selected option(s)
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * Function that runs when value is changed
   */
  onChange: PropTypes.func,
};
