// @flow
import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import type { UserStateEntityT } from '../../../../../ducks/entities/user/userTypes';
import {
  getNoAnswerForwardingDelayNumeric,
  getNoAnswerForwardingDelayOptionsAsStrings,
  getNoAnswerForwardingDelayString
} from '../../../forwardingHelpers';
import ForwardingTarget from '../../components/ForwardingTarget';
import { selectors as userSelectors } from '../../../../../ducks/entities/user';
import type { FieldT } from '../../../../../ducks/entities/callForwarding/callForwardingTypes';
import styles from './ForwardingTargetSelector.module.scss';

export type PropsT = {|
  field: string,
  enterpriseId: string,
  shouldValidate?: boolean,
  isEnterpriseForwarding: boolean,
  userId: string,
  userIsLoading: boolean
|};

export const ForwardingTargetSelector = (props: PropsT): Element<'div'> => {
  const {
    field,
    enterpriseId,
    isEnterpriseForwarding,
    userId,
    userIsLoading,
    shouldValidate
  } = props;

  const { t } = useTranslation();
  const enterpriseUsers: UserStateEntityT[] = useSelector(state =>
    userSelectors.byEnterpriseId(state, enterpriseId || '')
  );
  const currentUser = !R.isEmpty(enterpriseUsers)
    ? enterpriseUsers.find(user => user.id === userId)
    : null;
  const substituteList = currentUser && currentUser.substitutes ? currentUser.substitutes : [];

  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();
  const value: FieldT = values[field];
  return (
    <div id="forwarding-destination" className={styles['destination-area']}>
      {values.forwardingType === 'NO_ANSWER' && (
        <span className={classnames(styles['combobox-container'], styles['description-text'])}>
          {t('forwardingDetails.reasonNoAnswerPrefix')}
          <Dropdown
            items={getNoAnswerForwardingDelayOptionsAsStrings()}
            selectedValue={getNoAnswerForwardingDelayString(value.noAnswerForwardingDelay)}
            onValueChange={element => {
              const { innerText = '' } = element;
              setValue(
                field,
                {
                  noAnswerForwardingDelay: getNoAnswerForwardingDelayNumeric(innerText),
                  target: value.target || ''
                },
                {
                  shouldValidate: shouldValidate === undefined ? true : shouldValidate,
                  shouldDirty: true
                }
              );
            }}
          />
          {t('forwardingDetails.reasonNoAnswerSuffix')}
        </span>
      )}
      {values.forwardingType === 'ON_BUSY' && (
        <span className={styles['description-text']}>
          {t('forwardings.reasons.onBusy')}
          {t('forwardings.reasons.moveTo')}
        </span>
      )}
      {values.forwardingType === 'UNREACHABLE' && (
        <span className={styles['description-text']}>
          {t('forwardings.reasons.unreachable')}
          {t('forwardings.reasons.moveTo')}
        </span>
      )}
      {(values.forwardingType === 'PRESENCE' || values.forwardingType === 'ALWAYS') && (
        <span className={styles['description-text']}>{t('forwardings.reasons.moveWhere')}</span>
      )}
      <div className={styles.target}>
        <ForwardingTarget
          field={field}
          enterpriseId={enterpriseId}
          // $FlowFixMe
          substitutes={substituteList}
          errorMessage={errors[field] ? errors[field].message : ''}
          enterpriseRule={!!isEnterpriseForwarding}
          userIsLoading={userIsLoading}
        />
      </div>
    </div>
  );
};

export default ForwardingTargetSelector;
