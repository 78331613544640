// @flow

/* eslint-disable no-use-before-define */
import * as R from 'ramda';
import type {
  SelectEnterpriseT,
  CurrentUserStateT,
  ActionT,
  LoginActionT,
  LogoutActionT,
  DisableFirstTimeViewT,
  EnableFirstTimeViewT,
  UpdateConfigActionT,
  FetchCsrfActionT
} from './currentUserTypes';
import { pushAnalyticsEvent } from '../../utils/analyticsHandler';
import {
  isAcdManager,
  isSingleEnterpriseAdmin,
  isSwitchboardUser
} from '../../utils/accessRightUtils';
import {
  ELISA_SSO_LOGIN_FAILURE_INVALID_ACCESS_RIGHTS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS_ACD_MANGER_USER,
  LOGIN_SUCCESS_MULTI_ENTERPRISE_ADMIN,
  LOGIN_SUCCESS_SINGLE_ENTERPRISE_ADMIN,
  LOGIN_SUCCESS_SWITCHBOARD_ACD_MANGER_USER,
  LOGIN_SUCCESS_SWITCHBOARD_USER,
  LOGOUT_FAILURE,
  RING_USER_LOGGED_WITHOUT_ADDITIONAL_ROLES
} from '../../matomo_constants';

const initialState: CurrentUserStateT = {
  multiEnterpriseAdmin: false,
  enterprises: undefined,
  currentEnterprise: undefined,
  currentEnterpriseFullName: undefined,
  firstTimeView: true,
  hasWebAdminAccess: false,
  __metadata: {
    status: 'idle'
  },
  hasMultipleCallflowServices: false,
  appLanguage: undefined,
  featureFlags: [],
  environment: 'env'
};

const loginReducer = (
  state: CurrentUserStateT = initialState,
  action: LoginActionT | LogoutActionT
): CurrentUserStateT => {
  switch (action.type) {
    case 'ring/currentUser/LOGIN_REQUEST': {
      return {
        ...state,
        __metadata: {
          status: 'logging in'
        }
      };
    }
    case 'ring/currentUser/LOGOUT_REQUEST':
      return {
        ...state,
        __metadata: {
          status: 'logging out'
        }
      };
    case 'ring/currentUser/LOGIN_SUCCESS': {
      if (R.path(['multiEnterpriseAdmin'], action.payload)) {
        pushAnalyticsEvent(LOGIN_SUCCESS_MULTI_ENTERPRISE_ADMIN);
      } else if (isSingleEnterpriseAdmin(action.payload)) {
        pushAnalyticsEvent(
          LOGIN_SUCCESS_SINGLE_ENTERPRISE_ADMIN,
          R.path(['id'], (action.payload.enterprises || [])[0])
        );
      } else if (isSwitchboardUser(action.payload) && isAcdManager(action.payload)) {
        pushAnalyticsEvent(
          LOGIN_SUCCESS_SWITCHBOARD_ACD_MANGER_USER,
          R.path(['id'], (action.payload.enterprises || [])[0])
        );
      } else if (isSwitchboardUser(action.payload)) {
        pushAnalyticsEvent(
          LOGIN_SUCCESS_SWITCHBOARD_USER,
          R.path(['id'], (action.payload.enterprises || [])[0])
        );
      } else if (isAcdManager(action.payload)) {
        pushAnalyticsEvent(
          LOGIN_SUCCESS_ACD_MANGER_USER,
          R.path(['id'], (action.payload.enterprises || [])[0])
        );
      }

      return {
        ...state,
        ...action.payload,
        currentEnterprise: !R.path(['multiEnterpriseAdmin'], action.payload)
          ? (action.payload.enterprises || [])[0]
          : state.currentEnterprise,
        firstTimeView: true,
        __metadata: {
          status: 'logged in'
        }
      };
    }
    case 'ring/currentUser/LOGOUT_SUCCESS': {
      return {
        multiEnterpriseAdmin: false,
        enterprises: undefined,
        currentEnterprise: undefined,
        currentEnterpriseFullName: undefined,
        firstTimeView: true,
        appLanguage: undefined,
        hasWebAdminAccess: false,
        __metadata: {
          status: 'idle'
        },
        hasMultipleCallflowServices: false,
        featureFlags: [],
        environment: 'env'
      };
    }
    case 'ring/currentUser/LOGIN_FAILURE': {
      pushAnalyticsEvent(LOGIN_FAILURE);
      return {
        ...state,
        __metadata: {
          lastError: action.payload,
          status: 'error'
        }
      };
    }
    case 'ring/currentUser/ELISA_SSO_LOGIN_FAILURE_INVALID_ACCESS_RIGHTS': {
      pushAnalyticsEvent(ELISA_SSO_LOGIN_FAILURE_INVALID_ACCESS_RIGHTS);
      return {
        ...state,
        __metadata: {
          lastError: action.payload,
          status: 'error'
        }
      };
    }
    case 'ring/currentUser/RING_USER_LOGGED_WITHOUT_ADDITIONAL_ROLES': {
      pushAnalyticsEvent(RING_USER_LOGGED_WITHOUT_ADDITIONAL_ROLES);
      return {
        ...state,
        __metadata: {
          lastError: action.payload,
          status: 'error'
        }
      };
    }
    case 'ring/currentUser/LOGOUT_FAILURE': {
      pushAnalyticsEvent(LOGOUT_FAILURE);
      return {
        ...state,
        __metadata: {
          lastError: action.payload,
          status: 'error'
        }
      };
    }
    default:
      return state;
  }
};

const firstTimeViewReducer = (
  state: CurrentUserStateT = initialState,
  action: DisableFirstTimeViewT | EnableFirstTimeViewT
): CurrentUserStateT => {
  switch (action.type) {
    case 'ring/currentUser/DISABLE_FIRST_TIME_VIEW':
      return {
        ...state,
        firstTimeView: false
      };
    case 'ring/currentUser/ENABLE_FIRST_TIME_VIEW':
      return {
        ...state,
        firstTimeView: true
      };
    default:
      return state;
  }
};

const enterpriseReducer = (
  state: CurrentUserStateT = initialState,
  action: SelectEnterpriseT
): CurrentUserStateT => {
  switch (action.type) {
    case 'ring/currentUser/SELECT_ENTERPRISE':
      return {
        ...state,
        currentEnterprise: {
          id: action.payload.enterpriseId,
          fullName: action.payload.enterpriseFullName
        }
      };
    default:
      return state;
  }
};

const csrfReducer = (
  state: CurrentUserStateT = initialState,
  action: FetchCsrfActionT
): CurrentUserStateT => {
  switch (action.type) {
    case 'ring/currentUser/CSRF_SUCCESS':
      return {
        ...state,
        csrf: action.payload.csrf
      };
    default:
      return state;
  }
};

const updateConfigReducer = (
  state: CurrentUserStateT = initialState,
  action: UpdateConfigActionT
): CurrentUserStateT => {
  switch (action.type) {
    case 'ring/currentUser/UPDATE_CONFIG_REQUEST':
      return state;
    case 'ring/currentUser/UPDATE_CONFIG_SUCCESS':
      return {
        ...state,
        ...action.payload
      };
    case 'ring/currentUser/UPDATE_CONFIG_FAILURE':
      return state;
    default:
      return state;
  }
};

const rootReducer = (
  state: CurrentUserStateT = initialState,
  action: ActionT
): CurrentUserStateT => {
  switch (action.type) {
    case 'ring/currentUser/CSRF_REQUEST':
    case 'ring/currentUser/CSRF_SUCCESS':
    case 'ring/currentUser/CSRF_FAILURE': {
      return currentUserReducer.csrfReducer(state, action);
    }
    case 'ring/currentUser/UPDATE_CONFIG_REQUEST':
    case 'ring/currentUser/UPDATE_CONFIG_SUCCESS':
    case 'ring/currentUser/UPDATE_CONFIG_FAILURE': {
      return currentUserReducer.updateConfigReducer(state, action);
    }
    case 'ring/currentUser/LOGIN_REQUEST':
    case 'ring/currentUser/LOGOUT_REQUEST':
    case 'ring/currentUser/LOGIN_SUCCESS':
    case 'ring/currentUser/LOGOUT_SUCCESS':
    case 'ring/currentUser/LOGIN_FAILURE':
    case 'ring/currentUser/ELISA_SSO_LOGIN_FAILURE_INVALID_ACCESS_RIGHTS':
    case 'ring/currentUser/LOGOUT_FAILURE': {
      return currentUserReducer.loginReducer(state, action);
    }
    case 'ring/currentUser/DISABLE_FIRST_TIME_VIEW':
    case 'ring/currentUser/ENABLE_FIRST_TIME_VIEW': {
      return currentUserReducer.firstTimeViewReducer(state, action);
    }
    case 'ring/currentUser/SELECT_ENTERPRISE': {
      return currentUserReducer.enterpriseSelectionReducer(state, action);
    }
    default:
      return state;
  }
};

const currentUserReducer = {
  initialState,
  loginReducer,
  csrfReducer,
  firstTimeViewReducer,
  enterpriseSelectionReducer: enterpriseReducer,
  updateConfigReducer,
  rootReducer
};

export default currentUserReducer;
