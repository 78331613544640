// @flow

import React, { type Element } from 'react';
import Radio from '@design-system/component-library/src/components/Radio';
import { useTranslation } from 'react-i18next';
import styles from './ContactUserSelector.module.scss';

export type PropsT = {|
  contactType: 'sms' | 'email',
  handleChange: (type: 'sms' | 'email') => void
|};

export const ContactUserSelector = (props: PropsT): Element<'div'> => {
  const { contactType, handleChange } = props;

  const { t } = useTranslation();

  return (
    <div className={styles['contact-selector']}>
      <div className={styles['contact-selector--description']}>
        {t('contactUserSelector.description')}
      </div>
      <Radio.Group value={contactType}>
        <Radio
          id="contact-selector-send-sms-option"
          name="send-option"
          onChange={() => handleChange('sms')}
          className={styles.radio}
          label={t('contactUserSelector.sendSMS')}
          value="sms"
        />
        <Radio
          id="contact-selector-section-send-email-option"
          name="send-option"
          onChange={() => handleChange('email')}
          className={styles.radio}
          label={t('contactUserSelector.sendEmail')}
          value="email"
        />
      </Radio.Group>
    </div>
  );
};

export default ContactUserSelector;
