// @flow

/* eslint-disable react/no-unused-prop-types */

import classNames from 'classnames';
import React, { type Element } from 'react';
import { type TableHeaderColumnT } from './TableHeader';
import LinkButton from '../Button/LinkButton';
import HighlightText from '../../scenes/users/SearchMatchRow/HighlightText';

import styles from './TableRow.module.scss';

export type TableCellT = {
  emphasized?: boolean,
  valueClasses?: string[],
  size: 'x-small' | 'small' | 'medium' | 'large' | 'x-large',
  value: string | Element<*>
};

export type TableRowItemT = {
  id: string,
  rowId: string,
  searchTerm?: string,
  isSelected?: boolean,
  rowClasses?: string[],
  externalLink?: string,
  extraLine?: Element<*>,
  name?: string,
  number?: string,
  icon?: Element<*>,
  type?: mixed,
  rowIndex?: number,
  rowSelector?: ?Element<*>
};

export type OnSelectFnT = TableRowItemT => *;
export type HoverButtonT = {
  label: string,
  onClick: OnSelectFnT
};

export type PropsT = {
  cells?: TableCellT[],
  headerCells?: TableHeaderColumnT[],
  rowItem: TableRowItemT,
  hoverEnabled: boolean,
  hoverButton: ?HoverButtonT,
  rowExtraLine?: ?Element<*>,
  onSelect: ?OnSelectFnT,
  linkName?: ?string,
  linkFunction?: ?(mixed) => void,
  borderBottomClass?: ?string,
  rowSelector?: ?Element<*>
};

const TableRow = ({
  cells = [],
  headerCells = [],
  hoverEnabled,
  hoverButton,
  rowExtraLine,
  onSelect,
  rowItem,
  linkName,
  linkFunction,
  borderBottomClass,
  rowSelector
}: PropsT): Element<'a'> => {
  const handleSelect = () => {
    if (onSelect) {
      onSelect(rowItem);
    }
  };
  const handleHoverButtonClicked = () => {
    if (hoverButton) {
      hoverButton.onClick(rowItem);
    }
  };

  return (
    <a href={rowItem.externalLink} className={styles.link} id="table-row">
      <div
        className={classNames(
          styles.row,
          ...(rowItem.rowClasses || []),
          hoverEnabled ? styles['hover-enabled'] : ''
        )}
      >
        <div className={styles['check-container']}>
          {rowItem.isSelected ? (
            <img src="/checkmark-blue-small.svg" alt="check" className={styles['check-selected']} />
          ) : (
            <img className={styles.check} src="/checkmark-light-grey-small.svg" alt="check" />
          )}
        </div>
        {rowItem.rowIndex === 0 && <div className={styles['border-top']} />}
        <div className={styles['row-selector-container']}>
          {rowSelector && <div id={`rowSelector-${rowItem.rowId}`}>{rowSelector}</div>}
        </div>
        <div
          id={rowItem.rowId}
          className={styles.cells}
          onClick={handleSelect}
          onKeyPress={handleSelect}
          tabIndex={0}
          role="button"
        >
          {cells.map(
            (
              { value, emphasized, valueClasses, size }: TableCellT,
              index: number
            ): Element<'div'> => (
              <div
                className={classNames(
                  styles.cell,
                  emphasized ? styles.emphasized : '',
                  ...(headerCells[index].columnClasses || []),
                  styles[size]
                )}
                key={
                  `ea-cell-${rowItem.rowId}-${index}` // eslint-disable-line react/no-array-index-key
                }
                id={`row-${rowItem.rowId}-column-${headerCells[index].columnId}`}
              >
                <div
                  className={classNames(
                    'label ea-hide--tablet-and-up',
                    ...(headerCells[index].columnClasses || [])
                  )}
                />
                <span className={classNames(styles['cell-content'], ...(valueClasses || []))}>
                  {typeof value === 'string' && rowItem.searchTerm ? (
                    <HighlightText text={value} highlight={rowItem.searchTerm} />
                  ) : (
                    value
                  )}
                </span>
                {linkName && linkFunction && index === cells.length - 1 && (
                  <LinkButton
                    id={`${rowItem.rowId}-linkButton`}
                    className={styles.link}
                    onClickAction={event =>
                      linkFunction(rowItem.id && rowItem.type ? [rowItem.id, rowItem.type] : event)
                    }
                    label={linkName}
                  />
                )}
              </div>
            )
          )}
        </div>
        {rowExtraLine && <div id={`extra-line-${rowItem.rowId}`}>{rowExtraLine}</div>}
        <div className={classNames(styles['border-bottom'], borderBottomClass)} />
        {hoverEnabled && hoverButton && (
          <div className={styles['hover-button-container']}>
            <div
              id={`${rowItem.rowId}-hoverButton`}
              className={styles['hover-button']}
              onClick={handleHoverButtonClicked}
              onKeyPress={handleHoverButtonClicked}
              tabIndex={-1}
              role="button"
            >
              {hoverButton.label}
            </div>
          </div>
        )}
      </div>
    </a>
  );
};

TableRow.defaultProps = {
  cells: [],
  headerCells: []
};

export default TableRow;
