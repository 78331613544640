// @flow
/* eslint-disable import/prefer-default-export */

import * as R from 'ramda';
import type { CurrentUserT } from '../../ducks/currentUser/currentUserTypes';
import { getConfigurationValue } from '../../userConfigHelpers';
import type { UserConfigT } from '../../ducks/config';
import type { PushObjT } from '../../navigationOperations';
import type { BulletinT } from '../../ducks/config/configTypes';
import { ONBOARDING_DISPLAYED_CONFIG } from '../OnboardingModal/OnboardingModal';

export const getGotoDestination = (
  currentUser: CurrentUserT,
  username?: string,
  password?: string
): PushObjT | null => {
  if (currentUser.status === 'generic_login_error') {
    return {
      pathname: '/login',
      state: {}
    };
  }
  if (currentUser.status === 'elisa_ad_platform_missing_error') {
    return {
      pathname: '/login-with-elisa-ad',
      state: { username, password }
    };
  }
  if (currentUser.status === 'access_right_error') {
    return {
      pathname: '/no-admin-access-rights-error',
      state: {}
    };
  }
  if (currentUser.status === 'missing_role_access_right_error') {
    return {
      pathname: '/missing-role-access-right-error',
      state: {}
    };
  }
  if (currentUser.status === 'two_step_authentication') {
    return {
      pathname: '/two-step-authentication',
      state: { username, password, token: currentUser.token }
    };
  }
  return null;
};

export const getChangelogKey = (environment: string) => {
  return `changelog_displayed_${environment}`;
};

export const getBulletin = (environment: string, bulletins: BulletinT[], index?: number) => {
  const environmentBulletins = bulletins
    ? bulletins
        .filter(bulletin => bulletin.environment && bulletin.environment.includes(environment))
        .sort((a, b) => Date.parse(b.activationDate) - Date.parse(a.activationDate))
    : [];
  if (!index) {
    return environmentBulletins.find(b => new Date() >= Date.parse(b.activationDate));
  }
  return environmentBulletins.filter(b => new Date() >= Date.parse(b.activationDate))[index];
};

export const getNewChangelogDisplayedValue = (
  environment: string,
  bulletins: BulletinT[],
  currentChangelogDisplayedValue: ?string
) => {
  const foundBulletin = getBulletin(environment, bulletins);
  const currentValues = currentChangelogDisplayedValue
    ? currentChangelogDisplayedValue.split(',')
    : '';
  if (foundBulletin && foundBulletin.id) {
    const found = currentValues
      ? // $FlowFixMe
        currentValues.find(value => value === foundBulletin.id.toString()) !== undefined
      : false;
    return !found
      ? // $FlowFixMe
        `${currentChangelogDisplayedValue || ''},${foundBulletin.id}`
      : currentChangelogDisplayedValue;
  }
  return currentChangelogDisplayedValue;
};

export const getLoginConfigs = (
  environment: string,
  userConfig: UserConfigT,
  bulletins: BulletinT[]
) => {
  const changelogDisplayedKey = getChangelogKey(environment);
  if (!userConfig) {
    // eslint-disable-next-line no-param-reassign
    userConfig = [];
  }
  if (!bulletins) {
    // eslint-disable-next-line no-param-reassign
    bulletins = [];
  }
  const currentChangelogDisplayedValue = getConfigurationValue(userConfig, changelogDisplayedKey);
  const newChangelogDisplayedValue = getNewChangelogDisplayedValue(
    environment,
    bulletins,
    currentChangelogDisplayedValue
  );
  const shouldOpenChangelog = currentChangelogDisplayedValue !== newChangelogDisplayedValue;
  const shouldOpenOnboarding =
    getConfigurationValue(userConfig, ONBOARDING_DISPLAYED_CONFIG) !== 'true';
  let modifiedUserConfig = null;
  if (shouldOpenOnboarding) {
    const changelogValue: string = bulletins
      .filter(
        bulletin =>
          bulletin.environment &&
          bulletin.environment.includes(environment) &&
          new Date() >= Date.parse(bulletin.activationDate)
      )
      .map(b => (b && b.id ? `${b.id}` : ''))
      .join();
    modifiedUserConfig = [
      ...R.reject(item => 'key' in item && item.key === changelogDisplayedKey, userConfig),
      { key: changelogDisplayedKey, value: changelogValue }
    ];
  } else if (shouldOpenChangelog && changelogDisplayedKey && newChangelogDisplayedValue) {
    modifiedUserConfig = [
      ...R.reject(item => 'key' in item && item.key === changelogDisplayedKey, userConfig),
      { key: changelogDisplayedKey, value: newChangelogDisplayedValue }
    ];
  }

  return { modifiedUserConfig, shouldOpenOnboarding, shouldOpenChangelog };
};

export const isUserLanguageValid = (appLanguage: string) =>
  appLanguage && ['en', 'sv', 'fi', 'et'].includes(appLanguage);
