// @flow

import type { CallForwardingDestinationT } from '../../../../../../ducks/entities/callForwarding/callForwardingTypes';
import type { TranslateT } from '../../../../../../commonTypes';

export const NOT_IN_USE = 'NOT_IN_USE';
export const NO_TRANSFER = 'NO_TRANSFER';
export const TRANSFER_INTERNAL = 'TRANSFER_INTERNAL';
export const TRANSFER_EXTERNAL = 'TRANSFER_EXTERNAL';
export const TRANSFER_ENT_VM = 'TRANSFER_ENT_VM';

export const TRANSFER_CALL_QUEUING_SERVICE = 'TRANSFER_CALL_QUEUING_SERVICE';

export type CategoryOptionT =
  | 'NOT_IN_USE'
  | 'NO_TRANSFER'
  | 'TRANSFER_INTERNAL'
  | 'TRANSFER_EXTERNAL'
  | 'TRANSFER_ENT_VM'
  | 'TRANSFER_CALL_QUEUING_SERVICE';

export type DestinationFieldT = {
  type: CategoryOptionT,
  visualValue?: string,
  value: ?string
};

export const convertFwdDestinationTypeToCategoryOptionType = (
  dest: CallForwardingDestinationT
): CategoryOptionT => {
  if (!dest || dest.type === 'REJECTION') {
    return NO_TRANSFER;
  }
  if (dest.type === 'EXTERNAL') {
    return TRANSFER_EXTERNAL;
  }
  if (dest.type === 'ENT_VM') {
    return TRANSFER_ENT_VM;
  }
  if (dest.type === 'INTERNAL') {
    return TRANSFER_INTERNAL;
  }
  if (dest.type === 'CALL_QUEUING_SERVICE') {
    return TRANSFER_CALL_QUEUING_SERVICE;
  }
  return NO_TRANSFER;
};

export const convertForwardingToDestinationField = (
  destination: ?CallForwardingDestinationT,
  visualValue?: ?string,
  notInUseAvailable?: boolean
): DestinationFieldT => {
  if (!destination) {
    return { type: notInUseAvailable ? NOT_IN_USE : NO_TRANSFER, value: '' };
  }
  const convertedType = convertFwdDestinationTypeToCategoryOptionType(destination);
  return {
    type: convertedType,
    value: destination.value,
    ...(visualValue && convertedType === TRANSFER_INTERNAL ? { visualValue } : { visualValue: '' })
  };
};

export const destinationFieldOptions = (
  translate: TranslateT<>,
  noEndTransfer: ?boolean,
  notInUseAvailable: ?boolean
) => {
  let targets = !noEndTransfer
    ? [
        {
          value: NO_TRANSFER,
          label: translate('callflows.forwardingField.noTransfer')
        }
      ]
    : [];
  targets = [
    ...[
      {
        value: TRANSFER_ENT_VM,
        label: translate('callflows.forwardingField.transferEntVM')
      },
      {
        value: TRANSFER_INTERNAL,
        label: translate('callflows.forwardingField.transferInternal')
      },
      {
        value: TRANSFER_EXTERNAL,
        label: translate('callflows.forwardingField.transferExternal')
      }
    ],
    ...targets
  ];
  targets = notInUseAvailable
    ? [
        {
          value: NOT_IN_USE,
          label: translate('callflows.forwardingField.notInUse')
        },
        ...targets
      ]
    : [...targets];
  return targets;
};
