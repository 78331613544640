import React, { useState, useEffect, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import styles from "./Toggle.module.scss";
import { useIdWithFallback } from "../../utils/hooks";
import { classNames } from "../../utils/css";

function ToggleInternal(
  {
    id = null,
    className = null,
    value = false,
    size = "m",
    onToggle = () => { },
    onChange = () => { },
    label = null,
    labelPosition = "top",
    disabled = false,
    i18n_toggle_ariaLabel = null,
    i18n_toggle_infoText = null,
    i18n_toggle_helpText = null,
    on = false,
    ...otherProps
  },
  ref
) {
  if(on) {
    console.warn("Toggle component's `on` property is deprecated. Use `value` property instead. `on` property will be removed in a future version (during summer 2024).");
  }

  const htmlId = useIdWithFallback("dsToggle", id);
  const [selectedValue, setSelectedValue] = useState(value || on || false);
  const [disabledValue, setDisabledValue] = useState(disabled);

  useImperativeHandle(
    ref,
    () => ({
      value: (itemValue) => {
        if (
          (itemValue === true || itemValue === false) &&
          itemValue !== selectedValue
        ) {
          // This should not call onChange as this is a programmatic change
          setSelectedValue(itemValue);
        }
        return itemValue;
      },

      getValue: () => selectedValue,

      disabled: (newDisabledValue) => {
        if (newDisabledValue === true || newDisabledValue === false) {
          setDisabledValue(newDisabledValue);
        } else {
          return disabledValue;
        }
      },
    }),
    [disabledValue, selectedValue]
  );

  const onToggleChange = (e) => {
    const newValue = e.currentTarget.checked;
    // TODO should we not setSelectedValue if e.stopPropagation is called?
    if (onToggle) onToggle(e);
    if (onChange) onChange(e);
    setSelectedValue(newValue);
  };

  const inputId = `${htmlId}Check`;
  const labelId = `${htmlId}Label`;

  let inputLabel = null;
  if (label) {
    const LabelComponent = require('../Label').default; // eslint-disable-line global-require
    inputLabel = <LabelComponent className="ds-toggle--labelarea-label-content" labelFor={inputId}>{label}</LabelComponent>;

    // inputLabel = (
    //   <label className="ds-toggle--labelarea-label-content" htmlFor={inputId}>{label}</label>
    // );
  }

  let inputInfo = null;
  if (i18n_toggle_infoText) {
    const PopoverComponent = require("../Popover").default; // eslint-disable-line global-require
    const IconInformationRegularComponent =
      require("../Icon/lib/IconInformationRegular").default; // eslint-disable-line global-require
    inputInfo = (
      <PopoverComponent
        triggerElement={<IconInformationRegularComponent />}
        placement="top"
      >
        {i18n_toggle_infoText}
      </PopoverComponent>
    );
  }

  let inputHelp = null;
  if (i18n_toggle_helpText) {
    const HelpComponent = require("../InputHelp").default; // eslint-disable-line global-require
    inputHelp = (
      <HelpComponent id={`${htmlId}Help`}>{i18n_toggle_helpText}</HelpComponent>
    );
  }

  useEffect(() => {
    // This should not call onChange as this is a programmatic change
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    setDisabledValue(disabled);
  }, [disabled]);

  const allClasses = classNames([
    styles.toggle,
    disabledValue ? styles[`toggle--disabled`] : null,
    styles[`toggle--size-${size}`],
    styles[`toggle--position-${labelPosition}`],
    className,
  ]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div id={htmlId} className={allClasses} {...otherProps}>
      {label ? (
        <div className={styles[`toggle--labelarea`]}>
          {inputLabel} {inputInfo}
        </div>
      ) : null}
      <div className={styles[`toggle--inputarea`]}>
        <input
          type="checkbox"
          role="switch"
          className={styles.toggle__checkbox}
          id={inputId}
          onChange={onToggleChange}
          checked={selectedValue}
          aria-label={i18n_toggle_ariaLabel}
          disabled={disabledValue}
          aria-labelledby={labelId}
          key={selectedValue}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={styles.toggle__label} htmlFor={inputId} />
      </div>
      {inputHelp}
    </div>
  );
}

const Toggle = React.forwardRef(ToggleInternal);
export default Toggle;

Toggle.propTypes = {
  /**
   * ID of the dialog
   */
  id: PropTypes.string,
  /**
   * Whether the toggle is on or off by default
   */
  value: PropTypes.bool,
  /**
   * Whether the toggle is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Class names you want to give to the component
   */
  className: PropTypes.string,
  /**
   * The size of the component
   */
  size: PropTypes.oneOf(["s", "m"]),
  /**
   * Function that launches when the toggle switches
   */
  onToggle: PropTypes.func,
  /**
   * Label text for the toggle. Clicking this does not change the Toggle's value.
   */
  label: PropTypes.string,
  /**
   * Label position
   */
  labelPosition: PropTypes.oneOf(["top", "left", "right"]),
  /**
   * Aria-label. Use this if you are not using label.
   */
  i18n_toggle_ariaLabel: PropTypes.string,
  /**
   * Additional information and instructions for the toggle
   */
  i18n_toggle_infoText: PropTypes.string,
  /**
   * Always visible help text for the toggle
   */
  i18n_toggle_helpText: PropTypes.string,
  /**
   * @deprecated Use `value` property instead. `on` property will be removed in a future version (during summer 2024).
   */
  on: PropTypes.bool,
};

Toggle.defaultProps = {
  id: null,
  value: false,
  className: null,
  size: "m",
  onToggle: () => { },
  label: null,
  labelPosition: "top",
  disabled: false,
  i18n_toggle_ariaLabel: null,
  i18n_toggle_infoText: null,
  i18n_toggle_helpText: null,
  on: null,
};
