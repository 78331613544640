import React, { Children, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import IconArrowRightRegular from '../Icon/lib/IconArrowRightRegular';
import IconArrowLeftRegular from '../Icon/lib/IconArrowLeftRegular';
import { classNames } from '../../utils/css';
import { useIdWithFallback, usePrevious } from '../../utils/hooks';

import styles from './Carousel.module.scss';

// This might change whenever the component props are changed, so be sure you call this
// whenever you need a fresh value.
const getConfiguredVisibleItemsCount = (listElement) => {
  if (!listElement) {
    return null;
  }

  // breakpoint
  let screenSize = '';
  if(window.innerWidth < 481) {screenSize = '-mobile'}
  if(window.innerWidth > 480 && window.innerWidth < 961) {screenSize = '-tablet'}

  const rawValue = getComputedStyle(listElement).getPropertyValue(`--ds-carousel--visible-items${screenSize}`);
  if (rawValue.length) {
    const parsed = parseInt(rawValue, 10);
    return Number.isNaN(parsed) ? null : parsed;
  }
  return null;
};

const getVisibility = (listItem) => {
  const itemWidth = listItem.offsetWidth;
  const itemLeftX = listItem.offsetLeft;
  const { parentElement } = listItem;
  if (parentElement) {
    const parentLeftX = parentElement.scrollLeft;
    const parentWidth = parentElement.offsetWidth;
    if (parentLeftX != null && itemWidth != null) {
      const itemRightX = itemLeftX + itemWidth;
      const parentRightX = parentLeftX + parentWidth;
      const visibleWidth = Math.max(0, Math.min(itemRightX, parentRightX) - Math.max(itemLeftX, parentLeftX));
      // Make sure we don't divide by zero
      let fraction = visibleWidth ? 1 : 0;
      if (itemWidth) {
        fraction = visibleWidth / itemWidth;
      }
      return {
        fraction,
        // TODO get the cutoff value from css variable?
        // We give some leeway in the calculations
        visible: fraction > 0.6,
      };
    }
  }
  return {
    fraction: 0,
    visible: false,
  };
};

// TODO check if this could be implemented with intersectionobserver?
// - We'd need to attach it to all the children whenever the child array changes...
// - Not easy
// FIXME is it possible that resizing over a breakpoint _should_ trigger this, but doesn't?
// - I think so
const useVisibleChildren = (listRef) => {
  const debouncer = useRef({ bounces: 0 });
  const [visibilities, setVisibilities] = useState([]);
  const listElement = listRef.current;
  const fireVisibilityChange = useCallback(() => {
    debouncer.current = { bounces: 0 };
    // NB: While we could stop evaluating visibilities after we visit a
    // non-visible child preceded by a visible child, it would not provide
    // a measurable speedup at these element counts (I tested it).
    setVisibilities([].map.call(listElement?.children || [], getVisibility));
  }, [debouncer, listElement, setVisibilities]);

  useEffect(() => {
    const scrollListener = () => {
      if (debouncer.current) {
        clearTimeout(debouncer.current.timeout);
      }
      if (debouncer.current.bounces > 5) {
        fireVisibilityChange();
      } else {
        debouncer.current.bounces += 1;
        debouncer.current.timeout = setTimeout(fireVisibilityChange, 20);
      }
    };
    listElement?.addEventListener('scroll', scrollListener);
    setVisibilities([].map.call(listElement?.children || [], getVisibility));

    return () => {
      listElement?.removeEventListener('scroll', scrollListener);
    };
  }, [fireVisibilityChange, listElement]);
  return visibilities;
};

const getItemId = (listId, itemIdx) => `${listId}__item-${itemIdx}`;

function CarouselItem({ children, idx, rootId, count, separator, ...otherProps}) {
  return (
    <div role="group" {...otherProps} aria-label={`${idx+1} ${separator} ${count}`} className={styles.carousel__item} id={getItemId(rootId, idx)} key={idx} title={`${idx + 1}`}>
      {children}
    </div>
  );
}

CarouselItem.propTypes = {
  children: PropTypes.node,
  idx: PropTypes.number.isRequired,
  rootId: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  separator: PropTypes.string.isRequired
};

CarouselItem.defaultProps = {
  children: null,
};

export const Carousel = forwardRef(
  (
    {
      buttons = null,
      buttonLocation,
      children,
      className,
      i18n_indicators_label,
      i18n_label,
      i18n_next_button_label,
      i18n_next_button_loop_label,
      i18n_previous_button_label,
      i18n_previous_button_loop_label,
      id,
      indicators,
      indicatorStyle,
      loop,
      onVisibilityChange,
      peek = false,
      size = 1,
      tabletSize = 1,
      mobileSize = 1,
      snap,
      showAllLink,
      i18n_carousel_showAll,
      i18n_carousel_slide,
      i18n_carousel_role,
      i18n_carousel_slideCountSeparator,
      showAllClick,
    },
    ref
  ) => {
    const rootId = useIdWithFallback('dsCarousel', id);
    const listRef = useRef(null);
    const childVisibilities = useVisibleChildren(listRef);
    const previousChildVisibilities = usePrevious(childVisibilities);
    // useEffect is here just to stop react from updating parent component state while rendering this component
    useEffect(() => {
      childVisibilities.forEach((el, indx) => {
        const carouselItem = document.querySelector(`#${rootId}__list`).childNodes[indx];
        const carouselItemFocusableChildren = carouselItem.querySelectorAll("a, button");

        /** Hide and show from screen readers when item is visible or not */
        if(el.visible) {
          carouselItem.removeAttribute("aria-hidden");
          carouselItem.removeAttribute("tabindex");
          carouselItemFocusableChildren.forEach((childElem) => {
            childElem.removeAttribute("aria-hidden");
            childElem.removeAttribute("tabindex");
            });
        }
        else {
          carouselItem.setAttribute("aria-hidden", true);
          carouselItem.setAttribute("tabindex", -1);

          /** Also need to hide inner links and buttons  */
          carouselItemFocusableChildren.forEach((childElem) => {
            childElem.setAttribute("aria-hidden", true);
            childElem.setAttribute("tabindex", -1);
            });
        }
      });
      if (childVisibilities.length !== previousChildVisibilities?.length || childVisibilities.some((v, i) => v.visible !== previousChildVisibilities[i]?.visible || v.fraction !== previousChildVisibilities[i]?.fraction)) {
        onVisibilityChange(childVisibilities);
      }
    }, [childVisibilities, onVisibilityChange, previousChildVisibilities, rootId]);
    const scrollToIndex = useCallback(
      (scrollTargetIdx) => {
        listRef.current?.children[scrollTargetIdx]?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      },
      [listRef]
    );
    useImperativeHandle(
      ref,
      () => ({
        getVisibilities: () => childVisibilities,
        scrollTo: (idx) => scrollToIndex(idx),
      }),
      [childVisibilities, scrollToIndex]
    );

    const listId = `${rootId}__list`;
    const childCount = Children.count(children);

    const canLoopPrev = childVisibilities.length > 1 && childVisibilities[0].visible;
    const canLoopNext = !canLoopPrev && childVisibilities.length > 1 && childVisibilities[childVisibilities.length - 1].visible;

    const scrollLeft = useCallback(() => {
      const listElement = listRef.current;
      if (!listElement) {
        return;
      }
      if (canLoopPrev) {
        scrollToIndex(childVisibilities.length - 1);
        return;
      }
      const visibleItemsCount = getConfiguredVisibleItemsCount(listElement);
      if (visibleItemsCount) {
        const firstVisibleIdx = childVisibilities.findIndex((v) => v.visible);
        if (firstVisibleIdx !== -1) {
          const scrollTargetIdx = Math.max(0, firstVisibleIdx - visibleItemsCount);
          scrollToIndex(scrollTargetIdx);
        }
      }
    }, [canLoopPrev, childVisibilities, listRef, scrollToIndex]);

    const scrollRight = useCallback(() => {
      const listElement = listRef.current;
      if (!listElement) {
        return;
      }
      if (canLoopNext) {
        scrollToIndex(0);
        return;
      }
      const visibleItemsCount = getConfiguredVisibleItemsCount(listElement);
      if (visibleItemsCount) {
        const firstVisibleIdx = childVisibilities.findIndex((v) => v.visible);
        if (firstVisibleIdx !== -1) {
          const scrollTargetIdx = Math.min(listElement.children.length - 1, firstVisibleIdx + visibleItemsCount);
          scrollToIndex(scrollTargetIdx);
        }
      }
    }, [canLoopNext, childVisibilities, listRef, scrollToIndex]);

    const prevIsLoop = loop && canLoopPrev;
    const nextIsLoop = loop && canLoopNext;

    // TODO use inert for non-visible children once support is good enough:
    // https://github.com/facebook/react/pull/24730
    // https://caniuse.com/mdn-api_htmlelement_inert
    // We'll still want to keep the current visibility API for lazy loading and such
    // TODO better canLoop icons
    // FIXME maybe anchors should move to the anchor's direction just so that the image becomes visible?
    /*
            <li className={styles.carousel__item} id={getItemId(rootId, idx)} key={idx} title={`${idx + 1}`}>
              {child}
            </li>
    */

    const slideCount = children.length;
    return (
      <div
        aria-label={i18n_label}
        aria-roledescription={i18n_carousel_role}
        className={classNames([styles.carousel, peek && styles['carousel--peek'], styles[`carousel--size-${size}`], styles[`carousel--size-tablet-${tabletSize}`], styles[`carousel--size-mobile-${mobileSize}`], snap && styles['carousel--snap'], snap === 'always' && styles['carousel--snap-always'], buttonLocation === 'top' && styles['carousel-buttons-on-top'], className])}
        id={rootId}
        role="region"
      >

{/**
 * If show all link is present the markup should be slightly different - therefore this solution
 */}
        {showAllLink && (
          <>
            <a href={showAllLink} onClick={showAllClick} className={styles['show-all']}>{i18n_carousel_showAll}</a>
            {buttons && (
              <>
            <button
              aria-controls={listId}
              aria-label={prevIsLoop ? i18n_previous_button_loop_label : i18n_previous_button_label}
              className={classNames([styles.carousel__button, styles['carousel__button--previous'], styles[`carousel__button--${buttons}`], canLoopPrev && styles['carousel__button--loop']])}
              disabled={childVisibilities.length < 2 || (!loop && childVisibilities[0].visible)}
              onClick={scrollLeft}
              type="button"
            >
              <IconArrowLeftRegular />
            </button>

            <button
            aria-controls={listId}
            aria-label={nextIsLoop ? i18n_next_button_loop_label : i18n_next_button_label}
            className={classNames([styles.carousel__button, styles['carousel__button--next'], styles[`carousel__button--${buttons}`], canLoopNext && styles['carousel__button--loop']])}
            disabled={childVisibilities.length < 2 || (!loop && childVisibilities[childVisibilities.length - 1].visible)}
            onClick={scrollRight}
            type="button"
          >
            <IconArrowRightRegular />
          </button>
          </>
            )}
          </>
        )}

{/**
 * If show all link is NOT present - Next and previous buttons will be in different order
 */}

        {!showAllLink && buttons && (
          <button
            aria-controls={listId}
            aria-label={prevIsLoop ? i18n_previous_button_loop_label : i18n_previous_button_label}
            className={classNames([styles.carousel__button, styles['carousel__button--previous'], styles[`carousel__button--${buttons}`], canLoopPrev && styles['carousel__button--loop']])}
            disabled={childVisibilities.length < 2 || (!loop && childVisibilities[0].visible)}
            onClick={scrollLeft}
            type="button"
          >
{/**             {prevIsLoop ? <IconArrowRightAltRegular /> : <IconArrowLeftRegular />} */}
            <IconArrowLeftRegular />
          </button>
        )}
        <div className={styles.carousel__items} id={listId} ref={listRef}>
          {Children.map(children, (child, idx) => (
            <CarouselItem idx={idx} rootId={rootId} count={slideCount} aria-roledescription={i18n_carousel_slide} separator={i18n_carousel_slideCountSeparator}>
              {child}
            </CarouselItem>
          ))}
        </div>
        {!showAllLink && buttons && (
          <button
            aria-controls={listId}
            aria-label={nextIsLoop ? i18n_next_button_loop_label : i18n_next_button_label}
            className={classNames([styles.carousel__button, styles['carousel__button--next'], styles[`carousel__button--${buttons}`], canLoopNext && styles['carousel__button--loop']])}
            disabled={childVisibilities.length < 2 || (!loop && childVisibilities[childVisibilities.length - 1].visible)}
            onClick={scrollRight}
            type="button"
          >
{/**            {nextIsLoop ? <IconArrowLeftAltRegular /> : <IconArrowRightRegular />} */}
            <IconArrowRightRegular />
          </button>
        )}
        {indicators && (
          <div aria-label={i18n_indicators_label} aria-roledescription="slide" className={classNames([styles.carousel__indicators, styles[`carousel__indicators--${indicatorStyle}`]])} role="group">
            {Array.from({ length: childCount }, (_, idx) => (
              <button
                type="button"
                className={classNames([styles.carousel__indicator, childVisibilities[idx]?.visible && styles['carousel__indicator--active']])}
                href={`#${getItemId(rootId, idx)}`}
                key={idx}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToIndex(idx);
                }}
                title={`${idx + 1}`}
              >
                <span>{idx + 1}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    );
  }
);

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
  /**
   * Show previous/next buttons.
   */
  buttons: PropTypes.oneOf(['light', 'light-inverse', 'dark', 'primary', 'arrow', 'arrow-dark']),
  /**
   * Button location. Defaults on top of the content
   */
  buttonLocation: PropTypes.oneOf(['default', 'top']),
  /**
   * 'List items' for the carousel. We provide the li, just give us the content.
   */
  children: PropTypes.node.isRequired,
  /**
   * Additional class name(s) you want to give to the component.
   */
  className: PropTypes.string,
  /**
   * Label for indicators
   */
  i18n_indicators_label: PropTypes.string.isRequired,
  /**
   * Label for carousel
   */
  i18n_label: PropTypes.string.isRequired,
  /**
   * Label for next button
   */
  i18n_next_button_label: PropTypes.string.isRequired,
  /**
   * Label for next button when it will loop to the first item
   */
  i18n_next_button_loop_label: PropTypes.string.isRequired,
  /**
   * Label for the previous button
   */
  i18n_previous_button_label: PropTypes.string.isRequired,
  /**
   * Label for the previous button when it will loop to the last item
   */
  i18n_previous_button_loop_label: PropTypes.string.isRequired,
  /**
   * An id you want to give the component.
   */
  id: PropTypes.string,
  // TODO should these be breakpoint based as well?
  /**
   * Show indicator anchors for items.
   */
  indicators: PropTypes.bool,
  /* indicator style */
  indicatorStyle: PropTypes.oneOf(['default', 'hero', 'dark']),
  /**
   * Loop carousel from first/last items
   */
  loop: PropTypes.bool,
  // TODO could we pass the visibility as a prop to the children with React.cloneElement(child, { isVisible: foo }) ?
  /**
   * Can be used to set the children as inert if they're not visible.
   * Don't use this to change anything affecting layout, or it might cause an infinite loop.
   * @param {{ fraction: number, visible: boolean }[]} visibilities
   */
  onVisibilityChange: PropTypes.func,
  /**
   * Show part of previous & next item.
   */
  peek: PropTypes.bool,
  // TODO make this breakpoint based somehow...
  // 20 is completely arbitrary
  /**
   * How many children should be (fully) visible on screen.
   */
  size: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]),
  /**
   * How many children should be (fully) visible on tablet screen.
   */
  tabletSize: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  /**
   * How many children should be (fully) visible on mobile screen.
   */
  mobileSize: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  // Not sure if both are needed, or if a boolean would suffice.
  /**
   * Scroll snap type.
   */
  snap: PropTypes.oneOf(['always', 'normal']),
  /** Link */
  showAllLink: PropTypes.string,
  /** show all link CTA */
  i18n_carousel_showAll: PropTypes.string,
  /** Slide description. Defaults to Slide. In fin  */
  i18n_carousel_slide: PropTypes.string,
  /** Carousel role */
  i18n_carousel_role: PropTypes.string,
  /** Slide count separator. eg. Slide 1 OF 6, or in Finnish: Dia 1 kautta 6 */
  i18n_carousel_slideCountSeparator: PropTypes.string,
  /** Show all click handler */
  showAllClick: PropTypes.func,
};

Carousel.defaultProps = {
  buttons: false,
  buttonLocation: 'default',
  className: null,
  id: null,
  indicators: false,
  indicatorStyle: 'dark',
  loop: true,
  onVisibilityChange: (e) => {
    /* NOOP */
  },
  peek: false,
  size: 1,
  tabletSize: 1,
  mobileSize: 1,
  snap: null,
  showAllLink: null,
  i18n_carousel_showAll: 'Show all',
  i18n_carousel_slide: 'Dia',
  i18n_carousel_role: 'Carousel',
  i18n_carousel_slideCountSeparator: 'of',
  showAllClick: () => {},
};

export default Carousel;
