// @flow

import React, { type Element } from 'react';
import Radio from '@design-system/component-library/src/components/Radio';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TooltipExtension from '../../../../components/TooltipExtension';
import IntegerField from '../edit/children/IntegerField';

import styles from './GroupPriority.module.scss';

export type PropsT = {|
  field: string,
  errorMessage?: string
|};

export const GroupPriority = (props: PropsT): Element<'div'> => {
  const { field, errorMessage } = props;
  const { setValue, getValues } = useFormContext();
  const values = getValues();
  const { t } = useTranslation();

  const fieldValue = values[field] === 0 ? 'false' : 'true';
  return (
    <div className={styles['group-priority-selector']}>
      <div className={styles['title-area']}>
        <div className={styles.title}>{t('groupPriority.title')}</div>
        <TooltipExtension
          title={t('groupPriority.title')}
          text={t('groupPriority.tooltip')}
          subText={t('groupPriority.tooltip2')}
        />
      </div>
      <Radio.Group value={fieldValue}>
        <div id="group-priority-not-in-use" className={styles['group-priority-not-in-use']}>
          <Radio
            id="group-priority-not-in-use-option"
            name="group-priority-not-in-use-option"
            onChange={() =>
              setValue(field, 0, {
                shouldValidate: true,
                shouldDirty: true
              })
            }
            className={styles.radio}
            label={t('groupPriority.notInUse')}
            value="false"
          />
        </div>
        <div id="group-priority-in-use" className={styles['in-use-field']}>
          <Radio
            id="group-priority-section-in-use-option"
            name="group-priority-section-in-use-option"
            onChange={() =>
              setValue(field, 1, {
                shouldValidate: true,
                shouldDirty: true
              })
            }
            className={styles.radio}
            label={t('groupPriority.inUse')}
            value="true"
          />
          {values[field] !== 0 && (
            <IntegerField
              field={field}
              maxLength={2}
              postFixDescription=""
              className={styles['input-field']}
              errorMessage={errorMessage}
            />
          )}
        </div>
      </Radio.Group>
    </div>
  );
};

export default GroupPriority;
