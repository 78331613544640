// @flow strict-local

import React, { type Element } from 'react';
import { connect, useSelector } from 'react-redux';
import Radio from '@design-system/component-library/src/components/Radio';
import { useTranslation } from 'react-i18next';
import type { RingPatternT } from '../../../../../ducks/entities/callFlow/commonCallFlowTypes';
import type { ExactPropsT } from '../../../../../commonTypes';
import Tooltip from '../../../../../components/Tooltip';

import styles from './CallDistributionSelection.module.scss';

type OwnPropsT = {|
  ringPattern: RingPatternT,
  updateCallDistributionTarget: RingPatternT => void,
  errorMessage: string,
  numberOfMembers: number,
  unisonMaxMembers: number,
  overflowGroupEnabled?: boolean,
  isACD?: boolean,
  periodicStatsResetMoments?: string
|};

type StatePropsT = {||};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT>;

export const CallDistributionSelection = ({
  ringPattern,
  updateCallDistributionTarget,
  errorMessage,
  numberOfMembers,
  unisonMaxMembers,
  overflowGroupEnabled,
  isACD,
  periodicStatsResetMoments
}: PropsT): Element<'div'> => {
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.currentUser);
  return (
    <div className={styles.container} id="call-distribution-selection">
      {errorMessage && <div className={styles['specific-validation-error']}>{errorMessage}</div>}
      <Radio.Group value={ringPattern}>
        <div className={styles['radio-container']}>
          <Radio
            id="callflow-ext-group-details-ring-pattern-option-1"
            name="ring-pattern-option"
            onChange={() => updateCallDistributionTarget('oldestIdle')}
            className={styles.radio}
            label={t('callflows.callDistributionSelection.oldestIdle') || ''}
            value="oldestIdle"
          />
          <Tooltip>
            <div className={styles['tooltip-header']}>
              {t('callflows.callDistributionSelection.tooltip.oldestIdle')}
            </div>
          </Tooltip>
        </div>
        <div className={styles['radio-container']}>
          <Radio
            id="callflow-ext-group-details-ring-pattern-option-2"
            name="ring-pattern-option"
            onChange={() => updateCallDistributionTarget('sequential')}
            className={styles.radio}
            label={t('callflows.callDistributionSelection.sequential') || ''}
            value="sequential"
          />
          <Tooltip>
            <div className={styles['tooltip-header']}>
              {t('callflows.callDistributionSelection.tooltip.sequential')}
            </div>
          </Tooltip>
        </div>
        <div className={styles['radio-container']}>
          <Radio
            id="callflow-ext-group-details-ring-pattern-option-3"
            name="ring-pattern-option"
            onChange={() => updateCallDistributionTarget('cyclic')}
            className={styles.radio}
            label={t('callflows.callDistributionSelection.cyclic') || ''}
            value="cyclic"
          />
          <Tooltip>
            <div className={styles['tooltip-header']}>
              {t('callflows.callDistributionSelection.tooltip.cyclic')}
            </div>
          </Tooltip>
        </div>
        {((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') &&
          isACD && (
            <div>
              <div className={styles['radio-container']}>
                <Radio
                  id="callflow-ext-group-details-ring-pattern-option-4"
                  name="ring-pattern-option"
                  onChange={() => updateCallDistributionTarget('leastTalking')}
                  className={styles.radio}
                  label={t('callflows.callDistributionSelection.leastTalking') || ''}
                  value="leastTalking"
                />
                <Tooltip>
                  <div className={styles['tooltip-header']}>
                    {t('callflows.callDistributionSelection.tooltip.leastTalking', {
                      periodicStatsResetMoments
                    })}
                  </div>
                </Tooltip>
              </div>
              <div className={styles['radio-container']}>
                <Radio
                  id="callflow-ext-group-details-ring-pattern-option-5"
                  name="ring-pattern-option"
                  onChange={() => updateCallDistributionTarget('leastCalls')}
                  className={styles.radio}
                  label={t('callflows.callDistributionSelection.leastCalls') || ''}
                  value="leastCalls"
                />
                <Tooltip>
                  <div className={styles['tooltip-header']}>
                    {t('callflows.callDistributionSelection.tooltip.leastCalls', {
                      periodicStatsResetMoments
                    })}
                  </div>
                </Tooltip>
              </div>
            </div>
          )}
        <div className={styles['radio-container']}>
          <Radio
            id="callflow-ext-group-details-ring-pattern-option-6"
            name="ring-pattern-option"
            onChange={() => updateCallDistributionTarget('unisson')}
            className={styles.radio}
            label={t('callflows.callDistributionSelection.unisson') || ''}
            value="unisson"
            disabled={numberOfMembers > unisonMaxMembers || overflowGroupEnabled}
          />
          <Tooltip>
            <div className={styles['tooltip-header']}>
              {t('callflows.callDistributionSelection.tooltip.unisson')}
            </div>
          </Tooltip>
        </div>
        {overflowGroupEnabled && (
          <div>{t('callflows.callDistributionSelection.overflowInUse')}</div>
        )}
      </Radio.Group>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)(CallDistributionSelection);
