// @flow strict-local

import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken } from 'axios';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SpeedDiallingIcon } from '../../../../../assets/callflow/details/speeddialling-small.svg';
import { updateSpeedDial } from '../../../../../ducks/entities/speedDial/speedDialOperations';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import type {
  SpeedDialEntityT,
  SpeedDialUpdateEntityT
} from '../../../../../ducks/entities/speedDial/speedDialTypes';
import fieldValidators from '../../../../../fieldValidators';
import SpeedDialForwardingField from '../../../components/edit/children/forwardingField/SpeedDialForwardingField';
import { validateForwardingField } from '../../../../../utils/validationUtils';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';

export type PropsT = {|
  callflowId: string
|};

type FormT = {
  externalDestination: { value: string, type: string, text: string }
};

export const EditSpeedDialDetails = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const speedDial = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const initialFormValues: FormT = {
    externalDestination: {
      value: speedDial.externalDestination || '',
      text: speedDial.externalDestination || '',
      type:
        fieldValidators.E164PhoneNumberValidator(speedDial.externalDestination) === undefined
          ? 'TRANSFER_EXTERNAL'
          : 'TRANSFER_INTERNAL'
    }
  };

  const speedDialSchema = yup.object().shape({
    externalDestination: validateForwardingField(t('callflows.validation.forwardingError'))
  });

  // update
  const onSubmit = async (formData: FormT): Promise<SpeedDialEntityT> => {
    const patchPayload: SpeedDialUpdateEntityT = {
      externalDestination: formData.externalDestination.value
    };

    return dispatch(
      updateSpeedDial(
        speedDial.enterpriseId,
        speedDial.id,
        patchPayload,
        CancelToken.source().token,
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={speedDial.id}
      icon={<SpeedDiallingIcon />}
      title={t('callflows.editSpeedDialDetails.title')}
      description={t('callflows.editSpeedDialDetails.description')}
      defaultValues={initialFormValues}
      validationSchema={speedDialSchema}
      onSaveForm={onSubmit}
    >
      <SpeedDialForwardingField
        field="externalDestination"
        title={t('callflows.editSpeedDialDetails.targetNumber')}
        filterSearchResults={entry => (entry ? entry.value !== speedDial.addressNumber : false)}
      />
    </EditCallflowDetails>
  );
};

export default EditSpeedDialDetails;
