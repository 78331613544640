import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIdWithFallback } from '../../utils/hooks';
import { classNames } from '../../utils/css';
import Dummy2 from './Dummy2';
import DummyContext from './DummyContext';

import styles from './Dummy.module.scss';

function DummyInternal(
  {
    id = null,
    className = null,
    children = null,
    selectables = null,
    selected = null,
    i18n_dummy_ariaLabel = 'Linkkilista',
    i18n_dummy_translateText = '',
    i18n_dummy_selectedText = 'Valittu',
    onChange = () => {},
    ...otherProps
  },
  ref,
){
  const htmlId = useIdWithFallback('dsDummy', id);
  const [selectedValue, setSelectedValue] = useState(selected);
  const value = useMemo(() => ({selectedValue, setSelectedValue}), [selectedValue, setSelectedValue]);

  const allClasses = classNames([styles.dummy, className || null]);

  return (
    <DummyContext.Provider value={value}>
      <div id={htmlId} className={allClasses} {...otherProps}>
        <p>Selected value: {selectedValue}</p>
        {selectables.map((selectable, index) => (
          <Dummy2
            key={`${htmlId}Option${index}`}
            id={selectable.id || `${htmlId}Option${index}`}
            label={selectable.label}
            value={selectable.value}
          />
        ))}
        {children}
      </div>
    </DummyContext.Provider>
  );
}

const Dummy = React.forwardRef(DummyInternal);
export default Dummy;

Dummy.propTypes = {
  /** Element's id */
  id: PropTypes.string,
  /** Class names the user can give to the component */
  className: PropTypes.string,
  /** */
  selectables: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string, id: PropTypes.string })),
  /**  */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  /** */
  selected: PropTypes.string,
  /**  */
  i18n_dummy_ariaLabel: PropTypes.string,
  /**  */
  i18n_dummy_translateText: PropTypes.string,
  /**  */
  i18n_dummy_selectedText: PropTypes.string,
  /** */
  onChange: PropTypes.func,
};

Dummy.defaultProps = {
  id: null,
  className: null,
  selectables: null,
  children: null,
  selected: null,
  i18n_dummy_ariaLabel: 'Linkkilista',
  i18n_dummy_translateText: '',
  i18n_dummy_selectedText: 'Valittu',
  onChange: () => {},
};
