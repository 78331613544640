import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useIdWithFallback } from '../../utils/hooks';
import { classNames } from '../../utils/css';
import styles from './Input.module.scss';
import { KEY_RETURN } from '../../utils/keycodes';
import {INPUT_TYPES, NUMERIC_INPUT_TYPES, READONLY_INPUT_TYPES, REQUIRED_INPUT_TYPES} from '../../utils/elements';

const TEXTAREA_LINE_HEIGHT = 24;
const TEXTAREA_VERTICAL_PADDING = 22;
const ARIA_AUTOCOMPLETE_VALUES = ['inline', 'list', 'both', 'none'];

function InputInternal(
  {
    /* eslint-disable react/prop-types */
    ariaActivedescendant = null,
    ariaAutocomplete = null,
    ariaExpanded = null,
    ariaHaspopup = null,
    ariaInvalid = 'false',
    ariaLabel = null,
    ariaOwns = null,
    autocomplete = null,
    autofocus = false,
    children = null,
    className = null,
    clear = false,
    controlled = null,
    defaultValue = null,
    dirName = null,
    disabled = false,
    error = '',
    form = null,
    helpText = null,
    i18n_input_clearFieldTitle = 'Tyhjennä kenttä',
    i18n_input_errorMessage = '',
    i18n_input_helpText = null,
    i18n_input_hidePasswordTitle = 'Piilota salasana',
    i18n_input_infoText = null,
    i18n_input_optionalText = '(ei pakollinen)',
    i18n_input_showPasswordTitle = 'Näytä salasana',
    icon = null,
    id = null,
    inputmode = null,
    label = null,
    max = null,
    maxlength = null,
    maxrows = 10,
    min = null,
    minlength = null,
    minrows = 1,
    multiple = null,
    name = null,
    onBlur = () => {},
    onChange = null,
    onClearInput = () => {},
    onClick = () => {},
    onFocus = () => {},
    onKeyDown = () => {},
    onKeyUp = () => {},
    onPasswordToggle = () => {},
    onValueChange = () => {},
    optional = false,
    optionalText = null,
    pattern = null,
    placeholder = null,
    readonly = false,
    role = null,
    rows = null,
    size = null,
    step = null,
    style = null,
    textareaRows = null,
    touched = false,
    type = 'text',
    value = '',
    inputRef = null,
    ...otherProps
    /* eslint-enable react/prop-types */
  },
  ref
){
  if(onChange) {
    console.warn("Input component's `onChange` property is deprecated. Use `onValueChange` property instead. `onChange` property will be removed in a future version (during summer 2024).");
  }

  if(style) {
    console.warn("Input component's `style` property is deprecated. Use `className` property or React`s native `styles` property instead. `style` property will be removed in a future version (during summer 2024).");
  }

  if(error) {
    console.warn("Input component's `error` property is deprecated. Use `i18n_input_errorMessage` property instead. `error` property will be removed in a future version (during summer 2024).");
  }

  if(helpText) {
    console.warn("Input component's `helpText` property is deprecated. Use `i18n_input_helpText` property instead. `helpText` property will be removed in a future version (during summer 2024).");
  }

  if(optionalText) {
    console.warn("Input component's `optionalText` property is deprecated. Use `i18n_input_optionalText` property instead. `optionalText` property will be removed in a future version (during summer 2024).");
  }

  if(textareaRows) {
    console.warn("Input component's `textareaRows` property is deprecated. Use `rows` property instead. `textareaRows` property will be removed in a future version (during summer 2024).");
  }

  const passwordToggleRef = useRef(null);
  const htmlId = useIdWithFallback('dsInput', id);
  const isControlled = controlled || value !== '';
  let currentValue = isControlled ? value || '' : defaultValue || '';
  const [disabledState, setDisabledState] = useState(disabled);
  const [readonlyState, setReadonlyState] = useState(readonly);
  const [errorMessage, setErrorMessage] = useState(i18n_input_errorMessage);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [touchedState, setTouchedState] = useState(touched);
  const [changedState, setChangedState] = useState(false);
  const [currentRows, setCurrentRows] = useState(rows || textareaRows || minrows);
  const [showClearButton, setShowClearButton] = useState(clear ? (defaultValue || value ? true : false) : false);

  // Functions visible to outside.
  useImperativeHandle(ref, () => ({
    value: (newValue) => {
      const inputComponent = document.getElementById(htmlId);
      if (newValue) {
        inputComponent.value = newValue;
        currentValue = newValue;
        if (onValueChange) {
          onValueChange(newValue);
        }
        if (onChange) {
          onChange(newValue);
        }
      }
      else {
        currentValue = null;
        inputComponent.value = null;
        if (onValueChange) {
          onValueChange(null);
        }
        if (onChange) {
          onChange(null);
        }
      }
    },

    getValue: () => {
      if(isControlled){
        return currentValue;
      }

      const inputComponent = document.getElementById(htmlId);
      return inputComponent.value;
    },

    // eslint-disable-next-line consistent-return
    focus: (state) => {
      const inputComponent = document.getElementById(htmlId);

      if(state === true) {
        inputComponent.focus();
      }
      else if ( state === false) {
        inputComponent.blur();
      }
      else {
        return document.activeElement === inputComponent;
      }
    },

    // eslint-disable-next-line consistent-return
    touched: (state) => {
      if(state === true || state === false){
        setTouchedState(state);
      }
      else {
        return touched;
      }
    },

    // eslint-disable-next-line consistent-return
    changed: (state) => {
      if(state === true || state === false){
        setChangedState(state);
      }
      else {
        return changedState;
      }
    },

    // eslint-disable-next-line consistent-return
    disabled: (state) => {
      if(state === true || state === false){
        setDisabledState(state);
      }
      else {
        return disabledState;
      }
    },

    // eslint-disable-next-line consistent-return
    readonly: (state) => {
      if(state === true || state === false) {
        setReadonlyState(state);
      }
      else {
        return readonlyState;
      }
    },

    // eslint-disable-next-line consistent-return
    errorMessage: (message) => {
      if(typeof(message) === 'string') {
        setErrorMessage(message);
      }
      else {
        return errorMessage;
      }
    },
  }));

  const handleValueChange = (e) => {
    if (type === 'textarea') {
      const textarea = e.target;
      const rowsNow = Math.floor((textarea.scrollHeight - TEXTAREA_VERTICAL_PADDING) / TEXTAREA_LINE_HEIGHT);
      let newRows = minrows || rows || textareaRows || 2;

      if (rowsNow >= maxrows) {
        newRows = maxrows - 1;
        textarea.scrollTop = textarea.scrollHeight - TEXTAREA_VERTICAL_PADDING;
      }
      else if (rowsNow <= minrows) {
        newRows = minrows;
      }
      else if (rowsNow === currentRows) {
        newRows = rowsNow - 1;
      }
      else {
        newRows = rowsNow;
      }

      setCurrentRows(newRows);

      // const previousRows = textarea.rows;
      // textarea.rows = minrows - 1;

      // const rowsNow = Math.floor((textarea.scrollHeight - TEXTAREA_VERTICAL_PADDING) / TEXTAREA_LINE_HEIGHT);

      // // if (rowsNow === previousRows) {
      // //   textarea.rows = rowsNow - 1;
      // // }

      // if (rowsNow >= maxrows) {
      //   textarea.rows = maxrows - 1;
      //   textarea.scrollTop = textarea.scrollHeight - TEXTAREA_VERTICAL_PADDING;
      // }

      // setCurrentRows(rowsNow <= maxrows ? rowsNow : maxrows);
    }

    if (type === 'select' && multiple) {
      const { options } = e.target;
      const selections = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          selections.push(options[i].value);
        }
      }

      currentValue = selections;
    }

    setTouchedState(true);
    setChangedState(true);

    if (!isControlled){
      currentValue = e.target.value;
    }

    if(e.target.value) {
      setShowClearButton(true);
    }
    else {
      setShowClearButton(false);
    }

    if (onValueChange) {
      onValueChange(e);
    }
    if (onChange) {
      onChange(e);
    }
  }

  const handlePasswordToggle = (e) => {
    if(!e.key || (e.key && e.key === KEY_RETURN)){
      if(!isControlled){
        const inputComponent = document.getElementById(htmlId);
        inputComponent.setAttribute('type', passwordVisible ? "text" : "password");
      }

      setPasswordVisible(!passwordVisible);
      if(onPasswordToggle) {
        onPasswordToggle(e);
      }
    }
  }

  const handleInputClear = (e) => {
    if(!e.key || (e.key && e.key === KEY_RETURN)){
      const inputComponent = document.getElementById(htmlId);
      if(!isControlled){    
        inputComponent.value = '';
        inputComponent.focus();
      }
      currentValue = '';
      inputComponent.value = '';
      setShowClearButton(false);
      onClearInput(e);
    }
  }

  const handleInputClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  }

  const handleInputKeyDown = (e) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
  }

  const handleInputKeyUp = (e) => {
    if (onKeyUp) {
      onKeyUp(e);
    }
  }

  const handleInputFocus = (e) => {
    if (onFocus) {
      onFocus(e);
    }
  }

  const handleInputBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
  }

  useEffect(() => {
    const inputComponent = document.getElementById(htmlId);
    inputComponent.value = currentValue;
  }, [currentValue]);

  useEffect(() => {
    setErrorMessage(i18n_input_errorMessage);
  }, [i18n_input_errorMessage]);

  useEffect(() => {
    setDisabledState(disabled);
  }, [disabled]);

  useEffect(() => {
    setReadonlyState(readonly);
  }, [readonly]);

  useEffect(() => {
    passwordToggleRef.current?.addEventListener('click', handlePasswordToggle);
    passwordToggleRef.current?.addEventListener('keydown', handlePasswordToggle);

    return () => {
      passwordToggleRef.current?.removeEventListener('click', handlePasswordToggle);
      passwordToggleRef.current?.removeEventListener('keydown', handlePasswordToggle);
    };
  });

  let optionalIndicator = null;
  if (optional === true) {
    optionalIndicator = i18n_input_optionalText || optionalText;
  }

  let inputLabel = null;
  if (label) {
    const LabelComponent = require('../Label').default; // eslint-disable-line global-require
    inputLabel = <LabelComponent id={`${htmlId}Label`} labelFor={`${htmlId}`}>{label}</LabelComponent>;
  }

  let inputInfo = null;
  if (i18n_input_infoText) {
    const PopoverComponent = require('../Popover').default; // eslint-disable-line global-require
    const IconInformationRegularComponent = require('../Icon/lib/IconInformationRegular').default; // eslint-disable-line global-require
    inputInfo = <PopoverComponent triggerElement={<IconInformationRegularComponent />} placement="top">{i18n_input_infoText}</PopoverComponent>
  }

  let inputError = null;
  if (errorMessage) {
    const ErrorComponent = require('../InputError').default; // eslint-disable-line global-require
    inputError = <ErrorComponent className={styles.input__errormessage} id={`${htmlId}Error`}>{errorMessage}</ErrorComponent>;
  }

  let inputHelp = null;
  if (i18n_input_helpText) {
    const HelpComponent = require('../InputHelp').default; // eslint-disable-line global-require
    inputHelp = <HelpComponent className={styles.input__helptext} id={`${htmlId}Help`}>{i18n_input_helpText}</HelpComponent>;
  }

  let searchIcon = null;
  if (type === 'search') {
    const IconSearchRegularComponent = require('../Icon/lib/IconSearchRegular').default; // eslint-disable-line global-require
    searchIcon = <div className={styles[`input--inputarea-icon`]}><IconSearchRegularComponent aria-hidden="true" /></div>;
  }

  let inputIcon = null;
  if (icon && type !== 'search' && type !== 'password' && type !== 'textarea') {
    inputIcon = <div className={styles[`input--inputarea-icon`]}>{icon}</div>;
  }

  let inputClear = null;
  if (clear) {
    const IconCloseFilledComponent = require('../Icon/lib/IconCloseFilled').default; // eslint-disable-line global-require
    inputClear = <IconCloseFilledComponent size="s" />;
  }

  let passwordShowIcon = null;
  let passwordHideIcon = null;
  if (type === 'password') {
    const IconViewOffFilledComponent = require('../Icon/lib/IconViewOffFilled').default; // eslint-disable-line global-require
    const IconViewFilledComponent = require('../Icon/lib/IconViewFilled').default; // eslint-disable-line global-require
    passwordHideIcon = <IconViewOffFilledComponent title={i18n_input_hidePasswordTitle} />;
    passwordShowIcon = <IconViewFilledComponent title={i18n_input_showPasswordTitle} />;
  }

  let inputType = type;
  if (type === 'password') {
    if (passwordVisible) {
      inputType = 'text';
    }
  }

  const classes = classNames([
    styles.input,
    styles[`input-${type}`],
    (type !== 'password' && icon) || type === 'search' ? styles[`input-withicon`] : null,
    errorMessage || i18n_input_errorMessage !== '' || error !== '' ? styles[`input-error`] : null,
    disabledState ? styles[`input-disabled`] : null,
    readonlyState && READONLY_INPUT_TYPES.includes(type) ? styles[`input-readonly`] : null,
    className || null
  ]);

  const inputClasses = classNames([
    type === 'textarea' || type === 'select' ? styles[`input--inputarea-${type}`] : styles[`input--inputarea-input`],
    touchedState ? styles[`input--inputarea-${type === 'textarea' || type === 'select' ? type : 'input'}__touched`] : null,
    changedState ? styles[`input--inputarea-${type === 'textarea' || type === 'select' ? type : 'input'}__changed`] : null,
    optional ? styles[`input--inputarea-${type === 'textarea' || type === 'select' ? type : 'input'}__optional`] : null,
  ]);

  let valueProp = null;
  if(isControlled) {
    valueProp = {value: currentValue ? currentValue || '' : value || ''};
  }
  else {
    valueProp = {defaultValue: defaultValue || currentValue || null};
  }

  return (
    <div id={`${htmlId}-wrapper`} key={htmlId} className={classes} role="group" style={style} {...otherProps}>
      { inputLabel ?
        <div className={styles['input--labelarea']}>
          {inputLabel} {optionalIndicator} {inputInfo}
        </div>
      : null }

      <div className={styles[`input--inputarea`]}>
        {inputIcon}
        {searchIcon}

        {type === 'select' ? (
          <>
            <select
              aria-activedescendant={ariaActivedescendant}
              aria-describedby={i18n_input_helpText || helpText ? `${htmlId}Help` : null}
              aria-errormessage={`${htmlId}Error`}
              aria-expanded={ariaExpanded}
              aria-invalid={ariaInvalid !== 'false' ? ariaInvalid : null}
              aria-label={ariaLabel}
              aria-owns={ariaOwns}
              autoComplete={autocomplete}
              autoFocus={autofocus} // eslint-disable-line jsx-a11y/no-autofocus
              className={inputClasses}
              disabled={disabledState}
              form={form}
              id={htmlId}
              multiple={multiple}
              name={name}
              onChange={handleValueChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onClick={handleInputClick}
              onKeyUp={handleInputKeyUp}
              onKeyDown={handleInputKeyDown}
              ref={inputRef}
              required={!optional && REQUIRED_INPUT_TYPES.includes(type) ? !optional : null}
              size={size}
              {...valueProp}
            >
              {children}
            </select>
            <div className={styles[`input--select-chevron`]} />
          </>
        ) : null}
        {type === 'textarea' ? (
          <textarea
            aria-activedescendant={ariaActivedescendant}
            aria-autocomplete={ariaAutocomplete}
            aria-describedby={i18n_input_helpText || helpText ? `${htmlId}Help` : null}
            aria-errormessage={`${htmlId}Error`}
            aria-haspopup={ariaHaspopup}
            aria-invalid={ariaInvalid}
            aria-label={ariaLabel}
            aria-owns={ariaOwns}
            autoComplete={autocomplete}
            autoFocus={autofocus} // eslint-disable-line jsx-a11y/no-autofocus
            className={inputClasses}
            disabled={disabledState}
            form={form}
            id={htmlId}
            inputMode={inputmode}
            maxLength={['text', 'search', 'url', 'tel', 'email', 'password'].includes(type) ? maxlength : null}
            minLength={['text', 'search', 'url', 'tel', 'email', 'password'].includes(type) ? minlength : null}
            name={name}
            onChange={handleValueChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onClick={handleInputClick}
            onKeyUp={handleInputKeyUp}
            onKeyDown={handleInputKeyDown}
            placeholder={placeholder}
            readOnly={readonlyState && READONLY_INPUT_TYPES.includes(type) ? readonlyState : null}
            ref={inputRef}
            required={!optional && REQUIRED_INPUT_TYPES.includes(type) ? !optional : null}
            rows={currentRows}
            {...valueProp}
          />
        ) : null}
        {type !== 'select' && type !== 'textarea' ? (
          <input
            aria-activedescendant={ariaActivedescendant}
            aria-autocomplete={ariaAutocomplete}
            aria-describedby={i18n_input_helpText || helpText ? `${htmlId}Help` : null}
            aria-errormessage={`${htmlId}Error`}
            aria-expanded={ariaExpanded}
            aria-haspopup={ariaHaspopup}
            aria-invalid={ariaInvalid}
            aria-label={ariaLabel}
            aria-owns={ariaOwns}
            autoComplete={autocomplete}
            autoFocus={autofocus} // eslint-disable-line jsx-a11y/no-autofocus
            className={inputClasses}
            dirname={type === 'search' || type === 'text' ? dirName : null} // eslint-disable-line react/no-unknown-property
            disabled={disabledState}
            form={form}
            id={htmlId}
            inputMode={inputmode}
            max={NUMERIC_INPUT_TYPES.includes(type) ? max : null}
            maxLength={['text', 'search', 'url', 'tel', 'email', 'password'].includes(type) ? maxlength : null}
            min={NUMERIC_INPUT_TYPES.includes(type) ? min : null}
            minLength={['text', 'search', 'url', 'tel', 'email', 'password'].includes(type) ? minlength : null}
            multiple={type === 'email' || type === 'select' ? multiple : null}
            name={name}
            onChange={handleValueChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onClick={handleInputClick}
            onKeyUp={handleInputKeyUp}
            onKeyDown={handleInputKeyDown}
            pattern={['password', 'text', 'tel'].includes(type) ? pattern : null}
            placeholder={['number', 'password', 'search', 'tel', 'text', 'url', 'email'].includes(type) ? placeholder : null}
            readOnly={readonlyState && READONLY_INPUT_TYPES.includes(type) ? readonlyState : null}
            ref={inputRef}
            required={!optional && REQUIRED_INPUT_TYPES.includes(type) ? !optional : null}
            role={role}
            step={NUMERIC_INPUT_TYPES.includes(type) ? step : null}
            type={inputType}
            {...valueProp}
          />
        ) : null}


        {type !== 'password' && inputClear && showClearButton && !disabledState && !readonlyState ? (
          <button
            type="button"
            id={`${htmlId}InputClearButton`}
            className={styles[`input--inputarea-clear`]}
            title={i18n_input_clearFieldTitle}
            onClick={handleInputClear}
            // tabIndex="0"
          >
            {inputClear}
          </button>
        ) : null}
        {type === 'password' && !disabledState && !readonlyState ? (
          <button
            type="button"
            ref={passwordToggleRef}
            id={`${htmlId}ClearInputPasswordToggleButton`}
            className={styles[`input--inputarea-viewbutton`]}
            // tabIndex="0"
            aria-label={passwordVisible ? i18n_input_hidePasswordTitle : i18n_input_showPasswordTitle}
          >
            {passwordVisible ? passwordHideIcon : passwordShowIcon}
          </button>
        ) : null}
      </div>

      {inputError}
      {inputHelp}
    </div>
  );
};

const Input = React.forwardRef(InputInternal);
export default Input;

Input.propTypes = {
  /**
   * aria-activedescendant attribute of the input box
   */
  ariaActivedescendant: PropTypes.string,
  /**
   * aria-autocomplete attribute of the input box
   */
  ariaAutocomplete: PropTypes.oneOf(ARIA_AUTOCOMPLETE_VALUES),
  /**
   * aria-expanded attribute of the input box
   */
  ariaExpanded: PropTypes.bool,
  /**
   * aria-haspopup attribute of the input box
   */
  ariaHaspopup: PropTypes.bool,
  /**
   * aria-invalid attribute of the input box
   */
  ariaInvalid: PropTypes.oneOf(['true', 'false', 'grammar', 'spelling']),
  /**
   * Input's aria-label
   */
  ariaLabel: PropTypes.string,
  /**
   * aria-owns attribute of the input box
   */
  ariaOwns: PropTypes.string,
  /**
   * Hint for form autofill feature
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefautocomplete)
   */
  autocomplete: PropTypes.string,
  /**
   * Automatically focus the form control when the page is loaded
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefautofocus)
   */
  autofocus: PropTypes.bool,
  /**
   * Component's options. Automatically detected.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  /**
   * Class names you want to give to the component
   */
  className: PropTypes.string,
  /**
   * Flag for showing a clear control
   */
  clear: PropTypes.bool,
  /**
   * Forces the component to be controlled of uncontrolled.
   * If not specified, the component tries to deduct this from given props (controlled component has value prop while uncontrolled doesn't).
   */
  controlled: PropTypes.bool,
  /**
   * Default value of the input. Use this to store the original value if needed.
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * Name of form field to use for sending the element's directionality in form submission
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefdirname)
   */
  dirName: PropTypes.string,
  /**
   * Whether the form control is disabled
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefdisabled)
   */
  disabled: PropTypes.bool,
  /**
   * Associates the control with a form element
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefform)
   */
  form: PropTypes.string,
  /**
   * Title of clear button in field
   */
  i18n_input_clearFieldTitle: PropTypes.string,
  /**
   * Error message for the field
   */
  i18n_input_errorMessage: PropTypes.string,
  /**
   * Always visible help text for the field
   */
  i18n_input_helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Title of hide password button in field
   */
  i18n_input_hidePasswordTitle: PropTypes.string,
  /**
   * Additional information and instructions for the field
   */
  i18n_input_infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Optional field indicator text
   */
  i18n_input_optionalText: PropTypes.string,
  /**
   * Title of show password button in field
   */
  i18n_input_showPasswordTitle: PropTypes.string,
  /**
   * Icon for the input field as an Icon component
   */
  icon: PropTypes.node,
  /**
   * Element's id. Input element will get the raw version of this property.
   */
  id: PropTypes.string,
  /**
   * Input's inputmode
   */
  inputmode: PropTypes.oneOf(['none', 'text', 'decimal', 'numeric', 'tel', 'search', 'email', 'url']),
  /**
   * Label text for the input
   */
  label: PropTypes.string,
  /**
   * Maximum value
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefmax)
   */
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Maximum length (number of characters) of `value`
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefmaxlength)
   */
  maxlength: PropTypes.number,
  /**
   * Maximum number of rows in textarea
   */
  maxrows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Minimum value
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefmin)
   */
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Minimum length (number of characters) of `value`
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefminlength)
   */
  minlength: PropTypes.number,
  /**
   * Minimum number of rows in textarea
   */
  minrows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Whether to allow multiple values
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefmultiple)
   */
  multiple: PropTypes.bool,
  /**
   * Name of the form control. Submitted with the form as part of a name/value pair.
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefname)
   */
  name: PropTypes.string,
  /**
   * Function that runs when user moves focus out of field
   */
  onBlur: PropTypes.func,
  /**
   * @deprecated Use `onValueChange` property instead. `onChange` property will be removed in a future version (during summer 2024).
   */
  onChange: PropTypes.func,
  /**
   * Callback for clear control
   */
  onClearInput: PropTypes.func,
  /**
   * Function that runs when user clicks on field
   */
  onClick: PropTypes.func,
  /**
   * Function that runs when user moves focus into field
   */
  onFocus: PropTypes.func,
  /**
   * Function that runs when user presses down key
   */
  onKeyDown: PropTypes.func,
  /**
   * Function that runs when user presses up key
   */
  onKeyUp: PropTypes.func,
  /**
   * Function that runs when user toggles password's visibility
   */
  onPasswordToggle: PropTypes.func,
  /**
   * Function that runs when user changes field's value
   */
  onValueChange: PropTypes.func,
  /**
   * Whether field is optional or not. By default all fields are mandatory.
   */
  optional: PropTypes.bool,
  /**
   * Regex pattern the `value` must match to be valid
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefpattern)
   */
  pattern: PropTypes.string,
  /**
   * Text that appears in the form control when it has no value set
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefplaceholder)
   */
  placeholder: PropTypes.string,
  /**
   * The value is not editable
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefreadonly)
   */
  readonly: PropTypes.bool,
  /**
   * role of the input box
   */
  role: PropTypes.string,
  /**
   * Initial number of rows in textarea.
   * If you want text area to be always the same size, set minrows and maxrows to same value with each others
   */
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * If the control is presented as a scrolling list box (e.g. when multiple is specified), this attribute represents the number of rows in the list that should be visible at one time.
   */
  size: PropTypes.number,
  /**
   * Incremental values that are valid.
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefstep)
   */
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Whether a user has visited the input field or not
   */
  touched: PropTypes.bool,
  /**
   * Type of the input
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdeftype)
   */
  type: PropTypes.oneOf(INPUT_TYPES),
  /**
   * Current value of the form control. Submitted with the form as part of a name/value pair.
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefvalue)
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * Ref for the actual input element, not the wrapper
   */
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.element })]),
  /**
   * @deprecated Use `className` property or React`s native `styles` property instead. `style` property will be removed in a future version (during summer 2024).
   */
  style: PropTypes.oneOf([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * @deprecated Use `i18n_input_errorMessage` property instead. `error` property will be removed in a future version (during summer 2024).
   */
  error: PropTypes.node,
  /**
   * @deprecated Use `i18n_input_helpText` property instead. `helpText` property will be removed in a future version (during summer 2024).
   */
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * @deprecated Use `i18n_input_optionalText` property instead. `optionalText` property will be removed in a future version (during summer 2024).
   */
  optionalText: PropTypes.string,
  /**
   * @deprecated Use `rows` property instead. `textareaRows` property will be removed in a future version (during summer 2024).
   */
  textareaRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
