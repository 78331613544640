// @flow strict-local

import React, { type Element } from 'react';
import Input from '@design-system/component-library/src/components/Input';
import Radio from '@design-system/component-library/src/components/Radio';
import { useTranslation } from 'react-i18next';
import type { CallForwardingFilterTypeT } from '../../../../ducks/entities/callForwarding/callForwardingTypes';
import { PHONE_NUMBER_MAX_LENGTH } from '../../../../fieldValidators';

import styles from './FilterSelection.module.scss';

type OwnPropsT = {|
  handleChange: ({ filter?: ?CallForwardingFilterTypeT, specificCaller?: ?string }) => void,
  filter: CallForwardingFilterTypeT,
  specificCaller: string,
  errorMessage: string
|};

export type PropsT = OwnPropsT;

export const FilterSelection = ({
  handleChange,
  filter,
  specificCaller,
  errorMessage
}: PropsT): Element<'div'> => {
  const { t } = useTranslation();

  return (
    <div data-cy="filter-selection">
      <div className={styles.text}>{t('forwardingDetails.filters.title')}</div>
      {errorMessage && (
        <div data-cy="filter-selection-error" className={styles['specific-validation-error']}>
          {errorMessage}
        </div>
      )}
      <div>
        <Radio.Group value={filter}>
          <Radio
            id="forwarding-details-filter-option-1"
            name="filter-option"
            onChange={() => handleChange({ filter: 'ALL_CALLS', specificCaller: '' })}
            className={styles.radio}
            label={t('forwardingDetails.filters.allCalls')}
            value="ALL_CALLS"
          />
          <Radio
            id="forwarding-details-filter-option-2"
            name="filter-option"
            onChange={() => handleChange({ filter: 'INTERNAL_CALLER', specificCaller: '' })}
            className={styles.radio}
            label={t('forwardingDetails.filters.internalCaller')}
            value="INTERNAL_CALLER"
          />
          <Radio
            id="forwarding-details-filter-option-3"
            name="filter-option"
            onChange={() => handleChange({ filter: 'EXTERNAL_CALLER', specificCaller: '' })}
            className={styles.radio}
            label={t('forwardingDetails.filters.externalCaller')}
            value="EXTERNAL_CALLER"
          />
          <Radio
            id="forwarding-details-filter-option-4"
            name="filter-option"
            onChange={() => handleChange({ filter: 'ANONYMOUS_CALLER', specificCaller: '' })}
            className={styles.radio}
            label={t('forwardingDetails.filters.anonymousCaller')}
            value="ANONYMOUS_CALLER"
          />
          <Radio
            id="forwarding-details-filter-option-5"
            name="filter-option"
            onChange={() => handleChange({ filter: 'SPECIFIC_CALLER', specificCaller: '' })}
            className={styles.radio}
            label={t('forwardingDetails.filters.specificCaller')}
            value="SPECIFIC_CALLER"
          />
          {filter === 'SPECIFIC_CALLER' && (
            <div>
              <Input
                id="forwarding-details-filter-input"
                data-cy="input"
                className={styles['caller-input']}
                onValueChange={event => handleChange({ specificCaller: event.target.value })}
                defaultValue={specificCaller}
                maxlength={PHONE_NUMBER_MAX_LENGTH}
              />
            </div>
          )}
        </Radio.Group>
      </div>
    </div>
  );
};

export default FilterSelection;
