/* eslint-disable max-classes-per-file */
import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { classNames } from '../../utils/css';
import { useIdWithFallback } from '../../utils/hooks';

import styles from './TabNavigation.module.scss';
import TabNavigationTab from './TabNavigationTab';
import IconArrowLeftFilled from '../Icon/lib/IconArrowLeftFilled';
import IconArrowRightFilled from '../Icon/lib/IconArrowRightFilled';

import { KEY_LEFT, KEY_RIGHT, KEY_RETURN } from '../../utils/keycodes';

function TabNavigationInternal(
  {
    id = null,
    className = null,
    children = null,
    selected = null,
    ...otherProps
  },
  ref
) {
  const tabNavigationRef = useRef(null);
  const containerRef = useRef(null);
  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);
  const htmlId = useIdWithFallback('dsTabNavigation', id);

  let selectedFromChildren = selected;
  const tabIds = [];
  // if(!selected) {
    let tabCount = 0;
    children.forEach(child => {
      const thisTabId = child.props.id || `${htmlId}tab-button-${tabCount}`;
      tabIds.push(thisTabId);
      if(!selected && child.props.selected === true) {
        selectedFromChildren = thisTabId;
      }
      tabCount += 1;
    });
  // }

  const [selectedTab, setSelectedTab] = useState(selectedFromChildren);
  const [hideButtons, setHideButtons] = useState(true);
  const [isNextHidden, setIsNextHidden] = useState(false);
  const [isPrevHidden, setIsPrevHidden] = useState(true);
  const [currentElem, setCurrentElem] = useState(0);
  const [itemWidths, setItemWidths] = useState([]);

  useImperativeHandle(ref, () => ({
    selected: (tab) => {
      if (tab) {
        setSelectedTab(tab);
      }
      else {
        return selectedTab;
      }
    }
  }));

  useEffect(() => {
    containerRef.current.addEventListener(
      'scroll',
      () => {
        setIsNextHidden(!(containerRef.current.scrollLeft + containerRef.current.clientWidth < containerRef.current.scrollWidth));
        setIsPrevHidden(containerRef.current.scrollLeft <= 0);
      },
      { capture: false, once: false, passive: false }
    );
  }, []);


  useEffect(() => {
    const elemWidths = [];

    containerRef.current.childNodes.forEach((el) => {
      elemWidths.push(el.clientWidth);
    });

    setItemWidths(elemWidths);
    setHideButtons(containerRef.current.scrollWidth <= containerRef.current.clientWidth); // hide prev/next buttons if there is nothing to scroll,
  }, []);

  useEffect(() => {
    if(selected) {
      setSelectedTab(selected);
    }
  }, [selected]);

  const onSelect = (tabId) => {
    setSelectedTab(tabId);
    return false;
  }

  const next = () => {
    const nextTab = itemWidths.slice(0, currentElem + 1).reduce((sum, v) => sum + v, 0);
    const scrollLeftMax = containerRef.current.scrollWidth;
    const current = currentElem;

    setCurrentElem(containerRef.current.scrollLeft + containerRef.current.clientWidth < containerRef.current.scrollWidth ? current + 1 : itemWidths.length - 1)
    containerRef.current.scrollTo({ left: nextTab < scrollLeftMax ? nextTab : scrollLeftMax, top: 0, behavior: 'smooth' });
  }

  const prev = () => {
    const prevTab = itemWidths.slice(0, currentElem - 1).reduce((sum, v) => sum + v, 0);
    setCurrentElem(prevTab > 0 ? currentElem - 1 : 0);
    containerRef.current.scrollTo({ left: prevTab > 0 ? prevTab : 0, top: 0, behavior: 'smooth' });
  }

  const changeTab = (evt) => {
    const tabButtonId = `${htmlId}tab-button-`;

    let tabFocus = 0;
    if (tabIds.indexOf(selectedTab) > -1) {
      tabFocus = tabIds.indexOf(selectedTab);
    }
    else {
      tabFocus = Number(selectedTab.match(/button-\d+/g)[0].match(/\d+/g)[0]);
    }

    if (evt.key === KEY_RIGHT || evt.key === KEY_LEFT) {
      const selTab = selectedTab || `#${tabButtonId}${tabFocus}`;
      document.querySelector(`#${selTab}`).setAttribute('tabindex', -1);

      if (evt.key === KEY_RIGHT) {
        tabFocus += 1;
        if (tabFocus >= children.length) {
          tabFocus = 0;
        }
      } else if (evt.key === KEY_LEFT) {
        tabFocus -= 1;
        if (tabFocus < 0) {
          tabFocus = children.length - 1;
        }
      }

      let tabToFocusId = `${tabButtonId}${tabFocus}`;
      if(children[tabFocus].props.id) {
        tabToFocusId = children[tabFocus].props.id;
      }

      document.querySelector(`#${tabToFocusId}`).focus();
      document.querySelector(`#${tabToFocusId}`).setAttribute('tabindex', 0);

      setSelectedTab(tabToFocusId)
    }
  }

  const renderTabs = () => {
    const tabButtonId = `${htmlId}tab-button-`;

    return children.map((tab, count) => (
      <button
        className={classNames([styles['tab-item'], selectedTab === `${tabButtonId}${count}` || selectedTab === tab.props.id  ? styles['tab-item__selected'] : null])}
        id={tab.props.id || `${tabButtonId}${count}`}
        role="tab"
        type="button"
        aria-controls={tab.props.id || `${tabButtonId}${count}`}
        aria-selected={selectedTab === tab.props.id || selectedTab === `${tabButtonId}${count}`}
        onClick={() => {
          const iidee = tab.props.id || `${tabButtonId}${count}`;
          onSelect(iidee);
          if (tab.props.onClick) tab.props.onClick(tab);
        }}
        onSelect={tab.props.onSelect}
        key={`${tabButtonId}${count}`} // eslint-disable-line react/no-array-index-key
        aria-label={tab.props["aria-label"] || tab.props.title}
        tabIndex={selectedTab === tab.props.id || selectedTab === `${tabButtonId}${count}` ? 0 : -1}
      >
        {tab.props.title}
      </button>
    ));
  }

  const renderTabContent = () => {
    const tabButtonId = `${htmlId}tab-button-`;
    const tabPanelId = `${htmlId}tab-panel-`;

    return children.map((content, count) => (
      <div
        id={content.props.id || `${tabPanelId}${count}`}
        aria-labelledby={content.props.id || `${tabButtonId}${count}`}
        aria-hidden={selectedTab !== `${tabButtonId}${count}` && selectedTab !== content.props.id}
        role="tabpanel"
        hidden={selectedTab !== `${tabButtonId}${count}` && selectedTab !== content.props.id}
        key={`${htmlId}tabcontent${count}`} // eslint-disable-line react/no-array-index-key
      >
        {content.props.children}
      </div>
    ));
  }

  const allClasses = classNames([styles.tabnavigation, className || null]);

  return (
    <div ref={tabNavigationRef} className={allClasses} id={htmlId} {...otherProps}>
      <div className={styles['app-carousel--navigation']}>
        <span
          id={`${htmlId}ButtonPrev`}
          className={styles['btn-prev']}
          ref={prevButtonRef}
          role="button"
          tabIndex="0"
          onClick={() => prev()}
          hidden={isPrevHidden || hideButtons}
          onKeyDown={(e) => {
            if (e.key === KEY_RETURN) {
              prev();
            }
          }}
        >
          <IconArrowLeftFilled />
        </span>
        {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
        <div
          role="tablist"
          className={styles['tabnavigation-tab-container']}
          data-tabs
          ref={containerRef}
          onKeyDown={(e) => changeTab(e)}
        >
          {renderTabs()}
        </div>
        <span
          id={`${htmlId}ButtonNext`}
          className={styles['btn-next']}
          ref={nextButtonRef}
          role="button"
          tabIndex="0"
          onClick={() => next()}
          hidden={isNextHidden || hideButtons}
          onKeyDown={(e) => {
            if (e.key === KEY_RETURN) {
              next();
            }
          }}
        >
          <IconArrowRightFilled />
        </span>
      </div>
      {renderTabContent(children)}
    </div>
  );
}

const TabNavigation = React.forwardRef(TabNavigationInternal);
TabNavigation.Tab = TabNavigationTab;
export {TabNavigationTab};
export default TabNavigation;

TabNavigation.propTypes = {
  /** Element's id */
  id: PropTypes.string,
  /** Class names the user can give to the component */
  className: PropTypes.string,
  /** Content of the tab */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  /** Indicated selected tab */
  selected: PropTypes.string,
};
