
import React from 'react';

import { ReactComponent as IconSource } from './iconFiles/file-zip--regular.svg';
import IconContainer from '../IconContainer';

export default function IconFileZipRegular(props) {
  return (
    <IconContainer
      {...props}
      source={IconSource}
    />
  );
}
  