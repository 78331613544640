import React from 'react';
import PropTypes from 'prop-types';
import Link from './Link';

function LinkList({ links, titleLink, ariaLabelledBy, handleClick }) {
  const firstItem = titleLink ? { title: titleLink.title, url: titleLink.url } : null;
  return (
    <ul aria-labelledby={ariaLabelledBy}>
      {titleLink && <Link page={firstItem} />}
      {links.map((page) => (
        <Link key={page.title} page={page} handleClick={handleClick} />
      ))}
    </ul>
  );
}

LinkList.propTypes = {
  handleClick: PropTypes.func,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string,
    })
  ),
  titleLink: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string.isRequired,
  }),
  ariaLabelledBy: PropTypes.string,
};

LinkList.defaultProps = {
  handleClick: () => {},
  links: [],
  titleLink: null,
  ariaLabelledBy: null,
};

export default LinkList;
