export default {};


/**
 * Simple implementation of classNames, doesn't support string+boolean
 *   key-value-pairs.
 * @param {(false|null|string|undefined)[]} classes Array of class names or something null-ish
 * @returns {string} Found class names joined with whitespace.
 */
export function classNames(classes) {
  return classes.filter(Boolean).join(' ');
}

export const FOCUSABLE_SELECTOR = `\
a[href]:not([tabindex^="-"]),\
area[href]:not([tabindex^="-"]),\
audio[controls]:not([tabindex^="-"]),\
button:not([disabled]):not([tabindex^="-"]),\
iframe:not([tabindex^="-"]),\
input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"]),\
input[type="radio"]:not([disabled]):not([tabindex^="-"]),\
select:not([disabled]):not([tabindex^="-"]),\
textarea:not([disabled]):not([tabindex^="-"]),\
video[controls]:not([tabindex^="-"]),\
[contenteditable]:not([tabindex^="-"]),\
[tabindex]:not([tabindex^="-"])`;

// Breakpoints from /src/globals/scss/_variables.scss
export const BREAKPOINT_XS = 0;
export const BREAKPOINT_S = 481;
export const BREAKPOINT_M = 641;
export const BREAKPOINT_L = 961;
export const BREAKPOINT_XL = 1400;

export const BREAKPOINT_XS_MAX_WIDTH = BREAKPOINT_S - 1;
export const BREAKPOINT_S_MAX_WIDTH = BREAKPOINT_M - 1;
export const BREAKPOINT_M_MAX_WIDTH = BREAKPOINT_L - 1;
export const BREAKPOINT_L_MAX_WIDTH = BREAKPOINT_XL - 1;
export const BREAKPOINT_XL_MAX_WIDTH = BREAKPOINT_XL;
