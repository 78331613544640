import React from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import IconCheckRegular from '../Icon/lib/IconCheckRegular';

import { classNames } from '../../utils/css';

import styles from './List.module.scss';

class List extends React.Component {
  constructor(props) {
    super(props);

    this.htmlId = props.id || nextId(`ds${this.constructor.name}`);

    if(this.props.type) {
      console.warn("List component's `type` property is deprecated. Use `listType` instead. `type` property will be removed in a future version (during summer 2024).");
    }
  }

  render() {
    const { id, className, children, items, size, listType, type, ...otherProps } = this.props;

    let typeOfList = listType;
    if (type) {
      if (type === 'unordered' || type === 'ordered') {
        typeOfList = type;
      } else if (type === 'big_ordered') {
        typeOfList = 'ordered';
      } else if (type === 'checked') {
        typeOfList = 'icon';
      } else if (type === 'selectable') {
        typeOfList = 'unordered';
      } else {
        typeOfList = 'unordered';
      }
    }

    const allClasses = classNames([styles.list, styles[`list--${typeOfList}`], size ? styles[`list--size-${size}`] : null, className || null]);

    let itemList = null;
    if (items != null) {
      itemList = items.map((item) => <li>{item}</li>);
    }

    let childList = null;
    if (typeOfList === 'icon' && type !== 'checked') {
      childList = React.Children.map(children || null, (child) => {
        const { props } = child;
        const { icon } = props;

        let iconComponent = null;
        if (typeof icon === 'object') {
          const iconAllClasses = classNames([styles.list__icon, icon.props.className]);
          iconComponent = React.cloneElement(icon, {
            className: iconAllClasses,
            size: 's',
          });
        }

        return (
          <li key={nextId('dsListItem')}>
            {iconComponent}
            {props.children}
          </li>
        );
      });
    } else if (typeOfList === 'icon' && type === 'checked') {
      childList = React.Children.map(children || null, (child) => {
        const { props } = child;

        let iconComponent = null;
        iconComponent = <IconCheckRegular color="green-600" className={styles.list__icon} size="s" />;

        return (
          <li key={nextId('dsListItem')}>
            {iconComponent}
            {props.children}
          </li>
        );
      });
    }

    if (typeOfList === 'unordered') {
      return (
        <ul id={this.htmlId} className={allClasses} {...otherProps}>
          {itemList || null}
          {children}
        </ul>
      );
    }
    if (typeOfList === 'ordered') {
      return (
        <ol id={this.htmlId} className={allClasses} {...otherProps}>
          {itemList || null}
          {children}
        </ol>
      );
    }
    if (typeOfList === 'icon') {
      return (
        <ul id={this.htmlId} className={allClasses} {...otherProps}>
          {childList}
        </ul>
      );
    }
    return null;
  }
}

List.propTypes = {
  /** Element's id */
  id: PropTypes.string,
  /** Class names you want to give to the component */
  className: PropTypes.string,
  /** Size of the text and icons */
  size: PropTypes.oneOf(['s', 'm']),
  /** Component's content. Automatically detected. */
  children: PropTypes.node,
  /** List items as an array */
  items: PropTypes.arrayOf(PropTypes.string),
  /** Type of the list */
  listType: PropTypes.oneOf(['unordered', 'ordered', 'icon']),
  /**
   * @deprecated Use `listType` instead. `type` property will be removed in a future version (during summer 2024).
   */
  type: PropTypes.oneOf(['selectable', 'unordered', 'ordered', 'checked', 'big_ordered']),
};

List.defaultProps = {
  id: null,
  className: null,
  size: null,
  children: null,
  items: null,
  listType: 'unordered',
  type: null,
};

export default List;
