/* eslint-disable max-classes-per-file */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useIdWithFallback } from '../../utils/hooks';
import AccordionItem from './AccordionItem';
import { classNames } from '../../utils/css';
import styles from './Accordion.module.scss';

function AccordionInternal(
  {
    id = null,
    className = null,
    openMultiple = false,
    wrapTitle = false,
    headingLevel = 'h3',
    headingSize = 'm',
    children = null,
    onClick = () => {},
    onOpen = () => {},
    onClose = () => {},
    ...otherProps
  },
  ref
){
  const accordionRef = useRef(ref || null);
  const htmlId = useIdWithFallback('dsAccordion', id);
  let childItems = children;

  if(!Array.isArray(children)) {
    childItems = [children];
  }

  const onToggleHandler = (targetId) => {
    const accordion = document.getElementById(htmlId);
    const targetSection = document.getElementById(targetId);
    const contentContainer = targetSection.querySelector(`.${styles.accordion__section__content}`);

    if(!contentContainer.style.height) {
      contentContainer.style.height = `${contentContainer.scrollHeight}px`;
    }

    targetSection.classList.toggle(styles['accordion__section--open']);

    const sections = accordion.querySelectorAll(`.${styles.accordion__section}`);

    sections.forEach((section) => {
      const sectionButton = section.querySelector(`.${styles.accordion__section__heading__button}`);

      const sectionContentContainer = section.querySelector(`.${styles.accordion__section__content}`);
      sectionContentContainer.addEventListener(
        'transitionend',
        () => {
          const isOpen = section.classList.contains(styles['accordion__section--open']);
          sectionContentContainer.style.display = isOpen ? 'block' : 'none';
        },
        { capture: false, once: true, passive: false }
      );

      if(section !== targetSection && !openMultiple) {
        const accordionContentContainer = section.querySelector(`.${styles.accordion__section__content}`);

        if(!accordionContentContainer.style.height || accordionContentContainer.style.height === 'unset'){
          accordionContentContainer.style.height = `${accordionContentContainer.scrollHeight}px`;
        }

        section.classList.remove(styles['accordion__section--open']);
        sectionButton.setAttribute('aria-expanded', 'false');
        setTimeout(() => { accordionContentContainer.style.height = `0px`;}, 10);
      }
      else if(targetSection.classList.contains(styles['accordion__section--open'])) {
          sectionButton.setAttribute('aria-expanded', true);
          contentContainer.setAttribute('aria-hidden', false);
          contentContainer.style.display = 'block'
          contentContainer.style.height = `${contentContainer.scrollHeight}px`;
      }
      else {
        sectionButton.setAttribute('aria-expanded', false);
        contentContainer.setAttribute('aria-hidden', true);
        contentContainer.style.height = `${contentContainer.scrollHeight}px`;
        setTimeout(() => {contentContainer.style.height = `0px`;}, 10);
      }
    });
  }

  const allClasses = classNames([styles.accordion, wrapTitle ? styles['accordion__wrap-title'] : null, className]);

  return (
    <div ref={accordionRef} id={htmlId} className={allClasses} {...otherProps}>
      {childItems && childItems.length > 0 ? childItems.map((child, i) => (
        <AccordionItem
          {...child.props}
          id={child.id || child.props?.id || `${htmlId}Item${i}`}
          key={`${htmlId}Item${i}`}
          itemRef={child.itemRef || child.props?.itemRef || null}
          className={child.className || child.props?.className}
          contentClasses={child.contentClasses || child.props?.contentClasses}
          heading={child.heading || child.props?.heading}
          headingLevel={child.headingLevel || child.props?.headingLevel || headingLevel}
          headingSize={child.headingSize || child.props?.headingSize || headingSize}
          badge={child.badge || child.props?.badge}
          badgeType={child.badgeType || child.props?.badgeType}
          badgeColor={child.badgeColor || child.props?.badgeColor}
          content={child.content || child.props?.content}
          defaultOpen={child.defaultOpen || child.props?.defaultOpen}
          onToggle={onToggleHandler}
          onClick={onClick}
          onOpen={onOpen}
          onClose={onClose}
          onItemClick={child.onClick || child.props?.onClick}
          onItemOpen={child.onOpen || child.props?.onOpen}
          onItemClose={child.onClose || child.props?.onClose}
        >
          {child.content || child.props?.content || child.props?.children}
        </AccordionItem>
      )) : null}
    </div>
  );
}

const Accordion = React.forwardRef(AccordionInternal);
export default Accordion;


Accordion.propTypes = {
  /**
   * Custom id for the component. If none is given, this is automatically generated.
   */
  id: PropTypes.string,
  /**
   * Custom class names for the component.
   */
  className: PropTypes.string,
  /**
   * Enable multiple accordion items to be open at the same time.
   * By default (false) accordion will close all open accordion items when another accordion item is opened.
   */
  openMultiple: PropTypes.bool,
  wrapTitle: PropTypes.bool,
  headingLevel: PropTypes.oneOf(['div', 'h2', 'h3', 'h4']),
  headingSize: PropTypes.oneOf(['s', 'm', 'l']),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Custom id for the component. If none is given, this is automatically generated.
         */
        id: PropTypes.string,
        /**
         * Custom class names for the component.
         */
        className: PropTypes.string,
        /**
         * AccordionItem's heading that is visible when the item is closed.
         */
        heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
        /**
         * Heading level
         */
        headingLevel: PropTypes.oneOf(['div', 'h2', 'h3', 'h4']),
        /**
         * Heading size
         */
        headingSize: PropTypes.oneOf(['s', 'm', 'l']),
        /**
         * Header badge's text.
         */
        badge: PropTypes.string,
        /**
         * Header badge's type.
         */
        badgeType: PropTypes.string,
        /**
         * Header badge's color.
         */
        badgeColor: PropTypes.string,
        /**
         * Whether the AccordionItem is open by default when the page loads.
         */
        defaultOpen: PropTypes.bool,
        /**
         * AccordionItem's content. Automatically detected.
         */
        children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
        /**
         * Item's content as text or HTML.
         */
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
        /**
         * Function that launches when the Accordion is clicked
         */
        onClick: PropTypes.func,
        /**
         * Function that launches when the Accordion opens
         */
        onOpen: PropTypes.func,
        /**
         * Function that launches when the Accordion closes
         */
        onClose: PropTypes.func,
        /**
         * Ref for the AccordionItem
         */
        itemRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.element })]),
      })
    ),
  ]),
  /**
   * Function that launches when the Accordion is clicked
   */
  onClick: PropTypes.func,
  /**
   * Function that launches when the Accordion opens
   */
  onOpen: PropTypes.func,
  /**
   * Function that launches when the Accordion closes
   */
  onClose: PropTypes.func,
};

AccordionInternal.propTypes = Accordion.propTypes;