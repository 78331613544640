const fi = {
  i18n_login_header: 'Kirjaudu',
  i18n_login_header_usernamePassword: 'Kirjaudu Elisa tunnuksella',
  i18n_login_usernameLabel: 'Sähköpostiosoite tai tunnus',
  i18n_login_usernamePlaceholder: 'Sähköpostiosoite tai tunnus',
  i18n_login_passwordLabel: 'Salasana',
  i18n_login_passwordPlaceholder: 'Salasana',
  i18n_login_loginButtonLabel: 'Kirjaudu',
  i18n_login_altLoginText: 'tai tunnistaudu',
  i18n_login_elisatunnusMethodButtonText: 'Elisa Tunnuksella',
  i18n_login_elisatunnusMethodButtonAltText: 'Kirjaudu Elisa Tunnuksella',
  i18n_login_mobiilivarmenneMethodButtonText: 'Mobiilivarmenteella',
  i18n_login_mobiilivarmenneMethodButtonAltText: 'Kirjaudu Mobiilivarmenteella',
  i18n_login_bankauthMethodButtonText: 'Pankkitunnuksella',
  i18n_login_bankauthMethodButtonAltText: 'Kirjaudu pankkitunnuksella',
  i18n_login_auth2FactorText: 'Anna vahvistuskoodi, jonka lähetimme tekstiviestillä numeroon',
  i18n_login_auth2FactorPlaceholder: 'Vahvistuskoodi',
  i18n_login_auth2FactorLabel: 'Vahvistuskoodi',
  i18n_login_auth2FactorNewCodeText: 'Lähetä uusi tunnistautumiskoodi',
  i18n_login_errorAuth2FactorInvalidCode: 'Tarkista koodin muoto',
  i18n_login_auth2FactorDeviceShouldBeRememberedLabel: 'Muista tämä laite 30 päivää',

  i18n_login_header_mobilePhone: 'Kirjaudu Mobiilivarmenteella',
  i18n_login_phoneNumberLabel: 'Puhelinnumero',
  i18n_login_phoneNumberPlaceholder: 'Puhelinnumero',
  i18n_login_loginButtonLabel_mobilePhone: 'Seuraava',
  i18n_login_spamCodeLabel: 'Häirinnänestokoodi',
  i18n_login_spamCodePlaceholder: 'Häirinnänestokoodi',
  i18n_login_mobilePollingText: 'Saat kohta puhelimeesi tunnistepyynnön tunnisteella:',
  i18n_login_mobilePollingCancelLogin: 'Keskeytä kirjautuminen',
  i18n_login_mobileRegisterLinkText: 'Voit rekisteröidä mobiilivarmenteen tästä',
  i18n_login_header_strongIdentification: 'Kirjaudu pankkitunnuksella',
  i18n_login_ytunnusLabel: 'Y-Tunnus',
  i18n_login_ytunnusPlaceholder: 'Y-Tunnus',
  i18n_login_ytunnusChangeText: 'Muuta',
  i18n_login_buttonContinue: 'Jatka',
  i18n_login_ytynnysText: 'Y-Tunnus',

  i18n_login_loginHelpText: 'Unohtuiko salasana?',
  i18n_login_createLoginText: 'Luo Elisa Tunnus',

  i18n_login_errorUsernameRequired: 'Käyttäjätunnus on pakollinen',
  i18n_login_errorPasswordRequired: 'Salasana on pakollinen',
  i18n_login_errorAuth2FactorRequired: 'Syötä vahvistuskoodi',
  i18n_login_errorPhoneNumberRequired: 'Puhelinnumero on pakollinen',
  i18n_login_errorPhoneNumberInvalid: 'Tarkista puhelinnumero.',
  i18n_login_errorYTunnusRequired: 'Y-Tunnus on pakollinen',
  i18n_login_errorYTunnusInvalid: 'Tarkista Y-Tunnus',
};

const en = {
  i18n_login_header: 'Login',
  i18n_login_header_usernamePassword: 'Login with a username',
  i18n_login_usernameLabel: 'Email or username',
  i18n_login_usernamePlaceholder: 'Email or username',
  i18n_login_passwordLabel: 'Password',
  i18n_login_passwordPlaceholder: 'Password',
  i18n_login_loginButtonLabel: 'Login',
  i18n_login_altLoginText: 'or authenticate with',
  i18n_login_elisatunnusMethodButtonText: 'Elisa ID',
  i18n_login_elisatunnusMethodButtonAltText: 'Login with Elisa ID',
  i18n_login_mobiilivarmenneMethodButtonText: 'Mobile ID',
  i18n_login_mobiilivarmenneMethodButtonAltText: 'Login with Mobile ID',
  i18n_login_bankauthMethodButtonText: 'Bank credentials',
  i18n_login_bankauthMethodButtonAltText: 'Login with bank credentials',
  i18n_login_auth2FactorText: 'Input an authentication code that was sent via text message',
  i18n_login_auth2FactorPlaceholder: 'Authentication code',
  i18n_login_auth2FactorLabel: 'Authentication code',
  i18n_login_auth2FactorNewCodeText: 'Send new authentication code',
  i18n_login_errorAuth2FactorInvalidCode: 'Please check the format of the code',
  i18n_login_auth2FactorDeviceShouldBeRememberedLabel: 'Remember this device for 30 days',

  i18n_login_header_mobilePhone: 'Login using Mobile ID',
  i18n_login_phoneNumberLabel: 'Phone number',
  i18n_login_phoneNumberPlaceholder: 'Phone number',
  i18n_login_loginButtonLabel_mobilePhone: 'Continue',
  i18n_login_spamCodeLabel: 'Security code',
  i18n_login_spamCodePlaceholder: 'Security code',
  i18n_login_mobilePollingText: 'An authentication request has been sent to your phone with event id:',
  i18n_login_mobilePollingCancelLogin: 'Cancel login',
  i18n_login_mobileRegisterLinkText: 'You can register for a Mobile ID from here',
  i18n_login_header_strongIdentification: 'Login using bank credentials',
  i18n_login_ytunnusLabel: 'business ID',
  i18n_login_ytunnusPlaceholder: 'business ID',
  i18n_login_ytunnusChangeText: 'Change',
  i18n_login_buttonContinue: 'Continue',
  i18n_login_ytynnysText: 'business ID',

  i18n_login_loginHelpText: 'Did you forget your password?',
  i18n_login_createLoginText: 'Create Elisa ID',

  i18n_login_errorUsernameRequired: 'Username is required',
  i18n_login_errorPasswordRequired: 'Password is required',
  i18n_login_errorAuth2FactorRequired: 'Enter authentication code',
  i18n_login_errorPhoneNumberRequired: 'Phone number is required',
  i18n_login_errorPhoneNumberInvalid: 'Please check the phone number',
  i18n_login_errorYTunnusRequired: 'Business ID is required',
  i18n_login_errorYTunnusInvalid: 'Please check the business ID',
};

const sv = {
  i18n_login_header: 'Logga in',
  i18n_login_header_usernamePassword: 'Logga in med Elisa Id',
  i18n_login_usernameLabel: 'Email eller användarnamn',
  i18n_login_usernamePlaceholder: 'Email eller användarnamn',
  i18n_login_passwordLabel: 'Lösenord',
  i18n_login_passwordPlaceholder: 'Lösenord',
  i18n_login_loginButtonLabel: 'Logga in',
  i18n_login_altLoginText: 'eller logga in med',
  i18n_login_elisatunnusMethodButtonText: 'Elisa ID',
  i18n_login_elisatunnusMethodButtonAltText: 'Logga in med Elisa ID',
  i18n_login_mobiilivarmenneMethodButtonText: 'Mobil ID',
  i18n_login_mobiilivarmenneMethodButtonAltText: 'Logga in med Mobil ID',
  i18n_login_bankauthMethodButtonText: 'Bankkoderna',
  i18n_login_bankauthMethodButtonAltText: 'Logga in med bankkoderna',

  i18n_login_auth2FactorText: 'Ange autentiseringskod',
  i18n_login_auth2FactorPlaceholder: 'Autentiseringskod',
  i18n_login_auth2FactorLabel: 'Autentiseringskod',
  i18n_login_auth2FactorNewCodeText: 'Skicka ny autentiseringskod',
  i18n_login_errorAuth2FactorInvalidCode: 'Kontrollera koden',
  i18n_login_auth2FactorDeviceShouldBeRememberedLabel: 'Kom ihåg denna enhet i 30 dagar',

  i18n_login_header_mobilePhone: 'Logga in med Mobil ID',
  i18n_login_phoneNumberLabel: 'Telefonnummer',
  i18n_login_phoneNumberPlaceholder: 'Telefonnummer',
  i18n_login_loginButtonLabel_mobilePhone: 'Nästä',
  i18n_login_spamCodeLabel: 'Störningsspärrkod',
  i18n_login_spamCodePlaceholder: 'Störningsspärrkod',
  i18n_login_mobilePollingText: 'Du får snart en autentiseringsförfrågan till din telefon med transaktionskoden:',
  i18n_login_mobilePollingCancelLogin: 'Avbryt inloggning',
  i18n_login_mobileRegisterLinkText: 'Du kan registrera ett mobilcertifikat här',
  i18n_login_header_strongIdentification: 'Logga in med bankkoderna',
  i18n_login_ytunnusLabel: 'FO-nummer',
  i18n_login_ytunnusPlaceholder: 'FO-nummer',
  i18n_login_ytunnusChangeText: 'Ändra',
  i18n_login_buttonContinue: 'Fortsätta',
  i18n_login_ytynnysText: 'FO-nummer',

  i18n_login_loginHelpText: 'Har du glömt ditt lösenord?',
  i18n_login_createLoginText: 'Skapa Elisa Id',

  i18n_login_errorUsernameRequired: 'Användarnamn krävs',
  i18n_login_errorPasswordRequired: 'Lösenord krävs',
  i18n_login_errorAuth2FactorRequired: 'Ange autentiseringskod',
  i18n_login_errorPhoneNumberRequired: 'Telefonnummer krävs',
  i18n_login_errorPhoneNumberInvalid: 'Kontrollera telefonnummer',
  i18n_login_errorYTunnusRequired: 'FO-nummer krävs',
  i18n_login_errorYTunnusInvalid: 'Kontrollera FO-nummer',
};

export default { fi, en, sv };
