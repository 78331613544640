import React from 'react';
import PropTypes from 'prop-types';
import IconFacebookFilled from '../../../Icon/lib/IconFacebookFilled';
import IconTwitterFilled from '../../../Icon/lib/IconTwitterFilled';
import IconTiktokFilled from '../../../Icon/lib/IconTiktokFilled';
import IconInstagramFilled from '../../../Icon/lib/IconInstagramFilled';
import IconYoutubeFilled from '../../../Icon/lib/IconYoutubeFilled';
import IconLinkedinFilled from '../../../Icon/lib/IconLinkedinFilled';
import {classNames} from '../../../../utils/css';
import {LinkWrapper} from './LinkWrapper';
import styles from './SocialIcons.module.scss';

/*
  Default links were considered to be changing rarely, so these are included for convenience, and to prevent typos.
*/

const defaultLinks = {
  facebook: 'https://www.facebook.com/elisasuomi',
  twitter: 'https://twitter.com/elisaoyj',
  tiktok: 'https://www.tiktok.com/@elisaoyj',
  instagram: 'https://www.instagram.com/elisaoyj/',
  youtube: 'https://www.youtube.com/user/elisa',
  linkedin: 'https://www.linkedin.com/company/elisa',
};

/*
  Default aria labels are included here for better accessibility.
  As brand names, these are not localized.
  These were missing from the original react-patterns footer, and were missing from react-footer datamodel.
*/

const defaultAriaLabels = {
  facebook: 'Facebook',
  twitter: 'Twitter',
  tiktok: 'Tiktok',
  instagram: 'Instagram',
  youtube: 'Youtube',
  linkedin: 'LinkedIn',
};

function SocialLinks({links, ariaLabels, className, handleClick}) {
  return <ul className={classNames([styles.footer__socialiconlist, className])}>
    {
      links.facebook &&
      <LinkWrapper
        Icon={IconFacebookFilled}
        href={links.facebook}
        aria={ariaLabels.facebook}
        handleClick={handleClick}
      />
    }
    {
      links.twitter &&
      <LinkWrapper
        Icon={IconTwitterFilled}
        href={links.twitter}
        aria={ariaLabels.twitter}
        handleClick={handleClick}
      />
    }
    {
      links.tiktok &&
      <LinkWrapper
        Icon={IconTiktokFilled}
        href={links.tiktok}
        aria={ariaLabels.tiktok}
        handleClick={handleClick}
      />
    }
    {links.instagram &&
      <LinkWrapper
        Icon={IconInstagramFilled}
        href={links.instagram}
        aria={ariaLabels.instagram}
        handleClick={handleClick}
      />
    }
    {links.youtube &&
      <LinkWrapper
        Icon={IconYoutubeFilled}
        href={links.youtube}
        aria={ariaLabels.youtube}
        handleClick={handleClick}
      />
    }
    {links.linkedin &&
      <LinkWrapper
        Icon={IconLinkedinFilled}
        href={links.linkedin}
        aria={ariaLabels.linkedin}
        handleClick={handleClick}
      />
    }
  </ul>
}

SocialLinks.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func,
  links: PropTypes.shape({
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    tiktok: PropTypes.string,
    instagram: PropTypes.string,
    youtube: PropTypes.string,
    linkedin: PropTypes.string,
  }),
  ariaLabels: PropTypes.shape({
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    tiktok: PropTypes.string,
    instagram: PropTypes.string,
    youtube: PropTypes.string,
    linkedin: PropTypes.string,
  }),
};

SocialLinks.defaultProps = {
  className: undefined,
  handleClick: () => {
  },
  links: defaultLinks,
  ariaLabels: defaultAriaLabels,
};

export default SocialLinks;
