// @flow strict-local

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import {
  type CallFlowEntityT,
  selectors as callFlowSelect
} from '../../../ducks/entities/callFlow';
import { type CallFlowTypeT } from '../../../ducks/entities/callFlow/callFlowTypes';
import GenericError from '../../../components/Error/GenericError';
import CallFlowElement from './services/CallFlowElement';

type PropsT = {|
  enterpriseId: string,
  callFlowId?: string,
  callFlowType?: CallFlowTypeT,
  selectedCategory?: string,
  searchTerm?: string,
  loadMore?: (page: number) => Promise<void>
|};

export const ExpandableCallFlowGrid = (
  props: PropsT
): Element<typeof InfiniteScroll | typeof CallFlowElement> | null => {
  const { enterpriseId, callFlowId, callFlowType, loadMore, selectedCategory, searchTerm } = props;

  // redux
  const callflows: CallFlowEntityT[] = useSelector(state =>
    callFlowSelect.getCallFlowsByEnterpriseIdAndSearchFilter(
      state,
      enterpriseId,
      searchTerm,
      callFlowType
    )
  ).sort((a, b) => parseInt(a.addressNumber, 10) - parseInt(b.addressNumber, 10));

  const errorElement = <GenericError message="error" />;

  if (callFlowId && callFlowType) {
    const data = callflows.filter(({ id, type }) => id === callFlowId && type === callFlowType)[0];
    return data ? (
      <CallFlowElement
        callFlowData={data}
        enterpriseId={enterpriseId}
        hideDescription
        single
        errorElement={errorElement}
      />
    ) : null;
  }

  const filteredCallFlows = selectedCategory
    ? (callflows || []).filter(cf => cf != null).filter(cf => selectedCategory === cf.type)
    : [];

  return (
    <InfiniteScroll hasMore={false} loadMore={loadMore} initialLoad={false} pageStart={1}>
      {filteredCallFlows.map(callflow => (
        <CallFlowElement
          key={`callflowelement_${callflow.id}`}
          callFlowData={callflow}
          enterpriseId={enterpriseId}
          single={false}
          showError={false}
          errorElement={errorElement}
        />
      ))}
    </InfiniteScroll>
  );
};

export default ExpandableCallFlowGrid;
