import React from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';

import { classNames } from '../../utils/css';

import styles from './Label.module.scss';

class Label extends React.Component {
  constructor(props) {
    super(props);

    this.htmlId = props.id || nextId(`ds${this.constructor.name}`);
  }

  render() {
    const { id, className, children, labelFor, ...otherProps } = this.props;

    const allClasses = classNames([styles.label, className || null]);

    return (
      <label id={this.htmlId} className={allClasses} htmlFor={labelFor || null} {...otherProps}>
        {children}
      </label>
    );
  }
}

Label.propTypes = {
  /** Element's id */
  id: PropTypes.string,
  /** Class names the user can give to the component */
  className: PropTypes.string,
  /** Content of label. Automatically detected. */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Label's for attribute */
  labelFor: PropTypes.string,
};

Label.defaultProps = {
  id: null,
  className: null,
  children: null,
  labelFor: null,
};

export default Label;
