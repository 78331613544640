// @flow
import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import Radio from '@design-system/component-library/src/components/Radio';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { TranslateT } from '../../../../../../commonTypes';
import AudioFileSelection from './AudioFileSelection';
import type { AudioFieldT, AudioT } from './CallflowAudioUtils';
import { getAudioPlayData } from './CallflowAudioUtils';
import AudioPlayerContainer from '../../../../../../components/AudioPlayer/AudioPlayerContainer';
import styles from './IvrAudioField.module.scss';

export type PropsT = {|
  field: string,
  title?: string,
  description?: string,
  disabled?: boolean,
  hasDefault?: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  disabledFn?: any => boolean,
  translateMock?: TranslateT<>
|};

export const IvrAudioField = (props: PropsT): Element<'div'> => {
  const { field, title, description, disabled, disabledFn, translateMock, hasDefault } = props;
  const { t } = useTranslation();
  // form
  const { setValue, watch } = useFormContext();
  const values = watch();
  const currentUser = useSelector(state => state.currentUser);

  const audioField: AudioFieldT<AudioT> = values[field];

  const disabledValue = disabledFn === undefined ? disabled : disabledFn(values);

  const isInactiveLevel = values[field].level === 'Inactive';
  const isExtensionLevel = values[field].level === 'Extension';

  const extensionAudioData: AudioT = getAudioPlayData(
    audioField,
    isExtensionLevel ? 'Extension' : 'Entreprise'
  );

  const defaultAudioData = {
    audio: {
      ...audioField.audioPlayData,
      filename: 'Default.mp3',
      level: 'Default'
    },
    level: 'Default',
    loading: undefined,
    error: undefined
  };

  const hasDefaultAudio =
    audioField.hasAudioFilesOnLevels && audioField.hasAudioFilesOnLevels.includes('Default');

  const hasExtensionAudio =
    audioField.hasAudioFilesOnLevels && audioField.hasAudioFilesOnLevels.includes('Extension');

  const viewWithoutDefaultAudio = t('callflows.audioField.defaultAudioLabel');
  const viewWithDefaultAudio = t('callflows.audioField.defaultOption');
  const customAudioLabel = t('callflows.audioField.customAudioLabel');
  const defaultAudioFileSelector =
    hasDefaultAudio || hasDefault ? viewWithDefaultAudio : viewWithoutDefaultAudio;
  const customAudioFileSelector = (
    <div data-cy={field}>
      <AudioFileSelection
        audio={extensionAudioData}
        onChange={file =>
          setValue(
            field,
            {
              ...values[field],
              fileToImport: file
            },
            {
              shouldValidate: true,
              shouldDirty: true
            }
          )
        }
        disabled={disabledValue || !isExtensionLevel}
        // $FlowFixMe
        showInitialView={audioField.audioPlayData.level === 'Extension'}
        {...(translateMock ? { translateMock } : {})}
      />
    </div>
  );

  const fieldValue = values[field].level !== 'Extension' ? 'Default' : values[field].level;
  const isDefaultLevel = fieldValue === 'Default';
  return (
    <div className={title && styles['title-padding']}>
      {title && (
        <div
          className={classnames(styles['small-title'], {
            [styles['small-title--disabled']]: disabledValue
          })}
        >
          {title}
        </div>
      )}
      <div className={styles.description}>
        {description === undefined ? t('callflows.audioField.description') : description}
      </div>
      <div role="group" aria-label="Message type">
        <Radio.Group value={fieldValue}>
          <Radio
            id={`${field}_option_1`}
            name={field}
            label={defaultAudioFileSelector}
            value="Default"
            onChange={() =>
              setValue(
                field,
                {
                  ...values[field],
                  level: hasDefaultAudio ? 'Default' : 'Inactive'
                },
                {
                  shouldValidate: true,
                  shouldDirty: true
                }
              )
            }
            disabled={disabledValue}
            className={styles.radio}
          />

          <Radio
            id={`${field}_option_2`}
            name={field}
            value="Extension"
            onChange={() =>
              setValue(
                field,
                {
                  ...values[field],
                  level: 'Extension'
                },
                {
                  shouldValidate: true,
                  shouldDirty: true
                }
              )
            }
            disabled={disabledValue}
            label={customAudioLabel}
            className={styles.radio}
          />
          {isExtensionLevel && customAudioFileSelector}
          {((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') &&
            isDefaultLevel && (
              <div className={styles['audio-area']}>
                <AudioPlayerContainer {...defaultAudioData} />
              </div>
            )}
        </Radio.Group>
      </div>
      {isInactiveLevel && hasDefaultAudio && hasExtensionAudio && (
        <div style={{ color: 'red' }}>{t('callflows.audioField.error')}</div>
      )}
    </div>
  );
};

export default IvrAudioField;
