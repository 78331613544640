import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from '../../../../utils/css';
import styles from './Disclaimer.module.scss';

export function CookieSettingsButton({ text, handleClick }) {
  if (text) {
    return (
      <li className={styles.footer__disclaimer_item}>
        <button
          type="button"
          id="ot-sdk-btn"
          className={classNames([styles.footer__disclaimer_link, 'ot-sdk-show-settings'])}
          onClick={handleClick}
        >
          {text}
        </button>
      </li>
    );
  }
  return null;
}

CookieSettingsButton.defaultProps = {
  text: null,
  handleClick: () => {},
};

CookieSettingsButton.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
};
