
import React from 'react';

import { ReactComponent as IconSource } from './iconFiles/website--regular.svg';
import IconContainer from '../IconContainer';

export default function IconWebsiteRegular(props) {
  return (
    <IconContainer
      {...props}
      source={IconSource}
    />
  );
}
  