// @flow strict-local

import React, { type Element } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import type { BaseCalendarEntityT } from '../../../../../../../ducks/entities/calendar/calendarTypes';
import AbsoluteTimeSlots from '../../../../../calendar/AbsoluteTimeSlots';
import { sortAndRenderDaySchedule } from '../../../../../callFlowGrid/details/CalendarUtils';
import styles from './CalendarSection.module.scss';

export type PropsT = {
  calendar: BaseCalendarEntityT
};

export function CalendarSection(props: PropsT): Element<'div'> {
  const { calendar } = props;
  const { t } = useTranslation();
  const absoluteTimeSlots = R.path(['absoluteTimeSlots'], calendar);
  // rendering
  const renderHours = day => (
    <div>
      {day && day.length > 0 ? (
        sortAndRenderDaySchedule(day)
      ) : (
        <div>{t('callflows.details.closed')}</div>
      )}
    </div>
  );

  const renderOpeningHours = () => {
    const weekSlots = R.path(['weekSchedule'], calendar);
    return (
      weekSlots && (
        <div className={styles.section}>
          <div className={styles.title}>{t('callflows.details.openingHours')}</div>
          {Object.keys(weekSlots).map(key => (
            <div className={styles.openinghours} key={`openinghours_${key}`}>
              <div>{t(`callflows.details.${key}`)}</div>
              {renderHours(weekSlots[key])}
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    <div className={styles['calendar-section']}>
      {renderOpeningHours()}
      {absoluteTimeSlots && (
        <AbsoluteTimeSlots
          absoluteTimeSlots={absoluteTimeSlots}
          editable={false}
          handleDelete={() => {}}
          handleUpdate={() => {}}
        />
      )}
    </div>
  );
}

export default CalendarSection;
