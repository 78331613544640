// @flow

import * as R from 'ramda';
import Moment from 'moment';
import React, { type Element } from 'react';
import { extendMoment } from 'moment-range';
import compareAsc from 'date-fns/compareAsc';
import type {
  AbsoluteTimeSlotEntityT,
  AbsoluteTimeSlotWithRangeT,
  DayScheduleEntityT
} from '../../../../ducks/entities/calendar/calendarTypes';

export type IntervalEntryT = {|
  id: string,
  interval: string,
  active: boolean
|};

export type DateTimeIntervalEntryT = {|
  id: string,
  start: Moment,
  end: Moment,
  active: boolean
|};

export const zeroPad = (num: number, places: number = 2) => {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
};

export const renderEndTime = (endHour: number, endMinute: number) => {
  const fixedEndHour = endHour === 0 && endMinute === 0 ? 24 : endHour;
  return `${zeroPad(fixedEndHour, 2)}:${zeroPad(endMinute, 2)}`;
};

const parseDayScheduleEntity = (entity: DayScheduleEntityT): DateTimeIntervalEntryT => {
  const start = Moment(entity.startTime, 'HH:mm');
  const end = Moment(entity.endTime, 'HH:mm');
  return {
    id: entity.id,
    start,
    end,
    active: entity.active
  };
};

const formatTimeInterval = (interval: DateTimeIntervalEntryT): IntervalEntryT => {
  const { start, end, id } = interval;
  const endTime = renderEndTime(end.hour(), end.minutes());
  return {
    id,
    interval: `${start.format('HH:mm')} - ${endTime}`,
    active: interval.active
  };
};

export const compareDateAscbyStart = (a: DateTimeIntervalEntryT, b: DateTimeIntervalEntryT) =>
  compareAsc(a.start.toDate(), b.start.toDate());

const renderTimeSlotString = (slot: IntervalEntryT): Element<'div'> => (
  <div key={slot.id}>{slot.interval}</div>
);

export const sortAndRenderDaySchedule = (daySchedule: DayScheduleEntityT[]) => {
  // $FlowFixMe: compose missing in types
  const timeData = R.compose(
    R.map(formatTimeInterval),
    R.sort(compareDateAscbyStart),
    R.map(parseDayScheduleEntity)
  )(daySchedule);

  return R.map(renderTimeSlotString, timeData);
};

const moment = extendMoment(Moment);

const toRanges = (intervals: AbsoluteTimeSlotEntityT[]): AbsoluteTimeSlotWithRangeT[] =>
  intervals.map(interval => ({
    ...interval,
    range: moment.range(Moment(interval.startTime), Moment(interval.endTime))
  }));

const toIntervals = (ranges: AbsoluteTimeSlotWithRangeT[]): AbsoluteTimeSlotEntityT[] =>
  ranges.map(range => R.omit(['range'], range));

const removeContained = (ranges: AbsoluteTimeSlotWithRangeT[]): AbsoluteTimeSlotWithRangeT[] =>
  // remove all ranges that are completely contained within other ranges
  ranges.filter(
    rangeA => !ranges.some(rangeB => rangeA.id !== rangeB.id && rangeB.range.contains(rangeA.range))
  );
const mergeOverlapping = (ranges: AbsoluteTimeSlotWithRangeT[]): AbsoluteTimeSlotWithRangeT[] => {
  // merge all overlapping (or adjacent) ranges
  for (let i = 0; i < ranges.length; i++) {
    const rangeA = ranges[i];
    for (let j = 0; j < ranges.length; j++) {
      const rangeB = ranges[j];
      if (
        rangeA.active &&
        rangeB &&
        rangeB.active &&
        rangeA.id !== rangeB.id &&
        rangeA.range.overlaps(rangeB.range, { adjacent: true })
      ) {
        // merge with overlapping and delete unneeded range
        rangeB.range = rangeB.range.add(rangeA.range, { adjacent: true });
        rangeB.startTime = rangeB.range.start.format('YYYY-MM-DDTHH:mm:ss');
        rangeB.endTime = rangeB.range.end.format('YYYY-MM-DDTHH:mm:ss');
        ranges[j] = rangeB; // eslint-disable-line
        ranges.splice(i, 1);
        --i;
        --j;
        break;
      }
    }
  }
  return ranges;
};

export const removeContainedAndMergeOverlappingAbsoluteTimeSlots = (
  intervals: AbsoluteTimeSlotEntityT[]
): AbsoluteTimeSlotEntityT[] => {
  const ranges = toRanges(intervals);
  const containedRemoved = removeContained(ranges);
  const merged = mergeOverlapping(containedRemoved);
  const result = toIntervals(merged);
  return result;
};
