// @flow strict-local

import axios, { AxiosPromise } from 'axios';
import type { CancelToken } from 'axios';
import type { ThunkActionT } from '../../../commonTypes';
import type {
  DirectoryEntitiesT,
  DirectoryEntityT,
  SearchDirectoryActionT
} from './directoryTypes';
import { convertToDirectoryData, getCallflowServiceUrlByType } from './directoryUtils';
import type { SearchCategoryT } from './directoryUtils';
import {
  createRetrieveDirectoryCollectionCancel,
  createRetrieveDirectoryCollectionFailure,
  createRetrieveDirectoryCollectionRequest,
  createRetrieveDirectoryCollectionSuccess
} from './directoryActions';

type SearchParamsT = {|
  page?: number,
  size?: number,
  search?: string,
  sort?: string,
  type: SearchCategoryT
|};

type RetrieveCollectionFnT = (
  string,
  CancelToken,
  ?SearchParamsT
) => ThunkActionT<null, DirectoryEntityT[] | void>;

export const getForwardingTargets: RetrieveCollectionFnT = (
  id,
  cancelToken,
  params = {}
) => async () => {
  let url;
  if (params) {
    url = getCallflowServiceUrlByType(params.type, id);
    if (url) {
      try {
        const response = axios({
          method: 'GET',
          url,
          cancelToken,
          params
        });
        const { data } = await response;
        return convertToDirectoryData(params.type, data.results);
      } catch (error) {
        return undefined;
      }
    }
  }
  return undefined;
};

type RetrieveAllForwardingTargetsFnT = (
  string,
  CancelToken,
  { search?: string }
) => ThunkActionT<null, DirectoryEntityT[] | void>;

export type DirectoryFwdT = {|
  +id: string,
  +label: string,
  +value: string
|};
export const getAllForwardingTargets: RetrieveAllForwardingTargetsFnT = (
  enterpriseId,
  cancelToken,
  params = {}
) => async () => {
  try {
    const response: AxiosPromise<DirectoryEntitiesT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/directories/links`,
      params,
      cancelToken
    });
    const { data } = await response;
    return (data.results || []).map(d => ({
      id: d.contactId,
      label: `${d.displayName.replace('kutsu:', '')} (${d.addressNumber})`,
      value: d.addressNumber
    }));
  } catch (error) {
    return undefined;
  }
};

type SearchDirectoryFnT = (
  string,
  CancelToken,
  SearchParamsT
) => ThunkActionT<SearchDirectoryActionT, DirectoryEntitiesT | void>;
const searchDirectory: SearchDirectoryFnT = (
  enterpriseId,
  cancelToken,
  params = {}
) => async dispatch => {
  dispatch(createRetrieveDirectoryCollectionRequest(enterpriseId, params));
  try {
    const response: AxiosPromise<DirectoryEntitiesT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/directories`,
      params,
      cancelToken
    });
    const { data } = await response;
    dispatch(
      createRetrieveDirectoryCollectionSuccess(enterpriseId, data, {
        saveTotalCount: params.search === ''
      })
    );
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveDirectoryCollectionCancel());
    } else {
      dispatch(createRetrieveDirectoryCollectionFailure(enterpriseId, error));
    }
    return error;
  }
};

const directoryOperations = {
  getForwardingTargets,
  searchDirectory
};

export default directoryOperations;
