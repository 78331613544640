// @flow strict

import React, { type Element, Component } from 'react';
import classnames from 'classnames';
import type { ExactPropsT } from '../../commonTypes';
import styles from './EditIcon.module.scss'; // flowlint-line nonstrict-import: off

type PropsT = {
  className?: ?string,
  onClick: () => ?void,
  disabled: boolean,
  displayAsBlock?: ?boolean
};

export type TestPropsT = ExactPropsT<PropsT>;

type StateT = {};

export class EditIcon extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick: () => void;

  handleOnClick(e: SyntheticEvent<HTMLButtonElement>) {
    const { onClick, disabled } = this.props;
    if (e) e.preventDefault();
    if (!disabled) {
      onClick();
    }
  }

  render(): Element<'button'> {
    const { className, disabled, displayAsBlock } = this.props;
    return (
      <button
        id="edit-pencil-edit"
        className={classnames(
          styles['edit-button'],
          className,
          { [styles.disabled]: disabled },
          { [styles['display-as-block']]: displayAsBlock }
        )}
        onClick={this.handleOnClick}
      />
    );
  }
}

export default EditIcon;
