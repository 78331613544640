"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoppingCartTotalPrice = exports.ShoppingCartPrice = void 0;
function ShoppingCartPrice(_a) {
    var amount = _a.amount, unit = _a.unit;
    return {
        amount: amount,
        unit: unit,
    };
}
exports.ShoppingCartPrice = ShoppingCartPrice;
function ShoppingCartTotalPrice(_a) {
    var amount = _a.amount, name = _a.name, unit = _a.unit;
    return {
        amount: amount,
        name: name,
        unit: unit,
    };
}
exports.ShoppingCartTotalPrice = ShoppingCartTotalPrice;
exports.default = {};
