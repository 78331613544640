import React from 'react';
import PropTypes from 'prop-types';
import SelectGroup from '../SelectGroup';
import { classNames } from '../../utils/css';
import styles from './BankSelectGroup.module.scss';

const STATIC_CDN_URL = 'https://static.elisa.com/v2/image';

export const BANK_CONFIGURATION = {
  mobiilivarmenne: {
    id: 'mobiilivarmenne',
    name: 'Mobiilivarmenne',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/6KOK4BnbAFqQJk9GUL2lYk/mobiilivarmenne.svg`,
  },
  op: {
    id: 'osuuspankki',
    name: 'Osuuspankki',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/51QMcHx6N1XxVbyARcjchx/op.svg`,
  },
  nordea: {
    id: 'nordea',
    name: 'Nordea',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/5CgyvEE5d8VQyj1KA5Kdkt/nordea.svg`,
  },
  danskebank: {
    id: 'danskeBank',
    name: 'Danske Bank',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/1yuHlGZBpYfysbrH3N3SRi/danskebank.svg`,
  },
  handelsbanken: {
    id: 'handelsbanken',
    name: 'Handelsbanken',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/SycA4SBLA612wJGjTc9Py/handelsbanken.svg`,
  },
  alandsbanken: {
    id: 'alandsbanken',
    name: 'Ålandsbanken',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/4dqLPjNoboilf6VF5gcmoI/alandsbanken.svg`,
  },
  's-pankki': {
    id: 'sPankki',
    name: 'S-Pankki',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/37Rcr5tVwFxK3ZjiXeltkb/s-pankki.svg`,
  },
  aktia: {
    id: 'aktia',
    name: 'Aktia',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/4h0i6H8RhPnkd5qyPdNWGP/aktia.svg`,
  },
  'pop-pankki': {
    id: 'popPankki',
    name: 'POP-Pankki',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/5YYzdIbYBNGb9k5SuYoqpg/pop-pankki.svg`,
  },
  saastopankki: {
    id: 'saastopankki',
    name: 'Säästöpankki',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/4RShqrbALS32MSv9IxhHo9/saastopankki.svg`,
  },
  omasp: {
    id: 'omaSP',
    name: 'Oma SP',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/5CHv3Z8KqCDVozkgQ1LRrx/omasp.svg`,
  },
  korhopankki: {
    id: 'korhoPankki',
    name: 'KorhoPankki',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/3d1siV8dFnrkjNRYlhVcpp/korhopankki.svg`,
  },
  visa: {
    id: 'visa',
    name: 'Visa',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/76WmzBSS5GapsfgSsNcTkJ/visa.svg`,
  },
  mastercard: {
    id: 'mastercard',
    name: 'MasterCard',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/41fC1l7ZM6XTxqJtOdLcIW/mastercard.svg`,
  },
  mobilepay: {
    id: 'mobilepay',
    name: 'MobilePay',
    logoURL: `${STATIC_CDN_URL}/2tqybbhjs47b/53djOQbQ95lq4Yh8BVQz00/mobilepay.svg`,
  },
};

const getBankObjects = (bankConfig, withNames, idPrefix) => (key) => {
  if(key){
    const bank = bankConfig[key];
    const bankObj = { id: key, htmlId: idPrefix + key, bId: bank.id, image: { url: bank.logoURL, alt: bank.name } };
    return withNames ? { ...bankObj, name: bank.name } : bankObj;
  }
};

export const DEFAULT_BANKS = [['mobiilivarmenne'], ['op', 'nordea', 'danskebank', 'handelsbanken', 'alandsbanken', 's-pankki', 'aktia', 'pop-pankki', 'saastopankki', 'omasp', 'korhopankki']];

function BankSelectGroup(props) {
  const { bankConfig, showNames, errorMessage, name, banks, onBankSelect, value, id, infoText, autoFocus, className } = props;
  const classes = classNames([className, styles['selectgroup--type-bankbuttons']]);
  const getBank = getBankObjects(bankConfig, showNames, id);
  const bankArr = Array.isArray(banks[0]) ? banks : [banks];
  const bankObjects = bankArr.map((banks) =>
    banks.map((b) => {
      const bank = getBank(b);
      if (!bank) {
        console.error(`Bank configuration not found for "${b.id}"`);
        return null;
      }
      return bank;
    })
  );

  const handleChange = (selected) => {
    if(selected){
      const { id, htmlId, image } = getBank(selected);
      onBankSelect({ id, htmlId, name: image.alt, value: id });
    }    
  };

  return (
    <SelectGroup
      id={id}
      i18n_SelectGroup_errorMessage={errorMessage}
      name={name}
      autoFocus={autoFocus}
      infotext={infoText}
      className={classes}
      onChange={handleChange}
      value={value}
      products={bankObjects}
    />
  );
}

BankSelectGroup.propTypes = {
  /**
   * Element's id
   */
  id: PropTypes.string,
  /**
   * Additional CSS classes for the component
   */
  className: PropTypes.string,
  /**
   * Name of the component. This is for form purposes.
   */
  name: PropTypes.string,
  /**
   * Additional information and instructions for the SelectGroup
   */
  infoText: PropTypes.string,
  /**
   * Error message for the SelectGroup
   */
  errorMessage: PropTypes.string,
  /**
   * List and order of listed banks. Only works with 'bankbuttons' type
   */
  banks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  /**
   * Display bank name with the logo
   */
  showNames: PropTypes.bool,
  /**
   * Callback function that fires when any bankbutton is clicked. Used only with bankbuttons
   */
  onBankSelect: PropTypes.func.isRequired,
  /**
   * Automatically set focus to first element. Optional.
   */
  autoFocus: PropTypes.bool,
  /**
   * Custom bank configuration object
   */
  bankConfig: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      logoURL: PropTypes.string,
    })
  ),
  /**
   * Value of the selected option(s)
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

BankSelectGroup.defaultProps = {
  id: '',
  showNames: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  bankConfig: BANK_CONFIGURATION,
  banks: DEFAULT_BANKS,
  className: null,
  name: null,
  infoText: null,
  errorMessage: null,
  autoFocus: false,
  value: null,
};

export default BankSelectGroup;
