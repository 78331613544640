import React from 'react';
import PropTypes from 'prop-types';
import { useIdWithFallback } from '../../utils/hooks';

function TabNavigationTab(
  {
    id = null,
    className = null, 
    selected = false,
    title = null,
    onClick = () => {},
    children = null,
    ...otherProps
  }
) {
  const htmlId = useIdWithFallback('dsTabNavigationTab', id);

  return (
    <div id={`${htmlId}`} className={className} {...otherProps}>
      {children}
    </div>
  );
}

TabNavigationTab.displayName = 'TabNavigationTab';
TabNavigationTab.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object, PropTypes.element]),
};

export default TabNavigationTab;