import React from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';

import Label from '../Label';
import InputHelp from '../InputHelp';
import InputError from '../InputError';

import { classNames } from '../../utils/css';

import styles from './InputGroup.module.scss';

class InputGroup extends React.Component {
  constructor(props) {
    super(props);

    this.htmlId = props.id || nextId(`ds${this.constructor.name}`);
  }

  render() {
    const { id, className, label, labelFor, children, i18n_inputgroup_helpText, i18n_inputgroup_errorMessage, ...otherProps } = this.props;

    let isLabels = false;
    if (!label) {
      for (let i = 0; i < children.length; i += 1) {
        if (children[i].props.label !== undefined && children[i].props.label !== null) {
          isLabels = true;
        }
      }
    }

    const mainElements = [];
    let currentElement = null;

    for (let i = 0; i < children.length; i += 1) {
      let mainElement = null;
      currentElement = children[i];
      let popoverClass = null;

      React.Children.forEach(currentElement, (elem) => {if(elem.props.triggerElement && elem.props.placement) popoverClass = styles.popoverelement});

      const buttonClass = currentElement.type.displayName === "Button" || currentElement.type.name === "Button" ? styles.buttonelement : popoverClass;

      if(typeof currentElement.type !== "string"){
        if(i === 0) {
          if(typeof children[i + 1].type !== "string"){
            mainElement = <div key={`${this.htmlId}${i}`} className={classNames([styles.firstelement, buttonClass])}>{currentElement}</div>;
          }
          else {
            mainElement = <div key={`${this.htmlId}${i}`} className={classNames([styles.regularelement, buttonClass])}>{currentElement}</div>;
          }
        }
        else if(i === children.length - 1) {
          if(typeof children[i - 1].type === "string"){
            mainElement = <div key={`${this.htmlId}${i}`} className={classNames([styles.regularelement, buttonClass])}>{currentElement}</div>;
          }
          else {
            mainElement = <div key={`${this.htmlId}${i}`} className={classNames([styles.lastelement, buttonClass])}>{currentElement}</div>;
          }
        }
        else if(typeof children[i - 1].type !== "string" && children[i + 1] && typeof children[i + 1].type !== "string"){
            mainElement = <div key={`${this.htmlId}${i}`} className={classNames([styles.innerelement, buttonClass])}>{currentElement}</div>;
          }
          else if(typeof children[i + 1].type !== "string"){
            mainElement = <div key={`${this.htmlId}${i}`} className={classNames([styles.firstelement, buttonClass])}>{currentElement}</div>;
          }
          else if(typeof children[i - 1].type !== "string"){
            mainElement = <div key={`${this.htmlId}${i}`} className={classNames([styles.lastelement, buttonClass])}>{currentElement}</div>;
          }
          else {
            mainElement = <div key={`${this.htmlId}${i}`} className={classNames([styles.innerelement, buttonClass])}>{currentElement}</div>;
          }
      }
      else {
        mainElement = <div key={`${this.htmlId}${i}`} className={styles.spanelement}>{currentElement}</div>;
      }

      mainElements.push(mainElement);
    }

    const allClasses = classNames([
      styles.inputgroup,
      className || null,
      label ? styles.inputgroup__withlabel : null,
      !isLabels ? styles.inputgroup__nolabels : null
    ]);

    const componentId = this.htmlId;

    return (
      <div id={componentId} className={allClasses} {...otherProps}>
        <Label id={`${componentId}Label`} labelFor={labelFor}>
          {label}
        </Label>
        <div className={styles.inputgroup__inputcontainer}>{mainElements}</div>
        {i18n_inputgroup_errorMessage ? (
          <InputError className={styles[`inputgroup--errormessage`]} id={`${componentId}Error`} ariaDescribedby={labelFor}>
            {i18n_inputgroup_errorMessage}
          </InputError>
        ) : null}
        {i18n_inputgroup_helpText ? (
          <InputHelp id={`${componentId}Help`} className={styles[`inputgroup--helptext`]}>
            {i18n_inputgroup_helpText}
          </InputHelp>
        ) : null}
      </div>
    );
  }
}

InputGroup.propTypes = {
  /** Element's id */
  id: PropTypes.string,
  /** Class names the user can give to the component */
  className: PropTypes.string,
  /** InputGroup's label */
  label: PropTypes.string,
  /** The id of the input the group's label points to. This is the input that gains focus when user clicks the label. */
  labelFor: PropTypes.string,
  /** Child elements and components */
  children: PropTypes.node,
  /** InputGroup's helptext */
  i18n_inputgroup_helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** InputGroup's errormessage */
  i18n_inputgroup_errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

InputGroup.defaultProps = {
  id: null,
  className: null,
  label: null,
  labelFor: null,
  children: null,
  i18n_inputgroup_helpText: null,
  i18n_inputgroup_errorMessage: '',
};

export default InputGroup;
