import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { renderToString } from 'react-dom/server'

import PropTypes from 'prop-types';

import { classNames } from '../../utils/css';
import { useIdWithFallback } from '../../utils/hooks';
import { waitForElm } from '../../utils/elementMutation';
import { KEY_DOWN, KEY_END, KEY_ESC, KEY_HOME, KEY_RETURN, KEY_SHIFT, KEY_SPACE, KEY_TAB, KEY_UP } from '../../utils/keycodes';
import IconArrowDownRegular from '../Icon/lib/IconArrowDownRegular';
import IconCloseFilled from '../Icon/lib/IconCloseFilled';
import styles from './Combobox.module.scss';

function ComboboxInternal(
  {
    id = null,
    className = null,
    label = null,
    name = null,
    items = null,
    options = null,
    i18n_combobox_buttonAriaLabel = 'Etsi ja valitse vaihtoehto',
    i18n_combobox_errorMessage = '',
    i18n_combobox_infoText = null,
    i18n_combobox_helpText = null,
    i18n_combobox_placeholderText = null,
    i18n_combobox_optionalText = '(ei pakollinen)',
    i18n_combobox_clearFieldTitle = 'Tyhjennä kenttä',
    optional = false,
    selectedValue = null,
    selectedItemFormat = "html",
    onValueChange = () => {},
    onValueSelect = () => {},
    integrated = false,
    onOptionsHide = () => {},
    onOptionsShow = () => {},
    disabled = false,
    readonly = false,
    selectRef = null,
    ...otherProps
  },
  ref
){
  const comboboxRef = useRef(null);
  const valueRef = useRef(selectedValue);
  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const inputClearRef = useRef(null);
  const listboxRef = useRef(null);
  const htmlId = useIdWithFallback('dsCombobox', id);
  const [activeIndex, setActiveIndex] = useState(-1)
  const [shown, setShown] = useState(false);
  const [disabledState, setDisabledState] = useState(disabled);
  const [readonlyState, setReadonlyState] = useState(readonly);
  const [selectedItem, setSelectedItem] = useState(selectedValue);
  const [selectedFullItem, setSelectedFullItem] = useState(items.filter(obj => obj.value === selectedValue));
  const [currentSelection, setCurrentSelection] = useState(items || options);
  const [currentItems, setCurrentItems] = useState(items || options);
  const [errorMessage, setErrorMessage] = useState(i18n_combobox_errorMessage);
  const [activeDescendantId, setActiveDescendantId] = useState(selectedValue ? `${htmlId}Option${selectedValue.replace(/ /g,'')}` : null);

  const showListbox = () => {
    setShown(true);
    waitForElm(`#${inputRef.current.id}`).then((elm) => {
      elm.focus();
    });    
    onOptionsShow();
  };

  const hideListbox = () => {
    if (shown) {
      inputRef.current.value = null;
      setCurrentSelection(currentItems);
      setShown(false);
      onOptionsHide();
    }
    onOptionsHide(comboboxRef);
  };

  // Functions visible to outside.
  useImperativeHandle(ref, () => ({
    value: (itemValue) => {
      // Select item only if it is in currentSelection
      if (typeof(itemValue) === "string" && currentItems.includes(itemValue)) {
        valueRef.current.value = itemValue;
        setSelectedItem(itemValue);
        onValueSelect(itemValue);
      }
      else if (itemValue !== undefined && itemValue !== "" && currentItems.find(obj => obj.value === itemValue)) {
        setSelectedItem(itemValue);
        valueRef.current.value = itemValue;
      }
      else {
        setSelectedItem(null);
        valueRef.current.value = null;
      }
    },

    getValue: () => selectedItem,

    itemList: (newItems) => {
      if(newItems){
        const selection = newItems || [];
        if(!selection.includes(selectedItem) && newItems.filter(obj => obj.value === selectedItem).length < 1){
          setSelectedItem(null);
          valueRef.current.value = null;
        }
        setCurrentSelection(selection);
        setCurrentItems(selection);
      }
      else {
        return currentItems;
      }
    },

    disabled: (state) => {
      if(state === true || state === false){
        setDisabledState(state);
      }
      else {
        return disabledState;
      }
    },

    readonly: (state) => {
      if(state === true || state === false) {
        setReadonlyState(state);
      }
      else {
        return readonlyState;
      }
    },

    errorMessage: (message) => {
      if(typeof(message) === 'string') {
        setErrorMessage(message);
      }
      else {
        return errorMessage;
      }
    },
  }));

  const createOptions = (items) => {
    const cbOptions = [];
    const newOptions = items || options;

    if(i18n_combobox_placeholderText){
      cbOptions.push(<li className={styles.combobox__option} role='option' id={`${htmlId}OptionPlaceholder`} key={`${htmlId}OptionPlaceholder`} data-value={null} onClick={selectOption} onKeyDown={selectOption}>
         {i18n_combobox_placeholderText}
       </li>);
     }

     for (let i = 0; i < newOptions.length; i += 1) {
      const option = newOptions[i];
      cbOptions.push(createOption(option, i));
    }

    return cbOptions;
  }

  const createOption = (item, index) => {
    let optionClasses;
    let dataValue;
    let ariaSelected;
    let content;
    let listItem;
    let role;
    const isDisabled = item.disabled;
    let iId = null;

    if(item.id) {
      iId = item.id;
    }
    else if(typeof item === 'string') {
        iId = `${htmlId}Option${item.replace(/ /g,'')}`;
      }
      else if(item.value) {
        iId = `${htmlId}Option${item.value || index}`;
      }
      else {
        iId = `${htmlId}OptionTitle${item.value || index}`;
      }

    if (typeof item === 'object') {
      optionClasses = classNames([
        styles.combobox__option,
        isDisabled ? styles['combobox__option--disabled'] : null,
        !item.value ? styles.combobox__option__title : null,
        !isDisabled && item.value && selectedItem === item.value ? styles['combobox__option--selected'] : null,
      ]);

      role = item.value ? 'option' : 'title';
      dataValue = item.value;
      ariaSelected = selectedItem === item.value ? 'true' : 'false';
      content = item.html || item.label;

      listItem = (
        <li className={optionClasses} role={role} id={iId} key={iId} data-value={dataValue} aria-label={item.label || null} aria-selected={ariaSelected} onClick={!isDisabled ? selectOption : undefined} onKeyDown={!isDisabled ? selectOption : undefined}>
          {content}
        </li>
      );
    }
    // For migration purposes we support the old kind of dropdowns from React Patterns library
    else {
      optionClasses = classNames([styles.combobox__option, selectedItem === item ? styles['combobox__option--selected'] : null]);

      role = 'option';
      dataValue = item;
      ariaSelected = selectedItem === item ? 'true' : 'false';
      content = item;

      listItem = (
        <li className={optionClasses} role={role} id={iId} key={iId} data-value={dataValue} aria-selected={ariaSelected} onClick={!isDisabled ? selectOption : undefined} onKeyDown={!isDisabled ? selectOption : undefined}>
          {content}
        </li>
      );
    }

    return listItem;
  }

  const selectOption = (e) => {
    const newSelection = e.currentTarget;
    const newValue = newSelection.dataset.value;

    if(onValueSelect) {
      onValueSelect(newSelection);
    }

    hideListbox();
  }

  const checkHide = (evt) => {
    if (evt.target === comboboxRef.current || comboboxRef.current?.contains(evt.target)) {
      evt.preventDefault();
      return;
    }

    if(shown){
      hideListbox();
    }
  };

  const checkKey = (evt) => {
    const {key} = evt;

    switch (key) {
      case KEY_ESC:
        evt.preventDefault();
        onValueSelect();
        return;
      case KEY_UP:
      case KEY_DOWN:
      case KEY_RETURN:
      case KEY_TAB:
      case KEY_SPACE:
      case KEY_SHIFT:
      case KEY_HOME:
      case KEY_END:
        evt.preventDefault();
        return;
      default:
        updateResults(true);
    }
  };

  const selectItem = (item) => {
    if (item) {
      valueRef.current.value = item.dataset.value;
      setSelectedItem(item.dataset.value);
      setSelectedFullItem(items.filter(obj => obj.value === item.dataset.value));
      setActiveDescendantId(item.id);
      hideListbox();
      onValueSelect(item);
    }
  };

  const setActiveItem = (evt) => {
    showListbox();
    const {key} = evt;
    const resultsCount = currentSelection.length;
    let currentIndex = activeIndex;

    if (key === KEY_ESC) {
      reset();
      return;
    }

    if (resultsCount < 1) {
        return;
    }

    let prevActive = null;
    if (activeIndex >= 0) {
      prevActive = getItemAt(activeIndex);
    }
     
    let activeItem;

    switch (key) {
      case KEY_UP:
        if (activeIndex <= 0) {
          currentIndex = resultsCount - 1;
        } else {
          currentIndex -= 1;
        }
        break;
      case KEY_DOWN:        
        if (activeIndex === null || activeIndex === -1 || activeIndex >= resultsCount - 1) {
          currentIndex = 0;
        } else {
          currentIndex = activeIndex + 1;
        }
        break;
      case KEY_RETURN:
      case KEY_SPACE:
        activeItem = getItemAt(activeIndex);
        selectItem(activeItem);
        return;
      case KEY_HOME:
        activeItem = getItemAt(0);
        selectItem(activeItem);
        return;
      case KEY_END:
        activeItem = getItemAt(resultsCount - 1);
        selectItem(activeItem);
        return;
      default:
        return;
    }
    evt.preventDefault();

    activeItem = getItemAt(currentIndex);
    setActiveIndex(currentIndex);

    if (prevActive) {
      prevActive.classList.remove(styles[`combobox__option--active`]);
      prevActive.setAttribute('aria-selected', 'false');
    }

    if (activeItem) {
      activeItem.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
      setActiveDescendantId(activeItem.id);
      activeItem.classList.add(styles[`combobox__option--active`]);
      activeItem.setAttribute("aria-selected", "true");
      valueRef.current.setAttribute("aria-activedescendant", activeDescendantId);
    } else {
      valueRef.current.removeAttribute("aria-activedescendant");
    }
  };

  const clickItem = (evt) => {
    if(evt.target && evt.target.closest('li[class*=combobox__option]')){
      selectItem(evt.target.closest('li[class*=combobox__option]'));
    }
  };

  const searchFn = (searchString) => {
    const searchStr = searchString.toLowerCase();
    const foundItems = [];
    const objects = currentItems;
    const temporaryList = [];

    for (let i = 0; i < objects.length; i += 1) {
      let texContent = "";

      if (objects[i].label && !objects[i].html) {
        texContent = objects[i].label.toLowerCase();
        const position = texContent.indexOf(searchStr);

        if (position >= 0) {
          temporaryList.push({position, itemIndex: i});
        }
      }
      else if (objects[i].html) {
        if (objects[i].label) {
          texContent = objects[i].label.toLowerCase();
        }
        else {
          const regex = /(<([^>]+)>)/ig;
          const body = renderToString(objects[i].html);
          texContent = body.replace(regex, "").toLowerCase();
        }

        const position = texContent.indexOf(searchStr);

        if (position >= 0) {
          temporaryList.push({position, itemIndex: i});
        }
      }
      else {
        texContent = objects[i].toLowerCase();
        const position = texContent.indexOf(searchStr);

        if (position >= 0) {
          temporaryList.push({position, itemIndex: i});
        }
      }      
    }

    if (temporaryList.length) {
      temporaryList.sort((a, b) => a.position - b.position);

      for (let i = 0; i < temporaryList.length; i += 1) {
        foundItems.push(objects[temporaryList[i].itemIndex]);
      }
    }

    return foundItems;
  }

  const updateResults = (shouldShowAll) => {
    const searchString = inputRef.current.value;
    let results = [];

    if (!shouldShowAll && (!searchString || searchString === '')) {
      results = [];
    }
    else {
      setCurrentSelection(currentItems);
      results = currentItems;
    }

    if (searchString) {
      results = searchFn(searchString);
    }

    if (results.length > 0) {
      setCurrentSelection(results);
    } else {
      setCurrentSelection([]);
    }
  };

  const getItemId = (index) => {
    if(!currentSelection[index]) {
      return `${htmlId}Option${currentSelection[0].replace(/ /g,'')}`;
    }
    if(currentSelection[index] && !currentSelection[index].value) {
      return `${htmlId}Option${currentSelection[index].replace(/ /g,'')}`;
    }
    if(currentSelection[index].value) {
      return `${htmlId}Option${currentSelection[index].value.replace(/ /g,'')}`;
    }
    
      return null;
  };

  const getItemAt = (index) => {
    if(getItemId(index)) {
      return document.getElementById(getItemId(index));
    }
  };

  const getSelectedLabel = () => {
    let result = null;
    const cbitems = currentItems || items || options;

    if(cbitems && cbitems.length > 0) {
      for (let i = 0; i < cbitems.length; i += 1) {
        if (cbitems[i].value && cbitems[i].value === selectedItem && selectedItemFormat === "html") {
          result = cbitems[i].html || cbitems[i].label;
        }
        else if (cbitems[i].value && cbitems[i].value === selectedItem && selectedItemFormat === "label") {
          result = cbitems[i].label;
        }
        else if (cbitems[i] === selectedItem) {
          result = cbitems[i];
        }
      }
      return result;
    }

    return null;
  }

  const reset = () => {
    hideListbox();
    setTimeout(() => {
      // On Firefox, input does not get cleared here unless wrapped in a setTimeout
      inputRef.current.value = "";
    }, 1);
    inputRef.current.removeAttribute("value");
    setDisabledState(false);
  };

  const handleValueChange = () => {
    onValueChange(inputRef.current ? inputRef.current.value : null);
  };

  const toggleOptions = (e) => {
    e.stopPropagation();
    if (shown) {
      hideListbox();      
    } else {
      showListbox();
    }
  }

  const handleInputClear = (e) => {
    if(!e.key || (e.key && e.key === KEY_RETURN)){
      inputRef.current.focus();
      inputRef.current.value = null;
      setCurrentSelection(currentItems);      
    }
  }

  useEffect(() => {
    valueRef.current.value = selectedValue;
    setSelectedItem(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    setCurrentItems(items || options);
    setCurrentSelection(items || options);
  }, [items, options]);

  useEffect(() => {
    setErrorMessage(i18n_combobox_errorMessage);
  }, [i18n_combobox_errorMessage]);

  useEffect(() => {
    setDisabledState(disabled);
  }, [disabled]);

  useEffect(() => {
    setReadonlyState(readonly);
  }, [readonly]);

  useEffect(() => {
    document?.addEventListener('click', checkHide);
    inputRef.current?.addEventListener('keyup', checkKey);
    inputRef.current?.addEventListener('keydown', setActiveItem);
    inputClearRef.current?.addEventListener('click', handleInputClear);
    inputClearRef.current?.addEventListener('keydown', handleInputClear);
    listboxRef.current?.addEventListener('click', clickItem);
    
    return () => {
      document?.removeEventListener('click', checkHide);
      inputRef.current?.removeEventListener('keyup', checkKey);
      inputRef.current?.removeEventListener('keydown', setActiveItem);
      inputClearRef.current?.removeEventListener('click', handleInputClear);
      inputClearRef.current?.removeEventListener('keydown', handleInputClear);
      listboxRef.current?.removeEventListener('click', clickItem);
    };
  });

  let optionalIndicator = null;
  if (optional === true) {
    optionalIndicator = i18n_combobox_optionalText;
  }

  let cbLabel = null;
  if (label) {
    const LabelComponent = require('../Label').default;
    cbLabel = <LabelComponent labelFor={`${htmlId}ComboboxArea`}>{label}</LabelComponent>;
  }

  let cbInfo = null;
  if (i18n_combobox_infoText) {
    const PopoverComponent = require('../Popover').default;
    const IconInformationRegularComponent = require('../Icon/lib/IconInformationRegular').default;
    cbInfo = <PopoverComponent triggerElement={<IconInformationRegularComponent />} placement="top">{i18n_combobox_infoText}</PopoverComponent>
  }

  let cbError = null;
  if (errorMessage) {
    const ErrorComponent = require('../InputError').default;
    cbError = <ErrorComponent className={styles.combobox__errormessage} id={`${htmlId}Error`}>{errorMessage}</ErrorComponent>;
  }

  let cbHelp = null;
  if (i18n_combobox_helpText) {
    const HelpComponent = require('../InputHelp').default;
    cbHelp = <HelpComponent className={styles.combobox__helptext} id={`${htmlId}Help`}>{i18n_combobox_helpText}</HelpComponent>;
  }

  const classes = classNames([
    styles.combobox,
    className || null,
    errorMessage || i18n_combobox_errorMessage !== '' ? styles[`combobox-error`] : null,
    disabledState ? styles[`combobox-disabled`] : null,
    readonlyState ? styles[`combobox-readonly`] : null,
    integrated ? styles[`combobox-integrated`] : null]);

  return (
    <div id={htmlId} className={classes} role="group" {...otherProps}>
      { cbLabel ?
        <div className={styles.combobox__labelarea}>
          {cbLabel} {optionalIndicator} {cbInfo}
        </div>
      : null }

      <div id={`${htmlId}ComboboxArea`} className={styles[`combobox--inputarea`]} ref={comboboxRef}>
        <input
          type="text"
          id={`${htmlId}Value`}
          className={styles[`combobox--valueinput`]}
          ref={valueRef}
          name={name}
          defaultValue={selectedItem}
          aria-errormessage={`${htmlId}Error`}
          aria-activedescendant={activeDescendantId}
          required={!optional}
          readOnly
        />

        <button
          ref={selectRef}
          id={`${htmlId}Button`}
          type="button"
          className={styles.combobox__button}
          role="combobox"
          aria-labelledby={`${htmlId}Label ${htmlId}Button`}
          aria-expanded={shown}
          aria-controls={`${htmlId}Listbox`}
          aria-readonly={readonlyState}
          aria-disabled={disabledState}
          aria-label={i18n_combobox_buttonAriaLabel || i18n_combobox_placeholderText}
          onClick={toggleOptions}
          onKeyDown={setActiveItem}
        >
          {selectedItem ? getSelectedLabel() : i18n_combobox_placeholderText}
          <IconArrowDownRegular />
        </button>

        <div
          id={`${htmlId}Listbox`}
          className={styles.combobox__listbox}
          ref={listboxRef}
          role="listbox"
          data-show={shown}
          aria-expanded={shown}
        >
          <input
            type="text"
            role="combobox"
            id={`${htmlId}Input`}
            className={styles[`combobox__listbox-input`]}
            ref={inputRef}
            name={name}
            aria-autocomplete="none"
            aria-labelledby={`${htmlId}Label ${htmlId}Button`}
            onChange={handleValueChange}
            disabled={disabledState || null}
            readOnly={readonlyState || null}
            aria-errormessage={`${htmlId}Error`}
          />
          {inputRef.current?.value !== '' ? (
            <button
              ref={inputClearRef}
              id={`${htmlId}InputClearButton`}
              type="button"
              className={styles[`combobox--input-clear`]}
              tabIndex="0"
            >
              <IconCloseFilled size="s" title={i18n_combobox_clearFieldTitle} />
            </button>
          ) : null}

          <ul
            id={`${htmlId}Options`}
            className={styles.combobox__options}
            aria-labelledby={`${htmlId}Label`}
          >
            {createOptions(currentSelection)}
          </ul>
        </div>
        {cbError}
        {cbHelp}
      </div>
    </div>
  );
};

const Combobox = React.forwardRef(ComboboxInternal);
export default Combobox;

Combobox.propTypes = {
  /**
   * Id of the component
   */
  id: PropTypes.string,
   /**
    * Class names you want to give to the component
    */
  className: PropTypes.string,
   /**
    * Label text for the component
    */
  label: PropTypes.string,
   /**
    * Name of the form control. Submitted with the form as part of a name/value pair.
    * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefname)
    */
  name: PropTypes.string,
   /**
    * List of combobox's options
    */
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
        html: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object])
      })
    )
  ]),
   /**
    * Whether the selected item's HTML or label is shown as the selected content
    */
  selectedItemFormat: PropTypes.oneOf(['html', 'label']),
   /**
    * Selected option's value
    */
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

  i18n_combobox_buttonAriaLabel: PropTypes.string,
   /**
    * Placeholder text for the component
    */
  i18n_combobox_placeholderText: PropTypes.string,
   /**
    * Error message for the combobox
    */
  i18n_combobox_errorMessage: PropTypes.string,
   /**
    * Additional information and instructions for the field
    */
  i18n_combobox_infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
   /**
    * Always visible help text for the field
    */
  i18n_combobox_helpText: PropTypes.string,
   /**
    * Optional field indicator text
    */
  i18n_combobox_optionalText: PropTypes.string,
   /**
     * Title of clear button in field
     */
  i18n_combobox_clearFieldTitle: PropTypes.string,
   /**
    * Whether field is optional or not. By default all fields are mandatory.
    */
  optional: PropTypes.bool,
   /**
    * Function that runs when input's value is changed
    */
  onValueChange: PropTypes.func,
  /**
    * Function that runs when option is selected
    */
  onValueSelect: PropTypes.func,
   /**
    * Whether to use combobox as integrated part of another component/pattern
    */
  integrated: PropTypes.bool,
   /**
    * Function that runs when options are hid
    */
  onOptionsHide: PropTypes.func,
   /**
    * Function that runs when options are shown
    */
  onOptionsShow: PropTypes.func,
  /**
   * Whether the combobox is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Whether the combobox is readonly
   */
  readonly: PropTypes.bool,
  /**
   * Ref for the opening element
   */
  selectRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.element })]),
};
