// @flow
import React, { type Element, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import { useTranslation } from 'react-i18next';
import SearchInternalDestination from './SeachInternalDestination';
import { destinationFieldOptions } from './DestinationFieldUtils';
import PhoneNumberInputField from './PhoneNumberInputField';
import type { DestinationFieldT } from './DestinationFieldUtils';
import Tooltip from '../../../../../../components/Tooltip';

import styles from './DestinationField.module.scss';

export type PropsT = {|
  field: string,
  title: string,
  tooltip?: string,
  description?: string,
  // eslint-disable-next-line flowtype/no-weak-types
  hiddenFn?: any => boolean,
  noEndTransfer?: boolean,
  notInUseAvailable?: boolean,
  containerStyle?: string
|};

export const DestinationField = (props: PropsT): Element<'div'> => {
  const {
    field,
    title,
    description,
    noEndTransfer,
    containerStyle,
    notInUseAvailable,
    tooltip,
    hiddenFn
  } = props;

  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();
  const value: DestinationFieldT = values[field];

  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  const transferOptions: { value: string, label: string }[] = useMemo(
    () => destinationFieldOptions(t, noEndTransfer, notInUseAvailable),
    [activeLanguage] // eslint-disable-line
  );
  const hideBoolean: boolean = hiddenFn === undefined ? false : hiddenFn(values);
  const foundOption = transferOptions.find(o => o.value === value.type);
  const currentComboboxValue: string = foundOption ? foundOption.value : transferOptions[0].value;
  const descriptionMsg: string =
    description === undefined ? t('callflows.forwardingField.description') : description;

  return !hideBoolean ? (
    <div className={containerStyle} data-cy={`forwarding-field-${field}`}>
      <div>
        <div className={styles.title}>
          {title}
          {tooltip ? <Tooltip containerStyle={styles.tooltip}>{tooltip}</Tooltip> : null}
        </div>
        {descriptionMsg && <div className={styles.description}>{descriptionMsg}</div>}
      </div>
      <div className={styles.columns}>
        <div>
          {t('callflows.forwardingField.transferCalls')}
          <Dropdown
            items={transferOptions}
            selectedValue={currentComboboxValue}
            className={styles['target-options']}
            onValueChange={option => {
              setValue(
                field,
                {
                  ...values[field],
                  type: option.dataset.value,
                  visualValue: '',
                  value: ''
                },
                {
                  shouldValidate: true,
                  shouldDirty: true
                }
              );
            }}
          />
        </div>
        {value.type === 'TRANSFER_EXTERNAL' && <PhoneNumberInputField field={field} />}
        {value.type === 'TRANSFER_INTERNAL' && (
          <SearchInternalDestination
            placeholder="Search"
            errors={errors[field]}
            initialValue={value.visualValue || ''}
            handleOnTargetSelected={(addressNumber, visualValue) => {
              setValue(
                field,
                {
                  ...value,
                  value: addressNumber,
                  visualValue
                },
                {
                  shouldValidate: true,
                  shouldDirty: true
                }
              );
            }}
          />
        )}
      </div>
    </div>
  ) : (
    <div />
  );
};

export default DestinationField;
