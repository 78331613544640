// @flow
import React, { type Element, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import DirectorySelectorFormSmall from './DirectorySelectorFormSmall';
import DirectorySelectorForm from './DirectorySelectorForm';
import type { DirectoryStateEntityT } from '../../ducks/entities/directory';
import useEnterpriseUsers from './useEnterpriseUsers';
import styles from './DirectorySelector.module.scss';

export type PropsT = {|
  field: string,
  title: string,
  description?: string,
  enterpriseId: string,
  shouldValidate?: boolean,
  maxSelected?: (*) => number,
  hideInitialSelection?: boolean,
  forceSmall?: boolean
|};

export const DirectorySelector = (props: PropsT): Element<'div'> => {
  const {
    field,
    title,
    description,
    enterpriseId,
    shouldValidate,
    maxSelected,
    hideInitialSelection,
    forceSmall
  } = props;

  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();

  // State
  const [searchTerm, setSearchTerm] = useState('');
  const initialSelectedUsers = values[field];

  const { enterpriseUsers, isLoadingEnterpriseUsers } = useEnterpriseUsers(
    enterpriseId,
    searchTerm
  );

  return (
    <div className={styles.container}>
      <div className={styles['field-section']}>
        <div className={styles.title}>{title}</div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <div className={styles['field-section']}>
        <div id="member-selector-box" className={styles['selector-input']}>
          {forceSmall ? (
            <DirectorySelectorFormSmall
              searchResults={enterpriseUsers}
              isLoadingSearchResults={isLoadingEnterpriseUsers}
              onSearchTermChange={newSearchTerm => setSearchTerm(newSearchTerm)}
              onSearchTermClear={() => setSearchTerm('')}
              onSelectionChange={(selectedUsers: DirectoryStateEntityT[]) => {
                setValue(field, selectedUsers, {
                  shouldValidate: shouldValidate === undefined ? true : shouldValidate,
                  shouldDirty: true
                });
              }}
              preselection={initialSelectedUsers}
              hideInitialSelection={hideInitialSelection}
              errorMessage={errors[field] ? errors[field].message : ''}
              maxSelected={maxSelected && maxSelected(values)}
            />
          ) : (
            <DirectorySelectorForm
              searchResults={enterpriseUsers}
              isLoadingSearchResults={isLoadingEnterpriseUsers}
              onSearchTermChange={newSearchTerm => setSearchTerm(newSearchTerm)}
              onSearchTermClear={() => setSearchTerm('')}
              onSelectionChange={(selectedUsers: DirectoryStateEntityT[]) => {
                setValue(field, selectedUsers, {
                  shouldValidate: shouldValidate === undefined ? true : shouldValidate,
                  shouldDirty: true
                });
              }}
              preselection={initialSelectedUsers}
              hideInitialSelection={hideInitialSelection}
              errorMessage={errors[field] ? errors[field].message : ''}
              maxSelected={maxSelected && maxSelected(values)}
              selectedOrderable
              onReOrderUsers={(selectedUsers: DirectoryStateEntityT[]) => {
                setValue(field, selectedUsers, {
                  shouldValidate: shouldValidate === undefined ? true : shouldValidate,
                  shouldDirty: true
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DirectorySelector;
