import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';

import Popover from '../Popover';
import IconInformationRegular from '../Icon/lib/IconInformationRegular';

import { classNames } from '../../utils/css';
import { useIdWithFallback } from '../../utils/hooks';

import styles from './Radio.module.scss';

const radioGroupContext = React.createContext('DS-RadioGroup');
const { Provider, Consumer } = radioGroupContext;

function RadioInternal(
  {
    className = null,
    id = null,
    label = '',
    children = null,
    value = null,
    checked = false,
    name = '',
    disabled = null,
    onChange = () => {},
    onBlur = () => {},
    labelClassName = null,
    required = null,
    inputRef = null,
    ...otherProps
  },
  ref,
) {
  console.warn("Radio component is deprecated. Use RadioGroup instead. Radio will be removed in a future version (during summer 2024).");

  const content = label || children;
  // const { contextOfRadioGroup } = useContext(radioGroupContext);
  const contextOfRadioGroup = useContext(radioGroupContext);
  const htmlId = useIdWithFallback('dsRadio', id);

  const onInputChange = (e) => {
    // onChange && onChange(e);
    // contextOfRadioGroup.onChange(e);
    if (onChange) {
      onChange(e);
    }
    contextOfRadioGroup.onChange(e);
  };

  return (
    <Consumer>
      {({ checked, checkedValue, groupName, groupDisabled }) => {
        const allClasses = classNames([styles.radio, disabled || groupDisabled ? styles[`radio--disabled`] : null, className]);
        const labelClasses = classNames([styles.radio__label, labelClassName]);

        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div id={`${htmlId}Container`} className={allClasses} {...otherProps}>
            <input
              ref={inputRef}
              type="radio"
              id={htmlId}
              name={name || groupName || htmlId} value={value || ''}
              checked={checked || value === checkedValue}
              onChange={onInputChange}
              disabled={disabled || groupDisabled}
              required={required}
            />
            <label className={labelClasses} htmlFor={htmlId}>
              <span className={styles.radio__icon} />
              {content}
            </label>
          </div>
        );
      }}
    </Consumer>
  );
};

/**
 * @deprecated Radio component is deprecated. Use RadioGroup instead. Radio will be removed in a future version (during summer 2024).
 */
const Radio = React.forwardRef(RadioInternal);

Radio.propTypes = {
  /** Radio button's id */
  id: PropTypes.string,
  /** Any CSS classes for the component */
  className: PropTypes.string,
  /** Radio's label text */
  label: PropTypes.string,
  /** Component's content. Automatically detected. */
  children: PropTypes.node,
  /** Radio's value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** If present, indicates that this radio button is the default selected one in the group. */
  checked: PropTypes.bool,
  /** Radio's name */
  name: PropTypes.string,
  /** The function run after the radio is checked or unchecked */
  onChange: PropTypes.func,
  /** Whether the Radio is disabled */
  disabled: PropTypes.bool,
  /** LEGACY (do not use) - Label's class */
  labelClassName: PropTypes.string,
  /** Read more: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#required */
  required: PropTypes.bool,
  /** Ref for the actual input element, not the wrapper */
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.element })]),
};

Radio.defaultProps = {
  id: null,
  className: null,
  label: null,
  children: null,
  value: false,
  checked: false,
  name: null,
  onChange: () => {},
  disabled: false,
  labelClassName: null,
  required: null,
  inputRef: null,
};

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);

    this.htmlId = props.id || nextId('dsRadioGroup');

    this.state = {
      value: props.value || null,
    };

    console.warn("Radio.RadioGroup component is deprecated. Use RadioGroup instead. Radio.RadioGroup will be removed in a future version (during summer 2024).");
  }

  onInputChange(e) {
    const { onChange } = this.props;
    this.setState({
      value: e.target.value,
    });
    onChange(e);
  }

  render() {
    const { id, name, label, i18n_radiogroup_infoText, className, disabled, children, onChange, ...otherProps } = this.props;
    const allClasses = classNames([styles[`radio-group`], disabled ? styles[`radio-group-disabled`] : null, className]);
    const { value } = this.state;

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <fieldset id={this.htmlId} role="group" className={allClasses} {...otherProps}>
        {label ? (
          <div className={styles[`radio-group--labelarea`]}>
            <legend className={styles[`radio-group--labelarea-label`]}>
              <span className={styles[`radio-group--labelarea-label-content`]}>{label}</span>
              {i18n_radiogroup_infoText ? <Popover triggerElement={<IconInformationRegular />} placement="top" i18n_popover_contentText={i18n_radiogroup_infoText} /> : null}
            </legend>
          </div>
        ) : null}
        <Provider
          value={{
            checkedValue: value,
            groupName: name,
            groupDisabled: disabled,
            onChange: this.onInputChange.bind(this),
          }}
        >
          {children}
        </Provider>
      </fieldset>
    );
  }
}

/**
 * @deprecated Radio.RadioGroup component is deprecated. Use RadioGroup instead. Radio.RadioGroup will be removed in a future version (during summer 2024).
 */
RadioGroup.propTypes = {
  /** Group's id */
  id: PropTypes.string,
  /** Any CSS classes for the component */
  className: PropTypes.string,
  /** Label text for the radio group */
  label: PropTypes.string,
  /** Additional information and instructions for the field */
  i18n_radiogroup_infoText: PropTypes.string,
  /** Component's content. Automatically detected. */
  children: PropTypes.node,
  /** Radio's default value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Radio's name for all in the group */
  name: PropTypes.string,
  /** The function run after any Radio in the group is checked or unchecked */
  onChange: PropTypes.func,
  /** Whether the all the Radios in the group are disabled */
  disabled: PropTypes.bool,
};

RadioGroup.defaultProps = {
  id: null,
  className: null,
  label: null,
  i18n_radiogroup_infoText: null,
  children: null,
  value: null,
  name: '',
  onChange: () => {},
  disabled: null,
};

Radio.Group = RadioGroup;

export { RadioGroup };
export default Radio;
